import React, { Component, Fragment, useState } from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
// import { Label } from '@material-ui/icons';
import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// &.focused {
//   box-shadow: 0 20px 24px 0 rgba(0, 0, 0, 0.12);
//   border-bottom-left-radius: 0;
//   border-bottom-right-radius: 0;
// }
const InputWrapper = styled("div")`
  &.custom-search {
    border: none;
    background-color: var(--input-bg);
    border-radius: 8px;
    padding: 7px;
    // display: inline-block;
    // width: 93%;
    display: flex;
    flex-wrap: wrap;

    &:hover {
      border-color: none;
    }

    & input {
      font-size: 14px !important;
      background: transparent;
      box-sizing: border-box;
      width: 0;
      color: var(--text-color);
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
      // font-family: "lato-bold";
    }
    .down-icon {
      fill: #999;
      font-size: 2em;
      cursor: pointer;
    }
  }
`;

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const Listbox = styled("ul")`
  padding: 0;
  position: absolute;
  width: 100%;
  list-style: none;
  font-size: 14px !important;
  // font-family: "lato-bold";
  border: 1px solid var(--background-color);
  background-color: var(--background-color-contrast);
  overflow: auto;
  color: var(--text-color);
  max-height: 150px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 20px 24px 0 rgba(0, 0, 0, 0.12);
  z-index: 3;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }

  & li {
    padding: 10px 10px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: var(--select-option-circle-color);
      font-size: 30px;
    }
  }

  & li[aria-selected="true"] {
    font-weight: 600;

    & svg {
      color: var(--select-option-selected-circle-color);
    }
  }

  & li[data-focus="true"] {
    background-color: transparent;
    cursor: pointer;

    & svg {
      color: var(--select-option-selected-circle-color);
    }
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <small onClick={onDelete} className="delete">
      x
    </small>
  </div>
))`
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 26px;
  font-size: 12px;
  white-space: nowrap;
  box-sizing: content-box;
  padding: 5px 10px;

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }

  & span {
    padding-right: 10px;
    color: var(--text-color);
  }

  & small {
    padding-left: 8px;
    font-size: 15px;
    color: #e9e9e9;
    border-left: 2px solid;
    cursor: pointer;
    line-height: 0;
    border-image: linear-gradient(to top, #e9e9e9, #fff) 1 100%;
  }
`;

const FcCustomSearch = (props) => {
  //const [open, setPopUpState] = useState(false);
  const [focus, setFocusState] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [initialOpen, setInitialOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const handleChange = (e, val) => {
    //setFocusState(false);
    //setOpen(true);
    const { optionValue, multiple, value } = props;

    let convertToBackendResult = [];
    if (multiple) {
      val.forEach((res) => convertToBackendResult.push(res[optionValue]));
    }
    if (field) {
      const {
        form: { setFieldValue },
        field: { name },
      } = props;

      setFieldValue(name, val);
    }
    const { onChangeSelect } = props;
    if (onChangeSelect) {
      const onChangeVal = multiple ? convertToBackendResult : val[optionValue];
      onChangeSelect(onChangeVal);
    }
  };
  const handleClose = () => {
    // setFocusState(false);
    setOpen(false);
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    //setOpen(true);
    if (newInputValue.length > 0 && initialOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setInitialOpen(true);
  };
  const handleOpen = () => {
    setOpen(!open);
    //console.log(inputRef);
    //document.getElementById("input1").focus();
  };
  const handleFocus = () => {};

  const {
    field,
    source,
    optionValue,
    optionLabel,
    label,
    multiple,
    placeholder,
    val,
  } = props;
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    value: field ? field.value : val,
    defaultValue: [],
    multiple: multiple,
    options: source,
    getOptionLabel: (option) => option[optionLabel],
    onChange: handleChange,
    //popupOpen: open,
    //openOnFocus: true,
    //focused: handleFocus,
    onClose: handleClose,
    open: open || false,
    inputValue: inputValue,
    //autoSelect: false,
    onInputChange: handleInputChange,
    disableCloseOnSelect: true,
    // onHighlightChange: handleOpen,
  });
  const removeFocus = () => {
    setFocusState(false);
  };

  const renderSelected = () => {
    const selectedLabel = source.filter((s) =>
      field.value.includes(s[optionValue])
    );
    return selectedLabel.map((res, index) => (
      <Tag key={index} label={res[optionLabel]} {...getTagProps({ index })} />
      //   <Fragment key={res[optionValue]}>
      //     <label>{res[optionLabel]} </label>
      //     <label onClick={() => removeSelected(res[optionValue])}>X</label>
      //   </Fragment>
    ));
  };
  const inputRef = React.useRef();

  return (
    <div className="relative">
      <div {...getRootProps()}>
        {/* <Label {...getInputLabelProps()}>{label}</Label> */}
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? "focused custom-search" : "custom-search"}
        >
          <input ref={inputRef} onClick={handleOpen} {...getInputProps()} />
          <ArrowDropDownIcon className="down-icon" onClick={handleOpen} />
        </InputWrapper>

        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li key={index} {...getOptionProps({ option, index })}>
                <span>{option[optionLabel]}</span>
                {/* <RadioButtonUncheckedIcon fontSize="small" /> */}
                <FiberManualRecordIcon fontSize="large" />
              </li>
            ))}
          </Listbox>
        ) : null}
        {/* {renderSelected()} */}
        <div className="bottom-selected">
          {multiple && value.length >= 1
            ? value.map((option, index) => (
                <Tag
                  key={index}
                  label={option[optionLabel]}
                  {...getTagProps({ index })}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default FcCustomSearch;
