import React, { Component } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
import { Grid, TextField } from "@material-ui/core";
import { FcCustomSearch } from "../../presentational";
import "./account-form.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class AccountForm extends Component {
  constructor(props) {
    super(props);
    const { user, editUserInfo, countries } = this.props;
    // this.editUserInitial = {
    //   name: user.name || "",
    //   email: user.email || "",
    //   city: {},
    //   countryId: countries.find((c) => c.id === user.countryId) || {},
    //   phone: user.phone || "",
    //   gender: genderDropDown.find((g) => g.value === user.gender) || {},
    // };
    // editUserInfo(this.editUserInitial);
  }

  handleCountryChange = (countryId) => {
    const { editUser, editUserInfo } = this.props;
    editUserInfo({ ...editUser, countryId });
  };

  handleGenderChange = (gender) => {
    const { editUser, editUserInfo } = this.props;
    editUserInfo({ ...editUser, gender });
  };

  handleTextChange = (e) => {
    const { editUser, editUserInfo } = this.props;
    const { name, value } = e.target;
    editUserInfo({ ...editUser, [name]: value });
  };

  saveAccountForm = (e) => {
    const { updateProfile } = this.props;
    updateProfile();
  };

  render() {
    const { t, countries, error, isLoading, editUser } = this.props;
    const genderDropDown = [
      { label: t("accountForm.option1"), value: "MALE" },
      { label: t("accountForm.option2"), value: "FEMALE" },
      { label: t("accountForm.option3"), value: "OTHERS" },
    ];

    const selectedCountry = countries.filter(
      (country) => country.id === parseInt(editUser.countryId)
    );
    const selectedGender = genderDropDown.filter(
      (gender) => gender.value === editUser.gender
    );

    return (
      <div>
        <Grid container spacing={0} className="m-0">
          <Grid item xs={12} sm={6} className="grd-frm pt-0">
            <label className="bold">{t("accountForm.label1")}</label>
            <TextField
              name="name"
              value={editUser.name}
              variant="outlined"
              onChange={this.handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grd-frm pt-0">
            <label className="bold">{t("accountForm.label2")}</label>
            <TextField
              name="email"
              value={editUser.email}
              variant="outlined"
              onChange={this.handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grd-frm">
            <label className="bold">{t("accountForm.label3")}</label>
            <TextField
              name="city"
              value={editUser.city}
              variant="outlined"
              onChange={this.handleTextChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grd-frm">
            <label className="bold">{t("accountForm.label4")}</label>
            <FcCustomSearch
              name="countryId"
              source={countries}
              multiple={false}
              optionLabel="name"
              optionValue="id"
              val={!!selectedCountry.length ? selectedCountry[0] : {}}
              onChangeSelect={this.handleCountryChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grd-frm">
            <label className="bold">{t("accountForm.label5")}</label>
            <TextField
              name="phone"
              defaultValue={editUser.phone}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grd-frm">
            <label className="bold">{t("accountForm.label6")}</label>
            <FcCustomSearch
              name="gender"
              multiple={false}
              source={genderDropDown}
              optionLabel="label"
              optionValue="value"
              val={!!selectedGender.length ? selectedGender[0] : {}}
              onChangeSelect={this.handleGenderChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grd-frm">
            <label className="bold">{t("accountForm.label7")}</label>
            <TextField
              name="waterMark"
              value={editUser.waterMark}
              variant="outlined"
              onChange={this.handleTextChange}
            />
          </Grid>
        </Grid>
        <span className="error">{error}</span>
        <Grid container>
          <Grid item xs={4}>
            <button
              className={"submitButton no-shadow"}
              type="submit"
              style={{ marginLeft: 10 }}
              onClick={this.saveAccountForm}
              disabled={isLoading}
            >
              {/* Save */}
              {!isLoading ? (
                t("accountForm.button1")
              ) : (
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              )}
            </button>
            {/* {isLoading && <CircularProgress />} */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

//export default AccountForm;
export default compose(withTranslation())(AccountForm);
