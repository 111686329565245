import React from "react";
import "./comment.scss";

const Comment = ({ width, height, className }) => (
  // <svg
  //   className={`svg-icon ${className || ""}`}
  //   width={width}
  //   height={height}
  //   viewBox="0 0 16 16"
  //   style={{ enableBackground: "new 0 0 16 16" }}
  //   xmlns="http://www.w3.org/2000/svg"
  //   xmlnsXlink="http://www.w3.org/1999/xlink"
  // >
  //   <path
  //     className="primary"
  //     d="M14.5,4.2c-0.7-0.9-1.7-1.5-2.7-1.9C10.6,1.8,9.3,1.6,8,1.6C7,1.6,6,1.7,5.1,2C4.3,2.3,3.5,2.7,2.7,3.1
  // c-0.6,0.5-1.2,1-1.6,1.7C0.7,5.5,0.5,6.2,0.5,6.9c0,0.8,0.3,1.7,0.7,2.4c0.5,0.7,1.2,1.4,2.1,1.8c-0.1,0.2-0.1,0.4-0.2,0.7
  // C3,12,3,12.2,2.9,12.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.2,0.3-0.3,0.3l-0.3,0.3l-0.3,0.3l-0.1,0.1l-0.1,0.1l-0.1,0.1
  // c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0,0c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.6-0.1,1-0.2
  // c1.4-0.4,2.7-1.1,3.8-2c0.4,0,0.8,0.1,1.2,0.1c1.3,0,2.6-0.2,3.7-0.7c1.1-0.4,2-1.1,2.7-2C15.8,8.1,15.8,5.8,14.5,4.2L14.5,4.2z"
  //   />
  // </svg>

  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 17 17"
    style={{ enableBackground: "new 0 0 17 17" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" strokeWidth=".3" fill="none" fillRule="evenodd" >
      <g id="Actions-/-website-/-post" transform="translate(-25.000000, -3.000000)" fill="#6C7B8A" fillRule="nonzero">
        <g id="chat-bubble" transform="translate(25.000000, 3.000000)">
          <g id="XMLID_46_">
            <path
              d="M0.566666667,17 C0.51,17 0.51,17 0.453333333,17 C0.34,16.9433333 0.283333333,16.83 0.283333333,16.7166667 L0.283333333,3.11666667 C0.283333333,1.41666667 1.7,0 3.4,0 L13.6,0 C15.3,0 16.7166667,1.41666667 16.7166667,3.11666667 L16.7166667,9.35 C16.7166667,11.05 15.3,12.4666667 13.6,12.4666667 L4.13666667,12.4666667 L0.85,16.8866667 C0.736666667,16.9433333 0.68,17 0.566666667,17 Z M3.4,0.566666667 C1.98333333,0.566666667 0.85,1.7 0.85,3.11666667 L0.85,15.8666667 L3.74,12.0133333 C3.79666667,11.9566667 3.85333333,11.9 3.96666667,11.9 L13.6,11.9 C15.0166667,11.9 16.15,10.7666667 16.15,9.35 L16.15,3.11666667 C16.15,1.7 15.0166667,0.566666667 13.6,0.566666667 L3.4,0.566666667 Z"
              id="Shape"
            ></path>
          </g>
          <path
            d="M4.91111111,7.93333333 C4.28777778,7.93333333 3.77777778,7.42333333 3.77777778,6.8 C3.77777778,6.17666667 4.28777778,5.66666667 4.91111111,5.66666667 C5.53444444,5.66666667 6.04444444,6.17666667 6.04444444,6.8 C6.04444444,7.42333333 5.53444444,7.93333333 4.91111111,7.93333333 Z M4.91111111,6.23333333 C4.57111111,6.23333333 4.34444444,6.46 4.34444444,6.8 C4.34444444,7.14 4.57111111,7.36666667 4.91111111,7.36666667 C5.25111111,7.36666667 5.47777778,7.14 5.47777778,6.8 C5.47777778,6.46 5.25111111,6.23333333 4.91111111,6.23333333 Z"
            id="Shape"
          ></path>
          <path
            d="M8.31111111,7.93333333 C7.68777778,7.93333333 7.17777778,7.42333333 7.17777778,6.8 C7.17777778,6.17666667 7.68777778,5.66666667 8.31111111,5.66666667 C8.93444444,5.66666667 9.44444444,6.17666667 9.44444444,6.8 C9.44444444,7.42333333 8.93444444,7.93333333 8.31111111,7.93333333 Z M8.31111111,6.23333333 C7.97111111,6.23333333 7.74444444,6.46 7.74444444,6.8 C7.74444444,7.14 7.97111111,7.36666667 8.31111111,7.36666667 C8.65111111,7.36666667 8.87777778,7.14 8.87777778,6.8 C8.87777778,6.46 8.65111111,6.23333333 8.31111111,6.23333333 Z"
            id="Shape"
          ></path>
          <path
            d="M11.7111111,7.93333333 C11.0877778,7.93333333 10.5777778,7.42333333 10.5777778,6.8 C10.5777778,6.17666667 11.0877778,5.66666667 11.7111111,5.66666667 C12.3344444,5.66666667 12.8444444,6.17666667 12.8444444,6.8 C12.8444444,7.42333333 12.3344444,7.93333333 11.7111111,7.93333333 Z M11.7111111,6.23333333 C11.3711111,6.23333333 11.1444444,6.46 11.1444444,6.8 C11.1444444,7.14 11.3711111,7.36666667 11.7111111,7.36666667 C12.0511111,7.36666667 12.2777778,7.14 12.2777778,6.8 C12.2777778,6.46 12.0511111,6.23333333 11.7111111,6.23333333 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Comment;
