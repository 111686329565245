import React, { PureComponent } from "react";
import { ButtonBase, Grid } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import { ModalType } from "../../../constant";
import { Edit, Setting } from "../../../icons";
import "./user-info.scss";
import { reduceStringChar } from "../../../core";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
class UserInfo extends PureComponent {
  constructor(props) {
    super(props);
    const { loadFollowing, loadFollowers } = this.props;
    loadFollowing();
    loadFollowers();
  }
  redirectToSetting = () => {
    const { history } = this.props;
    history.push("/settings");
  };
  handleFollowers = () => {
    const { openModal, isLoggedIn } = this.props;
    console.log(isLoggedIn);
    isLoggedIn ? openModal(ModalType.FOLLOWERS) : openModal(ModalType.LOGIN);
  };
  handleFollowings = () => {
    const { openModal, isLoggedIn } = this.props;
    isLoggedIn ? openModal(ModalType.FOLLOWINGS) : openModal(ModalType.LOGIN);
  };
  render() {
    const {
      user,
      following,
      followers,
      countries,
      fullNameVisible,
      history, t
    } = this.props;
    //console.log(user);
    const selectedCountry = countries.filter(
      (country) => country.id === parseInt(user.countryId)
    );
    const userCountry = !!selectedCountry.length ? selectedCountry[0] : "";

    return (
      <Grid container>
        <Grid item xs={12} sm={5}>
          <h3 className="singleLineName">
            { }
            {history.location.pathname == "/account" ? (
              <span>
                {reduceStringChar(
                  fullNameVisible
                    ? user.name
                    : user.name.indexOf(" ") > -1
                      ? user.name.substr(0, user.name.indexOf(" "))
                      : user.name
                )}
              </span>
            ) : (
              <span>{user.name}</span>
            )}
          </h3>
          {!!user && (!!user.city || !!userCountry) && (
            <label className="location">
              <LocationOn className="locIcon" />
              {!!user.city && <span>{user.city} , </span>}
              {!!userCountry && <span>{userCountry.name}</span>}
            </label>
          )}
        </Grid>
        <Grid item xs={12} sm={7}>
          {/* <Grid container>
            <Grid item xs={2}>
              <h3>{user.postCount || 0}</h3>
              <label className="small-font">Posts</label>
            </Grid>
            <Grid item xs={4} onClick={this.handleFollowers} className="pointer">
              <h3>{user.followCount || 0}</h3>
              <label className="small-font">Followers</label>
            </Grid>
            <Grid item xs={4} onClick={this.handleFollowings} className="pointer">
              <h3>{user.followingCount || 0}</h3>
              <label className="small-font">Following</label>
            </Grid>
            <Grid item xs={2}>
              <h3>0</h3>
              <label className="small-font">Likes</label>
            </Grid>
          </Grid> */}

          <div className="userData">
            <div className="item">
              <h3>{user.postCount || 0}</h3>
              <label className="small-font">{t("userInfo.label1")}</label>
            </div>
            <div className="item pointer" onClick={this.handleFollowers}>
              <h3>{followers.length || 0}</h3>
              <label className="small-font">{t("userInfo.label2")}</label>
            </div>
            <div className="item pointer" onClick={this.handleFollowings}>
              <h3>{following.length || 0}</h3>
              <label className="small-font">{t("userInfo.label3")}</label>
            </div>
            {/* <div className="item">
              <h3>0</h3>
              <label className="small-font">{t("userInfo.label4")}</label>
            </div> */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <p>{user.bio}</p>
        </Grid>
      </Grid>

      // <div className="basicinfo">
      //   <div className="info">
      //     {!!user.name && <div className="name">{user.name}</div>}
      //     <div className="number">{user.phone}</div>
      //   </div>
      //   {/* <div className="editProfile" onClick={() => openModal(ModalType.EDIT_PROFILE)}>
      //     <Edit height={"16px"} width={"16px"} className="edit" />
      //     <span>Edit Profile</span>
      //   </div> */}
      //   <div className="settingMobile tab-hidden">
      //     <ButtonBase onClick={this.redirectToSetting}>
      //       <Setting height={"20px"} width={"20px"} className="setting" />
      //     </ButtonBase>
      //   </div>
      // </div>
    );
  }
}
export default compose(withTranslation())(UserInfo);
//export default UserInfo;
