import { combineReducers } from "redux";
import countryReducer from "./country";
import formatReducer from "./format";
import genreReducer from "./genre";
import languageReducer from "./language";
import reportReducer from "./report";
import videoFormatReducer from "./video-format";
import themeReducer from "./theme";

const masterReducer = combineReducers({
  countries: countryReducer,
  formats: formatReducer,
  genres: genreReducer,
  languages: languageReducer,
  report: reportReducer,
  videoFormats: videoFormatReducer,
  themes: themeReducer
});

export { masterReducer };
