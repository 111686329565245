import { CONTENT } from "../../actions";

const controlVideoReducer = (state = false, action) => {
   switch (action.type) {
      case CONTENT.YES_CONTROL_VIDEO:
         return true;
      case CONTENT.NO_CONTROL_VIDEO:
         return false;
      default:
         return state;
   }
};

export default controlVideoReducer;