import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as AvatarModal } from "./avatar-modal";
import {
  openModal,
  closeModal,
  updateProfileImage,
  setAlertAction,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.edit_avatar,
  isImageLoading: state.auth.isImageLoading,
  user: state.auth.userId ? state.auth.otherUser : state.auth.data,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  updateProfileImage: (image) => dispatch(updateProfileImage(image)),
  setAlertAction: (alert) => dispatch(setAlertAction(alert)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AvatarModal)
);
