import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as GenreSelector } from "./genre-selector";
import {
  setUploadGenre,
  publishContent,
  addMoreContent,
  resetAddMoreContent,
  listSearchedGenres,
  setSearchGenre,
  setImageUploadGenre,
  setVideoUploadGenre,
  closeModal,
  setLinkUploadGenre,
  setStoryUploadGenre,
  clearUploadGenre
} from "../../../store";

const mapStateToProps = (state) => ({
  genres: listSearchedGenres(state),
  selectedGenres: state.upload.uploadGenre,
  error: state.upload.error,
  isLoading: state.upload.isLoading,
  searchGenre: state.upload.searchGenre,
  activeTab: state.upload.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  setUploadGenre: (genreId) => dispatch(setUploadGenre(genreId)),
  publishContent: () => dispatch(publishContent()),
  addMoreContent: () => dispatch(addMoreContent()),
  resetAddMoreContent: () => dispatch(resetAddMoreContent()),
  setSearchGenre: (searchText) => dispatch(setSearchGenre(searchText)),
  setImageUploadGenre: (genre) => dispatch(setImageUploadGenre(genre)),
  setVideoUploadGenre: (genre) => dispatch(setVideoUploadGenre(genre)),
  setLinkUploadGenre: (genre) => dispatch(setLinkUploadGenre(genre)),
  setStoryUploadGenre: (genre) => dispatch(setStoryUploadGenre(genre)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  clearUploadGenre: () => dispatch(clearUploadGenre())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenreSelector));
