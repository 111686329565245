import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { ModalType } from "../../../../constant";
import "./avatar-modal.scss";
import {
  Grid,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Avatar from "avataaars";
import * as ReactDOM from "react-dom";

const topType = ["NoHair", "Hat", "Hijab", "Turban", "LongHairBob"];
const acessoriesType = ["Blank", "Kurt", "Round", "Sunglasses", "Wayfarers"];
const hairType = ["Brown", "Red", "Blonde", "Black", "Blue01"];
const facialHairType = [
  "Blank",
  "BeardMedium",
  "BeardLight",
  "BeardMagestic",
  "MoustacheFancy",
];
const clotheType = [
  "BlazerShirt",
  "Hoodie",
  "Overall",
  "GraphicShirt",
  "CollarSweater",
];
const clotheColorType = ["Black", "Blue01", "Pink", "Red", "White"];
const eyeType = ["Close", "Cry", "Dizzy", "Happy", "Hearts"];
const eyebrowType = [
  "Angry",
  "UpDown",
  "SadConcerned",
  "FlatNatural",
  "RaisedExcited",
];
const mouthType = ["Eating", "Smile", "Vomit", "Twinkle", "Sad"];
const skinType = ["Tanned", "Yellow", "Pale", "Light", "Brown"];
const buttonText = [
  "TOP",
  "ACESSORIES",
  "FACIALHAIR",
  "CLOTHES",
  "CLOTHESCOLOR",
  "EYES",
  "EYEBROW",
  "MOUTH",
  "SKIN",
  "HAIRCOLOR"
];
const arrArr = [
  topType,
  acessoriesType,
  facialHairType,
  clotheType,
  clotheColorType,
  eyeType,
  eyebrowType,
  mouthType,
  skinType,
  hairType
];
class AvatarModal extends PureComponent {
  constructor() {
    super();

    this.state = {
      topType: "",
      accessoriesType: "",
      facialHairType: "",
      clotheType: "",
      clotheColor: "",
      eyeType: "",
      eyebrowType: "",
      mouthType: "",
      skinColor: "",
      hairType: ""
    };
    this.exampleRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.avatarRef = Avatar | null;
    this.canvasRef = HTMLCanvasElement | null;
  }

  handleChange(event, index, value) {
    this.setState({ age: event.target.value });
  }
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.EDIT_AVATAR);
  };
  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };
  saveToBackend = () => {
    this.onDownloadPNG();
  };
  setAvatarProperties = (i, val) => {
    const value = `${val[this.getRandomInt(5)]}`;

    switch (i) {
      case 0:
        this.setState({ topType: value });
        break;
      case 1:
        this.setState({ accessoriesType: value });
        break;
      case 2:
        this.setState({ facialHairType: value });
        break;
      case 3:
        this.setState({ clotheType: value });
        break;
      case 4:
        this.setState({ clotheColor: value });
        break;
      case 5:
        this.setState({ eyeType: value });
        break;
      case 6:
        this.setState({ eyebrowType: value });
        break;
      case 7:
        this.setState({ mouthType: value });
        break;
      case 8:
        this.setState({ skinColor: value });
        break;
      case 9:
        this.setState({ hairType: value })
        break
      default:
    }
  };
  onAvatarRef = (ref = Avatar) => {
    this.avatarRef = ref;
  };

  onCanvasRef = (ref = HTMLCanvasElement) => {
    this.canvasRef = ref;
  };
  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  addValidFile = (preview) => {
    const { closeModal, updateProfileImage } = this.props;
    var filename = `${this.getRandomInt(13231)}.jpg`;
    const file = this.dataURLtoFile(preview, filename);
    updateProfileImage(file);
    closeModal(ModalType.EDIT_AVATAR);
  };
  onDownloadPNG = () => {
    const svgNode = ReactDOM.findDOMNode(this.avatarRef);
    const canvas = this.canvasRef;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const anyWindow = window;
    const DOMURL = anyWindow.URL || anyWindow.webkitURL || window;

    const data = svgNode.outerHTML;
    const img = new Image();
    const svg = new Blob([data], { type: "image/svg+xml" });
    const url = DOMURL.createObjectURL(svg);

    img.onload = () => {
      ctx.save();
      ctx.scale(2, 2);
      ctx.drawImage(img, 0, 0);
      ctx.restore();
      DOMURL.revokeObjectURL(url);
      const base64Canvas = this.canvasRef.toDataURL("image/jpeg");
      // console.log(base64Canvas);
      this.addValidFile(base64Canvas);
    };
    img.src = url;
  };
  render() {
    const { modal, user, updateProfile, t } = this.props;
    const {
      topType,
      accessoriesType,
      facialHairType,
      clotheType,
      clotheColor,
      eyeType,
      eyebrowType,
      mouthType,
      skinColor,
      hairType
    } = this.state;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper avataar">
            <div className="sub-header text-left tab-hidden">
              <button
                className="back-btn tab-hidden"
                onClick={this.handleClose}
              ></button>
              <h1> {t("avatars.title2")}</h1>
            </div>
            <div className="p-50">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {/* <div className="back-btn bio"></div> */}

                  <div>
                    <h1 id="transition-modal-title" className="heading modalTitle font-bold mobile-hidden">
                      {t("avatars.title2")}
                    </h1>
                    {/* <Avataar setAvatarState={true} /> */}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="modalBody">
              <Grid container spacing={0}>
                <Grid item xs={6} sm={4} className="avataarImg">
                  <Avatar
                    ref={this.onAvatarRef}
                    style={{ width: "100%", height: "auto" }}
                    avatarStyle="Transparent"
                    topType={topType}
                    accessoriesType={accessoriesType}
                    hairColor={hairType}
                    facialHairType={facialHairType}
                    clotheType={clotheType}
                    clotheColor={clotheColor}
                    eyeType={eyeType}
                    eyebrowType={eyebrowType}
                    mouthType={mouthType}
                    skinColor={skinColor}
                  />
                  <canvas
                    style={{ display: "none" }}
                    width="528"
                    height="560"
                    ref={this.onCanvasRef}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  {buttonText.map((item, i) => (
                    <button
                      key={i}
                      className="avataar-btn"
                      onClick={() => this.setAvatarProperties(i, arrArr[i])}
                    >
                      {item}
                    </button>
                  ))}
                </Grid>
                <Grid item xs={12} className="avsubmit-btn">
                  <button className="submitButton" onClick={this.saveToBackend}>Save</button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default compose(withTranslation())(AvatarModal);
