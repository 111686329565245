import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as MatureContentModal } from "./mature-content-modal";
import { openModal, closeModal, updateProfile, enableRestrictedMode, disableRestrictedMode } from "../../../../store";

const mapStateToProps = (state) => ({
    modal: state.ui.modal.mature_content || false,
    error: state.auth.error,
    isLoading: state.auth.isLoading,
    themes: state.master.themes,
    restrictedMode: state.setting.restrictedMode,
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (modal) => dispatch(openModal(modal)),
    closeModal: (modal) => dispatch(closeModal(modal)),
    updateProfile: () => dispatch(updateProfile()),
    enableRestrictedMode: () => dispatch(enableRestrictedMode()),
    disableRestrictedMode: () => dispatch(disableRestrictedMode()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatureContentModal));
