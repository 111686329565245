import React, { PureComponent } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
} from "@material-ui/core";
import { ModalType, AlertAction } from "../../../../constant";
import "./alert-modal.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AlertModal extends PureComponent {
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.ALERT);
  };

  handleSuccess = () => {
    const {
      alertAction,
      clearUploadContent,
      clearHistory,
      closeModal,
      logout,
      removeProfileImage,
      history,
      deleteContent,
    } = this.props;
    if (alertAction.type === AlertAction.UPLOAD) {
      clearUploadContent();
      localStorage.removeItem("memeProperties");
      localStorage.removeItem("baseImage");
      !!history.length ? history.goBack() : history.push("/");
    }
    alertAction.type === AlertAction.CLEAR_HISTORY && clearHistory();
    alertAction.type === AlertAction.FEEDBACK_RESPONSE &&
      closeModal(ModalType.FEEDBACK);
    alertAction.type === AlertAction.Delete_Post && deleteContent();
    if (alertAction.type === AlertAction.REMOVE_PROFILE_IMAGE) {
      removeProfileImage();
      if (history.location.pathname.includes("profile-image")) {
        history.goBack();
      }
    }
    if (alertAction.type === AlertAction.LOGOUT) {
      logout();
      history.push("/");
      const notificCount = "notificationCount";
      localStorage.removeItem(notificCount);
      localStorage.removeItem("memeProperties");
      localStorage.removeItem("baseImage");
    }
    closeModal(ModalType.ALERT);
  };

  render() {
    const { t, modal, alertAction } = this.props;

    return (
      <Dialog
        className="alert-modal"
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t(`${alertAction.title}`)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span>{t(`${alertAction.body}`)}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {alertAction.cancelText && (
            <Button onClick={this.handleClose} color="primary">
              {t(`${alertAction.cancelText}`)}
            </Button>
          )}
          <Button onClick={this.handleSuccess} color="primary">
            {t(`${alertAction.successText}`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default compose(withTranslation())(AlertModal);
