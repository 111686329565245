import React, { PureComponent, Fragment } from "react";
import VideoPlayer from "../video-player";
import "./selected-video.scss";
import { Button } from "@material-ui/core";
import Slider from "react-animated-slider";

export default class SelectedVideos extends PureComponent {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
  }
  removeFile = (index) => {
    const { videos, updateUploadVideo } = this.props;
    videos.splice(index, 1);
    updateUploadVideo(videos);
  };
  handleSlideChange = (e) => {
    console.log(e);
  };
  render() {
    const { videos, slider, videoDescription } = this.props;
    return (
      <Fragment>
        {slider ? (
          <div className="step-one-images" data-text="Preview not loaded">
            {videos.length > 1 ? (
              <Slider
                duration={200}
                ref={this.sliderRef}
                onSlideChange={(event) => this.handleSlideChange(event)}
                infinite={false}
              >
                {videos.map((video, index) => (
                  <div key={index} className="img" alt="">
                    <label className="bold">Preview</label>
                    <Button color="secondary" className="delete-img" onClick={() => this.removeFile(index)}>
                      Delete Video
                    </Button>
                    <VideoPlayer url={video.preview} className="autoHeight" />
                    <div className="desc">{videoDescription}</div>
                  </div>
                ))}
              </Slider>
            ) : !!videos.length ? (
              videos.map((video, index) => (
                <div key={index} className="img vdo" alt="">
                  <label className="bold">Preview</label>
                  <Button color="secondary" className="delete-img" onClick={() => this.removeFile(index)}>
                    Delete Video
                  </Button>
                  <VideoPlayer url={video.preview} className="autoHeight" />
                  <div className="desc">{videoDescription}</div>
                </div>
              ))
            ) : null}
          </div>
        ) : (
          <div className="step-one-images" data-text="Preview">
            {!!videos.length &&
              videos.map((video, index) => (
                <div key={index} className="img" alt="">
                  <button className="delete-img" onClick={() => this.removeFile(index)}>
                    X
                  </button>
                  <VideoPlayer url={video.preview} height={"100px"} width={"100px"} />
                </div>
              ))}
          </div>
        )}
      </Fragment>
    );
  }
}
