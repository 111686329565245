import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as MemeModal } from "./meme-modal";
import {
  openModal,
  closeModal,
  setUploadImage,
  updateUploadImage,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.meme || false,
  modalw: state.ui.modal.w3meme || false,
  imageFile: state.upload.images,
  isModalRoute: state.ui.isModalRoute,
  token: state.auth.token,
  activeTab: state.upload.activeTab,
  images: state.upload.images,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  setUploadImage: (image) => dispatch(setUploadImage(image)),
  updateUploadImage: (images) => dispatch(updateUploadImage(images)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemeModal)
);
