import { connect } from "react-redux";
import { default as AccountForm } from "./account-form";
import { editUserInfo, updateProfile, setGender, setFullName, setPhone, setCity, setCountry, setEmail } from "../../../store";

const mapStateToProps = (state) => ({
  languages: state.master.languages,
  countries: state.master.countries,
  user: state.auth.data,
  error: state.auth.error,
  isLoading: state.auth.isLoading,
  editUser: state.auth.editUser,
});

const mapDispatchToProps = (dispatch) => ({
  editUserInfo: (user) => dispatch(editUserInfo(user)),
  updateProfile: () => dispatch(updateProfile()),
  setFullName: fullName => dispatch(setFullName(fullName)),
  setEmail: email => dispatch(setEmail(email)),
  setCity: city => dispatch(setCity(city)),
  setPhone: phone => dispatch(setPhone(phone)),
  setCountry: country => dispatch(setCountry(country)),
  setGender: gender => dispatch(setGender(gender)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);
