import { AppBar, Badge, Grid, IconButton, Toolbar } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import React, { Fragment, PureComponent } from "react";
import { isMobileOnly } from "react-device-detect";
import IntervalTimer from "react-interval-timer";
import { Link } from "react-router-dom";
import { ContentAction, ModalType, SelectedText } from "../../../constant";
import { setGlobalThemeVariable, getProfileImageUrl, getRandomColor } from "../../../core";
import { Logo, Notification, Share, UploadProgress } from "../../../icons";
import avtaar from "../../../assets/images/av.svg";
import { Genre } from "../filter";
import NotificationModal from "../modal/notification-modal";
import SettingModal from "../modal/setting-modal";
import Search from "../search";
import Sidebar from "../sidebar";
import "./mobile-header.scss";
import MobileSidebar from "../mobile-sidebar";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "avataaars";

class MobileHeader extends PureComponent {
   state = {
    anchorEl: null,
    open: false,
    settingAnchorEl: null,

  };
  constructor(props) {
    super(props);
    const {
      loadUiData,
      isLoggedIn,
      loadNotification,
      loadUserDiscoverList,
      darkTheme,
      refreshUserProfile,
      notFound,
      closeSidebar,
    } = props;
    setGlobalThemeVariable(!darkTheme);
    loadUiData();
    props.history.listen(() => {
      if (isMobileOnly) {
        closeSidebar();
      }
    });
    if (isLoggedIn) {
      loadNotification();
      //loadUserDiscoverList();
      refreshUserProfile();

      props.history.listen(() => {
        notFound(null);
      });
    }
    if (isMobileOnly) {
      closeSidebar();
    }
  }
  getAvatars = () => {
    const avatar = localStorage.getItem("avatar");
    const checkAvatar = avatar ? JSON.parse(avatar) : [];
    if (avatar?.length) {
      this.setState({
        topType: checkAvatar.topType,
        accessoriesType: checkAvatar.accessoriesType,
        hairColor: checkAvatar.hairColor,
        facialHairType: checkAvatar.facialHairType,
        clotheType: checkAvatar.clotheType,
        clotheColor: checkAvatar.clotheColor,
        eyeType: checkAvatar.eyeType,
        eyebrowType: checkAvatar.eyebrowType,
        mouthType: checkAvatar.mouthType,
        skinColor: checkAvatar.skinColor,
        isAvatar: true,
      });
    }
  };
  componentDidMount() {
   // this.getAvatars();
  }
  handleSidebar = () => {
    const { sidebar, openSidebar, closeSidebar, notFound } = this.props;
    notFound(null);
    sidebar ? closeSidebar() : openSidebar();
  };

  handleLoginClick = () => {
    const { openModal, clearSelectedText, setLoginNumber, clearOtpSent } = this.props;
    setLoginNumber("");
    clearOtpSent();
    openModal(ModalType.LOGIN);
    clearSelectedText();
  };

  handleSearch = () => {
    const { history } = this.props;
    history.push("/search");
  };

  handleUploadClick = () => {
    const { isLoggedIn, openModal, showSelectedText } = this.props;
    if (isLoggedIn) openModal(ModalType.UPLOAD);
    else {
      openModal(ModalType.LOGIN);
      showSelectedText(SelectedText.UPLOAD_TEXT);
    }
  };

  handleNotificationClick = (e) => {
    const { isLoggedIn, openModal, setNotificationsCount, closeModal, notificationModal, history } = this.props;

    this.setState({ anchorEl: e.currentTarget });
    if (isLoggedIn) {
      history.push("/notifications");
      // if (!notificationModal) {
      //   openModal(ModalType.NOTIFICATION);
      //   closeModal(ModalType.SETTING);
      //   //console.log(notificationModal);
      // } else {
      //   closeModal(ModalType.NOTIFICATION);
      // }

      const count = 0;
      const notificCount = "notificationCount";
      const notificationCount = JSON.parse(localStorage.getItem(notificCount));
      if (notificationCount > 0) {
        localStorage.setItem(notificCount, count);
        setNotificationsCount(count);
      }
    }
  };

  handleUploadNotificationClick = () => {
    const { isLoggedIn, openModal } = this.props;
    if (isLoggedIn) openModal(ModalType.UPLOAD_NOTIFICATION);
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  displayNewFeed = () => {
    const { showNewfeed } = this.props;
    showNewfeed();
  };

  removeSession = () => {
    const { loadWebsite } = this.props;
    loadWebsite();
  };

  displayInstallApp = () => {
    const { showInstallApp, closeModal } = this.props;
    closeModal(ModalType.MORE_OPTION);
    closeModal(ModalType.MORE_OPTION_DETAIL);
    closeModal(ModalType.REPORT_USER);
    closeModal(ModalType.REPORT_OTHER);
    showInstallApp();
  };
  handleSettingModal = (e) => {
    const { openModal, settingModal, closeModal } = this.props;
    this.setState({ settingAnchorEl: e.currentTarget }, () => {
      if (!settingModal) {
        openModal(ModalType.SETTING);
        closeModal(ModalType.NOTIFICATION);
      } else {
        closeModal(ModalType.SETTING);
      }
    });
  };
  setStyle = () => {
    const { history } = this.props;
    const { location } = history;
    const searchRoute = "/search";
    const showSearchOnly = !!(isMobileOnly && location.pathname === searchRoute);
    let styles = {};
    if (isMobileOnly && location.pathname !== "/") {
      const firstStyle = {
        boxShadow: "none",
      };
      styles = Object.assign(styles, firstStyle);
    }
    if (showSearchOnly && isMobileOnly) {
      const secondStyle = {
        display: "none",
      };
      styles = Object.assign(styles, secondStyle);
    }

    return styles;
  };

  navigateTo = (url) => {
    const { history, isLoggedIn, openModal, showSelectedText } = this.props;
    if (isLoggedIn) {
      history.push(url);
    } else {
      openModal(ModalType.LOGIN);
      showSelectedText(SelectedText.UPLOAD_TEXT);
    }
  };

  render() {
    const {
      openModal,
      isLoggedIn,
      notificationCount,
      picUrl,
      uploadFileCount,
      history,
      newFeed,
      loadFromSession,
      contentAction,
      navlinkChange,
      installApp,
      user,
      notificationModal,
    } = this.props;
     const {
      anchorEl,
      open,
   
    } = this.state;
    const { location } = history;
    const searchRoute = "/search";
    const showSearchOnly = !!(isMobileOnly && location.pathname === searchRoute);
    const startNewFeedTimer = !newFeed && !!(contentAction !== ContentAction.upload);
    return (
      <Fragment>
        {isMobileOnly && (
          <IntervalTimer
            timeout={60000}
            callback={() => this.displayInstallApp()}
            enabled={!installApp}
            repeat={false}
          />
        )}
        <IntervalTimer
          timeout={60000 * 20}
          callback={() => this.displayNewFeed()}
          enabled={startNewFeedTimer}
          repeat={false}
        />
        <IntervalTimer
          timeout={60000 * 30}
          callback={() => this.removeSession()}
          enabled={loadFromSession}
          repeat={false}
        />
        <AppBar style={this.setStyle()} position="fixed" className="appBar" elevation={0}>
          <Toolbar className="MuiToolbarRegular">
            {location.pathname == "/" && isMobileOnly && (
              <div className="sectionMobile">
                {isLoggedIn && isMobileOnly && (
                  <IconButton aria-label="show more option" color="inherit" className="" onClick={this.handleSidebar}>
                    <Fragment>
                      <img
                        className="userIcon largeIcon"
                        src={getProfileImageUrl(picUrl, user.id, user.name)}
                        alt=""
                        onError={(e) => {
                          e.target.src = getProfileImageUrl("", user.id, user.name);
                        }}
                      />
                      <MenuIcon className="menuIcon" />
                    </Fragment>
                  </IconButton>
                )}
                {!isLoggedIn && isMobileOnly && (
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={this.handleSidebar}
                  >
                    <div className="user-non-login" style={{ backgroundColor: getRandomColor(Math.floor(Math.random() * Math.floor(100))) }}>
                      <img src={avtaar} alt="" />
                    </div>
                    {/* <AccountCircle fontSize="large" className="largeIcon" /> */}
                    <MenuIcon className="menuIcon" />
                  </IconButton>
                )}
              </div>
            )}
            <div className="headerLogo-mobile">
              <Link to="/">
                <Logo width={"100px"} height={"36px"} />
              </Link>
            </div>
            <div className="grow"></div>
            <div className="headerRight">
              <div className="sectionMobile">
                {/* {isLoggedIn && (
                  <IconButton color="inherit" className="br-1">
                    <Badge
                      badgeContent={2}
                      color="secondary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Share width={"28px"} height={"30px"} className="share" />
                    </Badge>
                  </IconButton>
                )} */}
                {isLoggedIn && (
                  <IconButton color="inherit" className="br-1" onClick={this.handleNotificationClick}>
                    {/* <Badge
                      badgeContent={12}
                      color="secondary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Notification width={"22px"} height={"27px"} className="notification" />
                    </Badge> */}
                    <Notification width={"22px"} height={"27px"} className="notification" />
                  </IconButton>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        {isMobileOnly ? <MobileSidebar /> : <Sidebar />}
        <NotificationModal anchorEl={this.state.anchorEl} open={notificationModal}></NotificationModal>
        <SettingModal anchorEl={this.state.settingAnchorEl}></SettingModal>
      </Fragment>
    );
  }
}

export default MobileHeader;
