import React, { PureComponent, Fragment } from "react";
import { ContactTag } from "../../jsonLD";
import "./contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Grid, Button, TextField } from "@material-ui/core";
import fileSelector from "../../components/containers/file-selector";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FcText, FcSelect } from "../../components/presentational";
import { isServer, sendContact } from "../../store";
import feedBack from "../../assets/images/feedback.svg";
import instagramSvg from "../../assets/images/insta.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";

const FACEBOOK = "facebook";
const LINKEDIN = "linkedin";
const INSTAGRAM = "instagram";
const TWITTER = "twitter";
const PINTEREST = "pinterest";
class Contact extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formValid: false,
      isLoading1: true
    };
  }
  handleBackClick = () => {
    const { history } = this.props;
    !!history.length ? history.goBack() : history.push("/");
  };

  handleChange = (event) => {
    event.preventDefault();
    setTimeout(() => {
      this.setState({ formValid: this.validateForm() });
    }, 100);
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading1: false })
    }, 500)
  }
  validateForm = () => {
    if (this.props.email === "" || this.props.message === "") {
      return false;
    } else {
      return true;
    }
  };

  formSubmit = (event) => {
    event.preventDefault();
    const { sendContact } = this.props;
    sendContact();
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { sendContact } = this.props;
    sendContact();
  };
  handleSocialLink = (sType) => {
    if (!isServer) {
      if (sType === FACEBOOK) {
        window.open("https://www.facebook.com/Tymoff-Social-111712980564959");
      } else if (sType === INSTAGRAM) {
        window.open("https://www.instagram.com/tymoff__social/");
      } else if (sType === TWITTER) {
        window.open("https://twitter.com/TymoffS");
      } else if (sType === LINKEDIN) {
        window.open("https://www.linkedin.com/company/tymoff-social");
      } else if (sType === PINTEREST) {
        window.open("https://in.pinterest.com/tymoffs");
      }
    }
  };
  render() {
    const {
      name,
      email,
      t,
      message,
      setContactName,
      setContactEmail,
      setContactMessage,
      error,
      isLoading,
      sendContact,
      clearContact,
    } = this.props;
    const { isLoading1 } = this.state
    const initialValues = {
      email: "",
      message: "",
    };
    //const { t } = this.props;
    return (
      <Fragment>
        <ContactTag />
        {/* <Formik
          initialValues={initialValues}
          validationSchema={null}
          onSubmit={async (fields, { resetForm }) => {
            setContactEmail(fields.email);
            setContactMessage(fields.message);
            await sendContact();

            //setContactEmail("");
            //setContactMessage("");
            resetForm({ email: "", message: "" });
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => ( */}
        {!isLoading1 ? <form onSubmit={this.handleSubmit}>
          <div className="tymoff-container">
            <div
              className="sub-header text-left tab-hidden"
              style={{ marginLeft: -13 }}
            >
              <button
                className="back-btn"
                onClick={this.handleBackClick}
              ></button>
              <h1>{t("editProfile.label9")}</h1>
            </div>
            <Grid container spacing={0} className="m-0">
              <Grid item xs={12} sm={5}>
                <div>
                  <h1 id="transition-modal-title" className="heading modalTitle font-bold mobile-hidden">
                    {t("contact.title")}
                  </h1>
                  <div className="my-20">
                    <label className="bold">{t("contact.title1")}</label>
                    {/* <FcText name="email" autoComplete="off" required={true} component={FcText}></Field> */}
                    <TextField
                      name="email"
                      variant="outlined"
                      value={email}
                      onChange={(e) => {
                        setContactEmail(e.target.value);
                        this.handleChange(e);
                      }}
                      autoComplete="off"
                    ></TextField>
                  </div>
                  <div className="my-20">
                    <label className="bold">{t("contact.title2")}</label>
                    <TextField
                      name="message"
                      variant="outlined"
                      rows={2}
                      value={message}
                      rowsMax={4}
                      multiline={true}
                      className="mobiletf"
                      onChange={(e) => {
                        setContactMessage(e.target.value);
                        this.handleChange(e);
                      }}
                    ></TextField>
                    {/* <Field
                          name="message"
                          multiline={true}
                          rows={2}
                          rowsMax={4}
                          autoComplete="off"
                          required={true}
                          component={FcText}
                        ></Field> */}
                  </div>

                  <div className="my-20">
                    <button
                      className={"submitButton contact-btn my-20"}
                      disabled={isLoading || !this.state.formValid}
                      type="submit"
                    >
                      {!isLoading ? (
                        t("contact.button")
                      ) : (
                        <div className="spinner">
                          <div className="bounce1"></div>
                          <div className="bounce2"></div>
                          <div className="bounce3"></div>
                        </div>
                      )}
                    </button>
                  </div>
                  <div className="error">{error}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7} className="MuiTypography-alignCenter">
                <img src={feedBack} className="contact-img" />
              </Grid>
              <Grid item xs={12} sm={12} className="mr5">
                <label className="bold">{t("editProfile.label10")}</label>

                <FacebookIcon
                  size={36}
                  round
                  className="socialSvg"
                  onClick={() => this.handleSocialLink(FACEBOOK)}
                />

                <img
                  src={instagramSvg}
                  className="instaSvg"
                  onClick={() => this.handleSocialLink(INSTAGRAM)}
                />

                <TwitterIcon
                  size={36}
                  round
                  className="socialSvg"
                  onClick={() => this.handleSocialLink(TWITTER)}
                />

                <LinkedinIcon
                  size={36}
                  round
                  className="socialSvg"
                  onClick={() => this.handleSocialLink(LINKEDIN)}
                />

                <PinterestIcon
                  size={36}
                  round
                  className="socialSvg"
                  onClick={() => this.handleSocialLink(PINTEREST)}
                />
              </Grid>
            </Grid>
          </div>
        </form> : null}
        {/* )} */}
        {/* </Formik> */}
      </Fragment>
    );
  }
}

export default compose(withTranslation())(Contact);
