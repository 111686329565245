import { connect } from "react-redux";
import { default as StoryUpload } from "./story-upload";
import {
  setUploadStoryTitle,
  setStoryUploadGenre,
  setUploadGenre,
  setUploadStoryLanguage,
  openModal,
  setUploadStep,
  closeModal,
  updateUploadGenre,
  updateUploadLanguage,
  disableStoryUploadMatureContent
} from "../../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.upload || false,
  title: state.upload.storyTitle,
  error: state.upload.error,
  genres: state.master.genres,
  languages: state.master.languages,
  uploadGenres: state.upload.uploadGenre,
  storyUploadGenre: state.upload.storyUploadGenre,
  storyLanguage: state.upload.storyLanguage,
  commonModal: state.ui.modal.common,
  matureContent: state.upload.storyMatureContent
});

const mapDispatchToProps = (dispatch) => ({
  setUploadStoryTitle: (title) => dispatch(setUploadStoryTitle(title)),
  setStoryUploadGenre: (genreId) => dispatch(setStoryUploadGenre(genreId)),
  setUploadGenre: (genreId) => dispatch(setUploadGenre(genreId)),
  setUploadStoryLanguage: (langId) => dispatch(setUploadStoryLanguage(langId)),
  openModal: (modal) => dispatch(openModal(modal)),
  setUploadStep: (step) => dispatch(setUploadStep(step)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  updateUploadGenre: (genres) => dispatch(updateUploadGenre(genres)),
  updateUploadLanguage: (languages) => dispatch(updateUploadLanguage(languages)),
  disableStoryUploadMatureContent: () => dispatch(disableStoryUploadMatureContent())
});

export default connect(mapStateToProps, mapDispatchToProps)(StoryUpload);
