import { UI } from "../../actions";

const siteLanguageReducer = (state = "", action) => {
    switch (action.type) {
        case UI.SITE_LANGUAGE:
            return action.searchText;
        default:
            return state;
    }
};

export default siteLanguageReducer;
