import React, { PureComponent, useState } from "react";
import { ListItem, List, IconButton } from "@material-ui/core";
import { Player, ControlBar, VolumeMenuButton } from "video-react";
import { isMobileOnly } from "react-device-detect";
import {
  kFormatter,
  getProfileImageUrl,
  reduceStringChar,
  getFirstName,
} from "../../../core";
import {
  Like,
  Comment,
  Share,
  Download,
  MoreOption,
  Report,
} from "../../../icons";
import {
  ModalType,
  ContentUserAction,
  ContentTypeId,
  ShareType,
  SelectedText,
} from "../../../constant";
import "./content-detail-footer.scss";
import Grid from "@material-ui/core/Grid";
import muteIcon from "../../../assets/images/muteA.svg";
import unMuteIcon from "../../../assets/images/unmute.svg";
//import AudioControls from "./audioControl/audioControl";
import Slider from "react-rangeslider";
import ContinuousSlider from "./audioControl/audioControl";
import luxorAdsImg1 from "../../../assets/ads/luxor_paker_2/Footer_Jotter-Campaign-728x90-04.jpg";
import luxorAdsImg2 from "../../../assets/ads/luxor_paker_1/Footer_Pramotion-range-FB-Camp_Final_728x90Pixel-02.jpg";
import spawakeAdsImg2 from "../../../assets/ads/Spawake_Feb/Footer_728_90.jpg";
import jackfruitAdsImg1 from "../../../assets/ads/jackfruit/Footer_Banner_728pxX90px.jpg";
import byjus from "../../../assets/ads/byjus/728x90.jpg";
import heroMoto from "../../../assets/ads/Hero_Motocorp/Destiny/728x90a.jpg"
import myntra from "../../../assets/ads/Myntra/728x90.jpg"
import netmeds from "../../../assets/ads/Netmeds/728x90.jpg"
import paytm from "../../../assets/ads/Paytm/728x90.jpg"
import policybazaar from "../../../assets/ads/policybazaar/728x902.jpg"
import { isServer } from "../../../store";
var volumeslider;
var audio_custom;
const initialAds = [
  { id: 1, src: luxorAdsImg1, url: "https://luxor.in/" },
  {
    id: 2,
    src: spawakeAdsImg2,
    url: "https://www.spawake.in/products/whitening-triple-care-serum",
  },
  { id: 3, src: luxorAdsImg2, url: "https://luxor.in/" },
  {
    id: 4,
    src: jackfruitAdsImg1,
    url:
      "https://www.amazon.in/Eastern-Jackfruit365-Green-Jackfruit-Flour/dp/B08BXWVKJJ/",
  },
  {
    id: 5,
    src: byjus,
    brandName: "byjus",
    url:
      "https://byjus.com/",
  },
  {
    id: 6,
    src: heroMoto,
    brandName: "heroMoto",
    url:
      "https://m.heromotocorp.com/two-wheelers.html",
  },
  {
    id: 7,
    src: myntra,
    brandName: "myntra",
    url:
      "https://www.myntra.com/",
  },
  {
    id: 8,
    src: netmeds,
    brandName: "netmeds",
    url:
      "https://www.netmeds.com/",
  },
  {
    id: 9,
    src: paytm,
    brandName: "paytm",
    url:
      "https://paytm.com/",
  },
  {
    id: 10,
    src: policybazaar,
    brandName: "policybazaar",
    url:
      "https://www.policybazaar.com/",
  },
];
export default class ContentDetailFooter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      volume: this.props.audioLevel ? this.props.audioLevel : 0.2,
      value: 25,
      reverseValue: 8,
      stateInitialAds: initialAds,
    };
  }

  handleCommentClick = () => {
    const {
      commentBox,
      showCommentBox,
      hideCommentBox,
      isLoggedIn,
      openModal,
      loadFromSession,
      showSelectedText,
    } = this.props;
    if (!loadFromSession && isLoggedIn) {
      commentBox ? hideCommentBox() : showCommentBox();
    } else {
      openModal(ModalType.LOGIN);
    }
  };
  handleShareClick = () => {
    const { openModal, loadFromSession, setShareType } = this.props;
    if (!loadFromSession) {
      setShareType(ShareType.CONTENT);
      openModal(ModalType.SHARE);
    }
  };

  handleLikeClick = (isLike) => {
    const {
      setContentUserAction,
      isLoggedIn,
      openModal,
      loadFromSession,
      showSelectedText,
    } = this.props;
    if (!loadFromSession) {
      if (isLoggedIn)
        isLike
          ? setContentUserAction(ContentUserAction.UNLIKE)
          : setContentUserAction(ContentUserAction.LIKE);
      else {
        openModal(ModalType.LOGIN);
        showSelectedText(SelectedText.LIKE_TEXT);
      }
    }
  };

  handleMoreOptionClick = (e) => {
    const {
      openModal,
      modal,
      closeModal,
      loadShareLink,
      stopScrollTimer,
      startScrollTimer,
      loadFromGrid,
      contentAlreadyVisited,
      loadFromSession,
      setShareType,
    } = this.props;
    if (!loadFromSession) {
      if (modal) {
        loadFromGrid && !contentAlreadyVisited && startScrollTimer();
        closeModal(ModalType.MORE_OPTION);
      } else {
        setShareType(ShareType.CONTENT);
        loadShareLink();
        loadFromGrid && stopScrollTimer();
        openModal(ModalType.MORE_OPTION);
      }
    }
  };

  isMyPost = () => {
    const { isLoggedIn, activeContent, user } = this.props;
    if (isLoggedIn) {
      const { userId } = activeContent,
        { id } = user;
      if (userId === id) return true;
      else return false;
    }
    return false;
  };

  handleReportContent = () => {
    const {
      openModal,
      isLoggedIn,
      loadFromSession,
      setErrorMessage,
    } = this.props;
    if (!loadFromSession) {
      this.handleClose();
      if (isLoggedIn) {
        const isMyPost = this.isMyPost();
        isMyPost
          ? setErrorMessage("You can't report your own content")
          : openModal(ModalType.REPORT_CONTENT);
      } else openModal(ModalType.LOGIN);
    }
  };

  handleDownloadedContent = () => {
    const {
      isLoggedIn,
      openModal,
      downloadFile,
      activeContent,
      loadFromSession,
      setErrorMessage,
      showSelectedText,
    } = this.props;
    if (!loadFromSession) {
      if (
        activeContent.typeId === ContentTypeId.Images ||
        activeContent.typeId === ContentTypeId.Videos
      ) {
        if (isLoggedIn) downloadFile();
        else {
          openModal(ModalType.LOGIN);
          showSelectedText(SelectedText.DOWNLOAD_TEXT);
        }
      } else {
        setErrorMessage("This content can't be downloaded");
      }
    }
  };

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.MORE_OPTION);
  };

  renderAds = (index) => {
    const { enableFooterAds } = this.props;
    const { stateInitialAds, state2InitialAds } = this.state;
    return (
      <div>
        {!isServer ? <img

          onClick={() => window.open(stateInitialAds[index].url)}
          src={stateInitialAds[index].src}
          style={{
            height: "60px",
            width: "100%",
            backgroundColor: "gray",
            cursor: "pointer"
          }}
        /> : null}
      </div>
    );
  };
  handleLabelClick = (userId) => {
    const {
      history,
      setAuthUserId,
      loadOtherUserProfile,
      clearUserId,
      contentClose,
    } = this.props;
    if (userId) {
      clearUserId();
      setAuthUserId(userId);
      loadOtherUserProfile();
      this.closeContentDetail();
      history.push(`/account/${userId}`);
    }
  };
  reduceStringChar = (w) => {
    return w.length > 15 ? `${w.substr(0, 15)}...` : w;
  };
  toUpper = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        if (word[0]) {
          return word[0].toUpperCase() + word.substr(1);
        } else {
          return null;
        }
      })
      .join(" ");
  };
  closeContentDetail = () => {
    const { hideCommentBox, contentClose, closeModal, isServer } = this.props;
    closeModal(ModalType.CONTENT_DETAIL);
    closeModal(ModalType.MORE_OPTION);
    closeModal(ModalType.MORE_OPTION_DETAIL);
    closeModal(ModalType.REPORT_USER);
    contentClose();
    hideCommentBox();
    !isServer && (document.body.className = "");
  };
  componentDidMount() {
    var audio_custom = document.getElementById("myCustomAudio");
    var vloumeSl = document.getElementById("volume");
    /*  if (vloumeSl) {
      document
        .getElementById("volume")
        .removeEventListener("volumechange", this.onVolumeChange);
    } */
    /* if (this.state.stateInitialAds?.length) {
      setInterval(() => {
        const newAds = this.shuffleArray(this.state.stateInitialAds);
        const new2Ads = this.shuffleArray(this.state.stateInitialAds);
        this.setState({ stateInitialAds: newAds, state2InitialAds: new2Ads });
      }, 30000);
    } */
  }
  shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
      // Generate random number
      var j = Math.floor(Math.random() * (i + 1));

      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };
  onVolumeChange = (event) => {
    const { muteAudio, unMuteAudio } = this.props;
    const target = event.target;
    target.muted ? muteAudio() : unMuteAudio();
  };
  openSoundSetting = () => {
    const { isLoggedIn, openModal } = this.props;
    if (isLoggedIn) {
      //openModal(ModalType.SOUND_SETTING);
    } else {
      openModal(ModalType.LOGIN);
    }
  };
  change_Volume = (e) => {
    const { value } = e.target;
    const { changeAudioLevel, openModal } = this.props;
    openModal(ModalType.SOUND_SETTING);
    if (parseFloat(value)) {
      this.props.unMuteAudio();
      this.setState(
        {
          volume: parseFloat(value),
        },
        () => {
          changeAudioLevel(this.state.volume);
        }
      );
    } else {
      this.props.muteAudio();
      //  videoPlayer.muted = true;
      this.setState(
        {
          volume: parseFloat(value),
        },
        () => {
          changeAudioLevel(this.state.volume);
        }
      );
    }
    var volume = document.getElementById("volume");
    var player = document.getElementById("myCustomAudio");
    if (player) {
      player.volume = this.state.volume;
    }
  };
  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };
  render() {
    const {
      activeContent,
      modal,
      muteUnmute,
      muteAudio,
      unMuteAudio,
      audioMuted,
      openModal,
      genres,
      content,
      moreOptionClick,
      soundOptionClick,
      brandName,
      adsIndex,
      adsFooter,
      brandImage,
      disableFooterAds
    } = this.props;
    const { volume, reverseValue } = this.state;
    //const genre = genres.find((gen) => String(gen.id) === String(content.catId));
    let displayName = ""
    if (!adsFooter && !isServer) {
      displayName = content.userDetail.name ? content.userDetail.name : "";
    }


    return (
      <div className="image-icon-text">
        <div className="image-text web imgwithtext">
          <div className="description">
            <input type="checkbox" className="read-more" id="read-more1" />
            <p className="view-more"></p>
          </div>
        </div>
        {!adsFooter ? <Grid container spacing={0} className="footer-detail-content">
          <Grid
            item
            xs={4}
            onClick={() => this.handleLabelClick(content.userId)}
            className="singleLineName pointer"
            style={{ position: "relative" }}
          >
            <img
              className="oval"
              src={getProfileImageUrl(
                content.userDetail.picUrl,
                content.userDetail.id,
                displayName
              )}
              alt=""
              onError={(e) => {
                e.target.src = getProfileImageUrl(
                  "",
                  content.userDetail.id,
                  displayName
                );
              }}
              style={{ marginLeft: 10 }}
            />
            <label
              className="top-label singleLineName pointer"
              style={{ width: "90%" }}
            >
              &ensp;{reduceStringChar(getFirstName(displayName))}
            </label>
          </Grid>

          <Grid item xs={8} className="right-align">
            <div className="footer-menu">
              <IconButton
                onClick={() => audioMuted ? unMuteAudio() : muteAudio()}
                className="more-icon footer-menu soundicon"
              >
                {activeContent.typeId !== 2 ? (

                  <img
                    src={audioMuted ? muteIcon : unMuteIcon}
                    alt=""
                    className="mute-unmute-icon"
                  />

                ) : null}
              </IconButton>
            </div>
            <div
              className="footer-menu"
              onClick={() => this.handleLikeClick(activeContent.isLike)}
            >
              <Like
                width={"23px"}
                height={"23px"}
                className={
                  activeContent.isLike ? "like-active" : "like-inactive-content"
                }
              />
              <label>{kFormatter(activeContent.likeCount)}</label>
            </div>

            <div className="footer-menu" onClick={this.handleCommentClick}>
              <Comment
                width={"22px"}
                height={"22px"}
                className="comment-content"
              />
              {/* <label className="mobile-hidden">6</label> */}
            </div>
            <IconButton
              onClick={(event) => moreOptionClick(event, displayName)}
              className="more-icon footer-menu"
            >
              <MoreOption
                width={"14px"}
                height={"14px"}
                className="more-option-content-white"
              />
            </IconButton>
          </Grid>
        </Grid> : <Grid container spacing={0} className="footer-detail-content">
          <Grid
            item
            xs={12}
            className="singleLineName pointer"
            style={{ position: "relative" }}
          >
            <img
              className="oval"
              src={getProfileImageUrl(
                "",
                adsIndex,
                brandName
              )}
              alt=""
              onError={(e) => {
                e.target.src = getProfileImageUrl(
                  "",
                  adsIndex,
                  brandName
                );
              }}
              style={{ marginLeft: 10 }}
            />
            <label
              className="top-label singleLineName pointer"
              style={{ width: "90%" }}
            >
              &nbsp;Promoted by {reduceStringChar(getFirstName(brandName))}
            </label>
          </Grid>
        </Grid>}

        <Grid item xs={12} className="right-align">
          {this.props.index % 10 == 1 && this.props.index >= 10 ? (
            <div>{this.renderAds(this.getRandomInt(10))}</div>
          ) : null}
        </Grid>

      </div>
    );
  }
}
