import React, { PureComponent, Fragment } from "react";
import StackGrid from "react-stack-grid";
import { MasonryInfiniteScroller } from "../../../modules";
import { ButtonBase } from "@material-ui/core";
import axios from "axios";
import { isMobileOnly } from "react-device-detect";
// import { Waypoint } from "react-waypoint";
import { GridLoading } from "../../containers";
import { BlankItems, ContentAction, ModalType } from "../../../constant";
import { clearCache } from "../../../core";
import { Newfeed, Uparrow } from "../../../icons";
import { closeModal, isServer } from "../../../store";
import Card from "../card";
import "./grid.scss";
import { withTranslation } from "react-i18next";
import { LazyImage, CardFooter } from "../../containers";
import SharePopperModal from "../modal/share-popper-modal";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router-dom";
//import sizeMe from "react-sizeme";
import { compose } from "redux";
import { Waypoint } from "react-waypoint";
import ContentNotFound from "../../../pages/content-not-found/content-not-found";
import ReactResizeDetector from "react-resize-detector";
import { ReportUserModal } from "../modal";
import ContentMoreOptionsDetail from "../modal/content-more-options-detail";
import ReportUserModalDetail from "../modal/report-user-modal-detail";
/* import feeling from "../../../assets/images/asound.mp3";
import feeling1 from "../../../assets/images/asound1.mp3"; */
import { apiSound, apiSelectedSound, apiGetMemes } from "../../../api";
import luxorAdsImg1 from "../../../assets/ads/luxor_paker_2/Grid_Jotter-Campaign-320x480-02.jpg";
import luxorAdsImg2 from "../../../assets/ads/luxor_paker_1/Grid_300x250.jpg";
import spawakeAdsImg2 from "../../../assets/ads/Spawake_Feb/Grid_320_480.jpg";
import jackfruitAdsImg1 from "../../../assets/ads/jackfruit/Grid_Banner_336pxX280px.jpg";
import byjus from "../../../assets/ads/byjus/336-280.jpg";
import heroMoto from "../../../assets/ads/Hero_Motocorp/Destiny/336x280.jpg"
import myntra from "../../../assets/ads/Myntra/336x280.jpg"
import netmeds from "../../../assets/ads/Netmeds/336x280.jpg"
import paytm from "../../../assets/ads/Paytm/336x280.jpg"
import policybazaar from "../../../assets/ads/policybazaar/336x2801.jpg"
import AdsFooter from "../../containers/ads-footer/ads-footer"
import { ContactSupportOutlined } from "@material-ui/icons";

const initialAds = [
  { id: 1, src: luxorAdsImg1, brandName: "Luxor Parker", url: "https://luxor.in/" },
  {
    id: 2,
    src: spawakeAdsImg2,
    brandName: "Spawake",
    url: "https://www.spawake.in/products/whitening-triple-care-serum",
  },
  { id: 3, src: luxorAdsImg2, brandName: "Luxor Parker", url: "https://luxor.in/" },
  {
    id: 4,
    src: jackfruitAdsImg1,
    brandName: "Jackfruit 365",
    url:
      "https://www.amazon.in/Eastern-Jackfruit365-Green-Jackfruit-Flour/dp/B08BXWVKJJ/",
  },
  {
    id: 5,
    src: byjus,
    brandName: "byjus",
    url:
      "https://byjus.com/",
  },
  {
    id: 6,
    src: heroMoto,
    brandName: "heroMoto",
    url:
      "https://m.heromotocorp.com/two-wheelers.html",
  },
  {
    id: 7,
    src: myntra,
    brandName: "myntra",
    url:
      "https://www.myntra.com/",
  },
  {
    id: 8,
    src: netmeds,
    brandName: "netmeds",
    url:
      "https://www.netmeds.com/",
  },
  {
    id: 9,
    src: paytm,
    brandName: "paytm",
    url:
      "https://paytm.com/",
  },
  {
    id: 10,
    src: policybazaar,
    brandName: "policybazaar",
    url:
      "https://www.policybazaar.com/",
  },
];
class Grid extends PureComponent {
  state = {
    anchorEl: null,
    contentIndex: null,
    content: null,
    open: false,
    username: "",
    sounds: [],
    isLoading: true,
    errors: null,
    mergeSounds: [],
    stateInitialAds: initialAds,
    state2InitialAds: initialAds,
  };

  constructor(props) {
    super(props);
    const { history, authSounds, token, isLoggedIn } = this.props;
    const { closeModal, openModal } = this.props;
    history.listen(() => {
      closeModal(ModalType.MORE_OPTION_DETAIL);
      closeModal(ModalType.SHARE_POPPER);
      closeModal(ModalType.REPORT_USER);
    });
    this.myobj = [
      /*       { id: 3, soundURL: feeling },
            { id: 4, soundURL: feeling1 }, */
    ];
    if (!isServer) {
      this.onceAday();
    }

    if (authSounds?.length) {
      const soundData = {
        ambientSoundList: [...authSounds],
      };
      const data = apiSelectedSound(soundData, token);
      //console.log(soundData);
      localStorage.setItem("authSounds", JSON.stringify(authSounds));
    }
    if (isLoggedIn) {
      //this.getApiMeme();
    }
  }

  nextDay = () => {
    var date = new Date().toLocaleDateString();
    if (localStorage.tymoff_date == date) {
      return false;
    } else {
      localStorage.tymoff_date = date;
      return true;
    }
  };
  onceAday = () => {
    if (!this.nextDay()) return false;
    this.getUsers();
  };
  getApiMeme = () => {
    const { modalw, token, isLoggedIn } = this.props;
    if (token || isLoggedIn) {
      apiGetMemes(token).then((result) => {
        const isMemeData = result.data !== "undefined" ? result.data : []
        localStorage.setItem("memeImages", JSON.stringify(isMemeData))
      });
    }
  };
  getUsers() {
    axios
      .get(
        "https://server-end.tymoff.com:8443/aintertain/api/v1/data/sound"
      )
      .then((response) => {
        const apiSounds = response.data.data.ambientSoundList;
        const mergeApi = [...this.myobj, ...apiSounds];
        window.localStorage.setItem("apiSounds", JSON.stringify(mergeApi));
      })
      .catch((error) => this.setState({ error, isLoading: false }));
  }
  renderAudio = () => {
    const { isLoading, sounds } = this.state;
    if (!isLoading) {
      return (
        <div style={{ display: "none" }}>
          <audio
            ref={this.myRef}
            controls
            loop={true}
            autoPlay
            play={false}
            muted={true}
          >
            <source src={sounds[0].soundURL} type="audio/mp3"></source>
            <source src={sounds[1].soundURL} type="audio/mp3"></source>
          </audio>
        </div>
      );
    } else {
      return null;
    }
  };
  renderAds = (index) => {
    const { stateInitialAds, state2InitialAds } = this.state;
    return (
      <div className="card-style">
        {!isServer ? <><img

          onClick={() => window.open(stateInitialAds[index].url)}
          src={stateInitialAds[index].src}
          style={{
            height: isMobileOnly ? "280px" : "200px",
            width: "100%",
            cursor: "pointer",
            // height: "280px",
            //width: "230px",
            backgroundColor: "gray",
          }}
        />
          <CardFooter
            brandName={stateInitialAds[index].brandName}
            adsIndex={index}
            adsFooter={true}
            brandImage={stateInitialAds[index].brandImage}
          />
        </> : null}
      </div>
    );
  };
  componentDidMount() {
    //apiMusicContent();
    const { history, authSounds } = this.props;
    //console.log(authSounds);

    //document.addEventListener("scroll", this.handleScroll, false);
  }
  shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
      // Generate random number
      var j = Math.floor(Math.random() * (i + 1));

      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  };
  componentDidUpdate = (prevProps) => {
    const { isLoading, filter, isLoggedIn } = this.props;
    if (isLoading !== prevProps.isLoading) {
      this.grid && this.grid.updateLayout();
    }
    if (filter.formatsList?.length !== prevProps.formatsList?.length) {
      this.grid && this.grid.updateLayout();
    }
    if (isLoggedIn) {
      this.getApiMeme();
    }
  };

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll, false);
  }

  loadMore = () => {
    const {
      setContentPageChange,
      loadContent,
      currentPage,
      isLoading,
      setContentAction,
      contentAction,
      loadFromSession,
      loadClient,
      totalPage,
      closeModal,
      settingModal,
      contentdetail
    } = this.props;
    if (!loadFromSession && !isLoading && currentPage < totalPage) {
      setContentPageChange(currentPage + 1);
      contentAction ? setContentAction(contentAction) : loadContent();
      loadClient();
    }
    if (contentdetail) {

    } else {
      !isServer && document.body.classList.remove("prevent-scroll");
    }
    // if (notificationModal) {
    //   closeModal(ModalType.NOTIFICATION);
    // }
    if (settingModal) {
      closeModal(ModalType.SETTING);
    }
    this.grid && this.grid.updateLayout();
  };

  handleScroll = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.MORE_OPTION_DETAIL);
    closeModal(ModalType.SHARE_POPPER_GRID);
    closeModal(ModalType.REPORT_USER_DETAIL);
    closeModal(ModalType.REPORT_OTHER);
    if (typeof window !== "undefined") {
      const { totalPage } = this.props;
      let element = document.getElementById("btnTakeToTop");
      if (window.pageYOffset > 1300 && element) {
        element.style.display = "block";
      } else if (element) {
        element.style.display = "none";
      }
      let scrollPos = 0;
      if (document.body.getBoundingClientRect().top <= scrollPos) {
        //console.log(totalPage)
        if (totalPage < 1) {
          this.loadMore();
        }
      }
      scrollPos = document.body.getBoundingClientRect().top;
    }
  };

  scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  onRefresh = () => {
    this.loadNewContent();
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  };

  loadNewContent = () => {
    clearCache();
    const {
      contentAction,
      setContentPageChange,
      setContentAction,
      loadContent,
      clearContent,
      hideNewfeed,
    } = this.props;
    hideNewfeed();
    setContentPageChange(0);
    clearContent();
    contentAction ? setContentAction(contentAction) : loadContent();
  };

  handleShareClick = (event, contentIndex, content) => {
    let open = false;
    let cont = content;
    const {
      setActiveContentIndex,
      setInitialSlide,
      setShareType,
      loadShareLink,
      openModal,
      closeModal,
    } = this.props;
    if (this.state.content) {
      if (content.id === this.state.content.id) {
        open = false;
        cont = null;
      } else {
        open = true;
      }
    } else {
      open = true;
    }
    if (open) {
      //openModal(ModalType.SHARE_POPPER);
      openModal(ModalType.MORE_OPTION_DETAIL);
    } else {
      //closeModal(ModalType.SHARE_POPPER);
      closeModal(ModalType.MORE_OPTION_DETAIL);
    }
    this.setState({
      anchorEl: event.currentTarget,
      contentIndex: contentIndex,
      content: cont,
      open,
    });
    setActiveContentIndex(contentIndex);
    //setInitialSlide(contentIndex);
    // setShareType(ShareType.CONTENT);
    //loadShareLink();
  };

  handleOnClose = () => {
    //this.setState({ open: false });
    const { closeModal } = this.props;
    closeModal(ModalType.MORE_OPTION_DETAIL);
  };

  navigateTo = (url) => {
    const { history } = this.props;
    history.push(url);
  };
  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  render() {
    const {
      contentList,
      isLoading,
      newFeed,
      loadFromSession,
      contentAction,
      totalPage,
      currentPage,
      notFound,
      filter,
      modal,
      history,
      reportModal,
      activeContent,
      moreOptionModal,
      t,
    } = this.props;
    const { stateInitialAds, state2InitialAds } = this.state;
    let columnWidth =
      isMobileOnly && history.location.pathname == "/"
        ? "100%"
        : isMobileOnly
          ? "48%"
          : 240; //adjust accordingly in card.scss {.grid.card-style} //design reference don't remove this comment.
    let path;
    if (history.location.pathname.match(/account/g)) {
      path = history.location.pathname.match(/account/g)[0];
    } else {
      path = history.location.pathname.match(/account/g);
    }
    if (path == "account" && !isMobileOnly) {
      columnWidth = 230;
    }
    return (
      <Fragment>
        {/*  {this.renderAudio()} */}
        {newFeed && !!(contentAction !== ContentAction.upload) && (
          <ButtonBase className="newpost" onClick={this.loadNewContent}>
            <Newfeed width={"16px"} height={"16px"} className="new-feed" />
            {t("user.title7")}
          </ButtonBase>
        )}
        {/* {filter.contentSearch &&
          !notFound &&
          history.location.pathname.match(/account/g) == "account" && (
            <Fragment>
              <label className="search-label">
                Search results for <strong>{filter.contentSearch}</strong>
              </label>
              <br />
            </Fragment>
          )} */}
        {notFound ? (
          // <div className="coming-soon">
          //   <div className="no-content">
          //     No content found around {filter.contentSearch ? filter.contentSearch : "this criteria"}
          //   </div>
          //   <div className="you-may">You may like this content</div>
          // </div>
          <Fragment>
            <label className="search-label">
              No content found for <strong>{filter.contentSearch} </strong>.
              Showing search results for <strong>{filter.contentSearch}</strong>
            </label>
            <br />
          </Fragment>
        ) : (
          ""
        )}

        <MasonryInfiniteScroller
          hasMore={!!(currentPage < totalPage)}
          loadMore={() => {
            !loadFromSession &&
              !isLoading &&
              contentList.length > 0 &&
              this.loadMore();
          }}
        >
          {isLoading || contentList.length > 0 ? (
            <StackGrid
              className={
                isMobileOnly && !!(contentAction === ContentAction.upload)
                  ? "grid grid-account"
                  : "grid"
              }
              monitorImagesLoaded={false}
              columnWidth={columnWidth}
              appearDelay={0}
              duration={0}
              gridRef={(grid) => (this.grid = grid)}
              enableSSR={true}
              gutterWidth={15}
              gutterHeight={30}
              style={
                history.location.pathname.match(/account/g) == "account"
                  ? { top: 0 }
                  : null
              }
            >
              {contentList.map((content, index) => {
                return (
                  <Fragment key={index}>
                    {/* {index === contentList.length - 20 && (
                      <Waypoint
                        onEnter={() => {
                          this.loadMore();
                        }}
                      />
                    )} */}
                    {index % 10 == 0 && index >= 10 ? (
                      <div>{this.renderAds(this.getRandomInt(10))}</div>
                    ) : (
                      <Card
                        content={content}
                        contentIndex={index}
                        onShareClick={this.handleShareClick}
                      />
                    )}
                  </Fragment>
                );
              })}
              {isLoading &&
                BlankItems.map((data, index) => (
                  <GridLoading key={data.id || index} data={data} />
                ))}
              {/* {!loadFromSession && !isLoading && (
              <Waypoint
                onEnter={() => {
                  this.loadMore();
                }}
              />
            )} */}
            </StackGrid>
          ) : (contentAction == "BOOKMARK" ||
            contentAction == "like" ||
            contentAction == "upload") &&
            isLoading === false ? (
            <Fragment></Fragment>
          ) : (
            <Fragment></Fragment>
          )}
        </MasonryInfiniteScroller>

        {/* <IconButton className="uploadBtn" onClick={() => this.navigateTo("/upload")}>
          <AddIcon />
        </IconButton> */}

        {/* {isMobileOnly ? null : (
          <div style={{ display: "none" }} id="btnTakeToTop" className="scroll">
            <ButtonBase
              onClick={() => {
                this.scrollToTop();
              }}
            >
              <Uparrow width={"20px"} height={"24px"} className="uparrow" />
            </ButtonBase>
          </div>
        )} */}

        <SharePopperModal
          anchorEl={this.state.anchorEl}
          contentIndex={this.state.contentIndex}
          content={this.state.content}
          open={modal}
          reset={true}
        ></SharePopperModal>
        <ReportUserModalDetail
          anchorEl={this.state.anchorEl}
          reset={true}
          open={reportModal}
          openReason={true}
        ></ReportUserModalDetail>
        <ContentMoreOptionsDetail
          open={moreOptionModal}
          anchorEl={this.state.anchorEl}
          userName={activeContent ? activeContent.username : null}
          handleOnClose={this.handleOnClose}
        />
        {!isServer && !isLoading && !!!contentList.length && (
          <ContentNotFound />
        )}
      </Fragment>
    );
  }
}

export default compose(withTranslation())(withRouter(Grid));
//export default composewithRouter(Grid);
