import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { ModalType } from "../../../../constant";
import "./bio-modal.scss";
import { Grid, Button, TextField } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class BioModal extends PureComponent {
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.BIO);
  };

  handleOnChange = (e) => {
    const { user, editUserInfo } = this.props;
    editUserInfo({ ...user, bio: e.target.value });
  };

  updateProfile = () => {
    const { updateProfile, closeModal } = this.props;
    updateProfile();
    closeModal(ModalType.BIO);
  };

  render() {
    const { modal, user, updateProfile,t } = this.props;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper">
            <div className="modalContent">
              <div className="modalBody">
                <div className="back-btn bio" onClick={this.handleClose}></div>
                <h6>{t("editProfile.title4")}</h6>
                <p>
                {t("editProfile.title5")} <span>({t("editProfile.title6")})</span>
                </p>
                <TextField
                  rows={4}
                  multiline={true}
                  className="txt"
                  value={user.bio}
                  onChange={this.handleOnChange}
                  inputProps={{ maxLength: 100 }}
                ></TextField>
                <button className={"submitButton fullWidth contact-btn"} type="submit" onClick={this.updateProfile}>
                  {t("profile.button0")}
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default compose(withTranslation())(BioModal);
