import React, { PureComponent } from "react";
import { Button } from "@material-ui/core";
import { getProfileImageUrl } from "../../core";
import { ContentAction, ModalType } from "../../constant";
import "./following.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
class Following extends PureComponent {
  constructor(props) {
    super(props);
    const { loadFollowing, setAuthUserId, history } = props;

    if (history.location.state) {
      const { userId } = history.location.state;
      setAuthUserId(userId);
    }
    loadFollowing();
  }

  handleBackClick = () => {
    const { history, loadFollowing } = this.props;
    !!history.length ? history.goBack() : history.push("/");
    loadFollowing();
  };
  followUser = (id) => {
    const { setSelectedUserID, followUser } = this.props;
    setSelectedUserID(id);
    followUser();
  };
  unfollowUser = (id) => {
    const { setSelectedUserID, openModal } = this.props;
    setSelectedUserID(id);
    openModal(ModalType.UNFOLLOW_ALERT);
  };

  navigate = (userId) => {
    const {
      history,
      setAuthUserId,
      loadOtherUserProfile,
      clearUserId,
      clearContent,
      resetContentPage,
      setContentAction,
    } = this.props;
    if (userId) {
      clearContent();
      resetContentPage();
      clearUserId();
      setAuthUserId(userId);
      setContentAction(ContentAction.upload);
      loadOtherUserProfile();

      history.push({
        pathname: `/account/${userId}`,
        state: { userId },
      });
    }
  };

  render() {
    const { following, isLoading, loginUser, isLoggedIn,t } = this.props;
    return (
      <div className="">
        <div className="sub-header text-left tab-hidden">
          <button className="back-btn" onClick={this.handleBackClick}></button>
          <h1>{t("followerModal.button1")}</h1>
        </div>

        <div className="followersModalMobile">
          <div className="modalBody">
            <div className="follow-list">
              {isLoading && (
                <div className="shimmer-follower">
                  <div className="follow-item">
                    <div className="follow-name">
                      <div className="img"></div>
                      <span></span>
                    </div>
                    <div className="follow-btn"></div>
                  </div>
                  <div className="follow-item">
                    <div className="follow-name">
                      <div className="img"></div>
                      <span></span>
                    </div>
                    <div className="follow-btn"></div>
                  </div>
                  <div className="follow-item">
                    <div className="follow-name">
                      <div className="img"></div>
                      <span></span>
                    </div>
                    <div className="follow-btn"></div>
                  </div>
                  <div className="follow-item">
                    <div className="follow-name">
                      <div className="img"></div>
                      <span></span>
                    </div>
                    <div className="follow-btn"></div>
                  </div>
                </div>
              )}
              {!isLoading && !!following.length ? (
                following.map((user, i) => (
                  <div key={i} className="follow-item">
                    <div
                      className="follow-name"
                      onClick={() => this.navigate(user.id)}
                    >
                      <img
                        src={getProfileImageUrl(
                          user.picUrl,
                          user.id,
                          user.name
                        )}
                        alt=""
                        onError={(e) => {
                          e.target.src = getProfileImageUrl(
                            "",
                            user.id,
                            user.name
                          );
                        }}
                      />
                      <span>{user.name}</span>
                    </div>

                    {((isLoggedIn && user.id !== loginUser.id) ||
                      !isLoggedIn) && (
                      <div className="follow-btn">
                        {user.isFollowingLoggedUser ? (
                          <Button
                            className="f-btn f-btn-dis"
                            onClick={() => this.unfollowUser(user.id)}
                          >
                            {t("followerModal.button1")}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="f-btn"
                            onClick={() => this.followUser(user.id)}
                          >
                            {t("followerModal.button2")}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : !isLoading ? (
                <div className="txt-cntr">{t("followerModal.div2")}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withTranslation())(Following)