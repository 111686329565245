import React, { PureComponent, Component, Fragment } from "react";
// import ScrollMenu from "react-horizontal-scrolling-menu";
import { ButtonBase } from "@material-ui/core";
// import { SliderColors } from "../../../../../constant";
import "./slider.scss";
// import { Left, Right } from "../../../../../icons";
import { GenreModal } from "../../../modal";
import { ContentAction, ModalType } from "../../../../../constant";
import { cloneDeep } from "lodash";
import { Surprise } from "../../../../../icons/wand";
import { Left, Right } from "../../../../../icons";
import { Tik, Untik } from "../../../../../icons/tik";
import { Catpin, Catunpin } from "../../../../../icons/pin";


import ScrollMenu from "react-horizontal-scrolling-menu";
import { isMobileOnly } from "react-device-detect";
// import ScrollArea from "react-scrollbar";
import ScrollContainer from "react-indiana-drag-scroll";
import { withRouter } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import CheckSvg from "../../../../../assets/images/chk-mark-filled.svg";
import UnCheckSvg from "../../../../../assets/images/chk-mark-unfilled.svg";
import CheckIcon from "@material-ui/icons/Check";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { apiCategories } from "../../../../../api/auth-api";
import PaperPin from "../../../../../assets/images/PAPERPIN.svg";
import PaperPin1 from "../../../../../assets/images/PAPER-PIN.svg";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const myArr = [];
class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      selectedGenres: [],
      open: false,
      anchorEl: null,
      numberOfGenre: null,
      uSelected: [],
    };
    this.genreWidth = React.createRef();
  }

  setPinCat = (id) => {
    const {
      authCategory,
      onFilterClick,
      setFilter,
      filter,
      token,
      updateAuth,
      user,
      setGenre,
      isLoggedIn,
      openModal,
    } = this.props;
    if (isLoggedIn) {
      const authCatId = authCategory && !isMobileOnly ? [...authCategory] : [];
      const checkIfCategoryExists = authCatId.findIndex((cat) => cat === id);
      if (checkIfCategoryExists === -1) {
        authCatId.push(id);
      } else {
        authCatId.splice(checkIfCategoryExists, 1);
      }
      const catData = {
        tappedCategoryList: [...authCatId],
      };
      apiCategories(catData, token);
      updateAuth({ ...user, tappedCategoryList: authCatId });
      isMobileOnly ? setFilter({ ...filter }) :
        setFilter({ ...filter, genresList: authCatId });
    } else {
      openModal(ModalType.LOGIN);
    }
    //onFilterClick(id);
  };
  handleCloseGenre = () => {
    this.setState({ open: false });
  };

  supriseMe = () => {
    const {
      setFilter,
      resetContentPage,
      contentAction,
      clearAction,
      selectedCountries,
      selectedLanguages,
      filter,
      enableMagicStick,
      disableMagicStick,
      magicStick,
    } = this.props;
    resetContentPage();
    setFilter({
      ...filter,
      countriesList: selectedCountries,
      languagesList: selectedLanguages,
    });
    magicStick ? disableMagicStick() : enableMagicStick();
  };

  getRestOfElements = (event) => {
    this.setState({
      open: !this.state.open,
      anchorEl: event.currentTarget,
    });
  };

  renderItems = (genres, selected, onFilterClick) => {
    const { authCategory } = this.props;
    const checkAuthCat = authCategory && !isMobileOnly ? authCategory : [];
    const newGener = [...this.props.selectedGenres];
    genres.forEach((gene) => {
      const found = newGener.find((newGe) => newGe.id === gene.id);
      if (!found) {
        newGener.push(gene);
      }
    });
    return newGener.map((genre, index) => {
      const isActive = !!this.props.selectedGenres.find(
        (elem) => elem.id === genre.id
      );
      const isPinned = checkAuthCat.find((elem) => elem === genre.id);

      return (
        isMobileOnly ? <ButtonBase key={genre.id || index} onClick={() => onFilterClick(genre.id)}>
          <div className={`spl-btn ${isActive ? "active" : ""}`}>{genre.name}</div>
        </ButtonBase> :
          <ListItem className="NavItem" title="" button key={genre.id || index}>
            <div className="checked-icon">
              {" "}
              {isActive ? (
                <div onClick={() => onFilterClick(genre.id)}>
                  <Tik className="catcheck" />
                </div>
              ) : (
                <div onClick={() => onFilterClick(genre.id)}>
                  <Untik className="untik" />
                </div>
              )}
            </div>

            <div
              className={`spl-btn ${isActive ? "active-sidebar-cat" : ""}`}
              onClick={() => onFilterClick(genre.id)}
            >
              {genre.name}
            </div>
            {isPinned ? (
              <div onClick={() => this.setPinCat(genre.id)}><Catpin
                className="catpin active"

              /></div>
            ) : (
              <div onClick={() => this.setPinCat(genre.id)}><Catunpin
                className="catpinunfill"

              /></div>
            )}

          </ListItem>
      );
    });
  };

  allButton = () => { };

  onSelect = (selected) => {
    //this.setState({ selected });
  };
  updateDimensions = () => {
    const WidthLeft = this.genreWidth.current.clientWidth - 210;
    const MinElementWidth = 100;
    const calculatedElements = Math.round(WidthLeft / MinElementWidth);
    this.setState({ numberOfGenre: calculatedElements });
  };
  componentDidMount() {
    const { match, genres, onFilterClick, filter, authCategory } = this.props;
    this.updateDimensions();
    //console.log(authCategory);
    window.addEventListener("resize", this.updateDimensions);
    //authCategory.forEach((cat) => onFilterClick(cat));
    //console.log({ props: this.props });
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    const {
      match,
      genres,
      authCategory,
      setFilter,
      onFilterClick,
      filter,
      selectedGenres,
    } = nextprops;
    if (match && Object.values(match.params).length && genres.length) {
      const foundGenre = genres.find((genre) => {
        return genre.name.toLowerCase() === match.params[0];
      });
      if (foundGenre && !filter.genresList.includes(foundGenre.id))
        onFilterClick(foundGenre.id);
    }
  }
  render() {
    const { selected } = this.state;
    const {
      genres,
      onFilterClick,
      magicStick,
      contentAction,
      filter, t,
    } = this.props;
    // Create menu from items
    const items = this.renderItems(genres, selected, onFilterClick);

    const firstElements = items.slice(0, this.state.numberOfGenre);
    const restElements = items.slice(4, items.length);

    return (
      <Fragment>
        <div className="action-btn" ref={this.genreWidth}>
          {!isMobileOnly && genres ? (
            <Fragment>
              <ButtonBase onClick={() => onFilterClick(null, true)}>
                {/* <div className="checked-icon">
                  {" "}
                  {!this.props.selectedGenres.length ? (
                    <img src={CheckSvg} style={{ width: "16px" }}></img>
                  ) : (
                      <img src={UnCheckSvg} style={{ width: "16px" }}></img>
                    )}
                </div> */}
                <div className="checked-icon">
                  {" "}
                  <img style={{ width: "16px" }}></img>
                </div>
                <div
                  className={`spl-btn ${!this.props.selectedGenres.length
                    ? "active-sidebar-cat"
                    : ""
                    }`}
                >
                  {t("user.title6")}
                </div>
              </ButtonBase>
              {items}

              {/*   <ButtonBase onClick={(event) => this.getRestOfElements(event)}>
                <label className="spl-btn">&#8226;&#8226;&#8226;</label>
              </ButtonBase> */}
              {/*  {filter.discoverId && (
                <Fragment>
                  <ButtonBase>
                    <div className="divider"></div>
                  </ButtonBase>
                  <ButtonBase onClick={(event) => this.supriseMe()}>
                    <Surprise
                      height={"32px"}
                      className={`spl-btn surprise ${
                        magicStick ? "active" : ""
                      }`}
                    />
                  </ButtonBase>
                  <ButtonBase>
                    <div className="divider"></div>
                  </ButtonBase>
                </Fragment>
              )} */}
            </Fragment>
          ) : // <ScrollMenu
            //   data={items}
            //   // arrowLeft={<Left height={"35px"} width={"35px"} className="fav-left" />}
            //   //arrowRight={<Right height={"35px"} width={"35px"} className="fav-right" />}
            //   selected={selected}
            //   // onSelect={this.onSelect}
            // />
            genres ? (
              <ScrollContainer className="scroll-container">
                <ButtonBase onClick={() => onFilterClick(null, true)}>
                  <div
                    className={`spl-btn ${!this.props.selectedGenres.length ? "active" : ""
                      }`}
                  >
                    {t("format.title")}
                  </div>
                </ButtonBase>
                {items}
              </ScrollContainer>
            ) : null}
        </div>
        <GenreModal
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          numberOfGenre={this.state.numberOfGenre}
          onFilterClick={onFilterClick}
          className="filter-modal"
          handleCloseGenre={this.handleCloseGenre}
        ></GenreModal>
      </Fragment>
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
}

export default compose(withTranslation())(withRouter(Slider));
