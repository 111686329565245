import { PREFERENCE } from "../../actions";

const cityReducer = (state = "", action) => {
  switch (action.type) {
    case PREFERENCE.CLEAR:
      return "";
    case PREFERENCE.SET_CITY:
      return action.city;
    default:
      return state;
  }
};

export default cityReducer;