import React from "react";
import "./play.scss";

const Play = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 64 64"
    style={{ enableBackground: "new 0 0 64 64" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g transform="translate(128.000000, 278.000000)">
        <path className="secondary" d="M-103-233.6v-24.7l21.2,12.4L-103-233.6L-103-233.6z" />
      </g>
    </g>
  </svg>
);
const UploadPlay = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 64 64"
    style={{ enableBackground: "new 0 0 64 64" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g transform="translate(128.000000, 278.000000)">
        <path className="secondary" d="M-103-233.6v-24.7l21.2,12.4L-103-233.6L-103-233.6z" />
      </g>
    </g>
  </svg>
);
const Pause = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 64 64"
    style={{ enableBackground: "0 0 64 64" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path class="secondary" d="M24.9,17.3c-0.5,0-3.9,0-3.9,3.9v21.7c0,3.9,3.4,3.9,3.9,3.9c3.9,0,3.9-3.4,3.9-3.9V21.2  C28.8,20.8,28.8,17.3,24.9,17.3z"/>
    <path class="secondary" d="M40.2,17.3c-0.5,0-3.9,0-3.9,3.9v21.7c0,3.9,3.4,3.9,3.9,3.9c3.9,0,3.9-3.4,3.9-3.9V21.2  C44.1,20.8,44.1,17.3,40.2,17.3z"/>
  </svg>
);
export { Play, UploadPlay, Pause };
