import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as MobileSidebar } from "./mobile-sidebar";
import {
  openModal,
  openSidebar,
  getActiveFilterCountries,
  getActiveFilterLanguages,
  navlinkChange,
  showSelectedText,
  setAlertAction,
  closeSidebar,
  setNavigationStep,
  clearSelectedText,
  setLoginNumber,
  clearOtpSent,
  clearUserId,
  cancelLoadContent,
  cancelUserLoadContent,
  resetContentPage,
  clearContent,
} from "../../../store";

const mapStateToProps = (state) => ({
  sidebar: state.ui.sidebar,
  isDiscoverRoute: state.ui.isDiscoverRoute,
  discoverList: state.discover.discoverList,
  userDiscoverList: state.discover.userDiscoverList,
  isLoggedIn: state.auth.isLoggedIn,
  selectedCountries: getActiveFilterCountries(state),
  selectedLanguages: getActiveFilterLanguages(state),
  isDarkTheme: state.setting.darkTheme,
  picUrl: state.auth.data ? state.auth.data.picUrl : "",
  user: state.auth.data,
  navigation: state.navigation.navigation,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  openSidebar: () => dispatch(openSidebar()),
  closeSidebar: () => dispatch(closeSidebar()),
  navlinkChange: () => dispatch(navlinkChange()),
  showSelectedText: (text) => dispatch(showSelectedText(text)),
  setAlertAction: (alert) => dispatch(setAlertAction(alert)),
  setNavigationStep: (step) => dispatch(setNavigationStep(step)),
  clearSelectedText: () => dispatch(clearSelectedText()),
  setLoginNumber: (number) => dispatch(setLoginNumber(number)),
  clearOtpSent: () => dispatch(clearOtpSent()),
  clearUserId: () => dispatch(clearUserId()),
  cancelLoadContent: () => dispatch(cancelLoadContent()),
  cancelUserLoadContent: () => dispatch(cancelUserLoadContent()),
  resetContentPage: () => dispatch(resetContentPage()),
  clearContent: () => dispatch(clearContent()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileSidebar));
