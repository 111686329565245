import { AppBar, Badge, Grid, IconButton, Toolbar } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import React, { Fragment, PureComponent } from "react";
import { isMobileOnly } from "react-device-detect";
import IntervalTimer from "react-interval-timer";
import { Link } from "react-router-dom";
import { ContentAction, ModalType, SelectedText } from "../../../constant";
import {
  setGlobalThemeVariable,
  getProfileImageUrl,
  getRandomColor,
} from "../../../core";
import { Logo, Notification, Share, UploadProgress } from "../../../icons";
import avtaar from "../../../assets/images/av.svg";
import { Genre } from "../filter";
import NotificationModal from "../modal/notification-modal";
import SettingModal from "../modal/setting-modal";
import Search from "../search";
import Sidebar from "../sidebar";
import "./header.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import MobileSidebar from "../mobile-sidebar";
import AddIcon from "@material-ui/icons/Add";
import { reduceStringChar } from "../../../core/funcs";
//import Avatar from "avataaars";

class Header extends PureComponent {
  state = {
    anchorEl: null,
    open: false,
    settingAnchorEl: null,

  };
  constructor(props) {
    super(props);
    const {
      loadUiData,
      isLoggedIn,
      loadNotification,
      darkTheme,
      refreshUserProfile,
      notFound,
      closeSidebar,
      isFirstTimeLogin,
      openModal,
    } = props;
    setGlobalThemeVariable(!darkTheme);
    loadUiData();
    props.history.listen(() => {
      if (isMobileOnly) {
        closeSidebar();
      }
    });
    if (isLoggedIn) {
      // if (isFirstTimeLogin) {
      //   openModal(ModalType.USERSEARCH);
      // }
      loadNotification();
      //loadUserDiscoverList();
      refreshUserProfile();
      props.history.listen(() => {
        notFound(null);
      });
    }
    if (isMobileOnly) {
      closeSidebar();
    }
  }
  getAvatars = () => {
    const avatar = localStorage.getItem("avatar");
    const checkAvatar = avatar ? JSON.parse(avatar) : [];
    if (avatar?.length) {
      this.setState({
        topType: checkAvatar.topType,
        accessoriesType: checkAvatar.accessoriesType,
        hairColor: checkAvatar.hairColor,
        facialHairType: checkAvatar.facialHairType,
        clotheType: checkAvatar.clotheType,
        clotheColor: checkAvatar.clotheColor,
        eyeType: checkAvatar.eyeType,
        eyebrowType: checkAvatar.eyebrowType,
        mouthType: checkAvatar.mouthType,
        skinColor: checkAvatar.skinColor,
        isAvatar: true,
      });
    }
  };
  componentDidMount() {
    //this.getAvatars();
  }
  handleSidebar = () => {
    const { sidebar, openSidebar, closeSidebar, notFound } = this.props;
    notFound(null);
    sidebar ? closeSidebar() : openSidebar();
  };

  handleLoginClick = () => {
    const {
      openModal,
      clearSelectedText,
      setLoginNumber,
      clearOtpSent,
    } = this.props;
    setLoginNumber("");
    clearOtpSent();
    openModal(ModalType.LOGIN);
    clearSelectedText();
  };

  handleSearch = () => {
    const { history } = this.props;
    history.push("/search");
  };

  handleUploadClick = () => {
    const { isLoggedIn, openModal, showSelectedText } = this.props;
    if (isLoggedIn) openModal(ModalType.UPLOAD);
    else {
      openModal(ModalType.LOGIN);
      showSelectedText(SelectedText.UPLOAD_TEXT);
    }
  };

  handleNotificationClick = (e) => {
    const {
      isLoggedIn,
      openModal,
      setNotificationsCount,
      closeModal,
      notificationModal,
    } = this.props;
    this.setState({ anchorEl: e.currentTarget });
    if (isLoggedIn) {
      if (!notificationModal) {
        openModal(ModalType.NOTIFICATION);
        closeModal(ModalType.SETTING);
      } else {
        closeModal(ModalType.NOTIFICATION);
      }

      const count = 0;
      const notificCount = "notificationCount";
      const notificationCount = JSON.parse(localStorage.getItem(notificCount));
      if (notificationCount > 0) {
        localStorage.setItem(notificCount, count);
        setNotificationsCount(count);
      }
    }
  };

  handleUploadNotificationClick = () => {
    const { isLoggedIn, openModal } = this.props;
    if (isLoggedIn) openModal(ModalType.UPLOAD_NOTIFICATION);
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  displayNewFeed = () => {
    const { showNewfeed } = this.props;
    showNewfeed();
  };

  removeSession = () => {
    const { loadWebsite } = this.props;
    loadWebsite();
  };

  displayInstallApp = () => {
    const { showInstallApp, closeModal } = this.props;
    closeModal(ModalType.MORE_OPTION);
    closeModal(ModalType.MORE_OPTION_DETAIL);
    closeModal(ModalType.REPORT_USER);
    closeModal(ModalType.REPORT_OTHER);
    showInstallApp();
  };
  handleSettingModal = (e) => {
    const { openModal, settingModal, closeModal } = this.props;
    this.setState({ settingAnchorEl: e.currentTarget }, () => {
      if (!settingModal) {
        openModal(ModalType.SETTING);
        closeModal(ModalType.NOTIFICATION);
      } else {
        closeModal(ModalType.SETTING);
      }
    });
  };
  setStyle = () => {
    const { history } = this.props;
    const { location } = history;
    const searchRoute = "/search";
    const showSearchOnly = !!(
      isMobileOnly && location.pathname === searchRoute
    );
    let styles = {};
    if (isMobileOnly && location.pathname !== "/") {
      const firstStyle = {
        boxShadow: "none",
      };
      styles = Object.assign(styles, firstStyle);
    }
    if (showSearchOnly && isMobileOnly) {
      const secondStyle = {
        display: "none",
      };
      styles = Object.assign(styles, secondStyle);
    }

    return styles;
  };

  navigateTo = (url) => {
    const { history, isLoggedIn, openModal, showSelectedText } = this.props;
    if (isLoggedIn) {
      history.push(url);
    } else {
      openModal(ModalType.LOGIN);
      showSelectedText(SelectedText.UPLOAD_TEXT);
    }
  };
  navigate = (url) => {
    const {
      history,
      cancelUserLoadContent,
      cancelLoadContent,
      clearContent,
      resetContentPage,
      clearUserId,
      loadFollowing,
    } = this.props;
    if (history.location.pathname === "/account") {
      return
    }
    clearUserId();
    cancelLoadContent();
    cancelUserLoadContent();
    clearContent();
    resetContentPage();
    history.push(url);
  };

  render() {
    const {
      t,
      openModal,
      isLoggedIn,
      notificationCount,
      picUrl,
      uploadFileCount,
      history,
      newFeed,
      loadFromSession,
      contentAction,
      navlinkChange,
      installApp,
      user,
      notificationModal,
      notification,
      isFirstTimeLogin,
    } = this.props;
    const {
      anchorEl,
      open,

    } = this.state;
    const { location } = history;
    const searchRoute = "/search";
    const showSearchOnly = !!(
      isMobileOnly && location.pathname === searchRoute
    );
    const startNewFeedTimer =
      !newFeed && !!(contentAction !== ContentAction.upload);
    const notifyCount = notification.length;
    return (
      <Fragment>
        {isMobileOnly && (
          <IntervalTimer
            timeout={60000}
            callback={() => this.displayInstallApp()}
            enabled={!installApp}
            repeat={false}
          />
        )}
        <IntervalTimer
          timeout={60000 * 20}
          callback={() => this.displayNewFeed()}
          enabled={startNewFeedTimer}
          repeat={false}
        />
        <IntervalTimer
          timeout={60000 * 30}
          callback={() => this.removeSession()}
          enabled={loadFromSession}
          repeat={false}
        />
        <AppBar
          style={this.setStyle()}
          position="fixed"
          className="appBar"
          elevation={0}
        >
          <Toolbar className="MuiToolbarRegular">
            {!showSearchOnly && !isMobileOnly && (
              <div className="headerLogo sectionDesktop">
                <Link to="/">
                  <Logo
                    width={"100px"}
                    height={"36px"}
                    className={`logo-original  ${!isMobileOnly && navlinkChange ? "nav-change" : null
                      }`}
                  />
                </Link>
              </div>
            )}
            {location.pathname == "/" && isMobileOnly && (
              <div className="sectionMobile">
                {isLoggedIn && isMobileOnly && (
                  <IconButton
                    aria-label="show more option"
                    color="inherit"
                    className="headerRightButtons"
                    onClick={this.handleSidebar}
                  >
                    {isLoggedIn && (
                      <Fragment>
                        <img
                          className="userIcon"
                          src={getProfileImageUrl(picUrl, user.id, user.name)}
                          alt=""
                          onError={(e) => {
                            e.target.src = getProfileImageUrl(
                              "",
                              user.id,
                              user.name
                            );
                          }}
                        />
                        <label className="username singleLineName font-bold">
                          {" "}
                          {reduceStringChar(user?.name)}
                        </label>
                      </Fragment>
                    )}
                  </IconButton>
                )}
                {!isLoggedIn && isMobileOnly && (
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={this.handleLoginClick}
                  >
                    {/* <AccountCircle fontSize="large" /> */}
                    <div className="user-non-login"></div>
                    <label className="username2"> Log in </label>
                  </IconButton>
                )}
                <div className="headerLogo-mobile">
                  <Link to="/">
                    <Logo width={"100px"} height={"36px"} />
                  </Link>
                </div>
              </div>
            )}

            {!showSearchOnly && <Search />}
            <div className="grow"></div>
            {!showSearchOnly && (
              <div className="headerRight">
                <div className="sectionDesktop">
                  <IconButton
                    color="inherit"
                    className="uploadBtnHeader"
                    onClick={() => this.navigateTo("/upload")}
                  >
                    <AddIcon width={"20px"} height={"20px"} />
                  </IconButton>
                  {/* {isLoggedIn && (
                    <IconButton color="inherit" className="br-1 bl-1">
                      <Share width={"20px"} height={"20px"} className="share" />
                    </IconButton>
                  )} */}
                  {isLoggedIn && (
                    <IconButton
                      aria-label={`show ${notifyCount} new notifications`}
                      onClick={this.handleNotificationClick}
                      color="inherit"
                      className="br-1"
                    >
                      <Badge
                        badgeContent={notifyCount}
                        color="secondary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Notification
                          width={"22px"}
                          height={"27px"}
                          className="notification"
                        />
                      </Badge>
                    </IconButton>
                  )}
                  {isLoggedIn && !!uploadFileCount && (
                    <IconButton
                      aria-label={`show ${uploadFileCount} new notifications`}
                      onClick={this.handleUploadNotificationClick}
                      color="inherit"
                      className="headerRightButtons uploadProgress desktop-hidden tab-hidden"
                    >
                      <Badge badgeContent={uploadFileCount} color="secondary">
                        <UploadProgress
                          width={"20px"}
                          height={"24px"}
                          className="upload-progress"
                        />
                      </Badge>
                    </IconButton>
                  )}

                  {isLoggedIn && (
                    <IconButton
                      aria-label="show more option"
                      color="inherit"
                      className=""
                      onClick={() => this.navigate("/account")}
                    >
                      <Fragment>

                        <img
                          className="userIcon"
                          src={getProfileImageUrl(picUrl, user.id, user.name)}
                          alt=""
                          onError={(e) => {
                            e.target.src = getProfileImageUrl(
                              "",
                              user.id,
                              user.name
                            );
                          }}
                        />

                        <label className="username singleLineName font-bold">
                          {" "}
                          {reduceStringChar(user ? user.name : "Hi")}
                        </label>
                      </Fragment>
                    </IconButton>
                  )}
                  {!isLoggedIn && (
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-haspopup="true"
                      color="inherit"
                      className="non-user"
                      onClick={this.handleLoginClick}
                    >
                      {/* <AccountCircle fontSize="large" style={{ fontSize: 40 }} /> */}
                      <div className="border-non-user"></div>
                      <div
                        className="user-non-login"
                        style={{
                          backgroundColor: getRandomColor(
                            Math.floor(Math.random() * Math.floor(100))
                          ),
                        }}
                      >
                        <img src={avtaar} alt="" />
                      </div>
                      <label className="username">{t("header.label1")}</label>
                    </IconButton>
                  )}
                  {isLoggedIn && (
                    <IconButton
                      aria-label="show more option"
                      color="inherit"
                      className="headerRightButtons"
                      edge="end"
                      onClick={this.handleSettingModal}
                    >
                      <MoreHoriz />
                    </IconButton>
                  )}
                </div>

                <div className="sectionMobile">
                  {isLoggedIn && (
                    <IconButton color="inherit" className="br-1">
                      <Badge
                        badgeContent={2}
                        color="secondary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Share
                          width={"28px"}
                          height={"30px"}
                          className="share"
                        />
                      </Badge>
                    </IconButton>
                  )}
                  <IconButton color="inherit" className="br-1">
                    <Badge
                      badgeContent={12}
                      color="secondary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Notification
                        width={"22px"}
                        height={"27px"}
                        className="notification"
                      />
                    </Badge>
                  </IconButton>
                </div>
              </div>
            )}
          </Toolbar>
        </AppBar>
        {isMobileOnly ? <MobileSidebar /> : <Sidebar />}
        <NotificationModal
          anchorEl={this.state.anchorEl}
          open={notificationModal}
        ></NotificationModal>
        <SettingModal anchorEl={this.state.settingAnchorEl}></SettingModal>
      </Fragment>
    );
  }
}

//export default Header;
export default compose(withTranslation())(Header);
