import { NAVIGATION } from "../../actions";
import { NavigationStep } from "../../../constant";

const navigationStepReducer = (state = null, action) => {
  switch (action.type) {
    case NAVIGATION.SET_NAVIGATION_STEP:
      return action.step;
    default:
      return state;
  }
};

export default navigationStepReducer;
