import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as BioModal } from "./bio-modal";
import { openModal, closeModal, editUserInfo, updateProfile } from "../../../../store";

const mapStateToProps = (state) => ({
    modal: state.ui.modal.bio || false,
    error: state.auth.error,
    isLoading: state.auth.isLoading,
    themes: state.master.themes,
    user: state.auth.editUser,
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (modal) => dispatch(openModal(modal)),
    closeModal: (modal) => dispatch(closeModal(modal)),
    editUserInfo: (user) => dispatch(editUserInfo(user)),
    updateProfile: () => dispatch(updateProfile()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BioModal));
