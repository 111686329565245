import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as UnfollowAlertModal } from "./unfollow-alert-modal";
import { openModal, closeModal, unFollowUser } from "../../../../store";

const mapStateToProps = state => ({
  modal: state.ui.modal.unfollow_alert || false,
});

const mapDispatchToProps = dispatch => ({
  openModal: modal => dispatch(openModal(modal)),
  closeModal: modal => dispatch(closeModal(modal)),
  unFollowUser: () => dispatch(unFollowUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnfollowAlertModal));
