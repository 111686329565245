import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { ButtonBase } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { ModalType } from "../../../../constant";
import { ProfileImage } from "../../../containers";
import "./theme-modal.scss";
import { Grid, Button } from "@material-ui/core";

class ThemeModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.USER_THEME);
  };

  handleThemeChange = (themeId) => {
    const { user, editUserInfo, themes } = this.props;
    const selectedTheme = themes.filter((theme) => theme.id === parseInt(themeId));
    editUserInfo({ ...user, themeId, themeInfo: !!selectedTheme.length ? selectedTheme[0] : {} });
  }

  render() {
    const { modal, themes, user } = this.props;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper themeModal">
            <div className="modalContent">
              <div className="modalBody">
                <h6>Profile color</h6>
                {!!themes.length && themes.map((theme, i) => (
                  <div className={`temp-box ${theme.id === user.themeId ? "selected" : ""}`} style={{ backgroundColor: theme.backgroundHex, color: theme.textColorHex }} onClick={() => this.handleThemeChange(theme.id)}>Aa</div>
                ))}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default ThemeModal;
