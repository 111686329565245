import { createSelector } from "reselect";
import { includes } from "lodash";
import { isMobileOnly } from "react-device-detect";
const getMergeStateGenre = (state) => isMobileOnly ? [] : state.auth.data.tappedCategoryList;
const getGenreList = (state) => state.content.filter.genresList;
const getGenres = (state) => state.master.genres;

const getActiveFilterGenres = createSelector(
  [getGenreList, getGenres, getMergeStateGenre],
  (selectedGenres, genres, selectedAuthgenre) => {
    //console.log({ selectedGenres, genres, selectedAuthgenre });
    return genres.reduce((u, p) => {
      if (!isMobileOnly) {
        if (includes(selectedGenres, p.id) || includes(selectedAuthgenre, p.id)) {
          u.push({ id: p.id, name: p.name });
        }
        return u;
      } else {
        if (includes(selectedGenres, p.id)) {
          u.push({ id: p.id, name: p.name });
        }
        return u
      }
    }, []);
  }
);

export default getActiveFilterGenres;
