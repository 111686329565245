import { closeModal, clearUploadGenre, clearUploadLanguage } from "../../../../store";
import { connect } from "react-redux";
import { default as CommonModal } from "./common-modal";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.common,
  activeStep: state.upload.activeStep,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: (modal) => dispatch(closeModal(modal)),
  clearUploadGenre: () => dispatch(clearUploadGenre()),
  clearUploadLanguage: () => dispatch(clearUploadLanguage())
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonModal);
