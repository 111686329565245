import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as UserSearchModal } from "./user-search-modal";
import {
  openModal,
  closeModal,
  setSelectedUserID,
  updateProfile,
  loadFollowers,
  loadFollowing,
  followUser,
  unFollowUser,
  setAuthUserID,
  loadOtherUserProfile,
  clearUserId,
  clearContent,
  resetContentPage,
  setContentAction,
  clearSearch,
  loadSearch,
  setFilter,
  clearFilter,
  setCustomFilter,
  clearSearchResult,
  loadSearchUser,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.user_search || false,
  isModalRoute: state.ui.isModalRoute,
  token: state.auth.token,
  error: state.auth.error,
  filter: state.content.filter,
  isLoading: state.auth.isLoading,
  following: state.auth.following,
  followers: state.auth.followers,
  searchUsers: state.auth.searchUsers,
  searchHint: state.search.searchHint,
  loginUser: state.auth.data,
  isLoggedIn: state.auth.isLoggedIn,
  otherUser: state.auth.otherUser,
  userId: state.auth.userId,
  loginUser: state.auth.data,
  isFirstTimeLogin: state.auth.data.isFirstTimeLogin,
});
const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  updateProfile: () => dispatch(updateProfile()),
  loadFollowers: () => dispatch(loadFollowers()),
  loadFollowing: () => dispatch(loadFollowing()),
  setSelectedUserID: (userId) => dispatch(setSelectedUserID(userId)),
  followUser: () => dispatch(followUser()),
  unFollowUser: () => dispatch(unFollowUser()),
  setAuthUserId: (id) => dispatch(setAuthUserID(id)),
  loadOtherUserProfile: () => dispatch(loadOtherUserProfile()),
  clearUserId: () => dispatch(clearUserId()),
  clearContent: () => dispatch(clearContent()),
  resetContentPage: () => dispatch(resetContentPage()),
  setContentAction: (action) => dispatch(setContentAction(action)),
  clearSearch: () => dispatch(clearSearch()),
  loadSearch: (searchHint) => dispatch(loadSearch(searchHint)),
  loadSearchUser: (data) => dispatch(loadSearchUser(data)),
  clearFilter: () => dispatch(clearFilter()),
  setFilter: (filter) => dispatch(setFilter(filter)),
  setCustomFilter: (filter) => dispatch(setCustomFilter(filter)),
  clearSearchResult: () => dispatch(clearSearchResult()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSearchModal)
);
