import { connect } from "react-redux";
import { contentOpen, openModal, setActiveContentIndex, setInitialSlide } from "../../../store";
import { default as Notifications } from "./notifications";

const mapStateToProps = (state) => ({ notifications: state.notification.data });

const mapDispatchToProps = (dispatch) => ({
  setActiveContentIndex: (index) => dispatch(setActiveContentIndex(index)),
  setInitialSlide: (initialSlide) => dispatch(setInitialSlide(initialSlide)),
  openModal: (modal) => dispatch(openModal(modal)),
  contentOpen: () => dispatch(contentOpen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
