import React from "react";
import SocialLogin from "react-social-login";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";

class SocialInstaButton extends React.Component {
  render() {
    return (
      <InstapaperShareButton>
        {" "}
        <InstapaperIcon
          size={36}
          round
          onClick={this.props.triggerLogin}
          {...this.props}
        >
          {this.props.children}
        </InstapaperIcon>
      </InstapaperShareButton>
    );
  }
}

export default SocialLogin(SocialInstaButton);
