import { PREFERENCE } from "../../actions";

const fullNameReducer = (state = "", action) => {
  switch (action.type) {
    case PREFERENCE.CLEAR:
      return "";
    case PREFERENCE.SET_FULL_NAME:
      return action.fullName;
    default:
      return state;
  }
};

export default fullNameReducer;