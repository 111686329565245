import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { AccountCircle, Settings, Message } from "@material-ui/icons";
import { Notification, Logout, Contact } from "../../../icons";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import React, { Fragment, PureComponent } from "react";
import { isMobileOnly } from "react-device-detect";
import {
  LogoutAlert,
  ModalType,
  SelectedText,
  StaticRoutes,
  NavigationStep,
} from "../../../constant";
import { getProfileImageUrl, reduceStringChar } from "../../../core";
import {
  Android,
  AndroidDark,
  Apple,
  AppleDark,
  Bookmark,
  Home,
  Likes,
  Preferences,
  Setting,
  Logo,
  Trending,
  Meme
} from "../../../icons";
import { SidebarNavLink } from "../../containers";
import "./mobile-sidebar.scss";
import { NAVIGATION } from "../../../store";
import { ButtonBase } from "@material-ui/core";
import Avatar from "avataaars";
class MobileSidebar extends PureComponent {
  state = { subMenu: true, isAvatar: false,
   };

  handleLogout = () => {
    const { setAlertAction } = this.props;
    setAlertAction(LogoutAlert);
  };
  getAvatars = () => {
    const avatar = localStorage.getItem("avatar");
    const checkAvatar = avatar ? JSON.parse(avatar) : [];
    if (avatar?.length) {
      this.setState({
        topType: checkAvatar.topType,
        accessoriesType: checkAvatar.accessoriesType,
        hairColor: checkAvatar.hairColor,
        facialHairType: checkAvatar.facialHairType,
        clotheType: checkAvatar.clotheType,
        clotheColor: checkAvatar.clotheColor,
        eyeType: checkAvatar.eyeType,
        eyebrowType: checkAvatar.eyebrowType,
        mouthType: checkAvatar.mouthType,
        skinColor: checkAvatar.skinColor,
        isAvatar: true,
      });
    }
  };
  componentDidMount() {
    //this.getAvatars();
  }
  handleUnauthorizedRoute = () => {
    const { openModal, navlinkChange, showSelectedText } = this.props;
    navlinkChange();
    openModal(ModalType.LOGIN);
    showSelectedText(SelectedText.DISCOVER_TEXT);
  };
  handleOnClose = () => {
    const { closeSidebar } = this.props;
    if (isMobileOnly) {
      closeSidebar();
    }
  };

  handleLoginClick = () => {
    const {
      openModal,
      clearSelectedText,
      closeSidebar,
      setLoginNumber,
      clearOtpSent,
    } = this.props;
    setLoginNumber("");
    clearOtpSent();
    openModal(ModalType.LOGIN);
    clearSelectedText();
    closeSidebar();
  };
  navigate = (url) => {
    const {
      history,
      cancelUserLoadContent,
      cancelLoadContent,
      clearContent,
      resetContentPage,
      clearUserId,
    } = this.props;
    clearUserId();
    cancelLoadContent();
    cancelUserLoadContent();
    clearContent();
    resetContentPage();
    history.push(url);
  };

  render() {
    const {
      sidebar,
      discoverList,
      userDiscoverList,
      isLoggedIn,
      selectedCountries,
      selectedLanguages,
      isDiscoverRoute,
      isDarkTheme,
      picUrl,
      user,
      history,
      openModal,
      setNavigationStep,
      navigation,
      t,
    } = this.props;

    const { subMenu } = this.state;

    return (
      <Drawer
        variant={"temporary"}
        className={clsx("drawer", {
          drawerOpen: sidebar,
          drawerClose: !sidebar,
        })}
        classes={{
          paper: clsx("sidebarStyle", {
            drawerOpen: sidebar,
            drawerClose: !sidebar,
          }),
        }}
        open={sidebar}
        onEscapeKeyDown={this.handleOnClose}
        onBackdropClick={this.handleOnClose}
        ModalProps={{ onBackdropClick: this.handleOnClose }}
      >
        {isLoggedIn && (
          <Fragment>
            <IconButton aria-label="show more option" color="inherit">
              {isLoggedIn && (
                <div className="sidebar-head">
                 
                  <img
                    className="largeIcon roundedimg"
                    src={getProfileImageUrl(picUrl, user.id, user.name)}
                    alt=""
                    onError={(e) => {
                      e.target.src = getProfileImageUrl("", user.id, user.name);
                    }}
                    onClick={() => this.navigate("/account")}
                  />
                  <div className="name-label singleLineName">
                    <label className="username">
                      {" "}
                      {reduceStringChar(user ? user.name : "Hi")}
                    </label>
                    <span
                      className="edit-profile"
                      onClick={() => this.navigate("/edit-profile")}
                    >
                      {t("sidebar.title9")}
                    </span>
                  </div>
                </div>
              )}
            </IconButton>
          </Fragment>
        )}
        {!isLoggedIn && (
          <Fragment>
            <div className="notlogin">
              {/* <label> Welcome to</label> */}
              <Logo width={"100px"} height={"36px"} />
              <button
                className={"submitButton no-shadow"}
                type="button"
                onClick={this.handleLoginClick}
              >
                {t("sidebar.title8")}
              </button>{" "}
            </div>
          </Fragment>
        )}

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className="nestedRoot"
        >
          {isLoggedIn && (
            <Fragment>
              <SidebarNavLink
                exact
                listItemClass={`NavItem NavItemMyLikes mobile-menu ${navigation === NavigationStep.LIKE ? "active" : ""
                  }`}
                itemTextClass="NavItemText"
                to={"/discover/my-likes"}
                title={t("sidebar.title7")}
                setNavigation={() => setNavigationStep(NavigationStep.LIKE)}
              >
                <IconButton>
                  <Likes
                    height={"16px"}
                    width={"16px"}
                    className="sidebar-icon"
                  />
                </IconButton>
              </SidebarNavLink>
              <SidebarNavLink
                exact
                listItemClass={`NavItem NavItemBookmark mobile-menu ${navigation == NavigationStep.BOOKMARK ? "active" : ""
                  }`}
                itemTextClass="NavItemText"
                activeClass="active"
                to={"/bookmark"}
                title={t("sidebar.title4")}
                setNavigation={() => setNavigationStep(NavigationStep.BOOKMARK)}
              >
                <IconButton>
                  <Bookmark
                    height={"16px"}
                    width={"16px"}
                    className="sidebar-icon"
                  />
                </IconButton>
              </SidebarNavLink>
              <SidebarNavLink
                exact
                listItemClass="NavItem NavItemMeme mobile-menu"
                itemTextClass="NavItemText"
                activeClass="active"
                to={"/meme"}
                title={t("sidebar.title10")}
              >
                <IconButton>
                  <Meme
                    height={"16px"}
                    width={"16px"}
                    className="sidebar-icon"
                  ></Meme>
                </IconButton>
              </SidebarNavLink>

            </Fragment>
          )}

          <SidebarNavLink
            exact
            listItemClass={`NavItem NavItemPreferences mobile-menu ${navigation == NavigationStep.SETTINGS ? "active" : ""
              }`}
            itemTextClass="NavItemText"
            activeClass="active"
            to={"/settings"}
            title={t("settingModal.title1")}
            setNavigation={() => setNavigationStep(NavigationStep.SETTINGS)}
          >
            <IconButton>
              <Preferences
                height={"15px"}
                width={"15px"}
                className="sidebar-icon"
              />
            </IconButton>
          </SidebarNavLink>

          <SidebarNavLink
            exact
            listItemClass="NavItem NavItemPreferences mobile-menu"
            itemTextClass="NavItemText"
            activeClass="none"
            to={"/notifications"}
            title={t("sidebar.title6")}
            setNavigation={() => setNavigationStep(NavigationStep.NOTIFICATION)}
          >
            <IconButton>
              <Notification
                height={"16px"}
                width={"16px"}
                className="sidebar-icon"
              />
            </IconButton>
          </SidebarNavLink>
          <SidebarNavLink
            exact
            listItemClass="NavItem NavItemPreferences mobile-menu"
            itemTextClass="NavItemText"
            activeClass="active"
            to={"/Contact"}
            title={t("contact.title")}
            setNavigation={() => setNavigationStep(NavigationStep.CONTACT)}
          >
            <IconButton>
              <Contact
                height={"16px"}
                width={"16px"}
                className="sidebar-icon"
              />
            </IconButton>
          </SidebarNavLink>
        </List>
        {isLoggedIn && (
          <ListItem
            className="NavItem NavItemCountry logout-sidebar-mobile"
            title="Country"
            button
            style={{ color: "#ff3636" }}
            onClick={this.handleLogout}
          >
            <Logout
              height={"16px"}
              width={"16px"}
              className="sidebar-icon logoutmobile"
            />
            {t("settingModal.title2")}
          </ListItem>
        )}
      </Drawer>
    );
  }
}

export default compose(withTranslation())(MobileSidebar);
