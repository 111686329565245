import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as SoundSettingModal } from "./sound-setting-modal";
import {
  closeModal,
  openModal,
  isServer,
  muteAudio,
  unMuteAudio,
  changeAudioLevel,
  contentClose,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.sound_setting || false,
  isServer,
  audioMuted: state.content.audioMuted,
  audioLevel: state.content.audioLevel,
  token: state.auth.token,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: (modal) => dispatch(closeModal(modal)),
  openModal: (modal) => dispatch(openModal(modal)),
  contentClose: () => dispatch(contentClose()),
  muteAudio: () => dispatch(muteAudio()),
  unMuteAudio: () => dispatch(unMuteAudio()),
  changeAudioLevel: (value) => dispatch(changeAudioLevel(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    SoundSettingModal
  )
);
