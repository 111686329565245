import { connect } from "react-redux";
import { default as Grid } from "./grid";
import {
  loadContent,
  setContentPageChange,
  setContentAction,
  clearContent,
  loadContentFromApi,
  hideNewfeed,
  loadClient,
  setActiveContentIndex,
  setInitialSlide,
  setShareType,
  loadShareLink,
  openModal,
  closeModal,
  setContentPerPageItemChange,
  clearFilter,
} from "../../../store";

const mapStateToProps = (state) => ({
  contentList: state.content.data,
  currentPage: state.content.currentPage,
  contentAction: state.content.contentAction,
  isLoading: state.content.isLoading,
  isCacheContent: state.content.isCacheContent,
  totalPage: state.content.totalPage,
  sidebar: state.ui.sidebar,
  newFeed: state.ui.newFeed,
  routeFromLightbox: state.content.routeFromLightbox,
  loadFromSession: state.content.loadFromSession,
  loadFromSsr: state.content.loadFromSsr,
  notFound: state.content.notFound,
  filter: state.content.filter,
  modal: state.ui.modal.share_popper_grid || false,
  notificationModal: state.ui.modal.notification,
  settingModal: state.ui.modal.setting,
  reportModal: state.ui.modal.report_user_detail || false,
  moreOptionModal: state.ui.modal.more_option_detail || false,
  activeContentIndex: state.content.activeContentIndex || 0,
  activeContent: state.content.activeContent,
  authSounds: state.auth.data.ambientSoundList,
  token: state.auth.token,
  isLoggedIn: state.auth.isLoggedIn,
  contentdetail: state.ui.modal.content_detail
});

const mapDispatchToProps = (dispatch) => ({
  loadContent: () => dispatch(loadContent()),
  clearContent: () => dispatch(clearContent()),
  setContentAction: (action) => dispatch(setContentAction(action)),
  setContentPageChange: (currentPage) =>
    dispatch(setContentPageChange(currentPage)),
  loadContentFromApi: () => dispatch(loadContentFromApi()),
  hideNewfeed: () => dispatch(hideNewfeed()),
  loadClient: () => dispatch(loadClient()),
  setActiveContentIndex: (index) => dispatch(setActiveContentIndex(index)),
  setInitialSlide: (initialSlide) => dispatch(setInitialSlide(initialSlide)),
  setShareType: (type) => dispatch(setShareType(type)),
  loadShareLink: () => dispatch(loadShareLink()),
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  setContentPerPageItemChange: (page) =>
    dispatch(setContentPerPageItemChange(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Grid);
