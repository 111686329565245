import { UPLOAD } from "../../actions";
import { indexOf, remove } from "lodash";

const storyGenreReducer = (state = [], action) => {
  switch (action.type) {
    case UPLOAD.CLEAR:
    case UPLOAD.CLEAR_CONTENT:
      return [];
    case UPLOAD.SET_STORY_UPLOAD_GENRE: {
      return action.storyUploadGenre;
    }
    default:
      return state;
  }
};

export default storyGenreReducer;
