import { ButtonBase, ClickAwayListener } from "@material-ui/core";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import React, { useRef, useState } from "react";
// import { Label } from '@material-ui/icons';
import styled from "styled-components";
import { getProfileImageUrl } from "../../../../core";
import { Cross } from "../../../../icons";
import { Search } from "../../../../icons";
import "./fc-autocomplete.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const InputWrapper = styled("div")`
  &.custom-search {
    border: none;
    background-color: var(--input-bg);
    border-radius: 8px;
    padding: 7px;
    display: inline-block;
    width: 93%;

    &:hover {
      border-color: none;
    }

    & input {
      font-size: 14px !important;
      background: transparent;
      box-sizing: border-box;
      width: 0;
      color: var(--text-color);
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
      // font-family: "lato-bold";
    }
    .down-icon {
      fill: #525252;
      font-size: 2em;
      cursor: pointer;
    }
  }
`;

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const Listbox = styled("ul")`
  padding: 0;
  position: absolute;
  left: -45px;
  min-width: 50%;
  list-style: none;
  font-size: 14px !important;
  // font-family: "lato-bold";
  border: 1px solid var(--background-color);
  background-color: var(--background-color-contrast);
  overflow: auto;
  color: var(--text-color);
  max-height: 350px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 20px 24px 0 rgba(0, 0, 0, 0.12);
  z-index: 3;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }

  & li {
    margin: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .search {
      img {
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    & .qty {
      text-align: right;
      color: #b0b0b0;
      margin-left: 5px;
    }

    & svg {
      color: var(--select-option-circle-color);
      font-size: 30px;
    }
  }

  & li[aria-selected="true"] {
    font-weight: 600;

    & svg {
      color: var(--select-option-selected-circle-color);
    }
  }

  & li[data-focus="true"] {
    background-color: transparent;
    cursor: pointer;

    & svg {
      color: var(--select-option-selected-circle-color);
    }
  }
  .query {
    color: var(--primary-color);
    padding: 10px;
  }
`;

const FcAutocomplete = (props) => {
  //const [open, setPopUpState] = useState(false);
  const [focus, setFocusState] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [initialOpen, setInitialOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const handleChange = (e, val) => {
    const { onChangeSelect, optionLabel } = props;
    if (onChangeSelect) {
      const onChangeVal = val ? val[optionLabel] : "";
      onChangeSelect(onChangeVal);
    }
  };
  const handleClose = () => {
    setFocusState(false);
    setOpen(false);
  };

  const handleInputChange = (event, newInputValue) => {
    const { onInputChange } = props;
    setInputValue(newInputValue);
    onInputChange(newInputValue);
    setOpen(true);
    // if (newInputValue.length > 0 && initialOpen) {
    //   setOpen(true);
    // } else {
    //   setOpen(false);
    // }
    // setInitialOpen(true);
  };
  const handleOpen = () => {
    const { searchWithCategory } = props;
    setOpen(!open);
    searchWithCategory();
    //console.log(inputRef);
    //document.getElementById("input1").focus();
  };
  const handleFocus = () => { };
  const handleSearchFor = () => {
    const { onEnter } = props;
    onEnter()
    setOpen(!open);
  }
  const {
    field,
    source,
    optionValue,
    optionLabel,
    label,
    multiple,
    placeholder,
    val,
    onEnter,
    clearSearch,
    t,
    searchWithCategory,
  } = props;
  //{searchString: "Users", type: "USER_SEARCH", count: 1}
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    value: { searchString: val },
    // defaultValue: "",
    options: source,
    getOptionLabel: (option) => {
      return option.searchString;
    },
    onChange: handleChange,
    onClose: handleClose,
    open: open || false,
    inputValue: inputValue,
    onInputChange: handleInputChange,
    freeSolo: true,
    disableClearable: true,
  });
  const removeFocus = () => {
    setFocusState(false);
  };
  const inputRef = useRef(null);
  return (
    <div className="relative">
      <div {...getRootProps()}>
        {/* <Label {...getInputLabelProps()}>{label}</Label> */}
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? "focused custom-search" : "custom-search"}
        >
          <input
            style={{ width: "100%" }}
            ref={inputRef}
            onClick={handleOpen}
            {...getInputProps()}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                onEnter();
                setOpen(!open);
              }
            }}
          />
        </InputWrapper>

        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()} className="suggestion">
            {groupedOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => console.log(option)}
                {...getOptionProps({ option, index })}
              >
                <div className="search">
                  <img
                    className="userIcon"
                    src={getProfileImageUrl("", "", option[optionLabel])}
                    alt=""
                    style={{ fontSize: 30 }}
                  />
                  <span>{option[optionLabel]}</span>
                </div>
                <div className="qty">
                  {option[optionLabel] === "Users" ? (
                    <span>{option["count"]} users</span>
                  ) : (
                    <span>{option["count"]} {t("search.span3")}</span>
                  )}
                </div>
              </li>
            ))}
            <ul>
              <li>
                <div className="searchfor" onClick={handleSearchFor}>
                  {/* <SearchIcon className="search-icon" /> */}
                  <Search className="discover-m" />
                  <span className="query">{t("search.span1")} "{inputValue}"</span>
                </div>
                <div className="qty">
                  <span>{t("search.span2")}</span>
                </div>
              </li>
            </ul>
          </Listbox>
        ) : inputValue && source.length == 0 && open ? (
          <Listbox {...getListboxProps()} className="suggestion">
            <ul>
              <li>
                <div className="searchfor" >
                  {/* <SearchIcon className="search-icon" /> */}
                  <Search className="discover-m" />
                  <span className="query">
                    {" "}
                    {t("search.span1")} <span>"{inputValue}" </span>
                  </span>
                </div>
                {/* <div className="qty">
                  <span>0 posts</span>
                </div> */}
              </li>
            </ul>
          </Listbox>
        ) : null}

        {!!val && (
          <ButtonBase onClick={clearSearch}>
            <Cross width={"16px"} height={"16px"} className="cross" />
          </ButtonBase>
        )}
      </div>
    </div >
  );
};

//export default FcAutocomplete;
export default compose(withTranslation())(FcAutocomplete);
