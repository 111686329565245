import React, { PureComponent, Fragment } from "react";
import { indexOf, remove } from "lodash";
import { ButtonBase } from "@material-ui/core";
import { Cross } from "../../../../icons";
import Slider from "./slider";
import "./genre.scss";

class Genre extends PureComponent {
  onFilterClick = (genreId, empty = false) => {
    const { setFilter, filter, genres, setGenre } = this.props;
    const { genresList } = filter;
    let genre = [...genresList];
    let makeNewGenre;
    if (empty) {
      genre = [];
    } else {
      if (indexOf(genresList, genreId) === -1) {
        genre.push(genreId);
      } else {
        remove(genre, (el) => genreId === el);
      }
      makeNewGenre = [
        ...genres.filter((g) => genre.includes(g.id)),
        ...genres.filter((g) => !genre.includes(g.id)),
      ];
      console.log(makeNewGenre);
      setGenre(makeNewGenre);
    }
    setFilter({ ...filter, genresList: genre });
  };

  render() {
    const { selectedGenres } = this.props;
    return (
      <Fragment>
        {/* <label className="spl-btn active">All</label>
        <label className="spl-btn">Motivational</label>
        <label className="spl-btn">Sports Inpirational</label>
        <label className="spl-btn">Religion</label>
        <label className="spl-btn">Comedy</label>
        <label className="spl-btn">&#8226;&#8226;&#8226;</label> */}

        <Slider onFilterClick={this.onFilterClick} />
        {/* <div className="filter-btn">
          {selectedGenres.map((genre, index) => (
            <div key={genre.id || index} className="f-btn">
              <span className="genre-initial">{genre.name.charAt(0)}</span>
              <span className="text">{genre.name}</span>
              <span className="cancel">
                <ButtonBase onClick={() => this.onFilterClick(genre.id)}>
                  <Cross width={"16px"} height={"16px"} className="cross" />
                </ButtonBase>
              </span>
            </div>
          ))}
        </div> */}
      </Fragment>
    );
  }
}

export default Genre;
