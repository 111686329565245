import { PREFERENCE } from "../../actions";

const phoneReducer = (state = "", action) => {
  switch (action.type) {
    case PREFERENCE.CLEAR:
      return "";
    case PREFERENCE.SET_PHONE:
      return action.phone;
    default:
      return state;
  }
};

export default phoneReducer;