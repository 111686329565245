import React from "react";
import "./contact.scss";

const Contact = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 33 22"
    style={{ enableBackground: "new 0 0 33 22" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Tymoff-app" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Menu" transform="translate(-43.000000, -829.000000)" fill="#6C7B8A" fill-rule="nonzero">
        <g id="menu" transform="translate(0.000000, 139.000000)">
          <g id="Icons-/-email" transform="translate(43.000000, 690.000000)">
            <path
              d="M29.5625,0 C31.45725,0 33,1.54275 33,3.4375 L33,18.5625 C33,20.45725 31.45725,22 29.5625,22 L3.4375,22 C1.54275,22 0,20.45725 0,18.5625 L0,3.4375 C0,1.54275 1.54275,0 3.4375,0 L29.5625,0 Z M29.5625,1.375 L3.4375,1.375 C2.300375,1.375 1.375,2.300375 1.375,3.4375 L1.375,18.5625 C1.375,19.699625 2.300375,20.625 3.4375,20.625 L29.5625,20.625 C30.699625,20.625 31.625,19.699625 31.625,18.5625 L31.625,3.4375 C31.625,2.300375 30.699625,1.375 29.5625,1.375 Z M12.2155,11.246125 C12.458875,11.537625 12.419,11.972125 12.1275,12.2155 L3.8775,19.0905 C3.749625,19.19775 3.592875,19.25 3.4375,19.25 C3.2395,19.25 3.04425,19.166125 2.908125,19.0025 C2.66475,18.711 2.704625,18.2765 2.996125,18.033125 L11.246125,11.158125 C11.537625,10.916125 11.972125,10.956 12.2155,11.246125 Z M21.753875,11.158125 L30.003875,18.033125 C30.295375,18.2765 30.33525,18.709625 30.091875,19.0025 C29.954375,19.166125 29.759125,19.25 29.5625,19.25 C29.407125,19.25 29.25175,19.19775 29.1225,19.0905 L20.8725,12.2155 C20.581,11.972125 20.541125,11.539 20.7845,11.246125 C21.027875,10.956 21.461,10.916125 21.753875,11.158125 Z M3.82525,2.871 L15.101625,10.586125 C15.95,11.166375 17.04725,11.166375 17.897,10.586125 L29.173375,2.871 C29.48825,2.657875 29.915875,2.73625 30.129,3.04975 C30.3435,3.36325 30.26375,3.790875 29.95025,4.005375 L18.673875,11.7205 C18.013875,12.172875 17.25625,12.398375 16.5,12.398375 C15.74375,12.398375 14.986125,12.172875 14.326125,11.7205 L3.04975,4.005375 C2.73625,3.790875 2.6565,3.36325 2.869625,3.04975 C3.08275,2.73625 3.510375,2.655125 3.82525,2.871 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { Contact };
