import React from "react";
import "./setting.scss";

const Setting = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 40.7 40.7"
    style={{ enableBackground: "new 0 0 40.7 40.7" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g transform="translate(1.000000, 1.000000)">
        <g>
          <path
            className="primary"
            d="M19.2,1.5c0.7,0,1.3,0,2,0.1c0.5,0.1,0.9,0.5,0.9,1C22,4.3,23,5.8,24.5,6.4
				c0.5,0.2,1,0.3,1.5,0.3c1.1,0,2.2-0.4,3-1.3c0.2-0.2,0.5-0.3,0.7-0.3s0.4,0.1,0.6,0.2c1,0.8,2,1.8,2.8,2.9c0.3,0.4,0.3,1-0.1,1.3
				c-0.9,0.8-1.3,1.9-1.3,3c0,2.2,1.8,3.9,3.9,3.9h0.1l0,0c0.5,0,1,0.4,1.1,0.9l0,0c0.2,1.3,0.2,2.7,0,4.1c-0.1,0.5-0.5,0.9-1,0.9
				c0,0,0,0-0.1,0h-0.2c-1.6,0-3,1-3.6,2.4c-0.6,1.5-0.2,3.3,1,4.5c0.4,0.4,0.4,1,0,1.4c-0.9,1.1-1.8,2-2.9,2.9
				c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.5-0.1-0.7-0.3c-0.8-0.8-1.9-1.3-3-1.3c-0.5,0-1,0.1-1.4,0.3c-1.5,0.6-2.5,2.2-2.4,3.8
				c0,0.5-0.4,1-0.9,1c-0.6,0.1-1.3,0.1-1.9,0.1l0,0c-0.7,0-1.4,0-2.1-0.1c-0.5-0.1-0.9-0.5-0.9-1c0.1-1.7-0.9-3.3-2.4-3.9
				c-0.5-0.2-1-0.3-1.5-0.3c-1.1,0-2.2,0.4-3,1.3c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.4-0.1-0.6-0.2c-1-0.8-2-1.8-2.8-2.9
				C4.8,30,4.8,29.4,5.2,29c1.2-1.1,1.6-2.9,1-4.4s-2-2.4-3.6-2.4l0,0l0,0c-0.5,0-1-0.4-1.1-0.9c-0.2-1.3-0.2-2.7,0-4.1
				c0-0.5,0.5-0.9,1-0.9h0.1h0.1c1.6,0,3.1-0.9,3.7-2.4s0.2-3.3-1-4.5C5,9,5,8.5,5.3,8c0.9-1.1,1.8-2,2.9-2.9C8.4,5,8.6,4.9,8.8,4.9
				c0.3,0,0.6,0.1,0.8,0.4c0.8,0.8,1.9,1.3,3,1.3c0.5,0,1-0.1,1.4-0.3c1.5-0.6,2.5-2.2,2.5-3.8c0-0.5,0.4-1,0.9-1
				C17.9,1.5,18.5,1.5,19.2,1.5 M37.4,14.4c-0.5-0.2-1-0.4-1.6-0.4c-0.9,0-1.6-0.7-1.6-1.5c0-0.4,0.2-0.9,0.5-1.2
				c1.3-1.2,1.5-3.2,0.4-4.6c-0.9-1.2-2-2.3-3.2-3.2c-0.6-0.5-1.4-0.7-2.1-0.7c-1,0-1.9,0.4-2.5,1.1c-0.3,0.3-0.7,0.5-1.2,0.5
				c-0.2,0-0.4,0-0.6-0.1c-0.7-0.3-1-0.9-1-1.5c0.1-1.8-1.2-3.4-3-3.6c-0.8-0.1-1.5-0.1-2.3-0.1s-1.5,0-2.3,0.1
				c-1.7,0.2-3.1,1.7-3,3.5c0,0.6-0.4,1.2-0.9,1.5c-0.2,0.1-0.4,0.1-0.5,0.1c-0.5,0-0.9-0.2-1.2-0.5c-0.6-0.7-1.6-1.1-2.5-1.1
				C8,2.7,7.3,3,6.7,3.4c-1.2,1-2.3,2.1-3.3,3.3c-1.1,1.4-1,3.4,0.4,4.6c0.5,0.4,0.6,1.1,0.4,1.7c-0.3,0.6-0.9,1-1.5,1
				c-0.1,0-0.1,0-0.2,0c-1.7,0-3.2,1.3-3.4,3c-0.1,1.4-0.1,3,0,4.5v0.2v0.2c0.4,1.6,1.8,2.7,3.5,2.7h0.1c0.6,0,1.2,0.4,1.4,1
				c0.2,0.6,0.1,1.3-0.4,1.7c-1.3,1.2-1.5,3.2-0.4,4.6c0.9,1.2,2,2.3,3.2,3.2c0.6,0.5,1.3,0.8,2.1,0.8c0.9,0,1.8-0.4,2.4-1
				c0.3-0.4,0.8-0.5,1.3-0.5c0.2,0,0.4,0,0.6,0.1c0.6,0.3,1,0.9,0.9,1.5c-0.1,1.8,1.2,3.4,3,3.6c0.8,0.1,1.5,0.1,2.3,0.1
				s1.5,0,2.2-0.1c1.7-0.2,3.1-1.7,3-3.5c0-0.6,0.3-1.2,0.9-1.5c0.2-0.1,0.4-0.1,0.5-0.1c0.5,0,0.9,0.2,1.2,0.5
				c0.6,0.7,1.5,1.1,2.5,1.1c0.8,0,1.5-0.3,2.1-0.7c1.2-1,2.3-2,3.2-3.2c1.2-1.3,1.1-3.4-0.2-4.6c-0.5-0.4-0.6-1.1-0.4-1.7
				c0.2-0.6,0.8-0.9,1.4-1h0.1h0.1c1.7,0,3.2-1.3,3.4-3c0.2-1.5,0.2-3.1,0-4.6C39.3,17.1,38.9,14.8,37.4,14.4z"
          />
          <path className="secondary" d="M13,19.2c0-3.4,2.7-6.1,6.1-6.1s6.1,2.7,6.1,6.1s-2.7,6.1-6.1,6.1C15.8,25.3,13,22.7,13,19.2z" />
        </g>
      </g>
    </g>
  </svg>
);

export { Setting };
