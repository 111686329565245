import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { ButtonBase, TextField } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { ContentAction, ModalType } from "../../../../constant";
import { getProfileImageUrl } from "../../../../core";
import { ProfileImage } from "../../../containers";
import "./user-search-modal.scss";
import { Grid, Button } from "@material-ui/core";
import { loadFollowing, openModal } from "../../../../store";
import SearchIcon from "@material-ui/icons/Search";
import { FcAutocomplete } from "../../fc";
import Autosuggest from "react-autosuggest";
import { set } from "lodash";

const localId = [];
class UserSearchModal extends PureComponent {
  constructor(props) {
    super(props);
    const { loadFollowers, loadSearchUser, searchUsers, searchHint } = props;
    this.state = {
      value: "",
      suggestions: [],
      followedId: []
    };

  }

  handleClose = () => {
    const { closeModal, openModal, isFirstTimeLogin, clearSearch, history } = this.props;
    clearSearch();

    if (history.location.pathname !== "/search") {
      openModal(ModalType.CLOSE_MEME_DIALOG);
    } else {
      closeModal(ModalType.USERSEARCH);
    }

  };

  // Render Each Option

  componentDidMount() {
    const { loadSearchUser, loadFollowing } = this.props;
    let response = loadSearchUser("1");

    //console.log(response);
  }

  searchUser = (e) => {

    const { loadSearchUser } = this.props;
    if (e.target.value) {
      loadSearchUser(e.target.value);
    } else {
      loadSearchUser("1");
    }


  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  followUser = (id) => {

    localId.push(id)
    let newId = [...new set(localId)]
    this.setState({ followedId: newId })
    const {
      setSelectedUserID,
      followUser,
      isLoggedIn,
      openModal,
      loadFollowing,
      loadFollowers
    } = this.props;
    if (isLoggedIn) {
      setSelectedUserID(id);
      followUser();

    } else {
      openModal(ModalType.LOGIN);
    }
  };

  unfollowUser = (id) => {
    /*  let isId = localId.includes(id)
     if (isId) {
       localId.pop(id)
     } */

    let newId = [...new set(localId)]
    this.setState({ followedId: newId })
    const { setSelectedUserID, loadFollowers, loadFollowing, openModal } = this.props;
    setSelectedUserID(id);
    openModal(ModalType.UNFOLLOW_ALERT);
  };
  navigate = (userId) => {
    const {
      history,
      setAuthUserId,
      loadOtherUserProfile,
      clearUserId,
      clearContent,
      resetContentPage,
      setContentAction,
    } = this.props;
    if (userId) {
      clearContent();
      resetContentPage();
      clearUserId();
      setAuthUserId(userId);
      setContentAction(ContentAction.upload);
      loadOtherUserProfile();
      this.handleClose();
      history.push({
        pathname: `/account/${userId}`,
        state: { userId },
      });
    }
  };

  isIdExist = (id) => {
    console.log(this.state.followedId)
    if (this.state.followedId.length) {
      let isId = this.state.followedId.includes(id)
      return true
    } else {
      return false
    }
  }
  render() {
    const {
      modal,
      followers,
      following,
      isLoading,
      isLoggedIn,
      loginUser,
      searchUsers,
      searchHint,
      openModal,
      userId,
      otherUser,
      searchResults,
      isFirstTimeLogin,
      history,
    } = this.props;
    const { value, suggestions } = this.state;
    //console.log(following)
    //console.log({ searchUsers })
    const isUsers = searchUsers?.length && Array.isArray(searchUsers) ? searchUsers : []
    const user = userId ? otherUser : loginUser;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper followersModal firstTime">

            <div className="p-50">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {/* <div className="back-btn bio"></div> */}

                  <div>
                    {history.location.pathname !== "/search" ? <h1 id="transition-modal-title" className="heading modalTitle font-bold mobile-hidden">
                      Follow amazing profiles!
                    </h1 > : <h1 id="transition-modal-title" className="heading modalTitle font-bold mobile-hidden"> Users</h1>}
                  </div>
                </Grid>
              </Grid>
              <div className="sub-header text-left tab-hidden">
                <button
                  className="back-btn tab-hidden"
                  onClick={this.handleClose}
                ></button>
                {history.location.pathname !== "/search" ? <h1> Follow amazing profiles!</h1> : <h1> Users</h1>}
              </div>
              {/* <button
              className="closeBtn mobile-hidden"
              onClick={this.handleClose}
            ></button>
            <button
              className="back-btn tab-hidden"
              onClick={this.handleClose}
            ></button> */}


              <div className="modalContent">
                {/* <div className="modalHeader mobile-hidden">
                <h1 className="heading modalTitle">Follow amazing profiles!</h1>
              </div> */}
                <div className="followerSearch">
                  <TextField variant="outlined" placeholder='Search profile' onChange={this.searchUser} />
                </div>
                <div className="modalBody">

                  {history.location.pathname === "/search" ? <div className="follow-list">
                    {isLoading && (
                      <div className="shimmer-follower">
                        <div className="follow-item">
                          <div className="follow-name ">
                            <div className="img"></div>
                            <span></span>
                          </div>
                          <div className="follow-btn"></div>
                        </div>
                        <div className="follow-item">
                          <div className="follow-name">
                            <div className="img"></div>
                            <span></span>
                          </div>
                          <div className="follow-btn"></div>
                        </div>
                        <div className="follow-item">
                          <div className="follow-name">
                            <div className="img"></div>
                            <span></span>
                          </div>
                          <div className="follow-btn"></div>
                        </div>
                        <div className="follow-item">
                          <div className="follow-name">
                            <div className="img"></div>
                            <span></span>
                          </div>
                          <div className="follow-btn"></div>
                        </div>
                      </div>
                    )}
                    { }
                    {!isLoading &&
                      searchHint === "Users" &&
                      !!searchUsers.length ? (
                      searchUsers.map((user, i) => (
                        <div key={i} className="follow-item">
                          <div
                            className="follow-name singleLineName"
                            onClick={() => {
                              isLoggedIn
                                ? this.navigate(user.id)
                                : openModal(ModalType.LOGIN);
                            }}
                          >
                            <img
                              src={getProfileImageUrl(
                                user.picUrl,
                                user.id,
                                user.name
                              )}
                              alt=""
                              onError={(e) => {
                                e.target.src = getProfileImageUrl(
                                  "",
                                  user.id,
                                  user.name
                                );
                              }}
                            />
                            <span>{user.name}</span>
                          </div>
                          {user.id !== loginUser.id && (
                            <div className="follow-btn">
                              {!!following.find(u => (u.id === user.id && u.isFollowingLoggedUser)) ? (
                                <Button
                                  className="f-btn f-btn-dis"
                                  onClick={() => this.unfollowUser(user.id)}
                                >
                                  Following
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  className="f-btn"
                                  onClick={() => {
                                    //console.log(user.id);
                                    this.followUser(user.id);
                                  }}
                                >
                                  Follow
                                </Button>
                              )}
                            </div>
                          )}
                        </div>
                      ))
                    ) : isLoading ? null : (
                      <div className="txt-cntr">No user found.</div>
                    )}
                  </div> : <div className="follow-list">
                    {isLoading && (
                      <div className="shimmer-follower">
                        <div className="follow-item">
                          <div className="follow-name ">
                            <div className="img"></div>
                            <span></span>
                          </div>
                          <div className="follow-btn"></div>
                        </div>
                        <div className="follow-item">
                          <div className="follow-name">
                            <div className="img"></div>
                            <span></span>
                          </div>
                          <div className="follow-btn"></div>
                        </div>
                        <div className="follow-item">
                          <div className="follow-name">
                            <div className="img"></div>
                            <span></span>
                          </div>
                          <div className="follow-btn"></div>
                        </div>
                        <div className="follow-item">
                          <div className="follow-name">
                            <div className="img"></div>
                            <span></span>
                          </div>
                          <div className="follow-btn"></div>
                        </div>
                      </div>
                    )}
                    { }
                    {!isLoading &&

                      isUsers.length ? (
                      isUsers.map((user, i) => (
                        <div key={i} className="follow-item">
                          <div
                            className="follow-name singleLineName"
                            onClick={() => {
                              isLoggedIn
                                ? this.navigate(user.id)
                                : openModal(ModalType.LOGIN);
                            }}
                          >
                            <img
                              src={getProfileImageUrl(
                                user.picUrl,
                                user.id,
                                user.name
                              )}
                              alt=""
                              onError={(e) => {
                                e.target.src = getProfileImageUrl(
                                  "",
                                  user.id,
                                  user.name
                                );
                              }}
                            />
                            <span>{user.name}</span>
                          </div>
                          {user.id !== loginUser.id && (
                            <div className="follow-btn">
                              {!!following.find(u => (u.id === user.id && u.isFollowingLoggedUser)) ? (
                                <Button
                                  className="f-btn f-btn-dis"
                                  onClick={() => this.unfollowUser(user.id)}
                                >
                                  Following
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  className="f-btn"
                                  onClick={() => {
                                    //console.log(user.id);
                                    this.followUser(user.id);
                                  }}
                                >
                                  Follow
                                </Button>
                              )}
                            </div>
                          )}
                        </div>
                      ))
                    ) : isLoading ? null : (
                      <div className="txt-cntr">No user found.</div>
                    )}
                  </div>}
                </div>
                {/* <div className="modalFooter mobile-hidden"></div> */}
              </div>
            </div> </div>
        </Fade>
      </Modal>
    );
  }
}

export default UserSearchModal;

//|| true === this.isIdExist(user.id)