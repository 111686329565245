import React from "react";
import { ButtonBase, Popper, ClickAwayListener } from "@material-ui/core";
import "./genre-modal.scss";

const GenreModal = (props) => {
  const renderItems = (genres, onFilterClick) =>
    genres.map((genre, index) => {
      const isActive = !!props.selectedGenres.find((elem) => elem.id === genre.id);
      return (
        <ButtonBase key={genre.id || index} onClick={() => onFilterClick(genre.id)}>
          <div className={`spl-btn ${isActive ? "active" : ""}`}>{genre.name}</div>
        </ButtonBase>
      );
    });

  const { genres, onFilterClick, open, anchorEl, numberOfGenre, handleCloseGenre } = props;
  const restOfGenres = genres.slice(numberOfGenre, genres.length);
  const items = renderItems(restOfGenres, onFilterClick);
  return (
    <Popper open={open} anchorEl={anchorEl} placement={"bottom-start"} transition className="genre-dropdown">
      <ClickAwayListener onClickAway={handleCloseGenre}>
        <div className="paper login-modal ">
          <div className="modalContent">{items}</div>
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

export default GenreModal;
