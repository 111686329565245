import { Grid, Switch, TextField } from "@material-ui/core";
import React, { Fragment, PureComponent } from "react";
import { ModalType, UploadStep } from "../../../../../constant";
import { StoryEditor } from "../../../../containers";
import "./story-upload.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class StoryUpload extends PureComponent {
  handleTitleChange = (title) => {
    const { setUploadStoryTitle } = this.props;
    setUploadStoryTitle(title);
  };
  handleLanguageChange = (lang) => {
    const { setUploadStoryLanguage } = this.props;
    setUploadStoryLanguage(lang);
  };
  handleGenreChange = (genre) => {
    const { setUploadGenre, setStoryUploadGenre } = this.props;
    setStoryUploadGenre(genre);
  };
  handleModal = (modalType) => {
    const {
      openModal,
      setUploadStep,
      updateUploadGenre,
      storyUploadGenre,
      storyLanguage,
      updateUploadLanguage,
    } = this.props;
    setUploadStep(modalType);
    if (modalType === UploadStep.GENRE) updateUploadGenre(storyUploadGenre);
    if (modalType === UploadStep.LANGUAGE) updateUploadLanguage(storyLanguage);
    openModal(ModalType.COMMON);
  };

  handleMatureContent = () => {
    const { openModal, disableStoryUploadMatureContent, matureContent } = this.props;
    matureContent ? disableStoryUploadMatureContent() : openModal(ModalType.UPLOAD_MATURE_CONTENT);
  };

  render() {
    const { t, title, error, genres, storyUploadGenre, storyLanguage, languages, matureContent } = this.props;
    const initialGenres = genres.filter((g) => storyUploadGenre.includes(g.id));
    const initialLanguage = languages.filter((l) => storyLanguage.includes(l.id));
    return (
      <Grid item xs={12}>
        <div className="description mr-10 mt-10">
          <label className="bold">{t("uploadSection.label4")}</label>
          <TextField
            className="upload-textarea"
            value={title}
            variant="outlined"
            onChange={(event) => this.handleTitleChange(event.target.value)}
          ></TextField>
          <br />
          <br />
          <label className="bold">{t("uploadSection.story.add")}</label>
          <StoryEditor />

          {/* <span className="error">{error}</span> */}
          {/* {isMobileOnly ? ( */}
          <Fragment>
            <h4 style={{ marginLeft: 10 }}>{t("uploadSection.h4")}</h4>
            <Grid container spacing={0} className="grid-container">
              <Grid container item xs={12} className="grid-section">
                <Grid item xs={4}>
                  <label className="">{t("uploadSection.label1")}</label>
                </Grid>
                <Grid item xs={8} className="txt-rght">
                  <label onClick={() => this.handleModal(UploadStep.GENRE)}>
                    {initialGenres.length ? (
                      initialGenres.length <= 2 ? (
                        initialGenres.map((res, index) => (
                          <span key={index}>
                            {res.name} {index < initialGenres.length - 1 ? ", " : null}
                          </span>
                        ))
                      ) : (
                        <Fragment>
                          <span key={0}>{initialGenres[0].name}, </span>
                          <span key={1}>{initialGenres[1].name}, </span>
                          <span key={2}>+{initialGenres.length - 2} more</span>
                        </Fragment>
                      )
                    ) : (
                      <span className="not-set"> {t("uploadSection.span")}</span>
                    )}
                  </label>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="grid-section">
                <Grid item xs={4}>
                  <label className="">{t("uploadSection.label2")}</label>
                </Grid>
                <Grid item xs={8} className="txt-rght">
                  <label onClick={() => this.handleModal(UploadStep.LANGUAGE)}>
                    {initialLanguage.length ? (
                      initialLanguage.length <= 2 ? (
                        initialLanguage.map((res, index) => (
                          <span key={index}>
                            {res.nameUtf8}
                            {index < initialLanguage.length - 1 ? "," : null}
                          </span>
                        ))
                      ) : (
                        <Fragment>
                          <span key={0}>{initialLanguage[0].nameUtf8}, </span>
                          <span key={1}>{initialLanguage[1].nameUtf8}, </span>
                          <span key={2}>+{initialLanguage.length - 2} more</span>
                        </Fragment>
                      )
                    ) : (
                      <span className="not-set"> {t("uploadSection.span")} </span>
                    )}
                  </label>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="grid-section" style={{ paddingBottom: 0 }}>
                <Grid item xs={8}>
                  <label className="">{t("uploadSection.label3")}</label>
                </Grid>
                <Grid item xs={4} className="txt-rght">
                  <Switch className="matureSwitch" checked={matureContent} onChange={this.handleMatureContent} />
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
          {/* ) : null} */}

          {/*  {!isMobileOnly && (
            <form>
              <Grid container spacing={0} className="m-0">
                <Grid item xs={12} sm={6} className="grd-frm">
                  <label className="bold">Genre</label>
                  <FcCustomSearch
                    name="genre"
                    optionValue="id"
                    optionLabel="name"
                    source={genres}
                    multiple={true}
                    val={initialGenres}
                    onChangeSelect={this.handleGenreChange}
                  ></FcCustomSearch>
                </Grid>
                <Grid item xs={12} sm={6} className="grd-frm">
                  <label className="bold">Language</label>
                  <FcCustomSearch
                    name="language"
                    optionValue="id"
                    optionLabel="nameUtf8"
                    source={languages}
                    multiple={true}
                    val={initialLanguage}
                    onChangeSelect={this.handleLanguageChange}
                  ></FcCustomSearch>
                </Grid>
              </Grid>
            </form>
          )} */}
        </div>
      </Grid>
    );
  }
  componentWillUnmount() {
    const { closeModal, commonModal } = this.props;
    if (commonModal) {
      closeModal(ModalType.COMMON);
    }
  }
}

export default compose(withTranslation())(StoryUpload);
