import { AUTH } from "../../actions";
const editUserReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH.CLEAR:
      return {};
    case AUTH.EDIT_USER_INFO:
      return action.user;
    default:
      return state;
  }
};

export default editUserReducer;
