import React, { PureComponent, Component } from "react";
import { kebabCase } from "lodash";
import { ContentAction } from "../../constant";
import { Grid as ListGrid, Formats } from "../../components";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import "./Bookmark.scss";

class Bookmark extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true }
    const {

      discoverName,
      setContentAction,
      clearContent,
      resetContentPage,
      contentList,
      cancelLoadContent,
      hideNewfeed,
    } = props;
    cancelLoadContent();
    resetContentPage();
    clearContent();
    hideNewfeed();
    const action = ContentAction["bookmark"];
    if (action) setContentAction(action);
  }
  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  componentDidMount() {
    this.setState({ isLoading: false })
  }
  render() {
    const { contentList, filter, formats, t, } = this.props;
    const { isLoading } = this.state
    return (
      <div>{!isLoading ? <div>
        <div className="sub-header text-left tab-hidden">
          <button className="back-btn" onClick={this.handleBack}></button>
          <h1>{t("messages.BOOKMARK_TEXT")}</h1>
        </div>

        <Grid container className="grid-discover">
          {/* <Grid item xs={6}>
            <h2 className="page-title mobile-hidden">{discoverName}</h2>
          </Grid> */}

          <Grid item xs={12}>
            {/* {contentList.length && ( */}
            <div className="profile-filter2">
              <label>
                <h4>
                  {filter.formatsList.length > 1 || filter.formatsList.length == 0
                    ? t("bookmark.span1")
                    : t(`bookmark.${formats.find((f) => f.id === filter.formatsList[0])?.name}`)}
                </h4>
              </label>
              <div className="filters MuiTypography-alignRight">
                <Formats />
              </div>
              {/* )} */}
            </div>
          </Grid>
        </Grid>
        {/* <br />
        <br />
        <br /> */}

        <ListGrid />
      </div> : null}</div>

    );
  }
}
export default compose(withTranslation())(Bookmark);

//export default Bookmark;
