import { UPLOAD } from "../../actions";

const imageUploadPercentageReducer = (state = -1, action) => {
    switch (action.type) {
        case UPLOAD.CLEAR:
        case UPLOAD.RESET_IMAGE_UPLOAD_PERCENTAGE:
            return -1;
        case UPLOAD.SET_IMAGE_UPLOAD_PERCENTAGE: {
            return action.imageUploadPercentage;
        }
        default:
            return state;
    }
};

export default imageUploadPercentageReducer;
