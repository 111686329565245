import { PREFERENCE } from "../../actions";

const emailReducer = (state = "", action) => {
  switch (action.type) {
    case PREFERENCE.CLEAR:
      return "";
    case PREFERENCE.SET_EMAIL:
      return action.email;
    default:
      return state;
  }
};

export default emailReducer;