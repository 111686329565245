import React, { PureComponent } from "react";
import Parser from "html-react-parser";
import { ModalType, Fonts } from "../../../../constant";
//  import { ContentArrow } from "../../../containers";
import "./story.scss";
import { getRandomColor } from "../../../../core";

export default class Story extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      heightOfContent: 0,
    };
    this.divElement = React.createRef();
  }

  componentDidMount() {
    const heightOfContent = this.calculateHeight();
    this.setState({ heightOfContent });
  }

  calculateHeight = () => {
    if (this.divElement && this.divElement.current) {
      return this.divElement.current.clientHeight;
    }
    return 0;
  };

  render() {
    const { activeContent, openModal } = this.props;
    const maxContent = activeContent.contentValue;
    const index = activeContent.id % Fonts.length;
    const font = Fonts[index];
    const color = getRandomColor(activeContent.id);

    return (
      <div
        className={`story`}
        style={{ backgroundColor: color, color: "white" }}
      >
        <h3 style={{ fontFamily: font, fontSize: 16 }}>
          {activeContent.contentTitle}
        </h3>
        <div className="description" ref={this.divElement}>
          <span>
            <span
              className="ql-size-large"
              style={{ fontFamily: font, fontSize: 16 }}
            >
              {Parser(maxContent)}
            </span>
          </span>
        </div>
        {this.state.heightOfContent > 250 && (
          <button
            className="text-readmore"
            onClick={() => {
              openModal(ModalType.SHOW_MORE_DETAIL);
            }}
          >
            See more
          </button>
        )}
        {/* {contentList.length > 1 && <ContentArrow />} */}
      </div>
    );
  }
}
