import React, { PureComponent, Fragment } from "react";
import { get, snakeCase } from "lodash";
import { ButtonBase } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Parser from "html-react-parser";
import { LazyImage, CardFooter } from "../../containers";
import { ContentTypeId, Fonts, ModalType } from "../../../constant";
import { WebLink, Play, Album, Gif } from "../../../icons";
import { isMobileOnly } from "react-device-detect";
import sessionImage from "../../../assets/images/blank.jpg";
import "./card.scss";
import { isServer } from "../../../store";
import { withRouter } from "react-router-dom";
import { AnimatedSlider } from "../../../modules";
import "react-animated-slider/build/horizontal.css";
import { getRandomColor } from "../../../core";

class Card extends PureComponent {
  state = { activeSlide: 0 };
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
  }
  handleLabelClick = (userId) => {
    const { history } = this.props;
    history.push(`/account/${userId}`);
  };
  handleContentClick = (contentId, index) => {
    const {
      setActiveContentIndex,
      openModal,
      loadFromSession,
      setInitialSlide,
      contentOpen,
      content,
    } = this.props;
    const encryptedUrl = btoa(contentId);
    const contentPath = content?.contentUrl[0]?.contentOcr
      ? `/${snakeCase(
        content?.contentUrl[0]?.contentOcr
      )}/content/${encryptedUrl.replace(/=/g, "")}`
      : `/content/${encryptedUrl.replace(/=/g, "")}`;
    if (!loadFromSession) {
      contentOpen();
      setActiveContentIndex(index);
      setInitialSlide(index);
      openModal(ModalType.CONTENT_DETAIL);
      window.history.pushState("content detail", "tymoff", contentPath);
    } else window.open(contentPath, "_self");

    // if (isMobileOnly) document.body.style.position = "fixed";
  };
  dotsChange = (index) => {
    let animation = "next";
    if (index > this.state.activeSlide) {
      animation = "next";
    } else {
      animation = "previous";
    }
    this.sliderRef.current.goTo(index, animation);
    this.setState({ activeSlide: index });
  };
  handleSlideChange = (event) => {
    this.setState({ activeSlide: event.slideIndex });
  };

  calculateHeight = (contentUrl, isContainer) => {
    let contURL =
      !!contentUrl.length && Array.isArray(contentUrl)
        ? contentUrl[0]
        : contentUrl;
    const dimensions = contURL.dimensions && contURL.dimensions.split("x");
    let fixWidth;
    const { history } = this.props;
    if (history.location.pathname == "/" && isMobileOnly) {
      fixWidth = window.innerWidth;
    } else if (isMobileOnly) {
      fixWidth = window.innerWidth * (48 / 100);
    } else {
      if (history.location.pathname == "/account" && !isMobileOnly) {
        fixWidth = 230;
      } else {
        fixWidth = 230;
      }
    }
    // const fixWidth = isMobileOnly ? window.innerWidth * (48 / 100) : 200;
    const height =
      dimensions && !!dimensions.length
        ? Math.floor(fixWidth * (dimensions[1] / dimensions[0]))
        : 200;
    return `${isContainer ? height + 30 : height}px`;
  };

  renderImage = (content) => {
    const { contentIndex } = this.props;
    const { contentUrl, typeId } = content;
    const { genres } = this.props;
    const { thumbnailImage, contentOcr, url } =
      contentUrl && Array.isArray(contentUrl)
        ? contentUrl[0] || {}
        : content || {};
    const src =
      typeId === ContentTypeId.Images ? thumbnailImage || url : thumbnailImage;
    const fullImage = typeId === ContentTypeId.Images ? url : thumbnailImage;
    const genre = genres.find(
      (gen) => String(gen.id) === String(content.catId)
    );
    const displayName = content.userName
      ? content.userName
      : genre
        ? genre.name
        : null;
    let height;
    let width;
    if (!isServer) {

      height = contentIndex % 10 == 0 && contentIndex >= 10 ? 280 : this.calculateHeight(contentUrl ? contentUrl : content);

    } else {
      height = "500px";
    }
    if (height == 200 || height == "200px") {
      width = "100%";
    }
    return (
      <Fragment>
        {/* <div className="rectangle">
          <Grid container spacing={0} className="my-0">
            <Grid item xs>
              <div className="oval" style={{ cursor: "pointer" }} onClick={() => this.handleLabelClick(content.userId)}>
                <span>#</span>
              </div>
              <label
                className="top-label"
                style={{ cursor: "pointer" }}
                onClick={() => this.handleLabelClick(content.userId)}
              >
                {displayName}
              </label>
            </Grid>
          </Grid>
        </div> */}
        {content.typeId !== ContentTypeId.Text ? (
          <LazyImage
            containerStyle={{ height: height }}
            style={{ height: height, width: width }}
            alt={contentOcr ? contentOcr.replace(/(?:\r\n|\r|\n)/g, " ") : ""}
            src={src}
            fullImage={fullImage}
          //onImageClick={() => this.handleContentClick(content.id, contentIndex)}
          />
        ) : null}
      </Fragment>
    );
  };

  renderSessionImage = (content) => {
    const { contentUrl } = content;
    const { contentOcr } = contentUrl[0] || {};
    const fixWidth = isMobileOnly ? window.innerWidth * (48 / 100) : 230;
    const height = `${Math.floor(fixWidth * (432 / 576))}px`;
    const { contentIndex } = this.props;
    return (
      <LazyImage
        containerStyle={{ height: height }}
        style={{ height: height }}
        alt={contentOcr ? contentOcr.replace(/(?:\r\n|\r|\n)/g, " ") : ""}
        src={sessionImage}
      />
    );
  };

  renderAnchorTag = (content, loadFromSession) => {
    const { contentUrl } = content;
    const { contentOcr } = contentUrl[0] || {};
    const encryptedUrl = btoa(content.id);
    return (
      <a
        href={`https://www.tymoff.com/content/${encryptedUrl.replace(
          /=/g,
          ""
        )}`}
        style={{ display: loadFromSession ? "" : "none" }}
        title={
          contentOcr
            ? contentOcr.replace(/(?:\r\n|\r|\n)/g, " ")
            : `https://www.tymoff.com/content/${encryptedUrl.replace(/=/g, "")}`
        }
        target={"_self"}
        rel="noopener noreferrer"
      >
        {contentOcr
          ? contentOcr.replace(/(?:\r\n|\r|\n)/g, " ")
          : `https://www.tymoff.com/content/${encryptedUrl.replace(/=/g, "")}`}
      </a>
    );
  };

  calculateContainerHeight = (content) => {
    const { contentUrl } = content;
    let height;
    if (!isServer) {
      height = this.calculateHeight(contentUrl, true);
    } else {
      height = "500px";
    }
    return height;
  };

  calculateSessionContainerHeight = () => {
    let height;
    if (!isServer) {
      const fixWidth = isMobileOnly ? window.innerWidth * (48 / 100) : 230;
      const heightContainer = Math.floor(fixWidth * (432 / 576));
      height = `${heightContainer + 30}px`;
    } else {
      height = "500px";
    }
    return height;
  };

  renderContentText = (content) => {
    const { contentValue, id, typeId } = content;
    const index = id % Fonts.length;
    const color = getRandomColor(id);
    const font = Fonts[index];
    const maxContent =
      contentValue && contentValue.length > 200
        ? contentValue.substring(0, 200)
        : contentValue;
    const { contentIndex } = this.props;
    return (
      <Fragment>
        {typeId === ContentTypeId.Text ? (
          <div
            className={`description`}
            style={{ backgroundColor: color, color: "white" }}
            id={`text_${id}`}
          // onClick={() => this.handleContentClick(content.id, contentIndex)}
          >
            <span style={{ fontFamily: font, fontSize: 16 }}>
              {Parser(content.contentTitle)}
            </span>
            <span
              style={{ fontFamily: font, fontSize: 16 }}
              className="view-more"
            >
              {Parser(maxContent)}
            </span>
          </div>
        ) : null}
      </Fragment>
    );
  };
  dotsChange = (index) => {
    let animation = "next";
    if (index > this.state.activeSlide) {
      animation = "next";
    } else {
      animation = "previous";
    }
    this.sliderRef.current.goTo(index, animation);
    this.setState({ activeSlide: index });
  };

  handleOtherContentClick = () => { };
  handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  render() {
    const {
      content,
      contentIndex,
      loadFromSession,
      onShareClick,
      history,
    } = this.props;
    const contentUrl = get(content, "contentUrl", []);
    const gif = "gif";
    const color = getRandomColor(content?.id);
    const { activeSlide } = this.state;
    return (
      <div
        className="card-style"
        style={{
          height: !loadFromSession
            ? this.calculateContainerHeight(content)
            : this.calculateContainerHeight(content),
        }}
      >
        <ButtonBase
          style={{ width: "100%" }}
          onClick={() => {
            content.typeId === ContentTypeId.Images && contentUrl.length > 1
              ? console.log("i")
              : this.handleContentClick(content.id, contentIndex);
          }}
        >
          {content.typeId === ContentTypeId.Videos && (
            <Play width={"40px"} height={"40px"} className="play play-icon" />
          )}
          {!loadFromSession &&
            contentUrl.length <= 1 &&
            this.renderImage(content)}
          {content.typeId === ContentTypeId.Videos &&
            contentUrl.length > 1 &&
            this.renderImage(contentUrl[0])}
          {/*  {content.typeId === ContentTypeId.Videos && contentUrl.length > 1 && (
            <Fragment>
              <AnimatedSlider
                duration={200}
                ref={this.sliderRef}
                onSlideChange={(event) => this.handleSlideChange(event)}
                infinite={false}
                height={this.calculateHeight(content)}
              >
                {contentUrl.map((cont, index) => (
                  <div
                    className="slide"
                    key={cont.id || index}
                    onClick={() =>
                      this.handleContentClick(content.id, contentIndex)
                    }
                  >
                    {this.renderImage(cont)}
                  </div>
                ))}
              </AnimatedSlider>
              { {
                <div className="album-dot">
                  {contentUrl.map((content, index) => (
                    <div
                      key={index}
                      onClick={(e) => this.dotsChange(index)}
                      className={`album-dot-item ${
                        activeSlide === index ? "active" : ""
                      }`}
                    ></div>
                  ))}
                </div>
              } }
            </Fragment>
          )} */}
          {content.typeId === ContentTypeId.Images && contentUrl.length > 1 && (
            <Fragment>
              <AnimatedSlider
                duration={200}
                ref={this.sliderRef}
                onSlideChange={(event) => this.handleSlideChange(event)}
                infinite={false}
                height={this.calculateHeight(content)}
              >
                {contentUrl.map((cont, index) => (
                  <div
                    className="slide"
                    key={cont.id || index}
                    onClick={() =>
                      this.handleContentClick(content.id, contentIndex)
                    }
                  >
                    {this.renderImage(cont)}
                  </div>
                ))}
              </AnimatedSlider>
              {
                <div className="album-dot">
                  {contentUrl.map((content, index) => (
                    <div
                      key={index}
                      onClick={(e) => this.dotsChange(index)}
                      className={`album-dot-item ${activeSlide === index ? "active" : ""
                        }`}
                    ></div>
                  ))}
                </div>
              }
            </Fragment>
          )}
          {loadFromSession && this.renderSessionImage(content)}
          {this.renderContentText(content)}
          {content.typeId === ContentTypeId.Weblink && (
            <div className="brand-link mobile-hidden desktop-hidden tab-hidden">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={contentUrl[0].url}
              >
                <WebLink width={"16px"} height={"16px"} className="web-link" />
              </a>
            </div>
          )}
          {/* {content.typeId === ContentTypeId.Images && contentUrl.length > 1 && (
            <div className="brand-link">
              <Album width={"16px"} height={"16px"} className="album" />
            </div>
          )} */}
          {!!content.contentUrl[0] &&
            content.contentUrl[0].thumbnailImage &&
            content.typeId === ContentTypeId.Images &&
            content.contentUrl[0].thumbnailImage.split(".")[3] === gif && (
              <div className="brand-link">
                <Gif width={"16px"} height={"16px"} className="gif" />
              </div>
            )}
        </ButtonBase>
        {this.renderAnchorTag(content, loadFromSession)}
        {!!content.contentTitle && content.typeId === ContentTypeId.Weblink && (
          <Fragment>
            <div
              onClick={() => this.handleContentClick(content.id, contentIndex)}
              className={`web-info`}
              style={{ backgroundColor: color, color: "white" }}
            >
              <WebLink className="link-icon" />
              <h6
                className="site"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.handleContentClick(content.id, contentIndex)
                }
              >
                {
                  content?.contentUrl[0]?.url
                    ?.replace("http://", "")
                    .replace("https://", "")
                    .split(/[/?#]/)[0]
                }
              </h6>
              {/* <h6
                style={{ cursor: "pointer" }}
                className="url"
                onClick={() => this.handleOpenUrl(content?.contentUrl[0]?.url)}
              >
                {content?.contentUrl[0]?.url}
              </h6> */}
            </div>
            <div className="webTitle">
              {" "}
              <ButtonBase
                onClick={() =>
                  this.handleContentClick(content.id, contentIndex)
                }
                className=""
              >
                {" "}
                {Parser(content.contentTitle)}
              </ButtonBase>
            </div>
          </Fragment>
        )}
        {/* {!!content.contentValue &&
          (content.typeId === ContentTypeId.Images ||
            content.typeId === ContentTypeId.Videos ||
            content.typeId === ContentTypeId.Weblink) &&
          (content.contentValue.length > 50 ? (
            <span onClick={() => this.handleContentClick(content.id, contentIndex)} className="readMore">
              {Parser(content.contentValue.substr(0, 50))}
            </span>
          ) : (
            <span onClick={() => this.handleContentClick(content.id, contentIndex)} className="readMore">
              {Parser(content.contentValue)}
            </span>
          ))} */}

        {/* //{history.location.pathname == "/search" && isMobileOnly ? null : ( */}
        <CardFooter
          content={content}
          contentIndex={contentIndex}
          shareClick={onShareClick}
        />
        {/* )} */}
      </div>
    );
  }
}

export default withRouter(Card);
