import React, { PureComponent, Fragment } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import "./validate-otp.scss";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";

class ValidateOtp extends PureComponent {
  componentDidMount() {
    this.selectText();
  }

  selectText = () => {
    const input = document.getElementById("text-otp");
    input.focus();
    input.select();
  };

  handleOtpChange = (event) => {
    const { target } = event;
    if (target.validity.valid) {
      const { setOtp } = this.props;
      setOtp(target.value);
      if (target.value.length === 4) {
        this.handleProceed();
      }
    }
  };
  handleProceed = () => {
    const { validateOtp } = this.props;
    validateOtp();
  };

  pressEnter = (e) => {
    const { otp, isLoading } = this.props;
    if (!(otp.length < 4 || isLoading)) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();
        this.handleProceed();
      }
    }
  };

  render() {
    const { t, sendOtp, otp, isLoading, clearLogin, number, error } = this.props;

    return (
      <Fragment>
        <div className={"afterOtp"}>
          {/* <div className={"enterOtp"}>Enter OTP</div> */}
          <div className="smallPara">
            {" "}
            {t("validateOtp.title")} <br /> {number}
          </div>
          <div style={{position:"relative"}}>
            <input
              type="text"
              className={"inputField validateOTP"}
              pattern="[0-9]*"
              maxLength={4}
              value={otp}
              onChange={this.handleOtpChange}
              id="text-otp"
              onKeyDown={this.pressEnter}
            />
            {isLoading && <CircularProgress className="loader2" />}
          </div>
          <div className={"error"}>{error}</div>

          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div style={{ textAlign: "left" }}>
                <ButtonBase onClick={clearLogin} className="change-mobile">
                  <span> {t("validateOtp.span1")}</span>
                </ButtonBase>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div style={{ textAlign: "right", marginTop: "2px" }}>
                <div className={"noAccountLinkMobile"}>
                  <label className="subheading" style={{ fontSize: 11 }}>
                  {t("validateOtp.label1")}&nbsp;
                    <ButtonBase onClick={sendOtp}>
                      <div className={"resendOtp"}>{t("validateOtp.label2")}</div>
                    </ButtonBase>
                  </label>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* <p className={"noAccountLinkMobile"}>
            An OTP has been sent to your ******
            {number.substr(number.length - 4)}, please enter the same to proceed
            or &nbsp;
          </p> */}
        </div>
        {/* <button
          className={"submitButton"}
          onClick={this.handleProceed}
          disabled={otp.length < 4 || isLoading}
        >
          Proceed
        </button> */}
      </Fragment>
    );
  }
}

//export default ValidateOtp;
export default compose(withTranslation())(ValidateOtp);