import React, { PureComponent, Fragment } from "react";
import { ButtonBase } from "@material-ui/core";
import { get, kebabCase } from "lodash";
import { LazyImage } from "../lazy-image";
import { ContentTypeId, ModalType, NotificationType } from "../../../constant";
import { AnimatedSlider } from "../../../modules";
import "react-animated-slider/build/horizontal.css";
import { WebLink, Play, Album, Gif } from "../../../icons";
import "./notification.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { getProfileImageFallBack, getProfileImageUrl, loadImage } from "../../../core";
import { withRouter } from "react-router";
import ReactTimeAgo from "react-time-ago";
class Notifications extends PureComponent {
  renderImages = (contentUrl) => {
    const { url, thumbnailImage } = contentUrl;
    return (
      <LazyImage
        containerStyle={{ height: "230px", width: "100%" }}
        style={{ height: "230px", width: "100%" }}
        alt={"alt"}
        src={url}
        thumbnail={thumbnailImage}
      />
    );
  };

  handleNotificationClick = (notification, index) => {
    const { notificationType } = notification;
    const { history } = this.props;
    const {
      setActiveContentIndex,
      openModal,
      setInitialSlide,
      contentOpen,
    } = this.props;
    if (notificationType === NotificationType.CONTENT) {
      const { contentMain } = notification;
      const encryptedUrl = btoa(contentMain.id);
      // contentOpen();
      // setActiveContentIndex(index);
      // setInitialSlide(index);
      // openModal(ModalType.CONTENT_DETAIL);
      // window.history.pushState("content detail", "tymoff", encryptedUrl);
      //history.push(`/content/${encryptedUrl.replace(/=/g, "")}`);
      window.open(`/content/${encryptedUrl.replace(/=/g, "")}`, "_self");
    } else if (notificationType === NotificationType.DISCOVER) {
      const { discoverData } = notification;
      //history.push(`/discover/${kebabCase(discoverData.name)}`);
      window.open(`/discover/${kebabCase(discoverData.name)}`, "_self");
    }
  };
  handleOtherContentClick = () => {};

  renderNotification = (notification, index) => {
    const contentUrl = get(notification, "contentMain.contentUrl", []);
    const content = get(notification, "contentMain", {});
    const userDetail = get(notification, "contentMain.userDetail", []);
    const isMultipleImages = !!(contentUrl.length > 1);
    return (
      //onClick={() => this.handleNotificationClick(notification)}
      <ButtonBase
        className="notifbtn"
        onClick={() => {
          content.typeId === ContentTypeId.Images && contentUrl.length > 1
            ? this.handleOtherContentClick()
            : this.handleNotificationClick(notification, index);
        }}
      >
        <div
          className={
            isMultipleImages ? "notification-scroll" : "notification-tile"
          }
        >
          <div className="notification-list">
            {/* <img className="oval" src={getProfileImageUrl(userDetail.picUrl, userDetail.id, userDetail.name)} /> */}
            {loadImage(userDetail.picUrl, userDetail.id, userDetail.name)}
            <label className="top-label">&ensp;{notification.title}</label>
            <small>
              <ReactTimeAgo
                date={notification.createdDateTime}
                locale="en-US"
              />
            </small>
          </div>
          {/* <span className="title">{notification.title}</span> */}
          {/* <span className="time"> 1h </span> */}
          {content.typeId === ContentTypeId.Videos && (
            <Play
              width={"40px"}
              height={"40px"}
              className="play play-icon notify-play"
            />
          )}
          {isMultipleImages && (
            <AnimatedSlider>
              {contentUrl.map((content, index) => (
                <div className="slide" key={content.id || index}>
                  {this.renderImages(content)}
                </div>
              ))}
            </AnimatedSlider>
          )}
          {!!contentUrl.length && !isMultipleImages && (
            <div className="notification-image">
              <LazyImage
                containerStyle={{ height: "230px", width: "100%" }}
                style={{ height: "230px", width: "100%" }}
                alt={"alt"}
                src={contentUrl[0].url}
                thumbnail={contentUrl[0].thumbnailImage}
              />
            </div>
          )}
          {content.typeId === ContentTypeId.Weblink && (
            <div className="brand-link mobile-hidden desktop-hidden tab-hidden">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={contentUrl[0].url}
              >
                <WebLink width={"16px"} height={"16px"} className="web-link" />
              </a>
            </div>
          )}
        </div>
      </ButtonBase>
    );
  };

  render() {
    const { t, notifications } = this.props;
    return (
      <Fragment>
        {!!notifications.length ? (
          notifications.map((notification, index) => (
            <Fragment key={index}>
              {this.renderNotification(notification, index)}
            </Fragment>
          ))
        ) : (
            <div className="notif-msg">
              <div className="content">
                {/* <img src={muteNotification} alt="" /> */}
                <h5>{t("notifications.h5")}</h5>
                <h6>{t("notifications.h6")}</h6>
              </div>
              {/* <div className="notification-list">
              <img
                className="oval"
                src="https://marvel-live.freetls.fastly.net/canvas/2020/6/16bcf5d2dfe34296b80d5efce402963b?quality=95&fake=.png"
              />
              <label className="top-label">&ensp;Barak obama posted a new video.</label>
              <small>2 hours ago</small>
            </div>
            <div className="notification-list">
              <img
                className="oval"
                src="https://marvel-live.freetls.fastly.net/canvas/2020/6/16bcf5d2dfe34296b80d5efce402963b?quality=95&fake=.png"
              />
              <label className="top-label">&ensp;Barak obama posted a new video.</label>
              <small>2 hours ago</small>
            </div>
            <div className="notification-list">
              <img
                className="oval"
                src="https://marvel-live.freetls.fastly.net/canvas/2020/6/16bcf5d2dfe34296b80d5efce402963b?quality=95&fake=.png"
              />
              <label className="top-label">&ensp;Barak obama posted a new video.</label>
              <small>2 hours ago</small>
            </div>
            <div className="notification-list">
              <img
                className="oval"
                src="https://marvel-live.freetls.fastly.net/canvas/2020/6/16bcf5d2dfe34296b80d5efce402963b?quality=95&fake=.png"
              />
              <label className="top-label">&ensp;Barak obama posted a new video.</label>
              <small>2 hours ago</small>
            </div>
          */}
          </div>
        )}
      </Fragment>
    );
  }
}
export default withRouter(compose(withTranslation())(Notifications));
//export default compose(withTranslation(),withRouter())(Notifications);
