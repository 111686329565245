import React, { Component } from "react";
import PropTypes from "prop-types";
import instagramSvg from "../../../assets/images/insta.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";
import "./instaram.scss";
const getPopupPositionProperties = ({ width = 600, height = 600 }) => {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;
  return `left=${left},top=${top},width=${width},height=${height}`;
};

export class Instagram extends Component {
  static propTypes = {
    className: PropTypes.string,
    onFailure: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    redirectUri: PropTypes.string.isRequired,
    renderElement: PropTypes.func,
  };

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false);
    if (this.popup && !this.popup.closed) this.popup.close();
  }

  getUrl = () => {
    const {
      redirectUri,
      clientId,
      state,
      scope,
      supportIE,
      redirectPath,
    } = this.props;
    console.log(redirectUri);
    const scopeParam = `&scope=${supportIE ? scope : encodeURI(scope)}`;
    const instaAuthenLink = `https://api.instagram.com/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${state}`;
    //`https://api.instagram.com/oauth/authorize?client_id=1325245911154811&redirect_uri=${instaUrl}&scope=user_profile,user_media&response_type=code
    if (supportIE) {
      const redirectLink = `${
        window.location.origin
      }${redirectPath}?redirect_url=${encodeURIComponent(instaAuthenLink)}`;
      return redirectLink;
    }
    return instaAuthenLink;
  };

  receiveMessage = (event) => {
    const { state } = this.props;
    console.log({ event, window });
    if (event.origin === window.location.origin) {
      if (event.data.errorMessage && event.data.from === "Instagram") {
        if (event.data.state !== state) {
          this.popup && this.popup.close();
          return;
        }
        this.props.onFailure(event.data);
        this.popup && this.popup.close();
      } else if (event.data.code && event.data.from === "Instagram") {
        if (event.data.state !== state) {
          this.popup && this.popup.close();
          return;
        }
        this.props.onSuccess({ code: event.data.code });
        this.popup && this.popup.close();
      }
    }
  };

  handleConnectInstagramClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.onClick && this.props.onClick();
    this.popup = window.open(
      this.getUrl(),
      "_blank",
      getPopupPositionProperties({ width: 600, height: 600 })
    );
    window.removeEventListener("message", this.receiveMessage, false);
    window.addEventListener("message", this.receiveMessage, false);
  };

  render() {
    const { className, disabled, children, renderElement } = this.props;
    if (renderElement) {
      return renderElement({
        onClick: this.handleConnectInstagramClick,
        disabled,
      });
    }
    return (
      <InstapaperShareButton>
        <img
          src={instagramSvg}
          className="instaSvg"
          onClick={this.handleConnectInstagramClick}
        />
      </InstapaperShareButton>
    );
  }
}

Instagram.defaultProps = {
  className: "btn-Instagram",
  disabled: false,

  state: "fdsf78fyds7fm",
  supportIE: false,
  redirectPath: "/instagram",
  scope: "scope=user_profile,user_media",
};
export default Instagram;
