import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import ColorizeIcon from "@material-ui/icons/Colorize";
import FormatSizeIcon from "@material-ui/icons/FormatSize";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles({
  root: {
    maxWidth: 280,
  },
});

export default function IconTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onTabClick(newValue);
  };

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon tabs example"
      >
        <Tab icon={<FormatSizeIcon />} aria-label="phone" />
        <Tab icon={<FontDownloadIcon />} aria-label="favorite" />
        <Tab icon={<ColorizeIcon />} aria-label="person" />
      </Tabs>
    </Paper>
  );
}
