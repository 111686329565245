import React, { PureComponent, Fragment } from "react";
import error from "../../assets/images/error.svg";
import "./content-not-found.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class ContentNotFound extends PureComponent {
  render() {
    const { t, filter, history } = this.props;
    console.log(history);
    return (
      <Fragment>
        <div className="txt-cntr no-content-found">
          <img src={error} className="contact-img" />
          <h2 className="singleLineName">
            {filter.contentSearch && history.location.pathname == "/search" ? (
              <span>
                {t("notFound.span0")} <span>{filter.contentSearch}</span>
              </span>
            ) : (
              t("notFound.span1")
            )}
          </h2>
          <p>
            {t("notFound.p1")}
          </p>
          {history.location.pathname == "/bookmark" &&
            history.location.pathname == "/discover/my-likes" &&
            history.location.pathname == "/account" && (
              <p>{t("notFound.p2")} </p>
            )}
          {history.location.pathname === "/" ? null : (
            <button
              className={"submitButton no-content-btn my-20"}
              type="button"
              onClick={() => history.push("/")}
            >
              {t("notFound.button")}
            </button>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.content.filter,
});

const mapDispatchToProps = (dispatch) => ({});
export default compose(withTranslation())(withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentNotFound)));
