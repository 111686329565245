import { connect } from "react-redux";
import { withRouter } from "react-router";

import { openModal, closeModal, loadShareLink, startScrollTimer, setShareType, setActiveContentIndex, setInitialSlide } from "../../../../store";
import { default as SharePopperModal } from "./share-popper-modal";

const mapStateToProps = (state) => ({
    modal: state.ui.modal.share_popper || false,
    isContentDetailOpen: state.content.isContentDetailOpen,
    contentAlreadyVisited: state.content.contentAlreadyVisited,
    activeContent: state.content.activeContent,
    shareLink: state.share.data,
    commentBox: state.ui.commentBox,
    shareType: state.share.type,
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (modal) => dispatch(openModal(modal)),
    closeModal: (modal) => dispatch(closeModal(modal)),
    loadShareLink: () => dispatch(loadShareLink()),
    startScrollTimer: () => dispatch(startScrollTimer()),

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SharePopperModal));