import { Grid, Switch, TextField } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { ModalType, UploadStep } from "../../../../../constant";
import { FileSelector } from "../../../../containers";
import "./image-upload.scss";
import AddIcon from "@material-ui/icons/Add";

import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reset: false,
      initialGenres: [],
      initialLanguage: [],
    };
  }

  handleDescriptionChange = (description) => {
    const { setUploadImageDescription } = this.props;
    setUploadImageDescription(description);
  };

  handleLanguageChange = (lang) => {
    const { setUploadImageLanguage } = this.props;
    setUploadImageLanguage(lang);
  };

  handleGenreChange = (genre) => {
    const { setUploadGenre, setImageUploadGenre } = this.props;
    setImageUploadGenre(genre);
  };

  handleModal = (modalType) => {
    const {
      openModal,
      setUploadStep,
      updateUploadGenre,
      imageUploadGenre,
      imageLanguage,
      updateUploadLanguage,
    } = this.props;
    setUploadStep(modalType);
    if (modalType === UploadStep.GENRE) updateUploadGenre(imageUploadGenre);
    if (modalType === UploadStep.LANGUAGE) updateUploadLanguage(imageLanguage);
    openModal(ModalType.COMMON);
  };
  uploadContent = () => {
    const {
      publishContent,
      resetAddMoreContent,
      validateUpload,
      error,
    } = this.props;
    resetAddMoreContent();
    validateUpload();
  };

  addMoreContent = () => {
    const { validateUpload, addMoreContent } = this.props;
    addMoreContent();
    validateUpload();
  };
  removeFile = (index) => {
    const { images, updateUploadImage } = this.props;
    images.splice(index, 1);
    updateUploadImage(images);
    localStorage.removeItem("memeProperties");
    localStorage.removeItem("baseImage");
  };
  handleMemeGen = (pathname, w3meme) => {
    const {
      openModal,
      setUploadStep,
      updateUploadGenre,
      imageUploadGenre,
      imageLanguage,
      updateUploadLanguage,
      images,
    } = this.props;
    if (pathname == "/meme") {
      if (w3meme == "w3meme") {
        openModal(ModalType.MEMEMODALW);
      } else {
        openModal(ModalType.MEMEMODAL);
      }
      //this.removeFile(0);
    }
  };
  handleMatureContent = () => {
    const {
      openModal,
      disableImageUploadMatureContent,
      matureContent,
    } = this.props;
    matureContent
      ? disableImageUploadMatureContent()
      : openModal(ModalType.UPLOAD_MATURE_CONTENT);
  };
  render() {
    const {
      t,
      description,
      error,
      genres,
      languages,
      setUploadImageDescription,
      imageLanguage,
      setImageUploadGenre,
      uploadGenres,
      imageUploadGenre,
      commonModal,
      activeStep,
      previewContents,
      isLoading,
      images,
      history,
      matureContent,
      pathLocation,
    } = this.props;
    const initialGenres = genres.filter((g) => imageUploadGenre.includes(g.id));
    const initialLanguage = languages.filter((l) =>
      imageLanguage.includes(l.id)
    );
    return (
      <Fragment>
        <Grid item xs={12} sm={12}>
          <div className="description">
            {/* {isMobileOnly ? ( */}
            <Fragment>
              <Grid container spacing={0} className="m-0">
                <Grid item xs={12} className="grd-frm">
                  <label className="bold">{t("uploadSection.label4")}</label>
                  <TextField
                    name="desc"
                    variant="outlined"
                    value={description}
                    onChange={(e) =>
                      this.handleDescriptionChange(e.target.value)
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12} className="select-photo">
                  {/* <SelectedImages slider={false}></SelectedImages> */}
                  <div className="step-two-images" data-text="Preview">
                    {!!images.length &&
                      images.map((image, index) => (
                        <div key={index} className="img" alt="">
                          <button
                            className="delete-img"
                            onClick={() => this.removeFile(index)}
                          ></button>
                          <img
                            src={image.preview}
                            alt=""
                            onClick={() => this.handleMemeGen(pathLocation, "")}
                          />
                          {history.location.pathname === "/meme" ? (
                            <button
                              className="edit-img"
                              onClick={() =>
                                this.handleMemeGen(pathLocation, "")
                              }
                            >
                              {t("user.button")}
                            </button>
                          ) : null}
                        </div>
                      ))}
                  </div>
                </Grid>
                <Grid item xs={12} className="select-photo">
                  {/* {history.location.pathname === "/meme" ? (
                    <div
                      className="text-center"
                      style={{ cursor: "pointer" }}
                      onClick={
                        !images?.length
                          ? () => this.handleMemeGen(pathLocation, "w3meme")
                          : () => alert("only one image Selected at a time")
                      }
                    >
                      <div className=" up-temp">
                        <p className="upload-btn">
                          <AddIcon />
                        </p>
                      </div>
                      <label className="mobile-label">{t("user.title5")}</label>
                    </div>
                  ) : null} */}
                  {history.location.pathname === "/meme" ? (
                    <div>
                      <div className="text-center meme-grid">
                        <FileSelector
                          title=" Browse or drop images here"
                          memepath={pathLocation}
                        />
                        <label className="mobile-label">
                          {t("uploadSection.image.add")}
                        </label>
                      </div>
                      <div
                        className="text-center  meme-grid"
                        onClick={!images?.length
                          ? () => this.handleMemeGen(pathLocation, "w3meme")
                          : () => alert("Single image can be selected at a time")}
                      >
                        <div className=" up-temp">
                          <p className="upload-btn">
                            <AddIcon />
                          </p>
                        </div>
                        <label className="mobile-label">{t("user.title5")}</label>
                      </div>
                    </div>

                  ) : <div className="text-center" style={{ cursor: "pointer" }}>
                    <FileSelector
                      title=" Browse or drop images here"
                      memepath={pathLocation}
                    />
                    <label className="mobile-label">
                      {t("uploadSection.image.add")}
                    </label>
                  </div>}
                </Grid>
              </Grid>
            </Fragment>
            {/* ) : (
                <FileSelector title=" Browse or drop images here" />
              )} */}

            {/* {isMobileOnly ? ( */}
            <Fragment>
              <h4 style={{ marginLeft: 10 }}>{t("uploadSection.h4")}</h4>
              <Grid container spacing={0} className="grid-container">
                <Grid container item xs={12} className="grid-section">
                  <Grid item xs={4}>
                    <label className="">{t("uploadSection.label1")}</label>
                  </Grid>
                  <Grid item xs={8} className="txt-rght">
                    <label onClick={() => this.handleModal(UploadStep.GENRE)}>
                      {initialGenres.length ? (
                        initialGenres.length <= 2 ? (
                          initialGenres.map((res, index) => (
                            <span key={index}>
                              {res.name}
                              {index < initialGenres.length - 1 ? ", " : null}
                            </span>
                          ))
                        ) : (
                          <Fragment>
                            <span key={0}>{initialGenres[0].name}, </span>
                            <span key={1}>{initialGenres[1].name}, </span>
                            <span key={2}>
                              +{initialGenres.length - 2} more
                            </span>
                          </Fragment>
                        )
                      ) : (
                        <span className="not-set">
                          {t("uploadSection.span")}
                        </span>
                      )}
                    </label>
                  </Grid>
                </Grid>
                <Grid container item xs={12} className="grid-section">
                  <Grid item xs={4}>
                    <label className="">{t("uploadSection.label2")}</label>
                  </Grid>
                  <Grid item xs={8} className="txt-rght">
                    <label
                      onClick={() => this.handleModal(UploadStep.LANGUAGE)}
                    >
                      {initialLanguage.length ? (
                        initialLanguage.length <= 2 ? (
                          initialLanguage.map((res, index) => (
                            <span key={index}>
                              {res.nameUtf8}
                              {index < initialLanguage.length - 1 ? "," : null}
                            </span>
                          ))
                        ) : (
                          <Fragment>
                            <span key={0}>{initialLanguage[0].nameUtf8}, </span>
                            <span key={1}>{initialLanguage[1].nameUtf8}, </span>
                            <span key={2}>
                              +{initialLanguage.length - 2} more
                            </span>
                          </Fragment>
                        )
                      ) : (
                        <span className="not-set">
                          {t("uploadSection.span")}
                        </span>
                      )}
                    </label>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className="grid-section"
                  style={{ paddingBottom: 0 }}
                >
                  <Grid item xs={8}>
                    <label className="">{t("uploadSection.label3")}</label>
                  </Grid>
                  <Grid item xs={4} className="txt-rght">
                    <Switch
                      className="matureSwitch"
                      checked={matureContent}
                      onChange={this.handleMatureContent}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
            {/* ) : null} */}

            {/* {!isMobileOnly && (
              <form onSubmit={this.handleSubmit} id="image-upload-form">
                <Grid container spacing={0} className="m-0">
                  <Grid item xs={12} className="grd-frm">
                    <label className="bold">Description</label>
                    <TextField
                      name="name"
                      placeholder="Enter Description"
                      // component={FcText}
                      multiline={true}
                      rows={1}
                      rowsMax={4}
                      value={description}
                      variant="outlined"
                      onChange={(e) => this.handleDescriptionChange(e.target.value)}
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} className="grd-frm">
                    <label className="bold">Genre</label>
                    <FcCustomSearch
                      name="genre"
                      optionValue="id"
                      optionLabel="name"
                      source={genres}
                      multiple={true}
                      val={initialGenres}
                      onChangeSelect={this.handleGenreChange}
                    ></FcCustomSearch>
                  </Grid>
                  <Grid item xs={12} sm={6} className="grd-frm">
                    <label className="bold">Language</label>
                    <FcCustomSearch
                      name="language"
                      optionValue="id"
                      optionLabel="nameUtf8"
                      source={languages}
                      multiple={true}
                      val={initialLanguage}
                      onChangeSelect={this.handleLanguageChange}
                    ></FcCustomSearch>
                  </Grid>
                </Grid>
              </form>
            )} */}
          </div>
        </Grid>
        {/* 
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={4}>
          {!isMobileOnly ? <SelectedImages slider={true} /> : null}
        </Grid> */}
      </Fragment>
    );
  }

  componentWillUnmount() {
    const { closeModal, commonModal } = this.props;
    if (commonModal) {
      closeModal(ModalType.COMMON);
    }
  }
}

export default compose(withTranslation())(withRouter(ImageUpload));
