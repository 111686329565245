import React, { PureComponent } from "react";
import { isMobileOnly } from "react-device-detect";
import { SignInUser, SendIcon } from "../../../icons";
import { ModalType } from "../../../constant";
import { getProfileImageUrl } from "../../../core";
import "./comment.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import comment from ".";

class Comment extends PureComponent {
  onCommentSubmit = (e) => {
    const { isLoggedIn, openModal, addComment, loadFromSession } = this.props;
    e.preventDefault();
    if (!loadFromSession) {
      if (isLoggedIn) {
        addComment();
      } else {
        openModal(ModalType.LOGIN);
      }
    }
  };
  onError = (e) => {
    const { user } = this.props;
    e.target.src = getProfileImageUrl("", user.id, user.name);
  };
  render() {
    const { totalComment, comments, hideCommentBox, user, commentText, setCommentText, t } = this.props;
    const userProfilePic = (
      <img
        src={getProfileImageUrl(user.picUrl, user.id, user.name)}
        width={"30px"}
        height={"30px"}
        className="signin-user"
        onError={this.onError}
      />
    );
    return (
      <div className="comment-container comment-animate">
        {/* <div className="comment-head">
          <div className="user-img">{userProfilePic}</div>
          <div className="user-comment head">
            <h2 className="heading">
              <div> {!!user.name ? user.name : user.phone} </div>
            </h2>
          </div>
        </div> */}
        <div className="sub-header text-left tab-hidden">
          <button className="back-btn" onClick={hideCommentBox}></button>
          <h1>{t("comments.h1")}</h1>
        </div>
        <div className="comment-count">
          <div className="col-md-12" onClick={hideCommentBox}>
            {/* <span></span> */}
            {totalComment == 0 ? t("formOptions.label2") : totalComment} {t("comments.h1")}
          </div>
        </div>
        <div className="comment-text-box-container">
          <div className="comment-text-box mobile-hidden bottom-comment-post">
            <div className="user-img">{userProfilePic}</div>
            <div className="user-comment edit-input">
              <form onSubmit={this.onCommentSubmit}>
                <input
                  name="commentTyped"
                  rows="3"
                  className="input-box"
                  placeholder={t("comments.title")}
                  autoComplete="off"
                  onChange={(e) => setCommentText(e.target.value)}
                  value={commentText}
                />
                {/* <input
                  type="text"
                  name="commentTyped"
                  className="input-box"
                  placeholder="Write a comment"
                  autoComplete="off"
                  onChange={(e) => setCommentText(e.target.value)}
                  value={commentText}
                /> */}

                <button type="submit" hidden></button>
              </form>
            </div>
            {/* <ul className="rply-like-time">
              <li className="input-msg">Press enter to post</li>
              <li></li>
            </ul> */}
          </div>
          <div className="comment-scroll">
            {comments.map((comment, index) => (
              <div className="comment-text-box" key={index}>
                <div className="user-img">
                  <img
                    src={getProfileImageUrl(
                      comment.user.picUrl,
                      comment.user.id,
                      comment.user.name
                    )}
                    width={"30px"}
                    height={"30px"}
                    className="signin-user"
                    onError={(e) => {
                      e.target.src = getProfileImageUrl(
                        "",
                        comment.user.id,
                        comment.user.name
                      );
                    }}
                  />
                </div>
                <div className="user-comment">
                  <h2 className="heading">
                    {!!comment.user && !!comment.user.name
                      ? comment.user.name
                      : comment.user.phone}
                  </h2>
                  <p>{comment.comments}</p>
                </div>
                <ul className="rply-like-time">
                  <li>{comment.commentText}</li>
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="">
          <div className="comment-text-box mobile-comment tab-hidden">
            <div className="user-img">{userProfilePic}</div>
            <div className="user-comment edit-input">
              <form onSubmit={this.onCommentSubmit}>
                <input
                  type="text"
                  name="commentTyped"
                  className="input-box"
                  placeholder=""
                  autoComplete="off"
                  onChange={(e) => setCommentText(e.target.value)}
                  value={commentText}
                />
              </form>
              {!isMobileOnly ? (
                <button className="send-icon" onClick={this.onCommentSubmit}>
                  <SendIcon className="send" />
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={this.onCommentSubmit}
                  className={"submitButton no-shadow commentbtn"}
                >
                  <SendIcon className="sendmobile" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(Comment);
