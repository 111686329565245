import { UPLOAD } from "../../actions";

const imageMatureContentReducer = (state = false, action) => {
    switch (action.type) {
        case UPLOAD.CLEAR:
        case UPLOAD.CLEAR_CONTENT:
        case UPLOAD.DISABLE_IMAGE_UPLOAD_MATURE_CONTENT:
            return false;
        case UPLOAD.ENABLE_IMAGE_UPLOAD_MATURE_CONTENT:
            return true;
        default:
            return state;
    }
};

export default imageMatureContentReducer;
