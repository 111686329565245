import { PREFERENCE } from "./action-types";
const setPreferenceActiveTab = (tab) => ({
  type: PREFERENCE.SET_ACTIVE_TAB,
  tab,
});

const setFullName = fullName => ({
  type: PREFERENCE.SET_FULL_NAME,
  fullName,
});

const setEmail = email => ({
  type: PREFERENCE.SET_EMAIL,
  email,
});

const setCity = city => ({
  type: PREFERENCE.SET_CITY,
  city,
});

const setCountry = country => ({
  type: PREFERENCE.SET_COUNTRY,
  country,
});

const setPhone = phone => ({
  type: PREFERENCE.SET_PHONE,
  phone,
});

const setGender = gender => ({
  type: PREFERENCE.SET_GENDER,
  gender,
});

export { setPreferenceActiveTab, setFullName, setEmail, setCity, setCountry, setPhone, setGender };
