import React, { PureComponent, Fragment } from "react";
import "./selected-images.scss";
import { Button } from "@material-ui/core";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Slider from "react-animated-slider";

export default class SelectedImages extends PureComponent {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
  }
  removeFile = (index) => {
    const { images, updateUploadImage } = this.props;
    images.splice(index, 1);
    updateUploadImage(images);
  };
  handleSlideChange = (e) => {
    console.log(e);
  };
  render() {
    const { images, imageDescription, slider } = this.props;
    return (
      <Fragment>
        {slider ? (
          <div
            className="step-one-images slider"
            data-text="Preview not loaded"
          >
            {images.length > 1 ? (
              <Slider
                duration={200}
                ref={this.sliderRef}
                onSlideChange={(event) => this.handleSlideChange(event)}
                infinite={true}
                className="imgslider"
              >
                {images.map((image, index) => (
                  <div key={index} className="img" alt="">
                    <label className="bold">Preview</label>
                    <Button
                      color="secondary"
                      className="delete-img"
                      onClick={() => this.removeFile(index)}
                    >
                      Delete photo
                    </Button>
                    <img src={image.preview} alt="" />
                    <div className="desc">{imageDescription}</div>
                  </div>
                ))}
              </Slider>
            ) : !!images.length ? (
              images.map((image, index) => (
                <div key={index} className="img" alt="">
                  <label className="bold">Preview</label>
                  <Button
                    color="secondary"
                    className="delete-img"
                    onClick={() => this.removeFile(index)}
                  >
                    Delete photo
                  </Button>
                  <img src={image.preview} alt="" />
                  <label id="edit-label">Preview</label>
                  <div className="desc">{imageDescription}</div>
                </div>
              ))
            ) : null}
          </div>
        ) : (
          <div className="step-two-images" data-text="Preview">
            <div style={{ display: "flex" }}>
              {!!images.length &&
                images.map((image, index) => (
                  <div key={index} className="img" alt="">
                    <button
                      className="delete-img"
                      onClick={() => this.removeFile(index)}
                    ></button>
                    <img src={image.preview} alt="" />
                  </div>
                ))}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
