import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as Sidebar } from "./sidebar";
import {
  openModal,
  openSidebar,
  getActiveFilterCountries,
  getActiveFilterLanguages,
  navlinkChange,
  showSelectedText,
  setAlertAction,
  closeSidebar,
  getSelectedCountries,
  setFilterCountry,
  listSearchedCountries,
  setFilter,
  clearCountry,
} from "../../../store";

const mapStateToProps = (state) => ({
  sidebar: state.ui.sidebar,
  isDiscoverRoute: state.ui.isDiscoverRoute,
  discoverList: state.discover.discoverList,
  userDiscoverList: state.discover.userDiscoverList,
  isLoggedIn: state.auth.isLoggedIn,
  selectedCountries: getActiveFilterCountries(state),
  selectedAllCountries: getSelectedCountries(state),
  countries: listSearchedCountries(state),
  selectedLanguages: getActiveFilterLanguages(state),
  isDarkTheme: state.setting.darkTheme,
  picUrl: state.auth.data ? state.auth.data.picUrl : "",
  user: state.auth.data,
  activeCountry: state.auth.activeCountry,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  openSidebar: () => dispatch(openSidebar()),
  closeSidebar: () => dispatch(closeSidebar()),
  navlinkChange: () => dispatch(navlinkChange()),
  showSelectedText: (text) => dispatch(showSelectedText(text)),
  setAlertAction: (alert) => dispatch(setAlertAction(alert)),
  setFilterCountry: (countryId) => dispatch(setFilterCountry(countryId)),
  setFilter: (filter) => dispatch(setFilter(filter)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
