import React from "react";
import "./tik.scss";

const Tik = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 23.4 17.8"
    style={{ enableBackground: "new 0 0 23.4 17.8" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <polygon className="primary" points="7.6,17.8 0,10.5 2.2,8.2 7.5,13.4 21.2,0 23.4,2.3 	" />
    </g>
  </svg>
);
const Untik = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 23.4 17.8"
    style={{ enableBackground: "new 0 0 23.4 17.8" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <path className="primary" d="M7.7,17.8L0,10.5l2.5-2.6l5.2,5L20.9,0l2.5,2.5L7.7,17.8z M0.7,10.5l7,6.7L22.7,2.5l-1.8-1.9L7.7,13.5
			L2.5,8.6L0.7,10.5z"/>
      </g>
    </g>
  </svg>
);



export { Tik, Untik };
