import React, { PureComponent, Fragment } from "react";
import { ButtonBase, CircularProgress } from "@material-ui/core";
import { ModalType } from "../../../../../constant";
import "./mobile-number.scss";
import { withTranslation } from "react-i18next";
import MuiPhoneNumber from "../../../../../modules/react-phone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactCountryFlag from "react-country-flag";
import Flag from "react-world-flags";

class MobileNumber extends PureComponent {
  constructor(props) {
    super(props);
    const { setLoginNumber } = this.props;
    setLoginNumber("");
  }
  // handleCountryChange = (e) => {
  //   const { setLoginCountry } = this.props;
  //   setLoginCountry({ countryCode: isoCode, callingCode: `+${diallingCode}` });
  // };
  handleInputChange = (event) => {
    const { target } = event;
    if (target.validity.valid) {
      const { setLoginNumber } = this.props;
      setLoginNumber(target.value);
    }
  };
  // handleInputChange = (val) => {
  //   console.log(val);
  //   //const { target } = event;
  //   // if (target.validity.valid) {
  //   const { setLoginNumber } = this.props;
  //   setLoginNumber(val);
  //   // }
  // };
  handleNextClick = () => {
    const { sendOtp } = this.props;
    sendOtp();
  };

  pressEnter = (e) => {
    const { number, isLoading } = this.props;
    if (!(number.length < 6 || isLoading)) {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();
        this.handleNextClick();
      }
    }
  };

  render() {
    const { isLoading, number, activeCountry, openModal, error, t } = this.props;

    return (
      <Fragment>
        <div>
          <div className={"noAccountLinkMobile"}>
            <label className="subheading">{t("login.mobile.title")}</label>
          </div>
          <div className={"inputNumberWrapper"} onKeyDown={this.pressEnter}>
            <ButtonBase className="countryCode" onClick={() => openModal(ModalType.COUNTRY)}>
              {/* <i className={`flag ${activeCountry.countryCode.toLowerCase()}`}></i> */}
              {/* <ReactCountryFlag
                style={{ width: "50px", height: "50px" }}
                countryCode={activeCountry.countryCode}

              ></ReactCountryFlag> */}
              <div className="countryFlag">
                <Flag code={activeCountry.countryCode} height="24" />
              </div>
              {activeCountry.countryCode + " " + activeCountry.callingCode}
              <ExpandMoreIcon />
            </ButtonBase>
            <input
              name="username"
              placeholder="xxxxx xxxxx"
              className={"inputField"}
              // maxLength={10}
              value={number}
              onChange={this.handleInputChange}
              pattern="[0-9]*"
              autoComplete="off"
              max="10"
              maxLength="10"
            />
            {/* {isLoading && <CircularProgress className="loader2" />} */}
            {/* {
              <MuiPhoneNumber
                defaultCountry={"in"}
                onChange={this.handleInputChange}
                onClick={this.handleCountryChange}
              />
            } */}
          </div>
          <button
            className={"submitButton ml-0"}
            disabled={number.length < 6 || isLoading}
            onClick={this.handleNextClick}
          >
            {!isLoading ? (
              t("login.mobile.button1")
            ) : (
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            )}
          </button>
          <span className="error">{error}</span>
          <p className={"para"}>{t("login.mobile.description")}</p>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(MobileNumber);
