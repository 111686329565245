import React from "react";
import "./pin.scss";

const Catpin = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    style={{ enableBackground: "new 0 0 20 20" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path className="primary" d="M13.7,10.2L13.7,10.2L14,9.7l2-2c1.2,0.5,2.4,0.5,3-0.2c1.1-1.1,0.5-3.4-1.3-5.2S13.6,0,12.5,1.1
	c-0.6,0.6-0.7,1.6-0.3,2.7l-2.1,2.1L9.7,6.3C7.9,5.4,6.2,5.4,5.3,6.4c-1.4,1.4-0.7,4.5,1.6,6.9s5.4,3.1,6.8,1.6
	C14.7,13.9,14.7,12,13.7,10.2z"/>
    <line className="primary2" x1="0.3" y1="19.6" x2="6.8" y2="13.1" />
  </svg>
);
const Catunpin = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    style={{ enableBackground: "new 0 0 20 20" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path className="primary" d="M13.7,10.1L13.7,10.1L14,9.7l1.9-1.9c1.2,0.5,2.4,0.5,3-0.2c1.1-1.1,0.5-3.4-1.3-5.2S13.6,0,12.5,1.1
	c-0.6,0.6-0.7,1.6-0.3,2.7l-2.1,2.1L9.7,6.2C7.9,5.4,6.3,5.4,5.3,6.4c-1.4,1.4-0.7,4.5,1.6,6.8s5.4,3,6.8,1.6
	C14.7,13.8,14.6,11.9,13.7,10.1z"/>
    <line className="primary" x1="0.3" y1="19.4" x2="6.8" y2="13" />
  </svg>
);



export { Catpin, Catunpin };
