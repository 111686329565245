export const AUTH = {
  LOAD: "AUTH_LOAD",
  LOAD_SUCCESS: "AUTH_LOAD_SUCCESS",
  LOAD_FAILED: "AUTH_LOAD_FAILED",
  LOAD_USER_PROFILE: "LOAD_USER_PROFILE",
  CLEAR: "AUTH_CLEAR",
  SET_NUMBER: "AUTH_SET_NUMBER",
  SET_OTP: "AUTH_SET_OTP",
  OTP_SUCCESS: "AUTH_OTP_SUCCESS",
  OTP_SENT: "AUTH_OTP_SENT",
  CLEAR_OTP_SENT: "CLEAR_OTP_SENT",
  SET_COUNTRY: "AUTH_SET_COUNTRY",
  SEND_OTP: "AUTH_SEND_OTP",
  VALIDATE_OTP: "AUTH_VALIDATE_OTP",
  CLEAR_LOGIN: "AUTH_CLEAR_LOGIN",
  EDIT_USER_INFO: "AUTH_EDIT_USER_INFO",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  CLEAR_USER_ID: "CLEAR_USER_ID",
  REFRESH_AUTH: "REFRESH_AUTH",
  CHANGE_PROFILE_IMAGE: "CHANGE_PROFILE_IMAGE",
  REMOVE_PROFILE_IMAGE: "REMOVE_PROFILE_IMAGE",
  NON_LOGIN_SELECTED_TEXT: "NON_LOGIN_SELECTED_TEXT",
  CLEAR_SELECTED_TEXT: "CLEAR_SELECTED_TEXT",
  USER_ID: "AUTH_SET_USER_ID",
  SELECTED_USER_ID: "AUTH_SELECTED_USER_ID",
  OTHER_USER_DATA: "AUTH_SET_OTHER_USER",
  LOAD_OTHER_USER_PROFILE: "LOAD_OTHER_USER_PROFILE",
  LOAD_FOLLOWERS: "LOAD_FOLLOWERS",
  LOAD_FOLLOWING: "LOAD_FOLLOWING",
  SET_FOLLOWERS: "SET_FOLLOWERS",
  SET_FOLLOWING: "SET_FOLLOWING",
  FOLLOW_USER: "FOLLOW_USER",
  UnFOLLOW_USER: "UnFOLLOW_USER",
  SEARCH_USER: "SEARCH_USER",
  SET_SEARCH_USER: "SET_SEARCH_USER",
};

export const CONTENT = {
  CHANGE_VOLUME: "CHANGE_VOLUME",
  CHANGE_AVOLUME: "CHANGE_AVOLUME",
  LOAD: "CONTENT_LOAD",
  CANCEL: "CONTENT_CANCEL",
  CANCEL_USER: "CONTENT_USER_CANCEL",
  LOAD_SSR: "LOAD_SSR",
  LOAD_CLIENT: "LOAD_CLIENT",
  DELETE_CONTENT: "DELETE_CONTENT",
  LOAD_SESSION: "SESSION_CONTENT_LOAD",
  LOAD_WEBSITE: "WEBSITE_CONTENT_LOAD",
  LOAD_SUCCESS: "CONTENT_LOAD_SUCCESS",
  LOAD_FAILED: "CONTENT_LOAD_FAILED",
  CLEAR: "CONTENT_CLEAR",
  CLEAR_ACTION: "CLEAR_ACTION",
  PAGE_RESET: "CONTENT_PAGE_RESET",
  PAGE_CHANGE: "CONTENT_PAGE_CHANGE",
  FILTER_RESET: "CONTENT_FILTER_RESET",
  FILTER_RESET_ON_ACTION: "CONTENT_FILTER_RESET_ON_ACTION",
  DISCOVER_FILTER_RESET: "CONTENT_DISCOVER_FILTER_RESET",
  FILTER_CHANGE: "CONTENT_FILTER_CHANGE",
  CUSTOM_FILTER_CHANGE: "CONTENT_CUSTOM_FILTER_CHANGE",
  PER_PAGE_CHANGE: "CONTENT_PER_PAGE_CHANGE",
  TOTAL_PAGE_RESET: "CONTENT_TOTAL_PAGE_RESET",
  TOTAL_PAGE_CHANGE: "CONTENT_TOTAL_PAGE_CHANGE",
  ACTIVE_CONTENT: "SET_ACTIVE_CONTENT",
  ACTIVE_CONTENT_URL: "ACTIVE_CONTENT_URL",
  ACTIVE_CONTENT_INDEX: "ACTIVE_CONTENT_INDEX",
  INITIAL_SLIDE: "INITIAL_SLIDE",
  RESET_ACTIVE_CONTENT: "RESET_ACTIVE_CONTENT",
  RESET_ACTIVE_CONTENT_URL: "RESET_ACTIVE_CONTENT_URL",
  ACTION_CHANGE: "CONTENT_ACTION_CHANGE",
  USER_ACTION_CHANGE: "CONTENT_USER_ACTION_CHANGE",
  TOTAL_CONTENT_COUNT: "TOTAL_CONTENT_COUNT",
  START_CONTENT_SCROLL_TIMER: "START_CONTENT_SCROLL_TIMER",
  STOP_CONTENT_SCROLL_TIMER: "STOP_CONTENT_SCROLL_TIMER",
  CONTENT_LOAD_FROM_GRID: "CONTENT_LOAD_FROM_GRID",
  LOAD_CONTENT_DETAIL: "LOAD_CONTENT_DETAIL",
  ROUTE_FROM_LIGHTBOX: "ROUTE_FROM_LIGHTBOX",
  CLEAR_ROUTE_FROM_LIGHTBOX: "CLEAR_ROUTE_FROM_LIGHTBOX",
  REPORT_CONTENT: "REPORT_CONTENT",
  SET_REPORT_CONTENT_ID: "SET_REPORT_CONTENT_ID",
  SET_REPORT_CONTENT_TEXT: "SET_REPORT_CONTENT_TEXT",
  HIDE_CONTENT: "HIDE_CONTENT",
  REFRESH_CONTENT: "REFRESH_CONTENT",
  CONTENT_DOWNLOAD: "CONTENT_DOWNLOAD",
  RESET_ALREADY_VISITED_CONTENT: "RESET_ALREADY_VISITED_CONTENT",
  SET_ALREADY_VISITED_CONTENT: "SET_ALREADY_VISITED_CONTENT",
  CONTENT_ALREADY_VISITED: "CONTENT_ALREADY_VISITED",
  NEW_CONTENT_VISITED: "NEW_CONTENT_VISITED",
  MUTE_VIDEO: "CONTENT_MUTE_VIDEO",
  UNMUTE_VIDEO: "CONTENT_UNMUTE_VIDEO",
  MUTE_AUDIO: "CONTENT_MUTE_AUDIO",
  UNMUTE_AUDIO: "CONTENT_UNMUTE_AUDIO",
  YES_CONTROL_VIDEO: "YES_CONTROL_VIDEO",
  NO_CONTROL_VIDEO: "NO_CONTROL_VIDEO",
  LOAD_CONTENT_FROM_API: "LOAD_CONTENT_FROM_API",
  LOAD_CONTENT_FROM_CACHE: "LOAD_CONTENT_FROM_CACHE",
  SHOW_NEXT_ARROW: "SHOW_NEXT_ARROW",
  HIDE_NEXT_ARROW: "HIDE_NEXT_ARROW",
  CONTENT_DETAIL_OPEN: "CONTENT_DETAIL_OPEN",
  CONTENT_DETAIL_CLOSE: "CONTENT_DETAIL_CLOSE",
  CONTENT_NOT_FOUND: "CONTENT_NOT_FOUND",
  CONTENT_FORMAT_RESET: "CONTENT_FORMAT_RESET",
  MAGIC_STICK_ENABLED: "MAGIC_STICK_ENABLED",
  MAGIC_STICK_DISABLED: "MAGIC_STICK_DISABLED",
  SHOW_FOOTER_ADS: "SHOW_FOOTER_ADS",
  HIDE_FOOTER_ADS: "HIDE_FOOTER_ADS"
};

export const SEARCH = {
  LOAD: "SEARCH_LOAD",
  LOAD_SUCCESS: "SEARCH_LOAD_SUCCESS",
  LOAD_FAILED: "SEARCH_LOAD_FAILED",
  CLEAR: "SEARCH_CLEAR",
  CLEAR_RESULT: "CLEAR_SEARCH_RESULT",
};

export const DISCOVER = {
  LOAD_DISCOVER_LIST: "LOAD_DISCOVER_LIST",
  LOAD_DISCOVER_LIST_SUCCESS: "LOAD_DISCOVER_LIST_SUCCESS",
  LOAD_DISCOVER_CONTENT_LIST_SUCCESS: "LOAD_DISCOVER_CONTENT_LIST_SUCCESS",
  LOAD_USER_DISCOVER_LIST: "LOAD_USER_DISCOVER_LIST",
  LOAD_USER_DISCOVER_LIST_SUCCESS: "LOAD_USER_DISCOVER_LIST_SUCCESS",
  LOAD_USER_DISCOVER_CONTENT_LIST_SUCCESS:
    "LOAD_USER_DISCOVER_CONTENT_LIST_SUCCESS",
  CLEAR_DISCOVER_LIST: "CLEAR_DISCOVER_LIST",
  CLEAR_DISCOVER_CONTENT_LIST: "CLEAR_DISCOVER_CONTENT_LIST",
  CLEAR_USER_DISCOVER_LIST: "CLEAR_USER_DISCOVER_LIST",
  CLEAR_USER_DISCOVER_CONTENT_LIST: "CLEAR_USER_DISCOVER_CONTENT_LIST",
  LOAD_FAILED: "DISCOVER_LOAD_FAILED",
};

export const UI = {
  OPEN_SIDEBAR: "OPEN_SIDEBAR",
  CLOSE_SIDEBAR: "CLOSE_SIDEBAR",
  OPEN_SNACKBAR: "OPEN_SNACKBAR",
  OPEN_ERROR_SNACKBAR: "OPEN_ERROR_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
  CLOSE_ERROR_SNACKBAR: "CLOSE_ERROR_SNACKBAR",
  SET_MESSAGE: "SET_MESSAGE",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  CLEAR_MASSAGE: "CLEAR_MASSAGE",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  MODAL_ROUTE: "MODAL_ROUTE",
  LOAD_UI_DATA: "LOAD_UI_DATA",
  MODAL_ACTION: "MODAL_ACTION",
  ALERT_ACTION: "ALERT_ACTION",
  SHOW_COMMENT: "SHOW_COMMENT",
  HIDE_COMMENT: "HIDE_COMMENT",
  RENDER_DISCOVER_ROUTE: "RENDER_DISCOVER_ROUTE",
  LEAVE_DISCOVER_ROUTE: "LEAVE_DISCOVER_ROUTE",
  SHOW_NEWFEED: "SHOW_NEWFEED",
  HIDE_NEWFEED: "HIDE_NEWFEED",
  NAVLINK_CHANGE: "NAVLINK_CHANGE",
  SHOW_INSTALL_APP: "SHOW_INSTALL_APP",
  HIDE_INSTALL_APP: "HIDE_INSTALL_APP",
  ACTIVE_TAB: "ACTIVE_TAB",
  INACTIVE_TAB: "INACTIVE_TAB",
  SITE_LANGUAGE: "SET_SITE_LANGUAGE",
  START_PROGRESS_BAR: "START_PROGRESS_BAR",
  STOP_PROGRESS_BAR: "STOP_PROGRESS_BAR",
};

export const SETTING = {
  CLEAR: "CLEAR_SETTING",
  ENABLE_DARK_THEME: "ENABLE_DARK_THEME",
  DISABLE_DARK_THEME: "DISABLE_DARK_THEME",
  ENABLE_RESTRICTED_MODE: "ENABLE_RESTRICTED_MODE",
  DISABLE_RESTRICTED_MODE: "DISABLE_RESTRICTED_MODE",
  CLEAR_HISTORY: "CLEAR_HISTORY",
  LOG_OUT: "LOG_OUT",
  SET_SCROLL_TIME: "SET_SCROLL_TIME",
  CHANGE_SCROLL_TIME: "CHANGE_SCROLL_TIME",
  ENABLE_NOTIFICATION: "ENABLE_NOTIFICATION",
  DISABLE_NOTIFICATION: "DISABLE_NOTIFICATION",
};

export const SHARE = {
  LOAD: "SHARE_LOAD",
  LOAD_SUCCESS: "SHARE_LOAD_SUCCESS",
  SET_CONTENT_URL: "SHARE_SET_CONTENT",
  LOAD_FAILED: "SHARE_LOAD_FAILED",
  SHARE_TYPE: "SHARE_TYPE",
  CLEAR: "SHARE_CLEAR",
};

export const MASTER = {
  LOAD: "MASTER_LOAD",
  LOAD_FAILED: "MASTER_LOAD_FAILED",
  LOAD_GENRE: "MASTER_LOAD_GENRE",
  LOAD_FORMAT: "MASTER_LOAD_FORMAT",
  LOAD_LANGUAGE: "MASTER_LOAD_LANGUAGE",
  LOAD_COUNTRY: "MASTER_LOAD_COUNTRY",
  LOAD_REPORT: "MASTER_LOAD_REPORT",
  LOAD_VIDEO_FORMAT: "MASTER_LOAD_VIDEO_FORMAT",
  LOAD_THEME: "LOAD_THEME",
  CLEAR: "MASTER_CLEAR",
};

export const COUNTRY = {
  FILTER_COUNTRY: "SET_FILTER_COUNTRY",
  UPLOAD_COUNTRY: "SET_UPLOAD_COUNTRY",
  LOGIN_COUNTRY: "SET_LOGIN_COUNTRY",
  CLEAR: "COUNTRY_CLEAR",
  SEARCH_COUNTRY: "SEARCH_COUNTRY",
  CLEAR_SEARCH: "CLEAR_SEARCH_COUNTRY",
};

export const LANGUAGE = {
  FILTER_LANGUAGE: "SET_FILTER_LANGUAGE",
  UPLOAD_LANGUAGE: "SET_UPLOAD_LANGUAGE",
  UPDATE_UPLOAD_LANGUAGE: "UPDATE_UPLOAD_LANGUAGE",
  CLEAR: "LANGUAGE_CLEAR",
  SEARCH_LANGUAGE: "SEARCH_LANGUAGE",
  CLEAR_SEARCH: "CLEAR_SEARCH_LANGUAGE",
};

export const UPLOAD = {
  CLEAR: "CLEAR_UPLOAD",
  CANCEL: "CANCEL_UPLOAD",
  RESET_UPLOAD_TAB: "RESET_UPLOAD_TAB",
  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_CONTENT: "CLEAR_UPLOAD_CONTENT",
  CLEAR_PREVIEW_FILES: "CLEAR_PREVIEW_FILES",
  SET_UPLOAD_IMAGE: "SET_UPLOAD_IMAGE",
  UPDATE_UPLOAD_IMAGE: "UPDATE_UPLOAD_IMAGE",
  SET_UPLOAD_IMAGE_DESCRIPTION: "SET_UPLOAD_IMAGE_DESCRIPTION",
  SET_UPLOAD_VIDEO: "SET_UPLOAD_VIDEO",
  UPDATE_UPLOAD_VIDEO: "UPDATE_UPLOAD_VIDEO",
  SET_UPLOAD_VIDEO_DESCRIPTION: "SET_UPLOAD_VIDEO_DESCRIPTION",
  SET_UPLOAD_STORY_TITLE: "SET_UPLOAD_STORY_TITLE",
  SET_UPLOAD_STORY_DESCRIPTION: "SET_UPLOAD_STORY_DESCRIPTION",
  SET_UPLOAD_WEBLINK: "SET_UPLOAD_WEBLINK",
  SET_ACTIVE_TAB: "SET_UPLOAD_ACTIVE_TAB",
  SET_UPLOAD_ERROR: "SET_UPLOAD_ERROR",
  VALIDATE_UPLOAD: "VALIDATE_UPLOAD",
  VALIDATE_UPLOAD_LANGUAGE: "VALIDATE_UPLOAD_LANGUAGE",
  SET_UPLOAD_STEP: "SET_UPLOAD_STEP",
  SET_UPLOAD_IMAGE_LANGUAGE: "SET_UPLOAD_IMAGE_LANGUAGE",
  SET_UPLOAD_VIDEO_LANGUAGE: "SET_UPLOAD_VIDEO_LANGUAGE",
  SET_UPLOAD_STORY_LANGUAGE: "SET_UPLOAD_STORY_LANGUAGE",
  SET_UPLOAD_WEB_LANGUAGE: "SET_UPLOAD_WEB_LANGUAGE",
  SET_UPLOAD_GENRE: "SET_UPLOAD_GENRE",
  UPDATE_UPLOAD_GENRE: "UPDATE_UPLOAD_GENRE",
  SET_UPLOAD_LANGUAGE: "SET_UPLOAD_LANGUAGE",
  UPDATE_UPLOAD_LANGUAGE: "UPDATE_UPLOAD_LANGUAGE",
  CLEAR_UPLOAD_GENRE: "CLEAR_UPLOAD_GENRE",
  CLEAR_UPLOAD_LANGUAGE: "CLEAR_UPLOAD_LANGUAGE",
  SET_IMAGE_UPLOAD_GENRE: "SET_IMAGE_UPLOAD_GENRE",
  SET_VIDEO_UPLOAD_GENRE: "SET_VIDEO_UPLOAD_GENRE",
  SET_LINK_UPLOAD_GENRE: "SET_LINK_UPLOAD_GENRE",
  SET_STORY_UPLOAD_GENRE: "SET_STORY_UPLOAD_GENRE",
  SET_IMAGE_UPLOAD_PERCENTAGE: "SET_IMAGE_UPLOAD_PERCENTAGE",
  SET_VIDEO_UPLOAD_PERCENTAGE: "SET_VIDEO_UPLOAD_PERCENTAGE",
  SET_LINK_UPLOAD_PERCENTAGE: "SET_LINK_UPLOAD_PERCENTAGE",
  SET_STORY_UPLOAD_PERCENTAGE: "SET_STORY_UPLOAD_PERCENTAGE",
  RESET_IMAGE_UPLOAD_PERCENTAGE: "RESET_IMAGE_UPLOAD_PERCENTAGE",
  RESET_VIDEO_UPLOAD_PERCENTAGE: "RESET_VIDEO_UPLOAD_PERCENTAGE",
  RESET_LINK_UPLOAD_PERCENTAGE: "RESET_LINK_UPLOAD_PERCENTAGE",
  RESET_STORY_UPLOAD_PERCENTAGE: "RESET_STORY_UPLOAD_PERCENTAGE",
  ENABLE_IMAGE_UPLOAD_MATURE_CONTENT: "ENABLE_IMAGE_UPLOAD_MATURE_CONTENT",
  ENABLE_VIDEO_UPLOAD_MATURE_CONTENT: "ENABLE_VIDEO_UPLOAD_MATURE_CONTENT",
  ENABLE_LINK_UPLOAD_MATURE_CONTENT: "ENABLE_LINK_UPLOAD_MATURE_CONTENT",
  ENABLE_STORY_UPLOAD_MATURE_CONTENT: "ENABLE_STORY_UPLOAD_MATURE_CONTENT",
  DISABLE_IMAGE_UPLOAD_MATURE_CONTENT: "DISABLE_IMAGE_UPLOAD_MATURE_CONTENT",
  DISABLE_VIDEO_UPLOAD_MATURE_CONTENT: "DISABLE_VIDEO_UPLOAD_MATURE_CONTENT",
  DISABLE_LINK_UPLOAD_MATURE_CONTENT: "DISABLE_LINK_UPLOAD_MATURE_CONTENT",
  DISABLE_STORY_UPLOAD_MATURE_CONTENT: "DISABLE_STORY_UPLOAD_MATURE_CONTENT",
  PUBLISH_UPLOAD: "PUBLISH_UPLOAD",
  PUBLISH_ALL: "PUBLISH_ALL",
  ADD_MORE_CONTENT: "ADD_MORE_CONTENT",
  MORE_SELECTED: "MORE_SELECTED",
  RESET_MORE_SELECTED: "RESET_MORE_SELECTED",
  RESET_ADD_MORE_CONTENT: "RESET_ADD_MORE_CONTENT",
  SET_CONTENT_DATA_LIST: "SET_CONTENT_DATA_LIST",
  UPDATE_CONTENT_DATA_LIST: "UPDATE_CONTENT_DATA_LIST",
  SET_CONTENT_FILES: "SET_CONTENT_FILES",
  SET_CONTENT_PREVIEW: "SET_CONTENT_PREVIEW",
  SET_PREVIEW_FILES: "SET_PREVIEW_FILES",
  UPLOAD_DATALIST: "UPLOAD_DATALIST",
  UPLOAD_FILES: "UPLOAD_FILES",
  CLEAR_UPLOADING_FILES: "CLEAR_UPLOADING_FILES",
  SET_UPLOADING_FILES: "SET_UPLOADING_FILES",
  UPDATE_UPLOADING_FILES: "UPDATE_UPLOADING_FILES",
  SET_META_DETAIL: "SET_META_DETAIL",
  LOAD_METADATA: "LOAD_METADATA",
  CLEAR_SEARCH: "CLEAR_GENRE_SEARCH",
  SEARCH_GENRE: "SEARCH_GENRE",
};

export const FEEDBACK = {
  SEND_FEEDBACK: "SEND_FEEDBACK",
  SET_FEEDBACK_DESCRIPTION: "SET_FEEDBACK_DESCRIPTION",
  SET_FEEDBACK_TITLE: "SET_FEEDBACK_TITLE",
  CLEAR: "CLEAR_FEEDBACK",
  LOAD_FAILED: "FEEDBACK_FAILED",
};

export const NOTIFICATION = {
  LOAD: "NOTIFICATION_LOAD",
  LOAD_SUCCESS: "NOTIFICATION_LOAD_SUCCESS",
  LOAD_FAILED: "NOTIFICATION_LOAD_FAILED",
  COUNT: "NOTIFICATION_COUNT",
  CLEAR: "NOTIFICATION_CLEAR",
};

export const COMMENT = {
  LOAD: "COMMENT_LOAD",
  LOAD_SUCCESS: "COMMENT_LOAD_SUCCESS",
  LOAD_FAILED: "COMMENT_LOAD_FAILED",
  CLEAR: "COMMENT_CLEAR",
  CLEAR_COMMENT_TEXT: "CLEAR_COMMENT_TEXT",
  TOTAL_COMMENT: "TOTAL_COMMENT",
  ADD: "COMMENT_ADD",
  COMMENT_TEXT_CHANGE: "COMMENT_TEXT_CHANGE",
  NEW_COMMENT_LOAD: "NEW_COMMENT_LOAD",
};

export const CONTACT = {
  SEND_CONTACT: "SEND_CONTACT",
  SET_CONTACT_NAME: "SET_CONTACT_NAME",
  SET_CONTACT_EMAIL: "SET_CONTACT_EMAIL",
  SET_CONTACT_MESSAGE: "SET_CONTACT_MESSAGE",
  CLEAR: "CLEAR_CONTACT",
  LOAD_FAILED: "CONTACT_FAILED",
};

export const PREFERENCE = {
  SET_ACTIVE_TAB: "SET_PREFERENCE_ACTIVE_TAB",
  SET_FULL_NAME: "SET_FULL_NAME",
  SET_EMAIL: "SET_EMAIL",
  SET_COUNTRY: "SET_COUNTRY",
  SET_CITY: "SET_CITY",
  SET_PHONE: "SET_PHONE",
  SET_GENDER: "SET_GENDER",
  CLEAR: "CLEAR_PREFERENCE",
};

export const NAVIGATION = {
  SET_NAVIGATION_STEP: "SET_NAVIGATION_STEP",
};