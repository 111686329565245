import { connect } from "react-redux";
import { default as Profile } from "./profile";
import {
  openModal,
  setShareType,
  setShareLink,
  clearFilter,
  setContentAction,
  resetContentPage,
  clearContent,
  editUserInfo,
  updateProfile,
} from "../../../store";

const mapStateToProps = (state) => ({
  picUrl: state.auth.data.picUrl || "",
  user: state.auth.data,
  routeFromLightbox: state.content.routeFromLightbox,
  editUser: state.auth.editUser,
  themes: state.master.themes,
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  setContentAction: (action) => dispatch(setContentAction(action)),
  resetContentPage: () => dispatch(resetContentPage()),
  clearContent: () => dispatch(clearContent()),
  editUserInfo: (user) => dispatch(editUserInfo(user)),
  updateProfile: () => dispatch(updateProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
