import { CONTENT } from "../../actions";

const audioMutedReducer = (state = true, action) => {
  switch (action.type) {
    case CONTENT.MUTE_AUDIO:
      return true;
    case CONTENT.UNMUTE_AUDIO:
      return false;
    default:
      return state;
  }
};

export default audioMutedReducer;
