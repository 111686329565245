import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as VerificationModal } from "./verification-modal";
import { openModal, closeModal } from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.verification || false,
  isModalRoute: state.ui.isModalRoute,
  token: state.auth.token,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerificationModal)
);
