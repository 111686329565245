import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TextField, ButtonBase } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { styles } from "./search.style";
import { Cross } from "../../../icons";
import SearchIcon from "@material-ui/icons/Search";
import { ModalType } from "../../../constant";
import "./search.scss";
import { FcAutocomplete } from "../fc";
import {
  http,
  headerOptions,
  fileHeaderOptions,
  metaHeaderOptions,
} from "../../../core";
import { URL } from "../../../api/_urls";
class Search extends PureComponent {
  state = { enterClick: false, inputSearch: "" };
  handleInputChange = (value) => {
    this.setState({ inputSearch: value });

    const { loadSearch } = this.props;
    const { enterClick } = this.props;
    !enterClick && loadSearch(value || "");
    if (value.length == 0) {
      this.handleChange("");
    }
  };

  searchWithCategory = () => {
    const { history } = this.props;
    const { location } = history;
    const searchRoute = "/search";
    if (location.pathname !== searchRoute) {
      history.push(searchRoute);
    }
  };

  clearSearch = () => {
    const { clearSearch } = this.props;
    clearSearch();
    this.handleChange("");
  };

  handleChange = (contentSearch) => {
    const {
      filter,
      setFilter,
      setCustomFilter,
      history,
      clearSearchResult,
      openModal,
      loadSearchUser,
      searchDataHint,
      clearSearch,
    } = this.props;
    const { location } = history;
    const { inputSearch } = this.state;
    let uCount;
    if (searchDataHint && searchDataHint.length) {
      uCount = searchDataHint[searchDataHint.length - 1].count;
    } else {
      clearSearch();
      return;
    }

    if (contentSearch === "Users") {
      loadSearchUser(inputSearch);
      openModal(ModalType.USERSEARCH);

      return;
    }
    const searchRoute = "/search";
    //console.log(contentSearch);
    clearSearchResult();
    if (location.pathname !== searchRoute && contentSearch) {
      setCustomFilter({
        ...filter,
        contentSearch: contentSearch || "",
      });
      history.push(searchRoute);
    } else {
      setFilter({
        ...filter,
        contentSearch: contentSearch || "",
        discoverId: null,
      });
    }
  };
  filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.searchString,
  });
  handleEnterClick = () => {
    const { searchHint } = this.props;
    this.handleChange(searchHint);
  };
  render() {
    const { classes, searchResults, searchHint } = this.props;
    return (
      <Fragment>
        <div className={`search-field ${classes.search} `}>
          <div className={classes.searchIcon}>
            <SearchIcon className="search-icon" />
          </div>
          <FcAutocomplete
            val={searchHint}
            name="search"
            optionValue="searchString"
            optionLabel="searchString"
            source={searchResults}
            onChangeSelect={this.handleChange}
            onInputChange={this.handleInputChange}
            onEnter={this.handleEnterClick}
            clearSearch={this.clearSearch}
            searchWithCategory={this.searchWithCategory}
          />
          {/* <Autocomplete
            freeSolo
            defaultValue=""
            value={searchHint}
            onChange={(event, value) => this.handleChange(value)}
            onInputChange={(event, value) => this.handleInputChange(value)}
            options={searchResults}
            getOptionLabel={(option) => option.searchString || ""}
            filterOptions={this.filterOptions}
            className="searchbar"
            // onBlur="onBlurFF"
            // onFocus="onFocusFF"
            renderInput={(params) => (
              <Fragment>
                <img className="userIcon" src={} alt="" style={{ fontSize: 30 }} />
                <TextField
                  {...params}
                  fullWidth
                  onClick={this.searchWithCategory}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      this.setState({ enterClick: true });
                      this.handleChange(searchHint);
                    }
                  }}
                  className={classes.underline}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: "",
                    disableUnderline: true,
                  }}
                />
              </Fragment>
            )}
          /> */}
          {/* <Autocomplete
            freeSolo
            value={searchHint}
            onChange={(event, value) => this.handleChange(value)}
            onInputChange={(event, value) => this.handleInputChange(value)}
            options={searchResults}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                onClick={this.searchWithCategory}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    this.setState({ enterClick: true });
                    this.handleChange(searchHint);
                  }
                }}
                className={classes.underline}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "",
                  disableUnderline: true,
                }}
              />
            )}
          /> */}
          {/* {!!searchHint && (
            <ButtonBase onClick={this.clearSearch}>
              <Cross width={"16px"} height={"16px"} className="cross" />{" "}
            </ButtonBase>
          )} */}
        </div>
      </Fragment>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Search);
