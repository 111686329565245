import React, { Component, Fragment } from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import { Formik, Field } from "formik";
import { FcRadio, FcText, FcCustomRadio } from "../../presentational";
import "./profile.scss";
import { editUserInfo } from "../../../store";
import Button from "@material-ui/core/Button";
import { ContentAction, ModalType, SelectedText } from "../../../constant";
import FacebookLogin from "react-facebook-login";

import { withTranslation } from "react-i18next";
import { compose } from "redux";
import profile from ".";
const mode = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

class Profile extends Component {
  constructor(props) {
    super(props);
    const { user, editUserInfo } = this.props;
    this.state = {
      bottomSheet: false,
    };
  }
  responseFacebook = (response) => {
    console.log(response);
  };

  handleTextChange = (e) => {
    const { editUser, editUserInfo } = this.props;
    const { name, value } = e.target;
    editUserInfo({ ...editUser, [name]: value });
  };

  handleRadioButtonChange = (e) => {
    const { editUser, editUserInfo } = this.props;
    const { name, value } = e.target;
    editUserInfo({ ...editUser, [name]: this.normalizeBoolean(value) });
  };

  handleThemeChange = (e) => {
    const { editUser, editUserInfo, themes } = this.props;
    const { name, value } = e.target;
    const selectedTheme = themes.filter(
      (theme) => theme.id === parseInt(value)
    );
    editUserInfo({
      ...editUser,
      [name]: +value,
      themeInfo: !!selectedTheme.length ? selectedTheme[0] : {},
    });
  };

  normalizeBoolean = (value) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return value;
  };

  toggleBottomSheet() {
    let obj = this.state.bottomSheet
      ? { bottomSheet: false }
      : { bottomSheet: true };
    this.setState(obj);
  }

  saveAccountForm = () => {
    const { updateProfile } = this.props;
    updateProfile();
  };

  render() {
    let obj = [];
    obj.push({
      title: "modify",
      handler: () => {
        window.alert("modify clicked!");
      },
    });
    obj.push({
      title: "delete",
      handler: () => {
        window.alert("delete clicked!");
      },
    });

    const { t, error, editUser, themes, isLoading } = this.props;
    const mode = [
      { label: t("formOptions.label1"), value: true },
      { label: t("formOptions.label2"), value: false },
    ];
    return (
      <div>
        <Grid container spacing={0} className="m-0 m-b-25">
          <Grid item xs={12}>
            <label className="bold m-b-10">{t("profile.label1")} <span className="gray"> ({t("profile.span0")})</span></label>
            <TextField
              name="bio"
              value={editUser.bio}
              variant="outlined"
              onChange={this.handleTextChange}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0} className="m-b-25">
          <Grid item xs={12}>
            <label className="bold">{t("profile.label2")}</label>
          </Grid>
          <Grid item xs={12} className="profile-color">
            <FcCustomRadio
              name="themeId"
              radioClass="checkboxes"
              source={themes}
              optionValue="id"
              value={editUser.themeId}
              onChange={this.handleThemeChange}
            ></FcCustomRadio>
          </Grid>
        </Grid>
        <h2 className="font-bold mx-3">{t("profile.h2")}</h2>
        <Grid container spacing={0} className="m-b-25">
          <Grid item xs={12} sm={4}>
            <label className="bold">{t("profile.label3")}</label>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="messagesEnable"
                name="messagesEnable"
                className="radioClass"
                value={editUser.messagesEnable}
                onChange={this.handleRadioButtonChange}
              >
                {mode.map((res, index) => (
                  <FormControlLabel
                    key={`${index}`}
                    value={res["value"]}
                    label={res["label"]}
                    className={res?.color ? "profileColor " + res.color : null}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className="pref-block">
              <span>{t("profile.span1")}</span>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="m-b-25">
          <Grid item xs={12} sm={4}>
            <label className="bold">{t("profile.label4")}</label>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="showName"
                name="fullNameVisible"
                className="radioClass"
                value={editUser.fullNameVisible}
                onChange={this.handleRadioButtonChange}
              >
                {mode.map((res, index) => (
                  <FormControlLabel
                    key={`${index}`}
                    value={res["value"]}
                    label={res["label"]}
                    className={res?.color ? "profileColor " + res.color : null}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className="pref-block">
              <span>{t("profile.span2")}</span>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={0} className="m-b-25">
          <Grid item xs={12} sm={4}>
            <label className="bold">{t("profile.label5")}</label>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="showLocation"
                name="locationEnable"
                className="radioClass"
                value={editUser.locationEnable}
                onChange={this.handleRadioButtonChange}
              >
                {mode.map((res, index) => (
                  <FormControlLabel
                    key={`${index}`}
                    value={res["value"]}
                    label={res["label"]}
                    className={res?.color ? "profileColor " + res.color : null}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className="pref-block">
              <span>{t("profile.span2")}</span>
            </div>
          </Grid>
        </Grid>

        <span className="error">{error}</span>
        <Grid container>
          <Grid item xs={4}>
            <button
              className={"submitButton no-shadow"}
              onClick={this.saveAccountForm}
              disabled={isLoading}
            >
              {/* Save */}
              {!isLoading ? (
                t("profile.button0")
              ) : (
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              )}
            </button>
          </Grid>
          <Grid item xs={4}>
            {/* <FacebookLogin
              appId="229231232149599"
              autoLoad={false}
              fields="name,email,picture"
              onClick={this.componentClicked}
              callback={this.responseFacebook}
              render={(renderProps) => (
                <button
                  className={"submitButton no-shadow"}
                  onClick={renderProps.handleVerifyClick}
                >
                  Verify
                </button>
              )}
            /> */}
            {/*  <button
              className={"submitButton no-shadow"}
              
            >
              Verify
            </button> */}
          </Grid>
          {/* <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.toggleBottomSheet.bind(this)}
                >
                  Bottom Sheet
                </Button>
                <div>
                  <ReactBottomsheet
                    visible={this.state.bottomSheet}
                    onClose={this.toggleBottomSheet.bind(this)}
                    list={obj}
                  />
                </div>
              </Grid> */}
        </Grid>
      </div>
    );
  }
}
export default compose(withTranslation())(Profile);