/* eslint-disable */
import { takeEvery, put, select, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { isEmpty } from "lodash";
import {
  AUTH,
  MASTER,
  setAuthError,
  loadUserDiscoverList,
  clearFilter,
  setOtpSent,
  setAuth,
  closeModal,
  loadNotification,
  updateAuth,
  enableRestrictedMode,
  disableRestrictedMode,
  setMessage,
  setErrorMessage,
  setOtherUser,
  setAuthUserID,
  updateFollowers,
  updateFollowing,
  setFollowers,
  setFollowing,
  editUserInfo,
  setSearchUser,
  openModal,
} from "../actions";
import { ModalType, SuccessMessage, ErrorMessage } from "../../constant";
import { sendOtpInfo } from "../../core";
import {
  apiSendOtp,
  apiValidateOtp,
  apiUpdateProfile,
  apiUpdateProfileImage,
  apiRemoveProfileImage,
  apiGetUserProfile,
  apiGetOtherUserProfile,
  apiGetUserFollowers,
  apiGetUserFollowing,
  apiFollowUser,
  apiUnFollowUser,
  apiGetUsersSearch,
} from "../../api";
import { Search } from "@material-ui/icons";

const getNumber = (state) => state.auth.number;
const getOtp = (state) => state.auth.otp;
const getIsModalRoute = (state) => state.ui.isModalRoute;
const getActiveCountry = (state) => state.auth.activeCountry;
const getEditUser = (state) => state.auth.editUser;
const getToken = (state) => state.auth.token;
const getProfileImage = (state) => state.auth.profileImage;
const getUser = (state) => state.auth.data;
const getIsContentDetailOpen = (state) => state.content.isContentDetailOpen;
const getUserId = (state) => state.auth.userId;
const getSelectedUserId = (state) => state.auth.selectedUserId;
const getOtherUser = (state) => state.auth.otherUser;
const getFollowing = (state) => state.auth.following;
const getFollowers = (state) => state.auth.followers;
const getUserSearch = (state) => state.auth.searchUsers;

function* handleAuthSuccess() {
  try {
    const isContentDetailOpen = yield select(getIsContentDetailOpen);
    const user = yield select(getUser);
    user.isAdultContentHide
      ? yield put(enableRestrictedMode())
      : yield put(disableRestrictedMode());
    yield put(loadUserDiscoverList());
    yield put(loadNotification());
    if (!isContentDetailOpen) yield put(clearFilter());
  } catch (error) {
    yield put(setAuthError(error.toString()));
  }
}

function* handleSendOtp() {
  try {
    const number = yield select(getNumber);
    const activeCountry = yield select(getActiveCountry);
    if (number && !!activeCountry.countryCode) {
      const data = sendOtpInfo(number, activeCountry.countryCode);
      const response = yield call(apiSendOtp, data);
      if (response && response.success) {
        yield put(setOtpSent());
      } else {
        yield put(
          setAuthError(response ? response.message : "error in sending otp")
        );
      }
    } else {
      if (!number) yield put(setAuthError("enter mobile number"));
      if (!!!activeCountry.countryCode)
        yield put(setAuthError("select country"));
    }
  } catch (error) {
    yield put(setAuthError(error.toString()));
  }
}

function* handleValidateOtp() {
  try {
    const otp = yield select(getOtp);
    const phone = yield select(getNumber);
    const isModalRoute = yield select(getIsModalRoute);
    if (phone && otp) {
      const response = yield call(apiValidateOtp, { otp, phone });

      if (response.message == "requested otp did not match") {
        yield put(setAuthError(response.message));
      } else {
        const { data } = response;
        const {
          name,
          email,
          phone,
          gender,
          age,
          address,
          countryId,
          languageId,
          isAdultContentHide,
          city,
          bio,
          theme,
          themeInfo,
          fullName,
          fullNameVisible,
          notificationEnable,
          locationEnable,
          messagesEnable,
          isFirstTimeLogin

        } = data;
        yield put(
          editUserInfo({
            name,
            email,
            phone,
            gender,
            age,
            address,
            countryId,
            languageId,
            isAdultContentHide,
            city,
            bio,
            theme,
            themeId: !isEmpty(themeInfo) ? themeInfo.id : 0,
            themeInfo,
            fullName,
            fullNameVisible,
            notificationEnable,
            locationEnable,
            messagesEnable,
            isFirstTimeLogin
          })
        );
        yield put(setAuth(data));
        yield put(closeModal(ModalType.LOGIN));

        if (isModalRoute) yield put(push("/"));
        yield call(handleFollowersData)
        if (isFirstTimeLogin) {
          yield put(openModal(ModalType.USERSEARCH))
        }


      }
    } else {
      if (!phone) yield put(setAuthError("enter mobile number"));
      if (!otp) yield put(setAuthError("enter otp"));
    }
  } catch (error) {
    yield put(setAuthError(error.message));
  }
}

function* handleUpdateProfile() {
  try {
    const data = yield select(getEditUser);
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (data.email && !emailRegex.test(data.email)) {
      yield put(setAuthError("Invalid email"));
    } else {
      const token = yield select(getToken);
      const response = yield call(apiUpdateProfile, data, token);
      if (response.success) {
        yield put(closeModal(ModalType.EDIT_PROFILE));
        const { data } = response;
        const {
          name,
          email,
          phone,
          gender,
          age,
          address,
          countryId,
          languageId,
          isAdultContentHide,
          city,
          bio,
          theme,
          themeInfo,
          fullName,
          fullNameVisible,
          notificationEnable,
          locationEnable,
          messagesEnable,
          waterMark,
        } = data;
        yield put(
          editUserInfo({
            name,
            email,
            phone,
            gender,
            age,
            address,
            countryId,
            languageId,
            isAdultContentHide,
            city,
            bio,
            theme,
            themeId: !isEmpty(themeInfo) ? themeInfo.id : 0,
            themeInfo,
            fullName,
            fullNameVisible,
            notificationEnable,
            locationEnable,
            messagesEnable,
            waterMark,
          })
        );
        yield put(updateAuth(data));
        yield put(
          setMessage(
            response.message ? response.message : SuccessMessage.PROFILE_UPDATED
          )
        );
      } else {
        yield put(setAuthError(response.message));
        yield put(
          setErrorMessage(
            response.message
              ? response.message
              : ErrorMessage.API_RESPONSE_ERROR
          )
        );
      }
    }
  } catch (error) {
    yield put(setAuthError(error.toString()));
    yield put(setErrorMessage(ErrorMessage.API_RESPONSE_ERROR));
  }
}

function* handleProfileImageChange() {
  try {
    const token = yield select(getToken);
    const profileImage = yield select(getProfileImage);
    let form = new FormData();
    form.append("image", profileImage);
    const response = yield call(apiUpdateProfileImage, form, token);
    if (response.success) {
      yield put(setMessage(SuccessMessage.PROFILE_IMAGE_UPDATED));
      const { data } = response;
      const {
        name,
        email,
        phone,
        gender,
        age,
        address,
        countryId,
        languageId,
        isAdultContentHide,
        city,
        bio,
        theme,
        themeInfo,
        fullName,
        fullNameVisible,
        notificationEnable,
        locationEnable,
        messagesEnable,
        waterMark,
      } = data;
      yield put(
        editUserInfo({
          name,
          email,
          phone,
          gender,
          age,
          address,
          countryId,
          languageId,
          isAdultContentHide,
          city,
          bio,
          theme,
          themeId: !isEmpty(themeInfo) ? themeInfo.id : 1,
          themeInfo,
          fullName,
          fullNameVisible,
          notificationEnable,
          locationEnable,
          messagesEnable,
          waterMark
        })
      );
      yield put(updateAuth(data));
    } else {
      yield put(setAuthError(response.message));
      yield put(
        setErrorMessage(
          response.message ? response.message : ErrorMessage.API_RESPONSE_ERROR
        )
      );
    }
  } catch (error) {
    yield put(setAuthError(error.toString()));
    yield put(setErrorMessage(ErrorMessage.API_RESPONSE_ERROR));
  }
}

function* handleRemoveProfile() {
  try {
    const token = yield select(getToken);
    const user = yield select(getUser);
    const response = yield call(apiRemoveProfileImage, token);
    if (response.success) {
      yield put(updateAuth({ ...user, picUrl: "" }));
      yield put(setMessage(SuccessMessage.REMOVE_PROFILE_IMAGE));
    }
  } catch (error) {
    yield put(setAuthError(error.toString()));
    yield put(setErrorMessage(ErrorMessage.API_RESPONSE_ERROR));
  }
}

function* handleRefreshProfile() {
  try {
    const token = yield select(getToken);
    const userId = yield select(getUserId);
    const response = yield call(apiGetUserProfile, token);

    if (response.success) {
      const { data } = response;
      const {
        name,
        email,
        phone,
        gender,
        age,
        address,
        countryId,
        languageId,
        isAdultContentHide,
        city,
        bio,
        theme,
        themeInfo,
        fullName,
        fullNameVisible,
        notificationEnable,
        locationEnable,
        messagesEnable,
        waterMark,
        isFirstTimeLogin
      } = data;
      yield put(
        editUserInfo({
          name,
          email,
          phone,
          gender,
          age,
          address,
          countryId,
          languageId,
          isAdultContentHide,
          city,
          bio,
          theme,
          themeId: !isEmpty(themeInfo) ? themeInfo.id : 1,
          themeInfo,
          fullName,
          fullNameVisible,
          notificationEnable,
          locationEnable,
          messagesEnable,
          waterMark,
          isFirstTimeLogin
        })
      );
      yield put(updateAuth(data));
    }
  } catch (error) {
    yield put(setAuthError(error.toString()));
    yield put(setErrorMessage(ErrorMessage.API_RESPONSE_ERROR));
  }
}

function* handleOtherUserProfile() {
  try {
    const token = yield select(getToken);
    const userId = yield select(getUserId);
    const response = yield call(
      apiGetOtherUserProfile,
      { userId: userId },
      token
    );

    if (response.success) {
      if (userId) {
        yield put(setOtherUser(response.data));
      }
    }
  } catch (error) {
    yield put(setAuthError(error.toString()));
    yield put(setErrorMessage(ErrorMessage.API_RESPONSE_ERROR));
  }
}
function* handleFollowersData() {
  try {
    const token = yield select(getToken);
    let userId;
    const user = yield select(getUser);
    const otherUserId = yield select(getUserId);
    if (otherUserId) {
      userId = otherUserId;
    } else {
      userId = user.id;
    }
    console.log({ userId });
    const response = yield call(apiGetUserFollowers, { userId }, token);
    yield put(setFollowers(response.data));
  } catch (error) {
    yield put(setAuthError(error.toString()));
  }
}
function* handleFollowingData() {
  try {
    const token = yield select(getToken);
    let userId;
    const user = yield select(getUser);
    const otherUserId = yield select(getUserId);
    if (otherUserId) {
      userId = otherUserId;
    } else {
      userId = user.id;
    }
    const response = yield call(apiGetUserFollowing, { userId }, token);
    yield put(setFollowing(response.data));
  } catch (error) {
    yield put(setAuthError(error.toString()));
  }
}
function* handleUserSearchData() {
  try {
    const token = yield select(getToken);
    const searchU = yield select(getUserSearch);
    let searchUserQuery = searchU;
    const response = yield call(apiGetUsersSearch, searchUserQuery, token);
    // console.log(response);
    yield put(setSearchUser(response.data));
  } catch (error) {
    yield put(setAuthError(error.toString()));
  }
}

function* handleFollowUser() {
  const otherUser = yield select(getOtherUser);
  try {
    const userId = yield select(getSelectedUserId);
    const token = yield select(getToken);
    console.log(token, userId)
    if (otherUser.id === +userId)
      yield put(setOtherUser({ ...otherUser, isFollowingLoggedUser: true }));
    else {
      const followers = yield select(getFollowers);
      yield put(
        setFollowers(
          followers.map((user) =>
            user.id === userId ? { ...user, isFollowingLoggedUser: true } : user
          )
        )
      );
    }
    const following = yield select(getFollowing);
    const response = yield call(apiFollowUser, userId, token);
    if (response.success) {
      const newUserFollowed = {
        id: response.data.id,
        name: response.data.fullName,
        picUrl: response.data.picUrl,
        isFollowingLoggedUser: true,
      };
      const findFollowedUserIndex = following.findIndex((follow) => {
        console.log({ follow, newUserFollowed });
        return follow.id === newUserFollowed.id;
      });
      if (findFollowedUserIndex > -1) {
        following[findFollowedUserIndex] = {
          ...following[findFollowedUserIndex],
          isFollowingLoggedUser: true,
        };
        yield put(setFollowing([...following]));
      } else {
        yield put(setFollowing([...following, newUserFollowed]));
      }
    }
  } catch (error) {
    if (otherUser.id === +userId)
      yield put(setOtherUser({ ...otherUser, isFollowingLoggedUser: false }));
    else {
      const followers = yield select(getFollowers);
      yield put(
        setFollowers(
          followers.map((user) =>
            user.id === userId
              ? { ...user, isFollowingLoggedUser: false }
              : user
          )
        )
      );
    }
    yield put(setAuthError(error.toString()));
  }
}
function* handleUnFollowUser() {
  const otherUser = yield select(getOtherUser);
  try {
    const token = yield select(getToken);
    const userId = yield select(getSelectedUserId);
    //console.log({ userId });
    if (otherUser.id === +userId)
      yield put(setOtherUser({ ...otherUser, isFollowingLoggedUser: false }));
    else {
      const followers = yield select(getFollowers);
      //console.log("unfollow", followers);
      yield put(
        setFollowers(
          followers.map((user) =>
            user.id === userId
              ? { ...user, isFollowingLoggedUser: false }
              : user
          )
        )
      );
    }
    const following = yield select(getFollowing);
    //console.log({ following });
    const response = yield call(apiUnFollowUser, userId, token);
    yield put(
      setFollowing(
        following.map((user) =>
          user.id === userId ? { ...user, isFollowingLoggedUser: false } : user
        )
      )
    );
  } catch (error) {
    if (otherUser.id === +userId)
      yield put(setOtherUser({ ...otherUser, isFollowingLoggedUser: true }));
    else {
      const followers = yield select(getFollowers);

      yield put(
        setFollowers(
          followers.map((user) =>
            user.id === userId ? { ...user, isFollowingLoggedUser: true } : user
          )
        )
      );
    }
    yield put(setAuthError(error.toString()));
  }
}

export function* watchAuthSuccess() {
  yield takeEvery(AUTH.LOAD_SUCCESS, handleAuthSuccess);
}

export function* watchRefreshProfile() {
  yield takeEvery(AUTH.LOAD_USER_PROFILE, handleRefreshProfile);
}
export function* watchOtherUserProfile() {
  yield takeEvery(AUTH.LOAD_OTHER_USER_PROFILE, handleOtherUserProfile);
}

export function* watchSendOtp() {
  yield takeEvery(AUTH.SEND_OTP, handleSendOtp);
}

export function* watchValidateOtp() {
  yield takeEvery(AUTH.VALIDATE_OTP, handleValidateOtp);
}

export function* watchUpdateProfile() {
  yield takeEvery(AUTH.UPDATE_PROFILE, handleUpdateProfile);
}

export function* watchChangeProfileImage() {
  yield takeEvery(AUTH.CHANGE_PROFILE_IMAGE, handleProfileImageChange);
}

export function* watchRemoveProfile() {
  yield takeEvery(AUTH.REMOVE_PROFILE_IMAGE, handleRemoveProfile);
}

export function* watchFollowersLoad() {
  yield takeEvery(AUTH.LOAD_FOLLOWERS, handleFollowersData);
}
export function* watchFollowingLoad() {
  yield takeEvery(AUTH.LOAD_FOLLOWING, handleFollowingData);
}
export function* watchUserSearchLoad() {
  yield takeEvery(AUTH.SEARCH_USER, handleUserSearchData);
}
export function* watchFollowUser() {
  yield takeEvery(AUTH.FOLLOW_USER, handleFollowUser);
}
export function* watchUnFollowUser() {
  yield takeEvery(AUTH.UnFOLLOW_USER, handleUnFollowUser);
}
