import React, { PureComponent } from "react";
import { Dialog, DialogTitle, Slide, ButtonBase, Popper, ClickAwayListener } from "@material-ui/core";
import { ModalType } from "../../../../constant";
import "./report-user-modal-detail.scss";
import { HorizontalSplitOutlined, HorizontalSplit } from "@material-ui/icons";
import { Report, ReportPost } from "../../../../icons";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { isMobileOnly } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ReportUserModalDetail extends PureComponent {
  state = {
    formValid: false,
    openReason: false,
    openOtherReason: false,
    reasonVal: "",
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { openReason, openOtherReason } = this.props;
    this.setState({ openReason: openReason || false, openOtherReason: openOtherReason || false });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.setState({ openReason: true, openOtherReason: false });
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    setTimeout(() => {
      this.setState({ formValid: this.validateForm() });
    }, 100);
  };

  validateForm = () => {
    if (this.props.setReportContentId === "") {
      return false;
    } else {
      return true;
    }
  };

  //   handleClose = () => {
  //     const { closeModal, startScrollTimer, isContentDetailOpen, contentAlreadyVisited, commentBox } = this.props;
  //     isContentDetailOpen && !commentBox && !contentAlreadyVisited && startScrollTimer();
  //     closeModal(ModalType.REPORT_CONTENT);
  //   };

  changeReportReason = (reportId, reasonVal) => {
    const { setReportContentId, setReportContentText } = this.props;
    setReportContentId(reportId);
    setReportContentText(reasonVal);
    this.setState({ reasonVal });
    this.reportContent();
    this.handleOnClose();
    // setTimeout(() => {
    //   this.setState({ formValid: this.validateForm() });
    // }, 100);
  };

  reportContent = () => {
    const { reportContent } = this.props;
    reportContent();
  };

  handleReasonChange = (e) => {
    const { setReportContentText } = this.props;
    this.setState({ reasonVal: e.target.value });
    setReportContentText(e.target.value);
  };

  handleReportReason = () => {
    this.setState({ openReason: true });
  };
  handleOtherReason = () => {
    this.setState({ openReason: false, openOtherReason: true });
  };
  submitOtherReason = () => {
    const { reportContent, setReportContentId } = this.props;
    setReportContentId(-1);
    this.setState({ openOtherReason: false });
    this.handleOnClose();
    reportContent();
  };
  handleOnClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.REPORT_USER_DETAIL);
  };

  render() {
    const { modal, reportReasons, error, anchorEl, open,t } = this.props;
    return (
      <Popper
        style={{ zIndex: 1, background: "#FFFFFF", borderRadius: "4px" }}
        open={modal}
        anchorEl={anchorEl}
        placement={"top-end"}
        className="dialog-modal report-content-modal"
        //TransitionComponent={Transition}
        aria-labelledby="report-content-title"
        aria-describedby="report-content-description"
        disableEnforceFocus
      >
        {/* <DialogTitle id="report-content-title">{"Report Profile"}</DialogTitle> */}
        <ClickAwayListener onClickAway={this.handleOnClose}>
          <div className="modal-body account-report-modal">
            {!this.state.openReason && !this.state.openOtherReason && (
              <label onClick={this.handleReportReason} className="report-red">
                {/* <ReportProblemIcon /> */}
                <ReportPost width={"17"} height={"19"} className="report" />

                {t("userInfo.label5")}
              </label>
            )}
            {this.state.openReason && (
              <div>
                <ul>
                  <li>
                    <label> {t("report.label1")}</label>
                  </li>
                  <li>
                    <hr />
                  </li>
                  <li>
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() => this.changeReportReason(-1, "Nudity or illegal substances")}
                    >
                      {" "}
                      {t("report.label2")}
                    </label>
                  </li>
                  <li>
                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() => this.changeReportReason(-1, "Threat or voilence")}
                    >
                      {" "}
                      {t("report.label3")}
                    </label>
                  </li>
                  <li>
                    <label style={{ cursor: "pointer" }} onClick={this.handleOtherReason}>
                      {" "}
                      {t("report.label4")}
                    </label>
                  </li>
                </ul>
              </div>
            )}
            {this.state.openOtherReason && (
              <form onSubmit={this.submitOtherReason}>
                <label className="f-1-2"> {t("editProfile.title")}</label>
                <input name="otherReason" onChange={(e) => this.handleReasonChange(e)}></input>
                {!isMobileOnly ? (
                  <button type="submit" hidden></button>
                ) : (
                    <button className={"submitButton small"} type="submit">
                      {t("report.label7")}
                    </button>
                  )}
              </form>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
    );
  }
}
export default compose(withTranslation())(ReportUserModalDetail)