import { UPLOAD } from "../../actions";
import { indexOf, remove } from "lodash";

const webLinkGenreReducer = (state = [], action) => {
  switch (action.type) {
    case UPLOAD.CLEAR:
    case UPLOAD.CLEAR_CONTENT:
      return [];
    case UPLOAD.SET_LINK_UPLOAD_GENRE: {
      return action.linkUploadGenre;
    }
    default:
      return state;
  }
};

export default webLinkGenreReducer;
