import React, { PureComponent } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Switch,
  ButtonBase,
  Select,
  MenuItem,
  withStyles,
  InputBase,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { ModalAction, ModalType } from "../../constant";
import Grid from "@material-ui/core/Grid";
import Flag from "react-world-flags";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { ProfileImage } from "../../components";

import "./edit-profile.scss";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    //borderRadius: 4,
    position: "relative",
    //backgroundColor: theme.palette.background.paper,
    //border: "1px solid #ced4da",
    //fontSize: 16,
    padding: "10px 26px 10px 12px",
    //transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      //borderRadius: 4,
      //borderColor: "#80bdff",
      //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
class EditProfile extends PureComponent {
  handleBackClick = () => {
    const { history } = this.props;
    !!history.length ? history.goBack() : history.push("/");
  };
  constructor(props) {
    super(props);
    this.state = {
      formValid: true,
    };
    const { setModalAction } = this.props;
    setModalAction(ModalAction.EDIT_PROFILE);
  }
  handleVerifyClick = () => {
    const { openModal } = this.props;
    openModal(ModalType.VERIFICATION);
  };
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.EDIT_PROFILE);
  };

  handleOnChange = (e) => {
    const { user, editUserInfo } = this.props;
    editUserInfo({ ...user, [e.target.name]: e.target.value });
    setTimeout(() => {
      this.setState({ formValid: this.validateForm() });
    }, 100);
  };

  handlePrivacyChange = (e) => {
    const { user, editUserInfo } = this.props;
    editUserInfo({ ...user, [e.target.name]: e.target.checked });
  };
  navigate = (url) => {
    const { history } = this.props;
    history.push(url);
  };
  validateForm = () => {
    const { user } = this.props;
    if (
      user.name === "" ||
      user.phone === "" ||
      user.countryId === "" ||
      user.languageId === ""
    ) {
      return false;
    } else {
      return true;
    }
    //return Object.values(user).every(x => (x === null && x === ''));
  };

  handleSubmit = () => {
    const { updateProfile } = this.props;
    updateProfile();
  };

  handleMoreOptions = () => {
    const { openModal } = this.props;
    openModal(ModalType.PROFILE_IMAGE_OPTION);
  };

  handleThemeModal = () => {
    const { openModal } = this.props;
    openModal(ModalType.USER_THEME);
  };

  viewBio = () => {
    const { openModal } = this.props;
    openModal(ModalType.BIO);
  };

  render() {
    const {
      user,
      languages,
      countries,
      error,
      isLoading,
      openModal,
      t,
    } = this.props;
    const selectedCountry = countries.filter(
      (country) => country.id === parseInt(user.countryId)
    );
    const countryCode = selectedCountry.length
      ? selectedCountry[0].diallingCode
      : "";
    let currentCountry;
    currentCountry = countries.find(
      (c) => String(c.id) == String(user.countryId)
    );

    return (
      <div className="editProfilePage">
        <div
          className="sub-header text-left tab-hidden"
          style={{
            backgroundColor: !isEmpty(user.themeInfo)
              ? user.themeInfo.backgroundHex
              : "#4f4bce",
          }}
        >
          <button
            className="back-btn btn2"
            onClick={this.handleBackClick}
          ></button>
          {/* <h1>Edit Profile</h1> */}
        </div>

        <div className="settingBody">
          <div
            className="bg-box"
            style={{
              backgroundColor: !isEmpty(user.themeInfo)
                ? user.themeInfo.backgroundHex
                : "#4f4bce",
            }}
          ></div>
          <div className="account-settings">
            <Grid container spacing={0} className="m-0">
              <Grid item xs={12} className="editprofileimage m-0">
                <ProfileImage />
                <div className="bg-camera"></div>
                <CameraAltIcon
                  className="camera"
                  onClick={this.handleMoreOptions}
                />
                <div className="change-profile">
                  <ButtonBase onClick={() => this.navigate("/verify")}>
                    {t("editProfile.label1")}
                  </ButtonBase>
                </div>
              </Grid>
            </Grid>
            <h3>{t("preference.button1")}</h3>
            <List>
              <ListItem>
                <ListItemText primary={t("accountForm.label1")} className="nonactive" />
                <input
                  type="text"
                  placeholder={t("accountForm.label1")}
                  maxLength={40}
                  name="name"
                  className="inputform"
                  value={user.name}
                  onChange={this.handleOnChange}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("editProfile.label2")} className="nonactive" />
                {/* <p>(+{countryCode})</p> */}
                <input
                  type="text"
                  name="phone"
                  className="inputform"
                  readOnly
                  defaultValue={user.phone}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("accountForm.label2")} className="nonactive" />
                <input
                  type="text"
                  placeholder={t("editProfile.label3")}
                  maxLength={40}
                  name="email"
                  className="inputform"
                  value={user.email}
                  onChange={this.handleOnChange}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("uploadSection.label2")} className="nonactive" />
                <select
                  name="languageId"
                  value={user.languageId}
                  onChange={this.handleOnChange}
                  dir="rtl"
                >
                  <option value="">{t("editProfile.label4")}</option>
                  {languages.map((language, index) => (
                    <option key={language.id || index} value={language.id}>
                      {language.nameUtf8}
                    </option>
                  ))}
                </select>
              </ListItem>
              <ListItem>
                <ListItemText primary={t("accountForm.label4")} className="nonactive" />
                <div className="countryselector">
                  <div className="flag">
                    <Flag code={currentCountry?.isoCode} height="24" />
                  </div>
                  <span onClick={() => openModal(ModalType.COUNTRY)}>
                    {currentCountry?.name}
                  </span>
                </div>
                {/* <select name="countryId" value={user.countryId} onChange={this.handleOnChange} dir="rtl">
                  <option value="">Select Country</option>
                  {countries.map((country, index) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select> */}
                {/* <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={user.countryId}
                  name="countryId"
                  onChange={(e) => this.handleOnChange(e)}
                  input={<BootstrapInput />}
                >
                  <MenuItem value="">Select Country</MenuItem>
                  {countries.map((country, index) => (
                    <MenuItem key={country.id} value={country.id}>
                      <Flag code={country.isoCode} height="24" /> {country.name}
                    </MenuItem>
                  ))}
                </Select> */}
              </ListItem>
              <ListItem>
                <ListItemText primary={t("accountForm.label3")} className="nonactive" />
                <input
                  type="text"
                  placeholder={t("editProfile.label5")}
                  maxLength={40}
                  name="city"
                  className="inputform"
                  value={user.city}
                  onChange={this.handleOnChange}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("accountForm.label6")} className="nonactive" />
                <select
                  name="gender"
                  value={user.gender}
                  onChange={this.handleOnChange}
                  dir="rtl"
                >
                  <option value="MALE">{t("accountForm.option1")}</option>
                  <option value="FEMALE">{t("accountForm.option2")}</option>
                  <option value="OTHERS">{t("accountForm.option3")}</option>
                </select>
              </ListItem>
              <ListItem>
                <ListItemText primary={t("profile.label1")} className="nonactive" />
                <span className="inputform bluetext" onClick={this.viewBio}>
                {t("editProfile.label6")}
                </span>
                {/* <input
                  type="text"
                  placeholder="Enter Bio"
                  maxLength={40}
                  name="bio"
                  className="inputform"
                  value={user.bio}
                  onChange={this.handleOnChange}
                /> */}
              </ListItem>
              <ListItem>
                <ListItemText primary={t("editProfile.label7")} className="nonactive" />
                {/* <Button color="primary" onClick={this.handleThemeModal} className="themebtn">
                  Primary
                </Button> */}
                {!isEmpty(user.themeInfo) ? (
                  <div
                    className="themebtn"
                    style={{ backgroundColor: user.themeInfo.backgroundHex }}
                    onClick={this.handleThemeModal}
                  >
                    <div
                      className="inner-color"
                      style={{ backgroundColor: user.themeInfo.textColorHex }}
                    ></div>
                  </div>
                ) : (
                  <div onClick={this.handleThemeModal} className="themeselect">
                    {t("editProfile.label8")}
                  </div>
                )}
              </ListItem>
              <ListItem>
                <ListItemText primary={t("accountForm.label7")} className="nonactive" />
                <input
                  type="text"
                  placeholder={t("editProfile.label5")}
                  maxLength={40}
                  name="waterMark"
                  className="inputform"
                  value={user.waterMark}
                  onChange={this.handleOnChange}
                />
              </ListItem>
            </List>
            <div className="link-settings">
              <h3>{t("profile.h2")}</h3>
              <List>
                <ListItem>
                  <ListItemText primary={t("profile.label4")} className="" />
                  <Switch
                    checked={user.fullNameVisible}
                    onChange={this.handlePrivacyChange}
                    name="fullNameVisible"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t("profile.label5")} className="" />
                  <Switch
                    checked={user.locationEnable}
                    onChange={this.handlePrivacyChange}
                    name="locationEnable"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t("profile.label3")} className="" />
                  <Switch
                    checked={user.messagesEnable}
                    onChange={this.handlePrivacyChange}
                    name="messagesEnable"
                  />
                </ListItem>
              </List>
            </div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <button
                  className={"submitButton no-shadow"}
                  type="submit"
                  disabled={isLoading}
                  onClick={this.handleSubmit}
                >
                  {!isLoading ? (
                    t("accountForm.button1")
                  ) : (
                    <div className="spinner">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </div>
                  )}
                </button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withTranslation())(EditProfile);