import React from "react";
import "./search.scss";

const Search = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 40 40"
    style={{ enableBackground: "new 0 0 40 40" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Navbar-home" transform="translate(-569.000000, -76.000000)" fill="#444444" fill-rule="nonzero">
        <g id="Group-2" transform="translate(0.000000, 6.000000)">
          <g id="search" transform="translate(569.000000, 70.000000)">
            <path
              d="M39.804,38.8626667 L26.6186667,25.6773333 C29.124,22.9466667 30.6666667,19.3213333 30.6666667,15.3333333 C30.6666667,6.87866667 23.788,0 15.3333333,0 C6.87866667,0 0,6.87866667 0,15.3333333 C0,23.788 6.87866667,30.6666667 15.3333333,30.6666667 C19.3213333,30.6666667 22.9453333,29.124 25.676,26.62 L38.8613333,39.8053333 C38.992,39.9346667 39.1626667,40 39.3333333,40 C39.504,40 39.6746667,39.9346667 39.804,39.8053333 C40.064,39.5453333 40.064,39.1226667 39.804,38.8626667 Z M15.3333333,29.3333333 C7.61333333,29.3333333 1.33333333,23.0533333 1.33333333,15.3333333 C1.33333333,7.61333333 7.61333333,1.33333333 15.3333333,1.33333333 C23.0533333,1.33333333 29.3333333,7.61333333 29.3333333,15.3333333 C29.3333333,23.0533333 23.0533333,29.3333333 15.3333333,29.3333333 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const SearchSelected = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 44 44"
    style={{ enableBackground: "new 0 0 44 44" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Navbar-discover" transform="translate(-568.000000, -69.000000)" fill="#444444" fill-rule="nonzero">
        <g id="ic-search-48px" transform="translate(568.000000, 69.000000)">
          <path
            d="M31.4465409,27.672956 L29.4465409,27.672956 L28.754717,26.9811321 C31.2201258,24.1257862 32.7044025,20.4150943 32.7044025,16.3522013 C32.7044025,7.32075472 25.3836478,0 16.3522013,0 C7.32075472,0 0,7.32075472 0,16.3522013 C0,25.3836478 7.32075472,32.7044025 16.3522013,32.7044025 C20.4150943,32.7044025 24.1257862,31.2201258 26.9811321,28.7672956 L27.672956,29.4591195 L27.672956,31.4465409 L40.2515723,44 L44,40.2515723 L31.4465409,27.672956 Z M16.3522013,27.672956 C10.1006289,27.672956 5.03144654,22.6037736 5.03144654,16.3522013 C5.03144654,10.1006289 10.1006289,5.03144654 16.3522013,5.03144654 C22.6037736,5.03144654 27.672956,10.1006289 27.672956,16.3522013 C27.672956,22.6037736 22.6037736,27.672956 16.3522013,27.672956 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export { Search, SearchSelected };
