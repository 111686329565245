import React, { PureComponent, Fragment } from "react";
import clsx from "clsx";
import { snakeCase } from "lodash";
import { isMobileOnly } from "react-device-detect";
import { Header, MobileNavigation, MobileHeader } from "../../../components";
import { ModalType, ContentTypeId, UploadAlert } from "../../../constant";
import { isServer } from "../../../store";
import { setGlobalThemeVariable } from "../../../core";
import Modal from "../../modal";
import RootRouter from "../Root-Router";
import "../index.css";
import "../modal.scss";
import "./layout.scss";
import LoadingBar from "react-top-loading-bar";
import Headroom from "react-headroom";
import firebase from "firebase";
const config = {
  apiKey: "AIzaSyAsvvbXCNt1rRX-UwkW4zmhG9EJFtkSH2Q",
  authDomain: "tymoff-original.firebaseapp.com",
  databaseURL: "https://tymoff-original.firebaseio.com",
  projectId: "tymoff-original",
  storageBucket: "tymoff-original.appspot.com",
  messagingSenderId: "418600127287",
  appId: "1:418600127287:web:4556c286237cf42c0a68b2",
  measurementId: "G-Z7F7BSGR3X",
};

class Layout extends PureComponent {
  state = {
    hide: false,
  };
  constructor(props) {
    super(props);
    const { history } = props;
    this.historyBlock = history.block((nextState, action) =>
      this.routerWillLeave(nextState, action, this.props)
    );
    try {
      firebase.initializeApp(config);
    } catch (err) { }
  }
  // componentDidMount() {
  //   let scrollPos = 0;
  //   // adding scroll event
  //   window.addEventListener("scroll", () => {
  //     // detects new state and compares it with the new one
  //     if (document.body.getBoundingClientRect().top > scrollPos) {
  //       this.setState({ hide: false });
  //       console.log("here");
  //     } else {
  //       this.setState({ hide: false });
  //       console.log("here2");
  //     }
  //     scrollPos = document.body.getBoundingClientRect().top;
  //   });
  // }
  componentDidMount() {
    let lng = localStorage.getItem("lang")
    if (lng === null) {
      localStorage.setItem("lang", "en")
    }
  }
  componentDidUpdate() {
    const { history } = this.props;
    history.block((nextState, action) =>
      this.routerWillLeave(nextState, action, this.props)
    );
  }
  routerWillLeave(nextState, action, props) {
    const {
      closeModal,
      isOtherModalOpen,
      contentModal,
      loginModal,
      commentBox,
      activeContent,
      hideCommentBox,
      moreOptionsModal,
      shareModal,
      copyLinkModal,
      reportContentModal,
      contentClose,
      editProfileModal,
      profileImageOptionModal,
      showMoreDetail,
      darkTheme,
      unsavedUploadChange,
      setAlertAction,
    } = props;
    setGlobalThemeVariable(!darkTheme);
    if (action === "POP") {
      if (unsavedUploadChange) {
        setAlertAction(UploadAlert);
        window.history.pushState("upload", "upload content", `/upload`);
        return false;
      } else if (contentModal) {
        if (!isOtherModalOpen && !commentBox) {
          document.body.style.position = "";
          contentClose();
          closeModal(ModalType.CONTENT_DETAIL);
        } else {
          if (isOtherModalOpen || commentBox) {
            const { contentTitle, contentUrl, typeId, id } = activeContent;
            let title;
            if (
              typeId === ContentTypeId.Images ||
              typeId === ContentTypeId.Videos
            ) {
              title = contentTitle || contentUrl[0].contentOcr;
            } else {
              title = contentTitle;
            }
            const encryptedUrl = btoa(id);
            const contentPath = contentUrl[0].contentOcr
              ? `/${snakeCase(
                contentUrl[0].contentOcr
              )}/content/${encryptedUrl.replace(/=/g, "")}`
              : `/content/${encryptedUrl.replace(/=/g, "")}`;
            window.history.pushState("content detail", title, contentPath);
          }
          loginModal && closeModal(ModalType.LOGIN);
          commentBox && hideCommentBox();
          moreOptionsModal && closeModal(ModalType.MORE_OPTION);
          shareModal && closeModal(ModalType.SHARE);
          copyLinkModal && closeModal(ModalType.COPY_LINK);
          reportContentModal && closeModal(ModalType.REPORT_CONTENT);
          showMoreDetail && closeModal(ModalType.SHOW_MORE_DETAIL);
          return false;
        }
      } else {
        contentClose();
        profileImageOptionModal && closeModal(ModalType.PROFILE_IMAGE_OPTION);
        shareModal && closeModal(ModalType.SHARE);
        if (editProfileModal) {
          closeModal(ModalType.EDIT_PROFILE);
          return false;
        } else return true;
      }
    } else if (action === "PUSH") {
      if (unsavedUploadChange) {
        setAlertAction(UploadAlert);
        window.history.pushState("upload", "upload content", `/upload`);
        return false;
      } else {
        document.body.style.position = "";
        const { navlinkChange } = props;
        navlinkChange();
        return true;
      }
    }
  }
  componentWillUnmount() {
    this.historyBlock();
  }
  render() {
    const { sidebar, progress, history } = this.props;
    const showSearchOnly = !!(
      isMobileOnly && history.location.pathname === "/search"
    );
    let path;
    if (history.location.pathname.match(/account/g)) {
      path = history.location.pathname.match(/account/g)[0];
    } else {
      path = history.location.pathname;
    }
    return (
      <Fragment>
        {!isServer && !isMobileOnly && <Header />}
        <Headroom
          onPin={() => this.setState({ hide: false })}
          onUnpin={() => this.setState({ hide: true })}
          disableInlineStyles
        >
          {!isServer && isMobileOnly && path !== "account" && <MobileHeader />}
        </Headroom>
        {!isServer && <Modal />}
        <div
          style={
            showSearchOnly
              ? // ? { top: "110px" }
              { top: "110px" }
              : path == "account" && isMobileOnly
                ? { top: "-22px" }
                : null
          }
          className={clsx("container", {
            drawerOpen: sidebar,
            drawerClose: !sidebar,
          })}
        >
          <RootRouter />
        </div>

        {isMobileOnly &&
          !this.state.hide &&
          (history.location.pathname == "/" ||
            history.location.pathname == "/search") ? (
          <MobileNavigation />
        ) : null}
      </Fragment>
    );
  }
}

export default Layout;
