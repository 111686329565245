import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as Comment } from "./comment";
import { hideCommentBox, addComment, setCommentText, openModal } from "../../../store";

const mapStateToProps = state => ({
  totalComment: state.comment.totalComment,
  comments: state.comment.data,
  user: state.auth.data,
  error: state.content.error,
  commentText: state.comment.commentText,
  isLoggedIn: state.auth.isLoggedIn,
  loadFromSession: state.content.loadFromSession
});

const mapDispatchToProps = dispatch => ({
  hideCommentBox: () => dispatch(hideCommentBox()),
  addComment: () => dispatch(addComment()),
  setCommentText: commentText => dispatch(setCommentText(commentText)),
  openModal: (modal) => dispatch(openModal(modal)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Comment));
