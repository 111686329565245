import React, { PureComponent } from "react";
import { Modal, Backdrop, Fade, Popper, ClickAwayListener } from "@material-ui/core";
import { ModalType } from "../../../../constant";
import { Notifications } from "../../../containers";
import "./notification-modal.scss";
import { isMobileOnly } from "react-device-detect";

class NotificationModal extends PureComponent {
  handleClose = () => {
    const { closeModal } = this.props;
    if (!isMobileOnly) {
      closeModal(ModalType.NOTIFICATION);
    }
  };

  render() {
    const { modal, anchorEl, open } = this.props;
    return (
      <Popper
        //aria-labelledby="transition-modal-title"
        //aria-describedby="transition-modal-description"
        className="modal modal-backdrop"
        style={{ zIndex: 1200, transform: "none", position: "fixed", right: 0, left: "initial", top: 54 }}
        open={modal || false}
        //onClose={this.handleClose}
        //closeAfterTransition
        // anchorEl={anchorEl}
        // placement={"bottom-start"}
        // transition
        //BackdropComponent={Backdrop}
        //BackdropProps={{
        //timeout: 500,
        //}}
      >
        <ClickAwayListener onClickAway={this.handleClose}>
          <Fade in={modal}>
            <div className="paper notification-modal">
              {/* <div className="header-notification">
              <div className="notifi-header">
                <span>Notifications</span>
              </div>
            </div> */}
              <Notifications />
            </div>
          </Fade>
        </ClickAwayListener>
      </Popper>
    );
  }
}

export default NotificationModal;
