import React, { PureComponent } from "react";
import { Dialog, Slide, ButtonBase } from "@material-ui/core";
import { ModalType, RemoveImageAlert } from "../../../../constant";
import "./profile-image-option-modal.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import removePhoto from "../../../../assets/images/remove-photo.svg";
import camera from "../../../../assets/images/camera.svg";
import gallery from "../../../../assets/images/gallery.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ProfileImageOptionModal extends PureComponent {
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.PROFILE_IMAGE_OPTION);
  };

  handleUploadProfileImage = (event) => {
    this.handleClose();
    const file = event.target.files[0];
    const { updateProfileImage } = this.props;
    updateProfileImage(file);
  };
 handleAvatarModal = () => {
    const { openModal } = this.props;
    openModal(ModalType.EDIT_AVATAR);
    this.handleClose();
  };
  handleRemoveImage = () => {
    this.handleClose();
    const { setAlertAction } = this.props;
    setAlertAction(RemoveImageAlert);
  };

  handleTakeImage = () => {
    const { openModal } = this.props;
    openModal(ModalType.CAMERA);
    this.handleClose();
  };

  render() {
    const { modal, picUrl, t } = this.props;
    return (
      <Dialog
        open={modal}
        className="bottom-profile-image-option-modal"
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="profile-image-option-title"
        aria-describedby="profile-image-option-description"
      >
        <p className="image-modal-title">{t("user.title1")}</p>
        <div className="edit-icon-container">
          <div className="img-option">
            <ButtonBase onClick={this.handleTakeImage}>
              <div title="Change Profile Photo">
                <p>{t("user.title2")}</p>
              </div>
            </ButtonBase>
            
          </div>
          <div className="img-option">
          <ButtonBase onClick={this.handleAvatarModal}>
              <div title="Change Profile Photo">
                <p>Use Avatar</p>
              </div>
            </ButtonBase></div>
          <div className="img-option">
            <ButtonBase onClick={() => (this.uploadRefs ? this.uploadRefs.click() : {})}>
              <div title="Change Profile Photo">
                <input
                  type="file"
                  name="file"
                  accept="image/*"
                  ref={(ref) => (this.uploadRefs = ref)}
                  style={{ display: "none" }}
                  onChange={this.handleUploadProfileImage}
                />
                <p>{t("user.title3")}</p>
              </div>
            </ButtonBase>
          </div>
          {!!picUrl && (
            <div className="img-option">
              <ButtonBase onClick={this.handleRemoveImage}>
                <div title="Remove Profile Photo">
                  <p>{t("user.title4")}</p>
                </div>
              </ButtonBase>
            </div>
          )}
        </div>
      </Dialog>
    );
  }
}
export default compose(withTranslation())(ProfileImageOptionModal)