import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as UploadMatureContentModal } from "./upload-mature-content-modal";
import {
    openModal, closeModal, updateProfile, enableRestrictedMode, disableRestrictedMode, getUploadMatureContent,
    enableVideoUploadMatureContent,
    disableVideoUploadMatureContent,
    enableImageUploadMatureContent,
    disableImageUploadMatureContent,
    enableStoryUploadMatureContent,
    disableStoryUploadMatureContent,
    enableWeblinkUploadMatureContent,
    disableWeblinkUploadMatureContent
} from "../../../../store";

const mapStateToProps = (state) => ({
    modal: state.ui.modal.upload_mature_content || false,
    error: state.auth.error,
    isLoading: state.auth.isLoading,
    themes: state.master.themes,
    activeTab: state.upload.activeTab,
    matureContent: getUploadMatureContent(state),
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (modal) => dispatch(openModal(modal)),
    closeModal: (modal) => dispatch(closeModal(modal)),
    updateProfile: () => dispatch(updateProfile()),
    enableRestrictedMode: () => dispatch(enableRestrictedMode()),
    disableRestrictedMode: () => dispatch(disableRestrictedMode()),
    enableVideoUploadMatureContent: () => dispatch(enableVideoUploadMatureContent()),
    disableVideoUploadMatureContent: () => dispatch(disableVideoUploadMatureContent()),
    enableImageUploadMatureContent: () => dispatch(enableImageUploadMatureContent()),
    disableImageUploadMatureContent: () => dispatch(disableImageUploadMatureContent()),
    enableStoryUploadMatureContent: () => dispatch(enableStoryUploadMatureContent()),
    disableStoryUploadMatureContent: () => dispatch(disableStoryUploadMatureContent()),
    enableWeblinkUploadMatureContent: () => dispatch(enableWeblinkUploadMatureContent()),
    disableWeblinkUploadMatureContent: () => dispatch(disableWeblinkUploadMatureContent()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadMatureContentModal));
