import { CONTENT } from "../../actions";

const reportTextReducer = (state = "", action) => {
  switch (action.type) {
    case CONTENT.CLEAR:
      return 0;
    case CONTENT.SET_REPORT_CONTENT_TEXT:
      return action.reportText;
    default:
      return state;
  }
};

export default reportTextReducer;
