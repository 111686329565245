import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as ProfileImage } from "./profile-image";
import { openModal, updateProfileImage, setAlertAction } from "../../../store";

const mapStateToProps = (state) => ({
  editProfileModal: state.ui.modal.edit_profile,
  isImageLoading: state.auth.isImageLoading,
  user: state.auth.userId ? state.auth.otherUser : state.auth.data,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  updateProfileImage: (image) => dispatch(updateProfileImage(image)),
  setAlertAction: (alert) => dispatch(setAlertAction(alert)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileImage)
);
