import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as EditProfile } from "./edit-profile";
import { openModal, closeModal, editUserInfo, updateProfile, setModalAction } from "../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.edit_profile || false,
  isModalRoute: state.ui.isModalRoute,
  languages: state.master.languages,
  countries: state.master.countries,
  user: state.auth.editUser,
  error: state.auth.error,
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  editUserInfo: (user) => dispatch(editUserInfo(user)),
  updateProfile: () => dispatch(updateProfile()),
  setModalAction: (action) => dispatch(setModalAction(action)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfile));
