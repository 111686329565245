import React, { Component, Fragment } from "react";
import { Checkbox } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class FcSearch extends Component {
  handleChange = (e, val) => {
    const { optionValue } = this.props;
    let convertToBackendResult = [];
    val.forEach((res) => convertToBackendResult.push(res[optionValue]));
    const {
      form: { setFieldValue },
      field: { name },
      onChangeSelect,
    } = this.props;
    setFieldValue(name, val);
    if (onChangeSelect) {
      onChangeSelect(convertToBackendResult);
    }
  };

  //   const mapLabel = () => {
  //     const { field} = this.props;
  //     const results = [];
  //     field.value.forEach((s) => {
  //       if (Array.isArray(selected)) {
  //         if (selected.includes(s[optionValue])) {
  //           results.push(s[optionLabel]);
  //         }
  //       } else {
  //         if (selected === s[optionValue]) {
  //           results.push(s[optionLabel]);
  //         }
  //       }
  //     });
  //     return results.join(", ");
  //   };
  getSelectedItem = () => {
    const item = this.props.options.find((opt) => {
      if (opt.value == this.props.selectedValue) return opt;
    });
    return item || {};
  };
  render() {
    const { field, source, optionValue, optionLabel, label, multiple, placeholder } = this.props;
    return (
      <Autocomplete
        name={field.name}
        value={field.value}
        multiple={multiple}
        options={source}
        defaultValue={field.value}
        disableCloseOnSelect
        getOptionLabel={(option) => option[optionLabel]}
        // getOptionSelected={(option, value) => option[optionValue] === value[optionValue]}
        onChange={(event, value) => this.handleChange(event, value)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {option[optionLabel]}
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              value={option[optionValue]}
            />
          </React.Fragment>
        )}
        // style={{ width: 500 }}
        renderInput={(params) => <TextField {...params} variant="outlined" placeholder={placeholder} />}
      />
    );
  }
}
export default FcSearch;
