import { Grid, Switch, TextField } from "@material-ui/core";
import React, { Fragment, PureComponent } from "react";
import * as Yup from "yup";
import { ModalType, UploadStep } from "../../../../../constant";
import "./web-upload.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const webLinkSchema = Yup.object().shape({
  weblink: Yup.string().required("Required"),
  language: Yup.number().required("Required"),
  genre: Yup.number().required("Required"),
});

class WebUpload extends PureComponent {
  handleWeblinkChange = (weblink) => {
    const { setUploadWeblink, loadMetadata } = this.props;
    setUploadWeblink(weblink);
    loadMetadata();
  };
  handleDescriptionChange = () => {};
  handleLanguageChange = (lang) => {
    const { setUploadWeblinkLanguage } = this.props;
    setUploadWeblinkLanguage(lang);
  };
  handleGenreChange = (genre) => {
    const { setUploadGenre, setLinkUploadGenre } = this.props;
    setLinkUploadGenre(genre);
  };
  handleModal = (modalType) => {
    const {
      openModal,
      setUploadStep,
      updateUploadGenre,
      linkUploadGenre,
      webLinkLanguages,
      updateUploadLanguage,
    } = this.props;
    setUploadStep(modalType);
    if (modalType === UploadStep.GENRE) updateUploadGenre(linkUploadGenre);
    if (modalType === UploadStep.LANGUAGE) updateUploadLanguage(webLinkLanguages);
    openModal(ModalType.COMMON);
  };
  handleMatureContent = () => {
    const { openModal, disableWeblinkUploadMatureContent, matureContent } = this.props;
    matureContent ? disableWeblinkUploadMatureContent() : openModal(ModalType.UPLOAD_MATURE_CONTENT);
  };
  render() {
    const {
      t,
      weblink,
      error,
      metaData,
      genres,
      languages,
      webLinkLanguages,
      uploadGenres,
      validateUpload,
      linkUploadGenre,
      matureContent,
    } = this.props;
    const initialGenres = genres.filter((g) => linkUploadGenre.includes(g.id));
    const initialLanguage = languages.filter((l) => webLinkLanguages.includes(l.id));
    return (
      <Fragment>
        <Grid item xs={12} sm={12}>
          <div className="description"></div>
          <form>
            <Grid container spacing={0} className="m-0 lnk">
              <Grid item xs={12} className="grd-frm">
                <label className="bold">{t("uploadSection.link.add")}</label>
                <TextField
                  name="weblink"
                  placeholder={t("uploadSection.title8")}
                  // component={FcText}
                  value={weblink}
                  onChange={(e) => this.handleWeblinkChange(e.target.value)}
                  variant="outlined"
                ></TextField>
              </Grid>
              {/* {!isMobileOnly ? (
                <Fragment>
                  <Grid item xs={12} sm={6} className="grd-frm">
                    <label className="bold">Genre</label>
                    <FcCustomSearch
                      name="genre"
                      // component={FcCustomSearch}
                      optionValue="id"
                      optionLabel="name"
                      source={genres}
                      multiple={true}
                      val={initialGenres}
                      onChangeSelect={this.handleGenreChange}
                    ></FcCustomSearch>
                    {/* {errors.genre && touched.genre && <div>{errors.genre}</div>} *
                  </Grid>
                  <Grid item xs={12} sm={6} className="grd-frm">
                    <label className="bold">Language</label>
                    <FcCustomSearch
                      name="language"
                      // component={FcCustomSearch}
                      optionValue="id"
                      optionLabel="nameUtf8"
                      source={languages}
                      multiple={true}
                      val={initialLanguage}
                      onChangeSelect={this.handleLanguageChange}
                    ></FcCustomSearch>
                    {/* {errors.language && touched.language && <div>{errors.language}</div>} *
                  </Grid>{" "}
                </Fragment>
              ) : ( */}
              <Fragment>
                <h4 style={{ marginLeft: 10 }}>{t("uploadSection.h4")}</h4>
                <Grid container spacing={0} className="grid-container" style={{ marginTop: 0 }}>
                  <Grid container item xs={12} className="grid-section">
                    <Grid item xs={4}>
                      <label className="">{t("uploadSection.label1")}</label>
                    </Grid>
                    <Grid item xs={8} className="txt-rght">
                      <label onClick={() => this.handleModal(UploadStep.GENRE)}>
                        {initialGenres.length ? (
                          initialGenres.length <= 2 ? (
                            initialGenres.map((res, index) => (
                              <span key={index}>
                                {res.name} {index < initialGenres.length - 1 ? ", " : null}
                              </span>
                            ))
                          ) : (
                            <Fragment>
                              <span key={0}>{initialGenres[0].name}, </span>
                              <span key={1}>{initialGenres[1].name}, </span>
                              <span key={2}>+{initialGenres.length - 2} more</span>
                            </Fragment>
                          )
                        ) : (
                          <span className="not-set"> {t("uploadSection.span")}</span>
                        )}
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} className="grid-section">
                    <Grid item xs={4}>
                      <label className="">{t("uploadSection.label2")}</label>
                    </Grid>
                    <Grid item xs={8} className="txt-rght">
                      <label onClick={() => this.handleModal(UploadStep.LANGUAGE)}>
                        {initialLanguage.length ? (
                          initialLanguage.length <= 2 ? (
                            initialLanguage.map((res, index) => (
                              <span key={index}>
                                {res.nameUtf8}
                                {index < initialLanguage.length - 1 ? "," : null}
                              </span>
                            ))
                          ) : (
                            <Fragment>
                              <span key={0}>{initialLanguage[0].nameUtf8}, </span>
                              <span key={1}>{initialLanguage[1].nameUtf8}, </span>
                              <span key={2}>+{initialLanguage.length - 2} more</span>
                            </Fragment>
                          )
                        ) : (
                          <span className="not-set"> {t("uploadSection.span")}</span>
                        )}
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} className="grid-section" style={{ paddingBottom: 0 }}>
                    <Grid item xs={8}>
                      <label className="">{t("uploadSection.label3")}</label>
                    </Grid>
                    <Grid item xs={4} className="txt-rght">
                      <Switch className="matureSwitch" checked={matureContent} onChange={this.handleMatureContent} />
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
              {/* )} */}
            </Grid>
          </form>
          {/*   )}
           </Formik> */}
        </Grid>

        {/* <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={4}>
          <div className="site-desc" data-text="Preview not loaded">
            {!isEmpty(metaData) && (
              <div>
                <label className="bold pv">Preview</label>
                <div className="site-img">
                  {(metaData.image || metaData.thumbnail) && (
                    <img src={metaData.image ? metaData.image : metaData.thumbnail} alt="" width="100%" />
                  )}
                </div>
                <div className="site-content">
                  <p className="title">{metaData.title}</p>
                  <p className="desc">{metaData.summery}</p>
                </div>
              </div>
            )}
          </div>
        </Grid> */}
      </Fragment>
    );
  }
  componentWillUnmount() {
    const { closeModal, commonModal } = this.props;
    if (commonModal) {
      closeModal(ModalType.COMMON);
    }
  }
}

export default compose(withTranslation())(WebUpload);
