import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./login-modal.style";
import { ModalType } from "../../../../constant";
import LoadQR from "./load-qr";
import MobileNumber from "./mobile-number";
import ValidateOtp from "./validate-otp";
import "./login-modal.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

class LoginModal extends PureComponent {
  handleClose = () => {
    const { closeModal, isModalRoute, history } = this.props;
    document.body.style.position = "";
    closeModal(ModalType.LOGIN);
    isModalRoute && history.goBack();
  };
  navigate = () => {
    const { history } = this.props;
    this.handleClose();
    history.push("/terms-conditions");
  };
  render() {
    const { classes, modal, otpSent, selectedText, t } = this.props;
    console.log(selectedText)
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper login-modal">
            {/* <button className="closeBtn" onClick={this.handleClose}></button> */}
            <div className="p-50">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {/* <button
                  className="closeBtn"
                  onClick={this.handleClose}
                ></button> */}
                  <div>
                    <h1 id="transition-modal-title" className="heading modalTitle font-bold">
                      {otpSent ? t("login.title1") : t("login.title")}
                      {!!selectedText &&  t('messages.title') + (t(`${selectedText}`))}
                    </h1>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} className="mobile-hidden mb-60">
                  <LoadQR />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <div className="orText"> </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="">
                    {otpSent ? (
                      <h3 className="title"> {t("login.title2")}</h3>
                    ) : isMobileOnly && !otpSent ? (
                      <h3 className="title"> Sign In</h3>
                    ) : (
                      <h3 className="title"> {t("login.heading")}</h3>
                    )}
                    {otpSent ? <ValidateOtp /> : <MobileNumber />}
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="modalFooter">
                  <p>
                  {t("login.p1")}{" "}
                    <span classname="font-bold pointer" style={{ cursor: "pointer" }} onClick={this.navigate}>
                    {t("login.span1")}{" "}
                    </span>
                  </p>
                </div>
              </Grid>
            </Grid>

            {/* <div id="transition-modal-description" className="modalContent">
              <div
                className="modalBody"
                style={{ padding: 0, paddingTop: 40 }}
              ></div>
            </div> */}
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(LoginModal);
