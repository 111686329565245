import React from "react";
import "./upload-btn-mobile.scss";

const PhotoMobile = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    style={{ enableBackground: "new 0 0 24 24" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Tymoff-app"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Add-images---multiple"
        transform="translate(-87.000000, -303.000000)"
        fill="#FF0080"
        fillRule="nonzero"
      >
        <g
          id="ic-insert-photo-24px-copy"
          transform="translate(15.000000, 254.000000)"
        >
          <g id="Group-6" transform="translate(56.000000, 33.000000)">
            <path
              d="M40,37.3333333 L40,18.6666667 C40,17.2 38.8,16 37.3333333,16 L18.6666667,16 C17.2,16 16,17.2 16,18.6666667 L16,37.3333333 C16,38.8 17.2,40 18.6666667,40 L37.3333333,40 C38.8,40 40,38.8 40,37.3333333 Z M23.05,30.55 L26.3714286,34.463 L31.0214286,28.6 L37,36.4 L18.4,36.4 L23.05,30.55 Z"
              id="Shape-Copy-3"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const VideoMobile = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 30 20"
    style={{ enableBackground: "new 0 0 30 20" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Tymoff-app"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Add-images---multiple"
        transform="translate(-270.000000, -304.000000)"
        fill="#F8860E"
        fillRule="nonzero"
      >
        <g
          id="ic-insert-photo-24px-copy"
          transform="translate(15.000000, 254.000000)"
        >
          <g id="Group-5" transform="translate(240.000000, 33.000000)">
            <path
              d="M38.3333333,24.5 L38.3333333,18.6666667 C38.3333333,17.75 37.5833333,17 36.6666667,17 L16.6666667,17 C15.75,17 15,17.75 15,18.6666667 L15,35.3333333 C15,36.25 15.75,37 16.6666667,37 L36.6666667,37 C37.5833333,37 38.3333333,36.25 38.3333333,35.3333333 L38.3333333,29.5 L45,36.1666667 L45,17.8333333 L38.3333333,24.5 Z"
              id="Path-Copy-2"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const StoryMobile = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 21 28"
    style={{ enableBackground: "new 0 0 21 28" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Tymoff-app"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Add-images---multiple"
        transform="translate(-457.000000, -302.000000)"
        fill="#9CA87A"
        fillRule="nonzero"
      >
        <g
          id="ic-insert-photo-24px-copy"
          transform="translate(15.000000, 254.000000)"
        >
          <g id="Group-7" transform="translate(424.000000, 33.000000)">
            <g
              id="compose-mail-copy"
              transform="translate(18.000000, 15.000000)"
            >
              <path
                d="M19.9008264,-2.77289303e-12 C20.1785124,-2.77289303e-12 20.3636364,0.185123967 20.3636364,0.462809917 L20.3636364,0.462809917 L20.3636364,27.3057851 C20.3636364,27.5834711 20.1785124,27.768595 19.9008264,27.768595 L19.9008264,27.768595 L0.462809917,27.768595 C0.185123967,27.768595 -3.46521709e-13,27.5834711 -3.46521709e-13,27.3057851 L-3.46521709e-13,27.3057851 L-3.46521709e-13,6.75702479 C-3.46521709e-13,6.66446281 0.0925619835,6.57190083 0.0925619835,6.57190083 L0.0925619835,6.57190083 L6.57190083,0.0925619835 C6.66446281,0.0925619835 6.66446281,-2.77289303e-12 6.75702479,-2.77289303e-12 L6.75702479,-2.77289303e-12 Z M13.4214876,2.31404959 C12.2181818,2.31404959 11.107438,3.42479339 11.107438,4.62809917 L11.107438,4.62809917 L11.107438,20.0859504 L11.107438,20.0859504 L12.9586777,24.7140496 C13.0512397,24.8991736 13.2363636,24.9917355 13.4214876,24.9917355 C13.6066116,24.9917355 13.7917355,24.8991736 13.8842975,24.7140496 L13.8842975,24.7140496 L15.7355372,20.0859504 L15.7355372,20.0859504 L15.7355372,4.62809917 C15.7355372,3.42479339 14.6247934,2.31404959 13.4214876,2.31404959 Z M14.6247934,20.3636364 L13.4214876,23.3256198 L12.2181818,20.3636364 L14.6247934,20.3636364 Z M14.8099174,7.40495868 L14.8099174,19.4380165 L12.0330579,19.4380165 L12.0330579,7.40495868 L14.8099174,7.40495868 Z M13.4214876,3.23966942 C14.1619835,3.23966942 14.8099174,3.88760331 14.8099174,4.62809917 L14.8099174,4.62809917 L14.8099174,6.47933884 L12.0330579,6.47933884 L12.0330579,4.62809917 C12.0330579,3.88760331 12.6809917,3.23966942 13.4214876,3.23966942 Z M6.47933884,1.57355372 L1.57355372,6.47933884 L6.47933884,6.47933884 L6.47933884,1.57355372 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const LinkMobile = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 32 16"
    style={{ enableBackground: "new 0 0 32 16" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Tymoff-app"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Add-images---multiple"
        transform="translate(-636.000000, -307.000000)"
        fill="#32C5FF"
        fillRule="nonzero"
      >
        <g
          id="ic-insert-photo-24px-copy"
          transform="translate(15.000000, 254.000000)"
        >
          <g id="Group-8" transform="translate(608.000000, 33.000000)">
            <path
              d="M16.04,28 C16.04,25.264 18.264,23.04 21,23.04 L27.4,23.04 L27.4,20 L21,20 C16.584,20 13,23.584 13,28 C13,32.416 16.584,36 21,36 L27.4,36 L27.4,32.96 L21,32.96 C18.264,32.96 16.04,30.736 16.04,28 Z M22.6,29.6 L35.4,29.6 L35.4,26.4 L22.6,26.4 L22.6,29.6 Z M37,20 L30.6,20 L30.6,23.04 L37,23.04 C39.736,23.04 41.96,25.264 41.96,28 C41.96,30.736 39.736,32.96 37,32.96 L30.6,32.96 L30.6,36 L37,36 C41.416,36 45,32.416 45,28 C45,23.584 41.416,20 37,20 Z"
              id="Shape-Copy-4"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { PhotoMobile, VideoMobile, StoryMobile, LinkMobile };
