import React, { PureComponent, Fragment } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  CountryModal,
  LanguageModal,
  ShareModal,
  LoginModal,
  SettingModal,
  EditProfileModal,
  AlertModal,
  NotificationModal,
  UploadNotificationModal,
  FeedbackModal,
  ScrollTimerModal,
  ContentMoreOptions,
  ContentModal,
  CopyLinkModal,
  ReportContentModal,
  ProfileImageOptionModal,
  ViewProfileImageModal,
  ShowMoreDetailModal,
  MessageSnackbar,
  ErrorMessageSnackbar,
  InstallAppModal,
  CameraModal,
  PreferenceModal,
  SeeMoreModal,
  CommonModal,
  FollowersModal,
  ThemeModal,
  UnfollowAlertModal,
  CloseMemeDialog,
  BioModal,
  MatureContentModal,
  FollowingModal,
  UploadMatureContentModal,
  UserSearchModal,
  MemeModal,
  SoundSettingModal,
  VerificationModal,
  AvatarModal,
} from "../../components";
//import memeModal from "../../components/presentational/modal/meme-modal";
import followingModal from "../../components/presentational/modal/following-modal";
import verificationModal from "../../components/presentational/modal/verification-modal/verification-modal";
import userSearchModal from "../../components/presentational/modal/users-search-modal/user-search-modal";
//import verificationModal from "../../components/presentational/modal/verification-modal/verification-modal";

class Modal extends PureComponent {
  render() {
    const {
      shareModal,
      countryModal,
      languageModal,
      loginModal,
      uploadModal,
      settingModal,
      editProfileModal,
      alertModal,
      notificationModal,
      uploadNotificationModal,
      feedbackModal,
      scrollTimerModal,
      moreOptionsModal,
      contentModal,
      copyLinkModal,
      reportContentModal,
      showMoreDetailModal,
      viewProfileImageModal,
      profileImageOptionModal,
      snackbar,
      installAppModal,
      cameraModal,
      errorSnackbar,
      changelanguageModal,
      preferences,
      seeMore,
      reportUserModal,
      commonModal,
      followersModal,
      themeModal,
      unfollowAlertModal,
      closeMemeDialog,
      bioModal,
      matureContentModal,
      followingModal,
      uploadMatureContentModal,
      soundSettingModal,
      memeModal,
      memeWModal,
      avatarModal,
    } = this.props;
    return (
      <Fragment>
        {countryModal && <CountryModal />}
        {languageModal && <LanguageModal />}
        {changelanguageModal && <LanguageModal />}
        {/* {shareModal && <ShareModal />} */}
        {/* {settingModal && <SettingModal />} */}
        {editProfileModal && <EditProfileModal />}
        {alertModal && <AlertModal />}
        {/* {notificationModal && <NotificationModal />} */}
        {uploadNotificationModal && <UploadNotificationModal />}
        {feedbackModal && <FeedbackModal />}
        {scrollTimerModal && <ScrollTimerModal />}
        {/* {moreOptionsModal && <ContentMoreOptions />} */}
        {contentModal && <ContentModal />}
        {copyLinkModal && <CopyLinkModal />}
        {/* {reportContentModal && <ReportContentModal />} */}
        {showMoreDetailModal && <ShowMoreDetailModal />}
        {viewProfileImageModal && <ViewProfileImageModal />}
        {profileImageOptionModal && <ProfileImageOptionModal />}
        {snackbar && <MessageSnackbar />}
        {errorSnackbar && <ErrorMessageSnackbar />}
        {loginModal && <LoginModal />}
        {installAppModal && <InstallAppModal />}
        {cameraModal && <CameraModal />}
        {preferences && <PreferenceModal />}
        {seeMore && <ShowMoreDetailModal />}
        {commonModal && <CommonModal />}
        {followersModal && <FollowersModal />}
        {themeModal && <ThemeModal />}
        {unfollowAlertModal && <UnfollowAlertModal />}
        {closeMemeDialog && <CloseMemeDialog />}
        {bioModal && <BioModal />}
        {matureContentModal && <MatureContentModal />}
        {uploadMatureContentModal && <UploadMatureContentModal />}
        {followingModal && <FollowingModal />}
        {userSearchModal && <UserSearchModal />}
         <ErrorBoundary> {memeModal && <MemeModal />}</ErrorBoundary>
         <ErrorBoundary> {memeWModal && <MemeModal />}</ErrorBoundary>
         <ErrorBoundary> {avatarModal && <AvatarModal />}</ErrorBoundary>
        <ErrorBoundary>
          {verificationModal && <VerificationModal />}
        </ErrorBoundary>
      </Fragment>
    );
  }
}

export default Modal;
