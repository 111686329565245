import { combineReducers } from "redux";
import filterLanguageReducer from "./filter-language";
import searchLanguageReducer from "./search-language";

const languageReducer = combineReducers({
  filterLanguage: filterLanguageReducer,
  searchLanguage: searchLanguageReducer
});

export { languageReducer };
