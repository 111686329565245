import React, { PureComponent } from "react";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import {
  ModalType,
  UploadModalRootClass,
  UploadStep,
} from "../../../../constant";
import { LanguageSelector, GenreSelector } from "../../../containers";

export default class CommonModal extends PureComponent {
  handleClose = () => {
    const {
      closeModal,
      clearUploadGenre,
      activeStep,
      clearUploadLanguage,
    } = this.props;
    document.body.style.position = "";
    if (activeStep === UploadStep.GENRE) clearUploadGenre();
    if (activeStep === UploadStep.LANGUAGE) clearUploadLanguage();
    closeModal(ModalType.COMMON);
  };
  render() {
    const { modal, activeStep } = this.props;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={`paper ${UploadModalRootClass[activeStep]}`}>
            <button
              className="closeBtn mobile-hidden"
              onClick={this.handleClose}
            ></button>
            <button
              className="back-btn tab-hidden"
              onClick={this.handleClose}
            ></button>
            {activeStep === UploadStep.LANGUAGE && (
              <LanguageSelector buttonText={"Save"} />
            )}
            {activeStep === UploadStep.GENRE && <GenreSelector />}
          </div>
        </Fade>
      </Modal>
    );
  }
}
