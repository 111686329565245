import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { AccountCircle } from "@material-ui/icons";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import React, { Fragment, PureComponent } from "react";
import { isMobileOnly } from "react-device-detect";
import {
  FcRadio,
  FcSelect,
  FcCustomSearch,
} from "../../../components/presentational/fc";
import {
  LogoutAlert,
  ModalType,
  SelectedText,
  StaticRoutes,
} from "../../../constant";
import { getProfileImageUrl } from "../../../core";
import {
  Android,
  AndroidDark,
  Apple,
  AppleDark,
  Bookmark,
  Home,
  Likes,
  Preferences,
  PreferencesNew,
  Trending,
  Meme,
} from "../../../icons";
import { SidebarNavLink } from "../../containers";
import "./sidebar.scss";
import { ListItemText } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { Grid as ListGrid, Formats, Genre } from "../../../components";
import Slider from "react-slick";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactCountryFlag from "react-country-flag";
import Flag from "react-world-flags";
import { withRouter } from "react-router-dom";
import { isServer } from "../../../store";
class Sidebar extends PureComponent {
  state = { subMenu: true };
  handleDiscoverClick = () => {
    const { openSidebar, sidebar, navlinkChange } = this.props;
    navlinkChange();
    if (!sidebar) {
      this.setState({ subMenu: true });
      openSidebar();
    } else this.setState((prevState) => ({ subMenu: !prevState.subMenu }));
  };

  handleModalClick = (modal) => {
    const { openModal, navlinkChange } = this.props;
    navlinkChange();
    openModal(modal);
  };
  handleLogout = () => {
    const { setAlertAction } = this.props;
    setAlertAction(LogoutAlert);
  };

  handleUnauthorizedRoute = () => {
    const { openModal, navlinkChange, showSelectedText } = this.props;
    navlinkChange();
    openModal(ModalType.LOGIN);
    showSelectedText(SelectedText.DISCOVER_TEXT);
  };
  handleOnClose = () => {
    const { closeSidebar } = this.props;
    if (isMobileOnly) {
      closeSidebar();
    }
  };
  handleCountryChange = (countryIds) => {
    const { setFilterCountry } = this.props;
    setFilterCountry(countryIds);
  };
  render() {
    const {
      t,
      sidebar,
      discoverList,
      userDiscoverList,
      isLoggedIn,
      selectedCountries,
      selectedLanguages,
      isDiscoverRoute,
      isDarkTheme,
      picUrl,
      user,
      history,
      openModal,
      countries,
      selectedAllCountries,
      activeCountry,
    } = this.props;

    const { location } = history;
    //console.log(location);
    const { subMenu } = this.state;
    const filterCountries = countries.filter((c) =>
      selectedCountries.includes(c.id)
    );
    return (
      <Drawer
        variant={!isMobileOnly ? "permanent" : "temporary"}
        className={clsx("drawer", {
          drawerOpen: sidebar,
          drawerClose: !sidebar,
        })}
        classes={{
          paper: clsx("sidebarStyle", {
            drawerOpen: sidebar,
            drawerClose: !sidebar,
          }),
        }}
        open={sidebar}
      //onEscapeKeyDown={this.handleOnClose}
      //onBackdropClick={this.handleOnClose}
      // ModalProps={{ onBackdropClick: this.handleOnClose }}
      >
        {isLoggedIn && isMobileOnly && (
          <Fragment>
            <IconButton
              aria-label="show more option"
              color="inherit"
              className="MuiTypography-alignLeft"
            >
              <Fragment className="">
                <img
                  className="userIcon-sidebar"
                  src={getProfileImageUrl(picUrl, user.id, user.name)}
                  alt=""
                  onError={(e) => {
                    e.target.src = getProfileImageUrl("", user.id, user.name);
                  }}
                  alt=""
                />
                <div className="name-label">
                  <label className="username font-bold">
                    {" "}
                    {user ? user.name : "Hi"}
                  </label>
                  <span
                    className="edit-profile"
                    onClick={() => openModal(ModalType.EDIT_PROFILE)}
                  >
                    Edit Profile
                  </span>
                </div>
              </Fragment>
            </IconButton>
          </Fragment>
        )}
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className="nestedRoot"
        >
          <SidebarNavLink
            exact
            listItemClass="NavItem NavItemHome"
            itemTextClass="NavItemText"
            activeClass="active"
            to={"/"}
            title={t("sidebar.title1")}
          >
            <IconButton>
              <Home height={"16px"} width={"16px"} className="sidebar-icon" />
            </IconButton>
          </SidebarNavLink>
          <SidebarNavLink
            exact
            listItemClass="NavItem NavItemTrending"
            itemTextClass="NavItemText"
            activeClass="active"
            to={"/discover/trending"}
            title={t("sidebar.title2")}
          >
            <IconButton>
              <Trending
                height={"16px"}
                width={"16px"}
                className="sidebar-icon"
              ></Trending>
            </IconButton>
          </SidebarNavLink>

          {isLoggedIn && (
            <Fragment>
              <SidebarNavLink
                exact
                listItemClass="NavItem NavItemMeme"
                itemTextClass="NavItemText"
                activeClass="active"
                to={"/meme"}
                title={t("sidebar.title10")}
              >
                <IconButton>
                  <Meme
                    height={"16px"}
                    width={"16px"}
                    className="sidebar-icon"
                  />
                </IconButton>
              </SidebarNavLink>
              <SidebarNavLink
                exact
                listItemClass="NavItem NavItemMyLikes"
                itemTextClass="NavItemText"
                activeClass="active"
                to={"/discover/my-likes"}
                title={t("sidebar.title3")}
              >
                <IconButton>
                  <Likes
                    height={"16px"}
                    width={"16px"}
                    className="sidebar-icon"
                  />
                </IconButton>
              </SidebarNavLink>
              <SidebarNavLink
                exact
                listItemClass="NavItem NavItemBookmark"
                itemTextClass="NavItemText"
                activeClass="active"
                to={"/bookmark"}
                title={t("sidebar.title4")}
              >
                <IconButton>
                  <Bookmark
                    height={"16px"}
                    width={"16px"}
                    className="sidebar-icon"
                  />
                </IconButton>
              </SidebarNavLink>

              {/* <SidebarNavLink
                exact
                listItemClass="NavItem NavItemPreferences"
                itemTextClass="NavItemText"
                activeClass="active"
                to={"/preference"}
                title={"Preferences"}
              >
                <IconButton>
                  <Preferences height={"15px"} width={"15px"} className="sidebar-icon" />
                </IconButton>
              </SidebarNavLink> */}
            </Fragment>
          )}
          <ListItem
            className="NavItem"
            title={t("sidebar.title5")}
            button
            onClick={() => this.handleModalClick(ModalType.PREFERENCES)}
          >
            <IconButton>
              <Preferences
                height={"15px"}
                width={"15px"}
                className="sidebar-icon"
              />
            </IconButton>
            <ListItemText
              className="NavItemText"
              primary={t("sidebar.title5")}
            />
          </ListItem>
          <ListItem
            className="NavItem"
            title="SiteLanguage"
            button
            onClick={() => this.handleModalClick(ModalType.PREFERENCES)}
          >
            <IconButton>
              <Flag code={activeCountry.countryCode} height="10" />
            </IconButton>
            <ListItemText className="NavItemText" primary={"India"} />
          </ListItem>
        </List>
        {/* <Divider light /> */}

        {location.pathname == "/" ||
          location.pathname == "/discover/trending" ||
          location.pathname == "/search" ? (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className="nestedRoot sliderGenre"
          >
            <Genre />
            {/* <Divider light /> */}
          </List>
        ) : null}

        {!isMobileOnly && (
          <div
            className={
              location.pathname == "/" ||
                location.pathname == "/discover/trending" ||
                location.pathname == "/search"
                ? "privacyContact"
                : "privacyContact1"
            }
          >
            <div className="storelink">
              <div className="bg">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!isServer) {
                      window.open(
                        "https://apps.apple.com/in/app/tymoff/id1486021014"
                      );
                    }
                  }}
                >
                  {!isDarkTheme ? <Apple></Apple> : null}
                  {isDarkTheme ? <AppleDark></AppleDark> : null}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!isServer) {
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.tymoff"
                      );
                    }
                  }}
                >
                  {!isDarkTheme ? <Android></Android> : null}
                  {isDarkTheme ? <AndroidDark></AndroidDark> : null}
                </div>
              </div>
            </div>
            <List>
              {StaticRoutes.map((route, index) => (
                <SidebarNavLink
                  key={index}
                  exact
                  listItemClass="NavItem bottom-menu"
                  itemTextClass="NavItemText"
                  activeClass="active"
                  to={route.to}
                  title={t(`${route.title}`)}
                />
              ))}
            </List>
          </div>
        )}
        {isMobileOnly && (
          <ListItem
            className="NavItem NavItemCountry logout-sidebar-mobile"
            title="Country"
            button
            onClick={this.handleLogout}
          >
            Logout
          </ListItem>
        )}
      </Drawer>
    );
  }
}
// export default withRouter(Sidebar);
export default compose(withTranslation())(Sidebar);
