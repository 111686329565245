import React, { PureComponent } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
} from "@material-ui/core";
import { ModalType } from "../../../../constant";
import "./close-meme-dialog.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class CloseMemeDialog extends PureComponent {
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.CLOSE_MEME_DIALOG);
  };

  handleSuccess = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.CLOSE_MEME_DIALOG);
    closeModal(ModalType.MEMEMODAL);
    closeModal(ModalType.MEMEMODALW);
    closeModal(ModalType.USERSEARCH);
  };

  render() {
    const { modal } = this.props;
    return (
      <Dialog
        className="alert-modal"
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="unfollow-alert-dialog-slide-title"
        aria-describedby="unfollow-alert-dialog-slide-description"
      >
        <DialogTitle id="unfollow-alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="unfollow-alert-dialog-slide-description">
            <span>Do you want to close?</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSuccess} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
