import React, { PureComponent, Fragment, Component } from "react";
import { Grid, Button } from "@material-ui/core";
import "./preference.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { UserInfo, ProfileImage, AccountForm, Profile } from "../../components";
import { ClearHistoryAlert, PreferenceTab } from "../../constant";
import { withRouter } from "react-router-dom";
import {
  http,
  headerOptions,
  fileHeaderOptions,
  metaHeaderOptions,
} from "../../core";
import { URL } from "../../api/_urls";
import axios from "axios";
const tokenData = (token, type) => {
  return {
    verificationData: [
      {
        token,
        socialType: type,
      },
    ],
  };
};
class Preference extends Component {
  state = {
    showProfile: true,
    verified: false,
  };
  constructor(props) {
    super(props);
    const { history, setAuthError } = this.props;
    history.listen(() => {
      setAuthError("");
    });
  }
  onhandleChange = (val) => {
    const { setPreferenceActiveTab } = this.props;
    setPreferenceActiveTab(val);
    //this.setState({ showProfile: val });
  };
  handleClearHistory = () => {
    const { setAlertAction } = this.props;
    setAlertAction(ClearHistoryAlert);
  };
  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  componentDidMount() {
    const { token, history } = this.props;
    var url = history.location;
    var access_token = new URLSearchParams(url.search).get("code");
    var stateId = new URLSearchParams(url.search).get("state");
    if (!access_token) {
      return;
    } else {
      if (stateId === "DCEeFWf45A53sdfKef424") {
        var options = {
          method: "POST",
          url: "https://www.linkedin.com/oauth/v2/accessToken",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: {
            grant_type: "authorization_code",
            client_id: "77dnnqniu0jula",
            client_secret: "qznQlbERIUSlFodE",
            code: access_token,
            redirect_uri: "https://tymoff.com/preference/",
          },
        };
        /* window.open(
        `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&redirect_uri=http://localhost:3000/preference/&client_id=77dnnqniu0jula&client_secret=qznQlbERIUSlFodE&code=${access_token}`
      ); */
        axios
          .request(options)
          .then((response) => {
            console.log(response);
            http.post(
              URL.VERIFICATION_API() + "?timestamp" + new Date().getTime(),
              tokenData(response.access_token, "linkedin"),
              headerOptions(token)
            );
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        var options = {
          method: "POST",
          url: "https://api.instagram.com/oauth/access_token",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: {
            grant_type: "authorization_code",
            client_id: "1325245911154811",
            client_secret: "f4faf0ae38b7d781e5279a395a62468a",
            code: access_token,
            redirect_uri: "https://tymoff.com/preference/",
          },
        };
        axios
          .request(options)
          .then((response) => {
            console.log(response);
            http.post(
              URL.VERIFICATION_API() + "?timestamp" + new Date().getTime(),
              tokenData(response.access_token, "instagram"),
              headerOptions(token)
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    /*  http.post(
      URL.VERIFICATION_API() + "?timestamp" + new Date().getTime(),
      tokenData(data.credential.accessToken, typeID),
      headerOptions(token)
    ); */
  }
  render() {
    const { t, activeTab } = this.props;
    return (
      <Fragment>
        <div className="sub-header text-left tab-hidden">
          <button className="back-btn" onClick={this.handleBack}></button>
          <h1>Preferences</h1>
        </div>
        <div className="paper login-modal">
          <div className="tymoff-container">
            <Grid container spacing={0} className="preference">
              <Grid item xs={12} sm={7}>
                <div>
                  <h1 id="transition-modal-title" className="heading modalTitle font-bold mobile-hidden">
                    {t("preferenceModal.h1")}
                  </h1>
                </div>
              </Grid>
              <Grid item xs={12} sm={5} className="pref-btn">
                <Button className="history-btn" onClick={this.handleClearHistory}>
                  {t("preferenceModal.website.button1")}
                </Button>
              </Grid>
              <Grid item xs={12} className="pref-btn txt-lft prefbtn">
                <Button
                  variant="contained"
                  color="default"
                  className={`spl-btn ${activeTab == PreferenceTab.PROFILE ? "active" : ""
                    }`}
                  onClick={() => this.onhandleChange(PreferenceTab.PROFILE)}
                  disableElevation
                >
                  {t("preference.button1")}
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  className={`mr-10 spl-btn ${activeTab == PreferenceTab.ACCOUNT ? "active" : ""
                    }`}
                  onClick={() => this.onhandleChange(PreferenceTab.ACCOUNT)}
                  disableElevation
                >
                  {t("preference.button2")}
                </Button>
              </Grid>
            </Grid>
            <div className="profileSection">
              <div className="profileImageSection">
                <ProfileImage />
              </div>
              <div className="profileDetailSection">
                {activeTab === PreferenceTab.PROFILE ? (
                  <Profile />
                ) : (
                  <AccountForm />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// export default withRouter(Preference);
export default compose(withTranslation())(Preference);
/* const params = new URLSearchParams();
    params.append("client_id", "1325245911154811");
    params.append("client_secret", "f4faf0ae38b7d781e5279a395a62468a");
    params.append("grant_type", "authorization_code");
    params.append("redirect_uri", urlVar);
    params.append("code", access_token); */
//var uri = `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&redirect_uri=${urlVar}&client_id=77dnnqniu0jula&client_secret=qznQlbERIUSlFodE&code=${access_token}`;
//var res = encodeURIComponent(uri);
//const typeID = data.additionalUserInfo.providerId.split(".").shift();
/* axios
  .post(
    `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&redirect_uri=${urlVar}&client_id=77dnnqniu0jula&client_secret=qznQlbERIUSlFodE&code=${access_token}`
  )
  .then((response) => {
    console.log(response);
  }); */
