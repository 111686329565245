import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as UserInfo } from "./user-info";
import { openModal, loadFollowing, loadFollowers } from "../../../store";

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  user: state.auth.userId ? state.auth.otherUser : state.auth.data,
  loginUser: state.auth.data,
  isLoggedIn: state.auth.isLoggedIn,
  following: state.auth.following,
  followers: state.auth.followers,
  countries: state.master.countries,
  fullNameVisible: state.auth.data.fullNameVisible,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  loadFollowing: () => dispatch(loadFollowing()),
  loadFollowers: () => dispatch(loadFollowers()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserInfo)
);
