import { connect } from "react-redux";
import { default as Verify } from "./Verify";

import {
  clearFilter,
  openModal,
  setContentAction,
  resetContentPage,
  clearContent,
  closeModal,
  setPreferenceActiveTab,
  setAuthUserID,
  loadOtherUserProfile,
  clearUserId,
  cancelLoadContent,
  loadFollowing,
  loadFollowers,
  followUser,
  unFollowUser,
  setSelectedUserID,
  loadUiData,
  hideNewfeed,
  cancelUserLoadContent,
} from "../../store";

const mapStateToProps = (state) => ({
  routeFromLightbox: state.content.routeFromLightbox,
  loginUser: state.auth.data,
  modal: state.ui.modal.report_user_profile || false,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  clearFilter: () => dispatch(clearFilter()),
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
