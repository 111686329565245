import { UPLOAD } from "../../actions";
import { indexOf, remove } from "lodash";

const uploadLanguageReducer = (state = [], action) => {
    switch (action.type) {
        case UPLOAD.CLEAR:
        case UPLOAD.CLEAR_CONTENT:
        case UPLOAD.CLEAR_UPLOAD_LANGUAGE:
            return [];
        case UPLOAD.SET_UPLOAD_LANGUAGE: {
            const languageList = [...state];
            if (indexOf(languageList, action.uploadLanguage) === -1) {
                languageList.push(action.uploadLanguage);
            } else {
                remove(languageList, el => action.uploadLanguage === el);
            }
            return languageList;
        }
        case UPLOAD.UPDATE_UPLOAD_LANGUAGE:
            return [...action.uploadLanguage];
        default:
            return state;
    }
};

export default uploadLanguageReducer;
