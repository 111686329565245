import { MASTER } from "../../actions";

const themeReducer = (state = [], action) => {
    switch (action.type) {
        case MASTER.CLEAR:
            return [];
        case MASTER.LOAD_THEME:
            return action.themes;
        default:
            return state;
    }
};

export default themeReducer;
