import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as ContentDetailFooter } from "./content-detail-footer";
import {
  showCommentBox,
  hideCommentBox,
  openModal,
  setContentUserAction,
  closeModal,
  loadShareLink,
  stopScrollTimer,
  startScrollTimer,
  downloadFile,
  setShareType,
  setErrorMessage,
  showSelectedText,
  setAuthUserID,
  loadOtherUserProfile,
  clearUserId,
  contentClose,
  isServer,
  muteAudio,
  unMuteAudio,
  changeAudioLevel,
  enableFooterAds,
  disableFooterAds
} from "../../../store";

const mapStateToProps = (state) => ({
  activeContent: state.content.activeContent,
  commentBox: state.ui.commentBox,
  modal: state.ui.modal.more_option || false,
  loadFromGrid: state.content.loadFromGrid,
  isLoggedIn: state.auth.isLoggedIn,
  contentAlreadyVisited: state.content.contentAlreadyVisited,
  loadFromSession: state.content.loadFromSession,
  user: state.auth.data,
  isServer,
  audioMuted: state.content.audioMuted,
  audioLevel: state.content.audioLevel,
});

const mapDispatchToProps = (dispatch) => ({
  showCommentBox: () => dispatch(showCommentBox()),
  hideCommentBox: () => dispatch(hideCommentBox()),
  loadShareLink: () => dispatch(loadShareLink()),
  stopScrollTimer: () => dispatch(stopScrollTimer()),
  startScrollTimer: () => dispatch(startScrollTimer()),
  downloadFile: () => dispatch(downloadFile()),
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  setShareType: (type) => dispatch(setShareType(type)),
  setContentUserAction: (userAction) =>
    dispatch(setContentUserAction(userAction)),
  setErrorMessage: (message) => dispatch(setErrorMessage(message)),
  showSelectedText: (text) => dispatch(showSelectedText(text)),
  setAuthUserId: (id) => dispatch(setAuthUserID(id)),
  loadOtherUserProfile: () => dispatch(loadOtherUserProfile()),
  clearUserId: () => dispatch(clearUserId()),
  contentClose: () => dispatch(contentClose()),
  muteAudio: () => dispatch(muteAudio()),
  unMuteAudio: () => dispatch(unMuteAudio()),
  changeAudioLevel: (value) => dispatch(changeAudioLevel(value)),
  enableFooterAds: () => dispatch(enableFooterAds()),
  disableFooterAds: () => dispatch(disableFooterAds())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentDetailFooter)
);
