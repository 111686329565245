import { UPLOAD } from "../../actions";

const storyUploadPercentageReducer = (state = -1, action) => {
    switch (action.type) {
        case UPLOAD.CLEAR:
        case UPLOAD.RESET_STORY_UPLOAD_PERCENTAGE:
            return -1;
        case UPLOAD.SET_STORY_UPLOAD_PERCENTAGE: {
            return action.storyUploadPercentage;
        }
        default:
            return state;
    }
};

export default storyUploadPercentageReducer;
