import React from "react";
import "./download.scss";

const Download = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 17 19"
    style={{ enableBackground: "new 0 0 17 19" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Tymoff-website" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Post-actions" transform="translate(-828.000000, -298.000000)" fill="#6C7B8A" fill-rule="nonzero">
        <g id="Group" transform="translate(811.000000, 132.000000)">
          <g transform="translate(17.000000, 166.000000)" id="download">
            <path
              d="M8.03902667,15.1075333 C8.04835778,15.1176667 8.05988444,15.1221 8.06976444,15.1309667 C8.08842667,15.1474333 8.10654,15.1651667 8.12849556,15.1753 C8.16197778,15.1917667 8.19765556,15.2 8.23333333,15.2 C8.26901111,15.2 8.30468889,15.1917667 8.33817111,15.1753 C8.37220222,15.1588333 8.40294,15.1347667 8.42873778,15.105 L12.8176533,10.0408667 C12.9246867,9.91736667 12.9246867,9.7166 12.8176533,9.5931 C12.71062,9.4696 12.5366222,9.4696 12.4295889,9.5931 L8.50777778,14.1182667 L8.50777778,0.316666667 C8.50777778,0.141866667 8.38482667,0 8.23333333,0 C8.08184,0 7.95888889,0.141866667 7.95888889,0.316666667 L7.95888889,14.1195333 L4.03598,9.5931 C3.92894667,9.4696 3.75494889,9.4696 3.64791556,9.5931 C3.54088222,9.7166 3.54088222,9.91736667 3.64791556,10.0408667 L8.03902667,15.1075333 Z M15.9177778,13.9333333 C15.7662844,13.9333333 15.6433333,14.0752 15.6433333,14.25 L15.6433333,18.3666667 L0.823333333,18.3666667 L0.823333333,14.25 C0.823333333,14.0752 0.700382222,13.9333333 0.548888889,13.9333333 C0.397395556,13.9333333 0.274444444,14.0752 0.274444444,14.25 L0.274444444,18.6833333 C0.274444444,18.8581333 0.397395556,19 0.548888889,19 L15.9177778,19 C16.0692711,19 16.1922222,18.8581333 16.1922222,18.6833333 L16.1922222,14.25 C16.1922222,14.0752 16.0692711,13.9333333 15.9177778,13.9333333 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Download;
