import { AUTH } from "../../actions";

const searchUserReducer = (state = [], action) => {
  switch (action.type) {
    case AUTH.SEARCH_USER:
      return action.data;
    case AUTH.SET_SEARCH_USER:
      return action.data;
    default:
      return state;
  }
};

export default searchUserReducer;
