import { createSelector } from "reselect";
import { UploadTab } from "../../constant";

const getImageMatureContent = state => state.upload.imageMatureContent;
const getVideoMatureContent = state => state.upload.videoMatureContent;
const getStoryMatureContent = state => state.upload.storyMatureContent;
const getWeblinkMatureContent = state => state.upload.weblinkMatureContent;
const getActiveTab = state => state.upload.activeTab;

const getUploadMatureContent = createSelector(
    [
        getImageMatureContent,
        getVideoMatureContent,
        getStoryMatureContent,
        getWeblinkMatureContent,
        getActiveTab
    ],
    (imageMatureContent, videoMatureContent, storyMatureContent, weblinkMatureContent, activeTab) => {
        if (activeTab === UploadTab.IMAGE) return imageMatureContent;
        else if (activeTab === UploadTab.VIDEO) return videoMatureContent;
        else if (activeTab === UploadTab.STORY) return storyMatureContent;
        else if (activeTab === UploadTab.WEB) return weblinkMatureContent;
        else return false;
    }
);

export default getUploadMatureContent;
