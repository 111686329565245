import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as FollowersModal } from "./followers-modal";
import {
  openModal,
  closeModal,
  setSelectedUserID,
  updateProfile,
  loadFollowers,
  loadFollowing,
  followUser,
  unFollowUser,
  setAuthUserID,
  loadOtherUserProfile,
  clearUserId,
  clearContent,
  resetContentPage,
  setContentAction,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.followers || false,
  error: state.auth.error,
  isLoading: state.auth.isLoading,
  following: state.auth.following,
  followers: state.auth.followers,
  searchUsers: state.auth.searchUsers,
  searchHint: state.search.searchHint,
  loginUser: state.auth.data,
  isLoggedIn: state.auth.isLoggedIn,
});
const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  updateProfile: () => dispatch(updateProfile()),
  loadFollowers: () => dispatch(loadFollowers()),
  loadFollowing: () => dispatch(loadFollowing()),
  setSelectedUserID: (userId) => dispatch(setSelectedUserID(userId)),
  followUser: () => dispatch(followUser()),
  unFollowUser: () => dispatch(unFollowUser()),
  setAuthUserId: (id) => dispatch(setAuthUserID(id)),
  loadOtherUserProfile: () => dispatch(loadOtherUserProfile()),
  clearUserId: () => dispatch(clearUserId()),
  clearContent: () => dispatch(clearContent()),
  resetContentPage: () => dispatch(resetContentPage()),
  setContentAction: (action) => dispatch(setContentAction(action)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FollowersModal)
);
