import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as CloseMemeDialog } from "./closeMemeDialog";
import { openModal, closeModal, unFollowUser } from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.close_meme_dialog || false,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CloseMemeDialog)
);
