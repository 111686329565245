import React from "react";
import "./logout.scss";

const Logout = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 16 19"
    style={{ enableBackground: "new 0 0 16 19" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Tymoff-website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-1156.000000, -110.000000)" fill="#6C7B8A" fillRule="nonzero">
        <g id="Group-3" transform="translate(50.000000, 10.000000)">
          <g id="Group" transform="translate(1089.000000, 43.000000)">
            <path
              d="M28.7166667,69.0333333 C28.5418667,69.0333333 28.4,69.1752 28.4,69.35 L28.4,74.7333333 C28.4,75.0823 28.1162667,75.3666667 27.7666667,75.3666667 L18.2666667,75.3666667 C17.9170667,75.3666667 17.6333333,75.0823 17.6333333,74.7333333 L17.6333333,58.2666667 C17.6333333,57.9177 17.9170667,57.6333333 18.2666667,57.6333333 L27.7666667,57.6333333 C28.1162667,57.6333333 28.4,57.9177 28.4,58.2666667 L28.4,63.65 C28.4,63.8248 28.5418667,63.9666667 28.7166667,63.9666667 C28.8914667,63.9666667 29.0333333,63.8248 29.0333333,63.65 L29.0333333,58.2666667 C29.0333333,57.5681 28.4652333,57 27.7666667,57 L18.2666667,57 C17.5681,57 17,57.5681 17,58.2666667 L17,74.7333333 C17,75.4319 17.5681,76 18.2666667,76 L27.7666667,76 C28.4652333,76 29.0333333,75.4319 29.0333333,74.7333333 L29.0333333,69.35 C29.0333333,69.1752 28.8914667,69.0333333 28.7166667,69.0333333 Z M33.3621667,66.2644 L30.1955,63.4144 C30.1030333,63.3308 29.9694,63.3105333 29.8547667,63.3605667 C29.7401333,63.4118667 29.6666667,63.5252333 29.6666667,63.65 L29.6666667,64.6 L24.9166667,64.6 C24.7418667,64.6 24.6,64.7418667 24.6,64.9166667 L24.6,68.0833333 C24.6,68.2581333 24.7418667,68.4 24.9166667,68.4 L29.6666667,68.4 L29.6666667,69.35 C29.6666667,69.4754 29.7401333,69.5887667 29.8547667,69.6394333 C29.8959333,69.6578 29.9396333,69.6666667 29.9833333,69.6666667 C30.0599667,69.6666667 30.1359667,69.6388 30.1955,69.5856 L33.3621667,66.7356 C33.4280333,66.6754333 33.4666667,66.5899333 33.4666667,66.5 C33.4666667,66.4100667 33.4280333,66.3245667 33.3621667,66.2644 Z M30.3,68.6387667 L30.3,68.0833333 C30.3,67.9085333 30.1581333,67.7666667 29.9833333,67.7666667 L25.2333333,67.7666667 L25.2333333,65.2333333 L29.9833333,65.2333333 C30.1581333,65.2333333 30.3,65.0914667 30.3,64.9166667 L30.3,64.3612333 L32.6769,66.5 L30.3,68.6387667 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Logout;
