import React, { PureComponent } from "react";
import { Dialog, DialogActions, DialogTitle, Slide, Button } from "@material-ui/core";
import { ModalType } from "../../../../constant";
import "./scroll-timer-modal.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ScrollTimerModal extends PureComponent {
  constructor(props) {
    super(props);
    const { contentScrollTime } = this.props;
    this.state = {
      scrollTime: contentScrollTime,
      prevScrollTime: contentScrollTime,
      formValid: false
    };
  }
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.SCROLL_TIMER);
  };

  handleSuccess = () => {
    const { changeContentScrollTime } = this.props;
    const { scrollTime } = this.state;
    this.handleClose();
    changeContentScrollTime(scrollTime);
  };

  handleChange = (e) => {
    this.setState({ scrollTime: parseInt(e.target.value) });

    setTimeout(() => {
      this.setState({ formValid: this.validateForm() });
    }, 100);

  }

  validateForm = () => {

    if (this.state.prevScrollTime === this.state.scrollTime) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { modal,t } = this.props;
    const { scrollTime } = this.state;
    return (
      <Dialog
        open={modal}
        className="dialog-modal content-scroll-modal"
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t("editProfile.title1")}</DialogTitle>
        <div className="modal-body">
          <p>
            <select value={scrollTime} onChange={e => this.handleChange(e)}>
              <option value={0}>0</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={35}>35</option>
              <option value={40}>40</option>
              <option value={45}>45</option>
              <option value={50}>50</option>
              <option value={55}>55</option>
              <option value={60}>60</option>
            </select>
            &nbsp; {t("formOptions.label5")}
          </p>
        </div>
        <div className="modal-footer">
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t("clearHistoryAlert.cancelText")}
            </Button>
            <Button onClick={this.handleSuccess} disabled={!this.state.formValid} color="primary">
              {t("report.label7")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}
export default compose(withTranslation())(ScrollTimerModal);