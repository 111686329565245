import React, { Component, Fragment } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { FormLabel, Input, Checkbox } from "@material-ui/core";

class FcSelect extends Component {
  handleChange = (e) => {
    const {
      form: { setFieldValue },
      field: { name },
      onChangeSelect,
      multiple,
    } = this.props;
    setFieldValue(name, e.target.value);
    if (onChangeSelect) {
      onChangeSelect(e.target.value);
    }
  };
  render() {
    const { field, source, optionValue, optionLabel, label, multiple, placeholder } = this.props;
    const result = [];
    const mapLabel = (selected) => {
      const results = [];
      source.forEach((s) => {
        if (Array.isArray(selected)) {
          if (selected.includes(s[optionValue])) {
            results.push(s[optionLabel]);
          }
        } else {
          if (selected === s[optionValue]) {
            results.push(s[optionLabel]);
          }
        }
      });
      return results.join(", ");
    };

    const removeSelected = (val) => {
      const selectedIndex = field.value.findIndex((v) => v === val);

      if (selectedIndex >= 1) {
        field.value.splice(selectedIndex, 1);
      } else {
        field.value = [];
      }

      this.forceUpdate();
    };

    const renderSelected = () => {
      const selectedLabel = source.filter((s) => field.value.includes(s[optionValue]));
      return selectedLabel.map((res) => (
        <Fragment key={res[optionValue]}>
          <label>{res[optionLabel]} </label>
          <label onClick={() => removeSelected(res[optionValue])}>X</label>
        </Fragment>
      ));
    };
    return (
      <Fragment>
        <div>{label}</div>
        <FormControl variant="outlined" className="">
          {/* <FormLabel component="legend">{label}</FormLabel> */}
          {/* <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel> */}
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name={field.name}
            value={field.value}
            onChange={this.handleChange}
            multiple={multiple}
            renderValue={(selected) => mapLabel(selected)}
          >
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
            {source.map((res, index) => (
              <MenuItem key={`${index}`} value={res[optionValue]}>
                {res[optionLabel]}
                {multiple ? <Checkbox checked={field.value.indexOf(res[optionValue]) > -1} /> : null}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {multiple ? renderSelected() : null}
      </Fragment>
    );
  }
}

export default FcSelect;
