import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as CardFooter } from "./card-footer";
import {
  openModal,
  setActiveContentIndex,
  setActiveContent,
  setShareType,
  setInitialSlide,
  setAlertAction,
  contentOpen,
  showSelectedText,
  setContentUserAction,
  showCommentBox,
  closeModal,
  setAuthUserID,
  loadOtherUserProfile,
  clearUserId,
  clearContent,
  resetContentPage,
  setContentAction,
  cancelUserLoadContent,
  cancelLoadContent,
  setOtherUser
} from "../../../store";

const mapStateToProps = (state) => ({
  contentAction: state.content.contentAction,
  loadFromSession: state.content.loadFromSession,
  activeContent: state.content.activeContent,
  isLoggedIn: state.auth.isLoggedIn,
  genres: state.master.genres,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  setActiveContentIndex: (index) => dispatch(setActiveContentIndex(index)),
  setInitialSlide: (initialSlide) => dispatch(setInitialSlide(initialSlide)),
  setActiveContent: (content) => dispatch(setActiveContent(content)),
  setShareType: (type) => dispatch(setShareType(type)),
  setAlertAction: (alert) => dispatch(setAlertAction(alert)),
  contentOpen: () => dispatch(contentOpen()),
  showSelectedText: (text) => dispatch(showSelectedText(text)),
  setContentUserAction: (userAction) => dispatch(setContentUserAction(userAction)),
  showCommentBox: () => dispatch(showCommentBox()),
  setAuthUserId: (id) => dispatch(setAuthUserID(id)),
  loadOtherUserProfile: () => dispatch(loadOtherUserProfile()),
  clearUserId: () => dispatch(clearUserId()),
  clearContent: () => dispatch(clearContent()),
  resetContentPage: () => dispatch(resetContentPage()),
  setContentAction: (action) => dispatch(setContentAction(action)),
  cancelUserLoadContent: () => dispatch(cancelUserLoadContent()),
  cancelLoadContent: () => dispatch(cancelLoadContent()),
  setOtherUser: (userId) => dispatch(setOtherUser(userId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardFooter));
