import { AUTH } from "../../actions";

const selectedUserIdReducer = (state = null, action) => {
    switch (action.type) {
        case AUTH.CLEAR:
            return null;
        case AUTH.SELECTED_USER_ID:
            return action.id;
        default:
            return state;
    }
};

export default selectedUserIdReducer;
