const styles = (theme) => ({
  search: {
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    backgroundSize: 18,
    backgroundPosition: "left center",
    backgroundRepeat: "no-repeat",
    paddingLeft: 45,
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.white, 0.25)
    // },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "33%",
      position: "relative",
    },
  },
  searchIcon: {
    // width: theme.spacing(7),
    // height: "100%",
    // position: "absolute",
    // pointerEvents: "none",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",

    // height: "100%",
    // position: "absolute",
    // margin: "-12px 0 0 -35px",
    // pointerEvents: "none",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",

    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      top: 20,
      left: 18,
      color: "var(--primary-color)",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
});

export { styles };
