import React, { Component, Fragment } from "react"
import { TextField } from "@material-ui/core"

class FcText extends Component {
  handleChange = (e) => {
    const {
      form: { setFieldValue },
      field: { name },
      onChangeText,
    } = this.props
    setFieldValue(name, e.target.value)
    if (onChangeText) {
      onChangeText(e.target.value)
    }
  }

  render() {
    const { field, label, multiline, rows, rowsMax, disabled } = this.props
    return (
      <Fragment>
        <div>{label}</div>
        <TextField
          name={field.name}
          value={field.value}
          variant="outlined"
          onChange={this.handleChange}
          multiline={multiline}
          rows={rows}
          rowsMax={rowsMax}
          disabled={disabled}
        />
      </Fragment>
    )
  }
}

export default FcText
