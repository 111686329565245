const SuccessMessage = {
  HISTORY_CLEARED: "messages.HISTORY_CLEARED",
  PROFILE_IMAGE_UPDATED: "messages.PROFILE_IMAGE_UPDATED",
  RESTRICTED_MODE_ENABLE: "messages.RESTRICTED_MODE_ENABLE",
  RESTRICTED_MODE_DISABLE: "messages.RESTRICTED_MODE_DISABLE",
  FEEDBACK_SUBMITTED: "messages.FEEDBACK_SUBMITTED",
  DATA_UPLOADED: "messages.DATA_UPLOADED",
  PROFILE_UPDATED: "messages.PROFILE_UPDATED",
  REMOVE_PROFILE_IMAGE: "messages.REMOVE_PROFILE_IMAGE",
  DELETE_POST: "messages.DELETE_POST",
  REPORT_POST: "messages.REPORT_POST",
  HIDE_POST: "messages.HIDE_POST",
};

const ErrorMessage = {
  API_RESPONSE_ERROR: "Something went wrong.",
};

const CopyLinkMessage = {
  COPY_LINK: "Link copied to clipboard.",
};

const SelectedText = {
  UPLOAD_TEXT: "messages.UPLOAD_TEXT",
  LIKE_TEXT: "messages.LIKE_TEXT",
  COMMENT_TEXT: "messages.COMMENT_TEXT",
  DOWNLOAD_TEXT: "messages.DOWNLOAD_TEXT",
  DISCOVER_TEXT: "messages.DISCOVER_TEXT",
  HIDE_POST_TEXT: "messages.HIDE_POST_TEXT",
  REPORT_POST_TEXT: "messages.REPORT_POST_TEXT",
  BOOKMARK_TEXT: "messages.BOOKMARK_TEXT",
  FOLLOW_TEXT: "messages.FOLLOW_TEXT"
};

export { SuccessMessage, ErrorMessage, CopyLinkMessage, SelectedText };
