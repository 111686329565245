import { connect } from "react-redux";
import { default as Upload } from "./upload";
import {
  setActiveTab,
  validateUpload,
  publishContent,
  addMoreContent,
  resetAddMoreContent,
  setUploadStep,
  closeModal,
  clearUploadContent,
  clearUploadGenre,
  clearUploadLanguage,
  clearUploadingFiles,
  moreSelected,
  areUnsavedUploadChanges,
  setAlertAction,
  clearUpload,
  resetImageUploadPercentage,
  resetVideoUploadPercentage,
  resetStoryUploadPercentage,
  resetLinkUploadPercentage,
  cancelUploadContent,
} from "../../store";

const mapStateToProps = (state) => ({
  activeTab: state.upload.activeTab,
  previewContents: state.upload.contentPreview,
  isLoading: state.upload.isLoading,
  error: state.upload.error,
  unsavedUploadChange: areUnsavedUploadChanges(state),
  contents: state.upload.contentPreview,
  videoUploadParcentage: state.upload.videoUploadParcentage,
  imageUploadPercentage: state.upload.imageUploadPercentage,
  weblinkUploadPercentage: state.upload.weblinkUploadPercentage,
  storyUploadPercentage: state.upload.storyUploadPercentage,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
  validateUpload: () => dispatch(validateUpload()),
  publishContent: () => dispatch(publishContent()),
  addMoreContent: () => dispatch(addMoreContent()),
  resetAddMoreContent: () => dispatch(resetAddMoreContent()),
  closeModal: (modal) => dispatch(closeModal(modal)),
  clearUploadGenre: () => dispatch(clearUploadGenre()),
  clearUploadLanguage: () => dispatch(clearUploadLanguage()),
  moreSelected: () => dispatch(moreSelected()),
  setAlertAction: (alert) => dispatch(setAlertAction(alert)),
  clearUpload: () => dispatch(clearUpload()),
  resetImageUploadPercentage: () => dispatch(resetImageUploadPercentage()),
  resetVideoUploadPercentage: () => dispatch(resetVideoUploadPercentage()),
  resetStoryUploadPercentage: () => dispatch(resetStoryUploadPercentage()),
  resetLinkUploadPercentage: () => dispatch(resetLinkUploadPercentage()),
  clearUploadingFiles: () => dispatch(clearUploadingFiles()),
  cancelUploadContent: () => dispatch(cancelUploadContent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
