import React, { Component, Fragment } from "react";
import { ContentTypeId } from "../../../constant";
import Image from "./image";
import Video from "./video";
import Weblink from "./weblink";
import Story from "./story";
// import feelingMusic from "../../../assets/images/feeling1.mp3";
// import feelingMusic1 from "../../../assets/images/feeling.mp3";
export default class ContentDetail extends Component {
  render() {
    const {
      activeContent,
      content,
      contentId,
      loadFromSession,
      isCawl,
      adsIndex
    } = this.props;

    return (
      <Fragment>
        {!loadFromSession &&
          activeContent.id === contentId &&
          activeContent.typeId === ContentTypeId.Videos && <Video adsIndex={adsIndex} />}
        {(loadFromSession || content.typeId === ContentTypeId.Images) && (
          <Image activeContent={content} adsIndex={adsIndex} />
        )}
        {!loadFromSession && content.typeId === ContentTypeId.Text && (
          <Story activeContent={content} />
        )}
        {!loadFromSession &&
          activeContent.id === contentId &&
          content.typeId === ContentTypeId.Weblink && (
            <Weblink activeContent={content} />
          )}
      </Fragment>
    );
  }
}
