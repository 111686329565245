import React, { PureComponent, Fragment } from "react";
import { indexOf } from "lodash";
import { ModalAction, ModalType, UploadStep } from "../../../constant";
import "./language-selector.scss";
import footerLine from "../../../assets/images/pbar2.png";
import { withNamespaces, withTranslation } from "react-i18next";
import {compose} from "redux";
import { withRouter } from "react-router";

class ChLanguageSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      prevSelectedSiteLanguage: this.props.selectedSiteLanguage,
      formValid: false,
    };
  }

  onLanguageChange = (id) => {
    const { setFilterLanguage, setUploadLanguage, modalAction, setSiteLanguage } = this.props;
    modalAction === ModalAction.FILTER && setSiteLanguage(id);
    modalAction === ModalAction.UPLOAD && setUploadLanguage(id);
    setTimeout(() => {
      this.setState({ formValid: this.validateForm() });
    }, 100);
  };

  compare = (arr1, arr2) => {
    const sorted1 = arr1.length ? arr1.sort() : arr1;
    const sorted2 = arr2.length ? arr2.sort() : arr2;
    if (JSON.stringify(sorted1) == JSON.stringify(sorted2)) {
      return true;
    } else {
      return false;
    }
  };
  validateForm = () => {
    if (this.state.prevSelectedSiteLanguage === this.props.selectedSiteLanguage) {
      return false;
    } else {
      return true;
    }
  };

  setLanguage = () => {
    const { t, i18n } = this.props;
    i18n.changeLanguage("de");
    const {
      filter,
      setFilter,
      modalAction,
      selectedLanguages,
      selectedSiteLanguage,
      setSiteLanguage,
      validateUploadLanguage,
      closeModal,
    } = this.props;
    setSiteLanguage(selectedSiteLanguage);
    if (modalAction === ModalAction.FILTER) {
      //setFilter({ ...filter, languagesList: selectedLanguages });
      closeModal(ModalType.CHANGE_LANGUAGE);
    }
    //  modalAction === ModalAction.FILTER && setFilter({ ...filter, languagesList: selectedLanguages });
    // modalAction === ModalAction.UPLOAD && validateUploadLanguage();
  };

  render() {
    const {
      buttonText,
      languages,
      selectedLanguages,
      selectedSiteLanguage,
      error,
      setSearchLanguage,
      searchLanguage,
      activeStep,
      t,
      history,
    } = this.props;

    return (
      <div className="modalContent">
        <div className="modalHeader">
          <h1 id="transition-modal-title" className="heading-modal modalTitle">
            {t("uploadSection.title3")}
          </h1>
        </div>
        <div className="modalBody">
          {/* <div className="search-language">
            <input
              type="text"
              placeholder="Search"
              value={searchLanguage}
              onChange={(e) => setSearchLanguage(e.target.value)}
            />
          </div> */}
          <div className="language-container c-height">
          {history.location.pathname === "/settings" ? <h4>
              {t("preferenceModal.label1")} 
            </h4>:
            <h4>
              {t("uploadSection.title1")} <span>({t("uploadSection.title2")})</span>
            </h4>}
            {/* {!!!languages.length && (
              <div className="no-content">
                <p>Entered language is not in the list. Please select from the available option.</p>
              </div>
            )} */}
            {languages.map((language, index) => (
              <Fragment key={`${index}_language`}>
                <input
                  type="checkbox"
                  name="language_check"
                  checked={selectedSiteLanguage === language.id}
                  onChange={() => this.onLanguageChange(language.id)}
                  id={`${index}_languageInput`}
                />
                <label className="language-label col " htmlFor={`${index}_languageInput`}>
                  {language.nameUtf8}
                  <div className="tick"></div>
                </label>
              </Fragment>
            ))}
          </div>
        </div>
        <div className="modalFooter">
          <div className="w-50">
            <button
              className="btn submit form-control"
              disabled={!this.state.formValid && buttonText !== "Next"}
              onClick={this.setLanguage}
            >
              {buttonText}
            </button>
          </div>
          
          <span className="error">{error}</span>
          {activeStep === UploadStep.LANGUAGE && (
            <div className="pbar">
              <img src={footerLine} alt="" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default compose(withTranslation())(withRouter(ChLanguageSelector));
