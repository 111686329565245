import { PREFERENCE } from "../../actions";

const genderReducer = (state = "", action) => {
  switch (action.type) {
    case PREFERENCE.CLEAR:
      return "";
    case PREFERENCE.SET_GENDER:
      return action.gender;
    default:
      return state;
  }
};

export default genderReducer;