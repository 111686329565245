import React, { PureComponent, Fragment } from "react";
import { indexOf, remove } from "lodash";
import "./formats.scss";
import { Photo, Video, Story, LinkIcon } from "../../../../icons/filter-icon";
import IconButton from "@material-ui/core/IconButton";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

class Formats extends PureComponent {
  formats = [];
  componentDidMount() { }

  componentWillUnmount() {
    const { setFilter, filter, cancelLoadContent } = this.props;
    setFilter({ ...filter, formatsList: this.formats });
  }
  onFilterClick = (formatId, empty = false) => {
    const { setFilter, filter, cancelLoadContent } = this.props;
    const { formatsList } = filter;
    cancelLoadContent();

    let formats = [...formatsList];
    //let formats = [];
    if (empty) {
      formats = [];
    } else {
      if (indexOf(formatsList, formatId) === -1) {
        formats.push(formatId);
      } else {
        remove(formats, (el) => formatId === el);
      }
    }
    setFilter({ ...filter, formatsList: formats });
  };
  render() {
    const { formats, selectedFormats, filter, history, t } = this.props;
    return (
      <Fragment>
        {/* {history.location.pathname !== "/search" && (
          <label>
            All{" "}
            <span>
              {filter.formatsList.length > 1 || filter.formatsList.length == 0
                ? "posts"
                : formats.find((f) => f.id === filter.formatsList[0])?.name}
            </span>
          </label>
        )} */}
        {/* <div className="text-right"> */}
        <div>

          <label
            className={`spl-btn icon ${!this.props.selectedFormats.length ? "active" : ""
              }`}
            onClick={() => this.onFilterClick(null, true)} style={{ textAlign: "center" }}
          >
            {t("format.title")}
          </label>
          {formats.map((format, index) => {
            const isActive = !!this.props.selectedFormats.find(
              (elem) => elem === format.id
            );
            return (
              <IconButton
                key={format.id || index}
                onClick={() => this.onFilterClick(format.id)}
                className={`spl-btn icon ${isActive ? "active" : ""}`}
              >
                {format.name == "images" ? (
                  <Photo width="21px" height="16px"></Photo>
                ) : format.name == "stories" ? (
                  <Story width="16px" height="16px"></Story>
                ) : format.name == "videos" ? (
                  <Video width="16px" height="16px"></Video>
                ) : (
                  <LinkIcon width="16px" height="16px"></LinkIcon>
                )}
              </IconButton>
            );
          })}
          {/* <IconButton className="spl-btn icon">
          <Photo width="21px" height="16px"></Photo>
        </IconButton>
        <IconButton className="spl-btn icon">
          <Story width="16px" height="16px"></Story>
        </IconButton>
        <IconButton className="spl-btn icon">
          <Video width="16px" height="16px"></Video>
        </IconButton>
        <IconButton className="spl-btn icon">
          <Link width="16px" height="16px"></Link>
        </IconButton> */}
        </div>
      </Fragment>
    );
  }
}
export default compose(withTranslation())(withRouter(Formats));
