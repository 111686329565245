import React, { PureComponent } from "react";
import {
  List,
  ListItem,
  Dialog,
  DialogTitle,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Offline, Online } from "react-detect-offline";
import {
  ModalType,
  CopyLinkMessage,
  SelectedText,
  ContentUserAction,
  DeletePost,
  ContentAction,
} from "../../../../constant";
import "./content-more-options-detail.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import {
  Link,
  Hide,
  Report,
  Download,
  Bookmark,
  ReportPost,
  Share,
} from "../../../../icons";
import { ContentTypeId } from "../../../../constant";
import { getProfileImageUrl } from "../../../../core";
import { Delete } from "@material-ui/icons";

class ContentMoreOptionsDetail extends PureComponent {
  // state = {
  //   anchorEl: null,
  // };

  constructor(props) {
    super(props);
    const { loadFollowing } = this.props;
    loadFollowing();
    // const { anchorEl } = this.props;
    // this.setState({ anchorEl });
    // this.PopperRef = React.createRef();
  }
  handleClose = () => {
    const {
      closeModal,
      startScrollTimer,
      loadFromGrid,
      isOtherModalOpen,
      contentAlreadyVisited,
      commentBox,
    } = this.props;
    loadFromGrid &&
      !isOtherModalOpen &&
      !contentAlreadyVisited &&
      !commentBox &&
      startScrollTimer();
    closeModal(ModalType.MORE_OPTION_DETAIL);
  };

  handleCopyLink = () => {
    const { setMessage } = this.props;
    this.handleClose();
    setMessage(CopyLinkMessage.COPY_LINK);
  };

  handleReportContent = () => {
    const {
      openModal,
      isLoggedIn,
      setErrorMessage,
      showSelectedText,
      stopScrollTimer,
    } = this.props;
    this.handleClose();
    if (isLoggedIn) {
      const isMyPost = this.isMyPost();
      isMyPost
        ? setErrorMessage("You can't report your own content")
        : openModal(ModalType.REPORT_USER_DETAIL);
      stopScrollTimer();
    } else {
      openModal(ModalType.LOGIN);
      showSelectedText(SelectedText.REPORT_POST_TEXT);
    }
  };
  handleShareContent = () => {
    const {
      openModal,
      isLoggedIn,
      setErrorMessage,
      showSelectedText,
      loadShareLink,
    } = this.props;
    this.handleClose();
    if (isLoggedIn) {
      loadShareLink();
      openModal(ModalType.SHARE_POPPER_GRID);
    } else {
      openModal(ModalType.LOGIN);
      showSelectedText(SelectedText.REPORT_POST_TEXT);
    }
  };

  handleHideContent = () => {
    const {
      hideContent,
      isLoggedIn,
      openModal,
      commentBox,
      hideCommentBox,
      setErrorMessage,
      showSelectedText,
    } = this.props;
    if (isLoggedIn) {
      if (commentBox) hideCommentBox();
    }
    this.handleClose();
    if (isLoggedIn) {
      const isMyPost = this.isMyPost();
      isMyPost
        ? setErrorMessage("You can't hide your own content")
        : hideContent();
    } else {
      openModal(ModalType.LOGIN);
      showSelectedText(SelectedText.HIDE_POST_TEXT);
    }
  };

  handleNoInternet = () => {
    const { setErrorMessage } = this.props;
    this.handleClose();
    setErrorMessage("No internet connection");
  };

  isMyPost = () => {
    const { isLoggedIn, activeContent, user } = this.props;
    if (isLoggedIn) {
      const { userId } = activeContent,
        { id } = user;
      if (userId === id) return true;
      else return false;
    }
    return false;
  };

  handleDownloadedContent = () => {
    const {
      isLoggedIn,
      openModal,
      downloadFile,
      activeContent,
      loadFromSession,
      setErrorMessage,
      showSelectedText,
    } = this.props;
    if (!loadFromSession) {
      if (
        activeContent.typeId === ContentTypeId.Images ||
        activeContent.typeId === ContentTypeId.Videos
      ) {
        if (isLoggedIn) { downloadFile(); this.handleOnClose(); }
        else {
          this.handleOnClose();
          openModal(ModalType.LOGIN);
          showSelectedText(SelectedText.DOWNLOAD_TEXT);
        }
      } else {
        setErrorMessage("This content can't be downloaded");
      }
    }
  };
  handleLabelClick = (userId) => {
    const {
      history,
      setAuthUserId,
      loadOtherUserProfile,
      clearUserId,
      clearContent,
      resetContentPage,
      setContentAction,
      cancelUserLoadContent,
      cancelLoadContent,
    } = this.props;
    if (userId) {
      cancelLoadContent();
      cancelUserLoadContent();
      clearContent();
      resetContentPage();
      clearUserId();
      setAuthUserId(userId);
      setContentAction(ContentAction.upload);
      loadOtherUserProfile();
      history.push({
        pathname: `/account/${userId}`,
        state: { userId },
      });
    }
  };
  handleFollowUser = () => { };

  handleBookMark = (isBookmarked) => {
    const {
      setContentUserAction,
      isLoggedIn,
      openModal,
      loadFromSession,
      showSelectedText,
    } = this.props;
    if (!loadFromSession) {
      if (isLoggedIn)
        isBookmarked
          ? setContentUserAction(ContentUserAction.UNBOOKMARK)
          : setContentUserAction(ContentUserAction.BOOKMARK);
      else {
        openModal(ModalType.LOGIN);
        showSelectedText(SelectedText.BOOKMARK_TEXT);
        this.handleOnClose();
      }
    }
  };
  handleOnClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.MORE_OPTION_DETAIL);
  };

  handleDeleteContent = () => {
    this.handleClose();
    const { setAlertAction } = this.props;
    setAlertAction(DeletePost);
  };

  render() {
    const {
      anchorEl,
      open,
      activeContent,
      contentAction,
      following,
      loadFromGrid,
      t,
    } = this.props;
    const { userDetail } = activeContent;
    var myArr = [];
    let isCurrentUid = false;
    if (loadFromGrid) {
      var currentUser = [
        {
          id: userDetail.id,
          name: userDetail.name,
          picUrl: "",
          isFollowingLoggedUser: false,
          isMyfollower: false,
        },
      ];

      if (following.some((user) => user.id === userDetail.id)) {
        myArr = [...following];
      } else {
        isCurrentUid = !isCurrentUid;
        myArr = [...currentUser, ...following];
      }
    } else {
      myArr.concat(following);
    }
    return (
      // <Dialog
      //   open={modal}
      //   className="web-more-link"
      //   onClose={this.handleClose}
      //   aria-labelledby="more-options"
      //   style={{ zIndex: 1300 }}
      // >
      <Popper
        style={{ zIndex: 0, background: "#FFFFFF", borderRadius: "4px" }}
        open={open}
        anchorEl={anchorEl}
        placement={"top-end"}
        transition
      //keepMounted={false}
      //ref={this.PopperRef}
      >
        {/* <DialogTitle id="more-options">More Options</DialogTitle> */}

        <List className="contentOption">
          {activeContent.userName ? (
            <ListItem
              button
              className="download"
              onClick={() => this.handleLabelClick(activeContent.userId)}
            >
              <div className="oval">
                <img
                  className="oval"
                  src={getProfileImageUrl(
                    userDetail.picUrl,
                    userDetail.id,
                    userDetail.name
                  )}
                  onError={(e) => {
                    e.target.src = getProfileImageUrl(
                      "",
                      userDetail.id,
                      userDetail.name
                    );
                  }}
                />
              </div>
              {myArr.length
                ? myArr.map((user, i) => (
                  <div key={i} className="follow-item">
                    {user.id == userDetail.id && (
                      <div className="follow-btn">
                        {!user.isFollowingLoggedUser ? (
                          <span>  {userDetail.name}</span>
                        ) : (
                          <span> {t("userInfo.label3")} {userDetail.name}</span>
                        )}
                      </div>
                    )}
                  </div>
                ))
                : null}
            </ListItem>
          ) : null}

          {/* <ListItem button className="download" onClick={this.handleShareContent}>
            <Share width={"16px"} height={"16px"} className="share-content" />
            <span>Message</span>
          </ListItem> */}

          <ListItem
            button
            className="download"
            onClick={this.handleShareContent}
          >
            <Share width={"16px"} height={"16px"} className="share-content" />
            <span>{t("contentmore.label2")}</span>
          </ListItem>

          <ListItem
            button
            className="download"
            onClick={() => this.handleBookMark(activeContent.isBookMarked)}
          >
            <Bookmark
              height={"16px"}
              width={"16px"}
              className={`sidebar-icon ${activeContent.isBookMarked ? "bmActive" : ""
                }`}
            />
            <span>
              {!activeContent.isBookMarked ? t("contentmore.label3") : t("contentmore.label4")}
            </span>
          </ListItem>

          <ListItem
            button
            className="download"
            onClick={this.handleDownloadedContent}
          >
            <Download
              width={"16px"}
              height={"16px"}
              className="download-content"
            />
            <span>{t("contentmore.label5")}</span>
          </ListItem>

          {/* <CopyToClipboard text={shareLink} onCopy={this.handleCopyLink}>
            <ListItem button>
              <Link width={"16"} height={"20"} className="link" />
              <div className="link-text">
                <p>Copy Link</p>
                <span>Add this link to your post</span>
              </div>
            </ListItem>
          </CopyToClipboard> */}

          {/* <ListItem button onClick={this.handleHideContent}>
              <Hide width={"16"} height={"20"} className="hide" />
              <div className="link-text">
                <p>Hide Post</p>
                <span>Don't want to see this post</span>
              </div>
            </ListItem> */}
          <ListItem button onClick={this.handleReportContent}>
            <ReportPost width={"17"} height={"19"} className="report" />
            <div className="link-text report">
              <p>{t("contentmore.label6")}</p>
              {/* <span>This post is objectionable</span> */}
            </div>
          </ListItem>
          {contentAction === ContentAction.upload && (
            <ListItem button onClick={this.handleDeleteContent}>
              <Delete width={"17"} height={"19"} className="delete" />
              <div className="link-text report">
                <p>{t("deletePost.successText")}</p>
              </div>
            </ListItem>
          )}

          {/* <ListItem button onClick={this.handleNoInternet}>
              <Hide width={"16"} height={"20"} className="hide" />
              <div className="link-text">
                <p>Hide Post</p>
                <span>Don't want to see this post</span>
              </div>
            </ListItem> */}
          <ListItem
            button
            onClick={this.handleNoInternet}
            className="mobile-hidden"
          >
            <ReportPost width={"17"} height={"19"} className="report" />
            <div className="link-text report">
              <p>{t("contentmore.label7")}</p>
              <span>{t("contentmore.label8")}</span>
            </div>
          </ListItem>

        </List>
      </Popper>
    );
  }

  // componentWillUnmount() {
  //   const { closeModal } = this.props;
  //   this.PopperRef.current = null;
  //   closeModal(ModalType.MORE_OPTION);
  // }
}
export default compose(withTranslation())(ContentMoreOptionsDetail)