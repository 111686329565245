import React, { PureComponent } from "react";
import { get, isEmpty } from "lodash";
import { CircularProgress } from "@material-ui/core";
import { isSafari } from "react-device-detect";
import { URL } from "../../../../../api";
import { ModalType } from "../../../../../constant";
import "./load-qr.scss";
import qrIcon from "../../../../../assets/images/qr-scan.svg";
import { withTranslation } from "react-i18next";

class LoadQR extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { qrCode: "" };
    this.loadQRCode();
  }
  loadQRCode = () => {
    const { setAuth, history, isContentDetailOpen, closeModal, editUserInfo } = this.props;
    const source = new EventSource(URL.QRCODE_API());
    source.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (get(response, "data.token", "")) {
        const user = get(response, "data.user", {});
        const { name, email, phone, gender, age, address, countryId, languageId, isAdultContentHide, city, bio, theme, themeInfo, fullName, fullNameVisible, notificationEnable, locationEnable, messagesEnable } = user;
        editUserInfo({ name, email, phone, gender, age, address, countryId, languageId, isAdultContentHide, city, bio, theme, themeId: !isEmpty(themeInfo) ? themeInfo.id : 0, themeInfo, fullName, fullNameVisible, notificationEnable, locationEnable, messagesEnable });
        setAuth(user);
        closeModal(ModalType.LOGIN);
        if (!isContentDetailOpen) {
          history.push("/");
        }
      }
      if (get(response, "data.fileBase64String", "")) {
        const b64Data = get(response, "data.fileBase64String", "");
        if (isSafari) {
          const b64toBlob = require("b64-to-blob");
          const blob = b64toBlob(b64Data, "image/png");
          const blobUrl = window.URL.createObjectURL(blob);
          this.setState({
            qrCode: blobUrl,
          });
        } else
          this.setState({
            qrCode: `data:image/png;base64 ,${b64Data}`,
          });
      } else {
        this.setState({ qrCode: null });
      }
    };
  };
  render() {
    const { qrCode } = this.state;
    const { t } = this.props;
    return (
      <div className="qr">
        <h3 className="title">{t("login.qr.title")}</h3>
        <div className="scanQr">
          {qrCode ?
            (
              <img src={qrCode} width={200} height={200} alt="" />
            ) : (
              <CircularProgress color="secondary" style={{ marginLeft: -25 }} />
            )}
        </div>
        {/* <div className="noAccountlink">
          <img src={qrIcon} alt="" style={{ width: 35 }} /> */}
        {/* <img src={sendIcon} alt="sendIcon" class="send-icon" onClick={this.onCommentSubmit}/> */}

        {/* <img src={qrCode} alt="" style={{ width: 35 }} /> */}
        {/* <div style={{ paddingLeft: 10, textAlign: "left" }}>
            {t('login.qr.text')}</div> */}
        {/* </div> */}
      </div>
    );
  }
}

export default withTranslation()(LoadQR);
