const ContentTypeId = {
  Text: 1,
  Videos: 2,
  Weblink: 3,
  Images: 4,
};

const ModalType = {
  LOGIN: "login",
  SHARE: "share",
  SHARE_POPPER: "share_popper",
  SHARE_POPPER_GRID: "share_popper_grid",
  NOTIFICATION: "notification",
  UPLOAD_NOTIFICATION: "upload_notification",
  DARK_THEME: "dark_theme",
  COUNTRY: "country",
  LANGUAGE: "language",
  UPLOAD: "upload",
  MENU: "menu",
  COMMON: "common",
  SETTING: "setting",
  EDIT_PROFILE: "edit_profile",
  FOLLOWERS: "followers",
  FOLLOWINGS: "followings",
  ALERT: "alert",
  UNFOLLOW_ALERT: "unfollow_alert",
  CLOSE_MEME_DIALOG: "close_meme_dialog",
  FEEDBACK: "feedback",
  SCROLL_TIMER: "scroll_timer",
  MORE_OPTION: "more_option",
  MORE_OPTION_DETAIL: "more_option_detail",
  CONTENT_DETAIL: "content_detail",
  COPY_LINK: "copy_link",
  REPORT_CONTENT: "report_content",
  SHOW_MORE_DETAIL: "show_more_detail",
  PROFILE_IMAGE_OPTION: "profile_image_option",
  VIEW_PROFILE_IMAGE: "view_profile_image",
  MESSAGE_SNACKBAR: "message_snackbar",
  INSTALL_APP: "install_app",
  CAMERA: "camera",
  CHANGE_LANGUAGE: "change_language",
  PREFERENCES: "preferences",
  SEE_MORE: "see_more",
  REPORT_USER: "report_user",
  REPORT_USER_PROFILE: "report_user_profile",
  REPORT_USER_DETAIL: "report_user_detail",
  REPORT_OTHER: "report_other",
  REPORT_OTHER_PROFILE: "report_other_profile",
  USER_THEME: "user_theme",
  MATURE_CONTENT: "mature_content",
  UPLOAD_MATURE_CONTENT: "upload_mature_content",
  SOUND_SETTING: "sound_setting",
  BIO: "bio",
  VERIFICATION: "verification",
  USERSEARCH: "user_search",
  MEMEMODAL: "meme",
  MEMEMODALW: "w3meme",
  EDIT_AVATAR: "edit_avatar",
};

const ModalAction = {
  FILTER: "filter",
  LOGIN: "login",
  UPLOAD: "upload",
  EDIT_PROFILE: "edit_profile",
  VERIFICATION: "verification",
  USERSEARCH: "user_search",
  MEMEMODAL: "meme",
  MEMEMODALW: "w3meme",
  EDIT_AVATAR: "edit_avatar",
};

const AlertAction = {
  UPLOAD: "upload",
  CLEAR_HISTORY: "clear_history",
  LOGOUT: "logout",
  FEEDBACK_RESPONSE: "feedback_response",
  REMOVE_PROFILE_IMAGE: "remove_profile_image",
  Delete_Post: "delete_post",
};

const UploadTab = {
  IMAGE: "image",
  VIDEO: "video",
  STORY: "story",
  WEB: "web",
  MEME: "meme",
};
const PreferenceTab = {
  PROFILE: "profile",
  ACCOUNT: "account",
};

const UploadStep = {
  DATA: "upload_step",
  LANGUAGE: "language_step",
  GENRE: "genre_step",
};
const NavigationStep = {
  LIKE: "like",
  BOOKMARK: "bookmark",
  SETTINGS: "settings",
  CONTACT: "contact",
  NOTIFICATION: "notification",
};

const UploadModalRootClass = {
  upload_step: "upload-modal",
  language_step: "language-modal",
  genre_step: "genre-modal",
};

const SettingMenu = {
  PROFILE: "profile",
  DARK_THEME: "dark_theme",
  RESTRICTED_MODE: "restricted_mode",
  CONTENT_SCROLL_TIMER: "content_scroll_timer",
  FEEDBACK: "feedback",
  CLEAR_HISTORY: "clear_history",
  LOG_OUT: "log_out",
  CHANGE_LANGUAGE: "change_language",
  PREFERENCES: "preferences",
  NOTIFICATION: "notification",
};

const ContentAction = {
  "my-likes": "like",
  downloads: "download",
  upload: "upload",
  bookmark: "BOOKMARK",
  search: "search",
  home: "home",
};

const ContentUserAction = {
  LIKE: "like",
  UNLIKE: "unLike",
  DOWNLOAD: "download",
  BOOKMARK: "BOOKMARK",
  UNBOOKMARK: "UNBOOKMARK",
};

const ShareType = {
  CONTENT: "content",
  PROFILE_IMAGE: "profile_image",
};

const NotificationType = {
  CONTENT: "CONTENT_TRENDING",
  DISCOVER: "DISCOVER",
};

export {
  ContentTypeId,
  ModalType,
  ModalAction,
  UploadTab,
  UploadStep,
  UploadModalRootClass,
  AlertAction,
  SettingMenu,
  ContentAction,
  ContentUserAction,
  ShareType,
  NotificationType,
  PreferenceTab,
  NavigationStep,
};
