import React from "react";
import "./like.scss";

const Like = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    style={{ enableBackground: "new 0 0 18 18" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" strokeWidth=".7" fill="none" fillRule="evenodd">
      <g id="desktop-sidemenu" transform="translate(-121.000000, -72.000000)">
        <g id="heart-copy" transform="translate(121.000000, 72.000000)">
          <g id="heart" transform="translate(0.735507, 0.637500)">
            <path
              d="M7.68939397,15.159091 C7.62018943,15.159091 7.55098488,15.136984 7.49331443,15.09277 C7.18702023,14.8590673 0,9.30831349 0,4.26349434 C0,1.91257198 1.94029041,0 4.32528411,0 C5.64465596,0 6.8711143,0.586151518 7.68939397,1.58791478 C8.50767365,0.586151518 9.73413199,0 11.0535038,0 C13.4384975,0 15.3787879,1.91257198 15.3787879,4.26349434 C15.3787879,9.30831349 8.19176771,14.8590673 7.88547352,15.09277 C7.82780306,15.136984 7.75859852,15.159091 7.68939397,15.159091 Z"
              id="Path"
              stroke="#6B7B8B"
              fill="none"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Like;
