import React, { PureComponent, Fragment } from "react";
import { indexOf, remove } from "lodash";
import "./genre-selector.scss";
import footerLine from "../../../assets/images/pbar3.png";
import { ModalType, UploadTab } from "../../../constant";
import { withNamespaces, withTranslation } from "react-i18next";
import {compose} from "redux";
class GenreSelector extends PureComponent {
  onGenreChange = (id) => {
    const { setUploadGenre, selectedGenres } = this.props;
    (selectedGenres.length < 8 || indexOf(selectedGenres, id) !== -1) && setUploadGenre(id);
  };

  saveGenre = () => {
    const { closeModal, activeTab, selectedGenres, setImageUploadGenre, setVideoUploadGenre, setLinkUploadGenre, setStoryUploadGenre, clearUploadGenre } = this.props;
    if (activeTab === UploadTab.IMAGE) {
      setImageUploadGenre(selectedGenres);
    } else if (activeTab === UploadTab.VIDEO) {
      setVideoUploadGenre(selectedGenres);
    } else if (activeTab === UploadTab.WEB) {
      setLinkUploadGenre(selectedGenres);
    } else if (activeTab === UploadTab.STORY) {
      setStoryUploadGenre(selectedGenres);
    }
    clearUploadGenre();
    closeModal(ModalType.COMMON);
  };

  render() {
    const { genres, selectedGenres, error, isLoading, searchGenre, setSearchGenre,t } = this.props;
    return (
      <div className="modalContent">
        <div className="modalHeader">
          <h1 id="transition-modal-title" className="heading-modal modalTitle">
            {t("uploadSection.title4")}
          </h1>
        </div>
        <div className="modalBody">
          {/* <div className="search-language">
            <input
              type="text"
              placeholder="Search"
              value={searchGenre}
              onChange={(e) => setSearchGenre(e.target.value)}
            />
          </div> */}
          <div className="language-container c-height">
            <h4>{t("uploadSection.title6")} <span>({t("uploadSection.title2")})</span></h4>
            {!!!genres.length && (
              <div className="no-content">
                <p>{t("uploadSection.title5")}</p>
              </div>
            )}
            {genres.map((genre, index) => (
              <Fragment key={`${index}_genre`}>
                <input
                  type="checkbox"
                  name="genre_check"
                  checked={indexOf(selectedGenres, genre.id) !== -1}
                  onChange={() => this.onGenreChange(genre.id)}
                  id={`${index}_genreInput`}
                />
                <label className="language-label col " htmlFor={`${index}_genreInput`}>
                  {genre.name}
                  <div className="tick"></div>
                </label>
              </Fragment>
            ))}
          </div>
        </div>
        <div className="modalFooter">
          {!!genres.length && (
            <div className="w-50">
              <button className="btn submit form-control" disabled={isLoading} onClick={this.saveGenre}>
                {t("uploadSection.button")}
              </button>
            </div>
          )}
          <span className="error">{error}</span>
          <div className="pbar">
            <img src={footerLine} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
export default compose(withTranslation())(GenreSelector);