import { render } from "@testing-library/react";
import React, { Fragment, PureComponent } from "react";
import { isMobileOnly } from "react-device-detect";
import { withRouter } from "react-router";
import WindowOpener from "./instagram/WindowOpener";
import {
  Button,
  ButtonBase,
  ClickAwayListener,
  Grid,
  Popper,
} from "@material-ui/core";
//import { FontAwesome } from "@fortawesome/fontawesome-free";
import {
  http,
  headerOptions,
  fileHeaderOptions,
  metaHeaderOptions,
} from "../../core";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { URL } from "../../api/_urls";
import { SocialIcon } from "react-social-icons";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { LinkedIn } from "./linkedin";
import { LinkedInLoginButton } from "react-social-login-buttons";
import instagramSvg from "../../assets/images/insta.svg";
import InstagramLogin from "./instagram";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";

import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { isEmpty } from "lodash";
import { isServer, loadFollowers, loadFollowing } from "../../store";
import "./verify.scss";
import firebase from "firebase";
const FACEBOOK = "facebook";
const TWITTER = "twitter";
const INSTAGRAM = "instagram";
const LINKEDIN = "linkedin";
const STATUS = "Verifying";
const STATUS1 = "Submitted";
let socialHashMap = {};

class Verify extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      facebook: false,
      twitter: false,
      instagram: false,
      linkedin: false,
      verificationStatus: false,
      isSaveEnable: false,
      code: "",
      errorMessage: "",
      verified: false,
      showUrl: false,
    };
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
    this.myMap = new Map();
    this.myobj = { verificationData: [] };
  }
  codeData = (sType, code) => ({
    socialType: sType,
    token: code,
  });

  tokenData = (token, type) => ({
    verificationData: [
      {
        token,
        socialType: type,
      },
    ],
  });
  authHandlerFirebase = (provider, socialId) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((data) => {
        this.postTokenData(data.user._lat, socialId);
      });
  };
  postDataToServer = () => {
    const { token } = this.props;
    http
      .post(
        URL.VERIFICATION_API() + "?timestamp" + new Date().getTime(),
        this.convertMapToArray(),
        headerOptions(token)
      )
      .then((response) => {
        this.setState({ verificationStatus: true });
        console.log(response);
      });
  };
  convertMapToArray = () => {
    for (const [key, value] of this.myMap.entries()) {
      this.myobj.verificationData.push({ token: value, socialType: key });
    }
    console.log(this.myobj);
    return this.myobj;
  };
  handleBackClick = () => {
    const { history } = this.props;
    !!history.length ? history.goBack() : history.push("/");
  };
  postTokenData = (tokenData, sid) => {
    this.myMap.set(sid, tokenData);
    this.setState({ isSaveEnable: true });
    console.log(this.myMap);
    if (sid == FACEBOOK) {
      this.setState({ facebook: true });
    } else if (sid == TWITTER) {
      this.setState({ twitter: true });
    } else if (sid == LINKEDIN) {
      this.setState({ linkedin: true });
    } else if (sid == INSTAGRAM) {
      this.setState({ instagram: true });
    }
  };
  showUrlGrid = () => {
    this.setState({ showUrl: !this.state.showUrl });
  };
  componentDidMount() {
    http
      .get(URL.GET_VERIFICATION_API(), headerOptions(this.props.token))
      .then((response) => {
        console.log(response);
        if (response.data?.length && response.data[0].token) {
          console.log(response.data.length);
          this.setState({ verificationStatus: true });
        } else {
          this.setState({ verificationStatus: false });
        }
      });
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => console.log("success "));
  }
  postCodeToServer = (type, code) => {
    console.log(this.codeData(type, code));
    const { token } = this.props;
    http
      .post(
        URL.VERIFY_CODE_API(),
        this.codeData(type, code),
        headerOptions(token)
      )
      .then((response) => {
        console.log(response);
        this.postTokenData(response.data.access_token, type);
      });
  };
  handleLinkedinSuccess = (data) => {
    this.postCodeToServer(LINKEDIN, data.code);
    console.log(data.code);
  };
  handleInstagramSuccess = (data) => {
    this.postCodeToServer(INSTAGRAM, data.code);
    console.log(data.code);
  };
  handleFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };
  deleteRequest = () => {
    http
      .delete(URL.GET_VERIFICATION_API(), headerOptions(this.props.token))
      .then((response) => {
        if (response.success) {
          this.setState({ verificationStatus: false });
        }
      });
  };

  navigate = () => {
    console.log("navi");
  };

  render() {
    const {
      facebook,
      twitter,
      instagram,
      linkedin,
      verificationStatus,
      isSaveEnable,
      verified,
      showUrl,
    } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        {!isServer && (
          <Fragment>
            {" "}
            <div className="verify tymoff-container">
              <div
                className="sub-header text-left tab-hidden"
                style={{ marginLeft: -13 }}
              >
                <button
                  className="back-btn"
                  onClick={this.handleBackClick}
                ></button>
                <h1>{t("verify.h1")}</h1>
              </div>
              <Grid container className="m-0">
                {/* <Grid item xs={12} className="back-btn-mobile">
                  <button
                    className="back-btn btn2 tab-hidden desktop-hidden"
                    onClick={this.handleBackClick}
                  ></button>
                </Grid> */}
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <div>
                      {verified ? (
                        <h3
                          id="transition-modal-title"
                          className="modalTitle m-1"
                        >
                          {t("verify.title1")}
                        </h3>
                      ) : (
                        <h1
                          id="transition-modal-title"
                          className="heading modalTitle font-bold mobile-hidden"
                        >
                          {t("verify.title2")}
                        </h1>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <label id="transition-modal-title" className="gray gap">
                      {t("verify.label1")}
                    </label>
                  </Grid>
                  <div className="p10 user-verify">
                    <Grid container spacing={0}>
                      {verificationStatus ? (
                        <Grid item xs={12} className="social-right">
                          <FacebookIcon
                            size={50}
                            round
                            //onClick={this.handleFacebookLogin}
                          />
                          <InstapaperShareButton>
                            <img src={instagramSvg} className="instaSvg" />
                          </InstapaperShareButton>
                          <TwitterIcon
                            size={50}
                            round
                            //onClick={this.handleTwitterLogin}
                          />

                          <LinkedinIcon
                            size={50}
                            round
                            //onClick={this.handleLinkdinLogin}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} className="social-right">
                          <FacebookShareButton url="" quote="" className="">
                            <FacebookIcon
                              size={50}
                              round
                              onClick={() =>
                                this.authHandlerFirebase(
                                  this.facebookProvider,
                                  FACEBOOK
                                )
                              }
                            />
                          </FacebookShareButton>

                         {!isServer? <InstagramLogin
                            clientId="1325245911154811"
                            redirectUri={`${window.location.origin}/instagram`}
                            scope="user_profile,user_media"
                            state="34232423"
                            onFailure={this.handleFailure}
                            onSuccess={this.handleInstagramSuccess}
                            supportIE
                            redirectPath="/instagram"
                          /> :null}

                          <TwitterShareButton url="" title="" className="">
                            <TwitterIcon
                              size={50}
                              round
                              onClick={() =>
                                this.authHandlerFirebase(
                                  this.twitterProvider,
                                  TWITTER
                                )
                              }
                            />
                          </TwitterShareButton>

                         {!isServer ? <LinkedIn
                            clientId="77dnnqniu0jula"
                            redirectUri={`${window.location.origin}/linkedin`}
                            scope="r_emailaddress"
                            state="34232423"
                            onFailure={this.handleFailure}
                            onSuccess={this.handleLinkedinSuccess}
                            supportIE
                            redirectPath="/linkedin"
                          ></LinkedIn>:null}
                        </Grid>
                      )}
                    </Grid>
                    {verificationStatus ? (
                      <Grid container spacing={0} className="social-right">
                        <Grid item xs={12}>
                          {t("verify.label2")}
                        </Grid>
                      </Grid>
                    ) : null}

                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <ButtonBase
                          onClick={this.showUrlGrid}
                          className="url-btn"
                        >
                          <span>{t("verify.title3")}</span>
                        </ButtonBase>
                      </Grid>
                    </Grid>
                    {showUrl ? (
                      <div className="" style={{ marginBottom: 35 }}>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <label id="transition-modal-title" className="gap">
                              {t("verify.label3")}
                            </label>
                          </Grid>
                          <Grid item xs={12}>
                            <label
                              id="transition-modal-title"
                              className="gap gray"
                            >
                              {t("verify.label4")}
                            </label>
                            <input placeholder="Url" name="url" />
                            <label
                              id="transition-modal-title"
                              className="gap gray"
                            >
                              {t("verify.label5")}
                            </label>
                            <input type="text" placeholder="Url" name="url" />
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                  </div>

                  {/* <Grid container spacing={0}>
                <Grid item xs={12}>
                  {this.state.verified ? (
                    <div>
                      <SocialIcon url="https://facebook.com" />
                    </div>
                  ) : (
                    <div>
                      <Grid item xs={6}>
                        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                      </Grid>
                      <Grid item xs={6}>
                        <div></div>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <LinkedIn
                          clientId="81lx5we2omq9xh"
                          onFailure={this.handleFailure}
                          onSuccess={this.handleSuccess}
                          redirectUri="http://localhost:3000/linkedin"
                        >
                          <LinkedInLoginButton onClick={() => alert("Hello")} />
                        </LinkedIn>
                      </Grid> * /}
                    </div>
                  )}
                </Grid>
              </Grid> */}
                  <Grid container spacing={0}>
                    {" "}
                    <Grid item xs={12}>
                      {isSaveEnable ? (
                        <button
                          className={"submitButton"}
                          onClick={this.postDataToServer}
                        >
                          {t("uploadSection.button")}
                        </button>
                      ) : (
                        <button className={"submitButton"}>
                          {t("uploadSection.button")}
                        </button>
                      )}
                    </Grid>
                    {/* <Grid item xs={6}>
                      {isSaveEnable ? (
                        <button
                          className={"submitButton"}
                          onClick={this.deleteRequest}
                        >
                          Delete
                        </button>
                      ) : null}
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withRouter(compose(withTranslation())(Verify));

// {this.state.verificationStatus ? (
//
//                   ) : (
