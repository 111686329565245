import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as Story } from "./story";
import { openModal, closeModal } from "../../../../store";

const mapStateToProps = (state) => ({ contentList: state.content.data });

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Story));
