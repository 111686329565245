import React, { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as Routes from "./Routes";
import { DefaultPage } from "./../404-page";
import Preference from "../preference";
import Upload from "../upload";
import Bookmark from "../bookmark";
import { connect } from "react-redux";
import EditProfile from "../edit-profile";
import Followers from "../followers";
import Following from "../following";
import MobileNotification from "../mobile-notification";
import { LinkedInPopUp } from "./../verify/linkedin";
import Login from "./../verify/instagram/Login";
import Verify from "../verify";
// import Profile from "../profile"
import { InstagramPopUp } from "./../verify/instagram";
const RootRouter = (props) => {
  return (
    <Fragment>
      <Switch>
        <Route path="/settings" exact component={Routes.Setting} />
        <Route path="/notification" exact component={Routes.Notification} />
        <Route
          path="/discover-category"
          exact
          component={Routes.DiscoverCategory}
        />
        <Route path="/profile-image" exact component={Routes.ProfileImage} />
        <Route
          path="/account"
          exact
          key={"My_Account"}
          component={Routes.Account}
        />
        {/*  <Route path="/verify" component={Verify} /> */}
        <Route
          path="/verify"
          exact
          render={() =>
            props.isLoggedIn ? <Verify /> : <Redirect to={{ pathname: "/" }} />
          }
        />

        <Route
          path="/account/:id"
          key={"Other_User"}
          exact
          component={Routes.Account}
        />
        <Route
          path="/categories/:category?"
          exact
          component={Routes.Category}
        />
        <Route path="/content/:contentId" component={Routes.Content} />
        <Route path="/:ocr/content/:contentId" component={Routes.Content} />
        <Route path="/discover" component={Routes.Discover} />
        <Route path="/search" exact component={Routes.Search} />
        <Route path="/contact" exact component={Routes.Contact} />
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        <Route exact path="/instagram" component={InstagramPopUp} />
        <Route
          path="/terms-conditions"
          exact
          component={Routes.TermsCondition}
        />
        <Route path="/privacy-policy" exact component={Routes.PrivacyPolicy} />
        {/* <Route
          path="/preference"
          exact
          render={() => (props.isLoggedIn ? <Preference /> : <Redirect to={{ pathname: "/" }} />)}
        /> */}
        <Route path="/preference" component={Preference} />
        <Route
          path="/upload"
          exact
          render={() =>
            props.isLoggedIn ? <Upload /> : <Redirect to={{ pathname: "/" }} />
          }
        />
        <Route path="/meme" exact render={() => <Upload />} />
        {/* <Route path="/profile" exact component={Profile} /> */}
        <Route path="/rm" exact component={Routes.Session} />
        <Route path="/" exact component={Routes.Dashboard} />
        <Route path="/404" exact component={DefaultPage} />
        <Route path="/bookmark" exact component={Bookmark} />
        <Route path="/edit-profile" exact component={EditProfile} />
        <Route path="/followers/:id" exact component={Followers} />
        <Route path="/followings/:id" exact component={Following} />
        <Route path="/notifications" exact component={MobileNotification} />
        <Redirect to="/404" />
      </Switch>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(RootRouter);
