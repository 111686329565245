import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as Bookmark } from "./Bookmark";
import {
  clearFilter,
  setContentAction,
  clearContent,
  resetContentPage,
  cancelLoadContent,
  hideNewfeed,
} from "../../store";

const mapStateToProps = (state) => ({
  routeFromLightbox: state.content.routeFromLightbox,
  activeContent: state.content.activeContent,
  contentList: state.content.data,
  filter: state.content.filter || [],
  formats: state.master.formats,
});

const mapDispatchToProps = (dispatch) => ({
  clearContent: () => dispatch(clearContent()),
  resetContentPage: () => dispatch(resetContentPage()),
  setContentAction: (action) => dispatch(setContentAction(action)),
  cancelLoadContent: () => dispatch(cancelLoadContent()),
  hideNewfeed: () => dispatch(hideNewfeed()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bookmark));
