import React, { PureComponent, Fragment } from "react";
import { ButtonBase } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { UploadTab, ModalType } from "../../../constant";
import uploadImage from "../../../assets/images/output-onlinepngtools.png";
import "./file-selector.scss";
import AddIcon from "@material-ui/icons/Add";
import { isMobileOnly } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { isServer } from "../../../store";

class FileSelector extends PureComponent {
  allFileSize = 0;
  allVideoSize = 0;
  fileCounter = 0;
  baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  baseStyleMobile = {
    width: "10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  addValidFile = (file) => {
    const { activeTab, setUploadImage, setUploadVideo, history } = this.props;
    const reader = new FileReader();
    reader.onload = () => {
      const preview = reader.result;
      if (activeTab !== UploadTab.VIDEO) {
        try {
          localStorage.setItem("baseImage", JSON.stringify(preview));
        } catch (error) {
          console.log(error)
        }

      }

      activeTab === UploadTab.IMAGE
        ? setUploadImage({ file, preview })
        : setUploadVideo({ file, preview });
    };
    console.log(history)
    reader.readAsDataURL(file);
    if (history.location.pathname === "/meme") {
      setTimeout(() => {
        this.handleMemeModal(history.location.pathname, "")
      }, 200)

    }
  };
  handleMemeModal = (pathname, w3meme) => {
    const {
      openModal,
    } = this.props;
    if (pathname == "/meme") {
      if (w3meme == "w3meme") {
        openModal(ModalType.MEMEMODALW);
      } else {
        openModal(ModalType.MEMEMODAL);
      }
      //this.removeFile(0);
    }
  };
  handleImagesCounter = () => {
    const { images } = this.props;

    if (images.length > 0 && images.length <= 20) {
      this.allFileSize = images.reduce((a, b) => a + b.file.size, 0);
      this.fileCounter = images.length + 1;
    } else {
      return;
    }
  };
  handleMemeCounter = () => {
    const { images } = this.props;

    if (images.length > 0 && images.length <= 1) {
      this.fileCounter = images.length + 1;
    } else {
      return;
    }
  };
  handleVideosCounter = () => {
    const { videos } = this.props;

    if (videos.length > 0 && videos.length <= 5) {
      this.allVideoSize = videos.reduce((a, b) => a + b.file.size, 0);
      //this.fileCounter = videos.length + 1;
    } else {
      return;
    }
  };
  handleOnDrop = (acceptedFiles) => {
    const { activeTab, memepath, images, history } = this.props;
    const filesSize = acceptedFiles.reduce((a, b) => a + b.size, 0);
    if (activeTab === UploadTab.VIDEO) {
      acceptedFiles.forEach((file) => {
        this.allVideoSize += file.size;
        if (acceptedFiles.length === 0) {
          return;
        } else if (file.type === "video/mp4" && filesSize > 150000000) {
          alert("Video size should be less than 150 mb");
          return;
        } else if (!this.isFileExist(file)) {
          this.addValidFile(file);
        }
      });
    } else if (
      activeTab === UploadTab.IMAGE &&
      history.location.pathname === "/upload"
    ) {
      if (acceptedFiles.length > 20) {
        alert("You can select maximum 20 images");
        return;
      } else if (this.fileCounter > 20) {
        alert("You can select maximum 20 images");
        return;
      } else if (filesSize >= 100000000) {
        alert("Total selected images size should be less than 100 mb");
      } else {
        acceptedFiles.forEach((file) => {
          this.allFileSize += file.size;
          if (acceptedFiles.length === 0) {
            return;
          } else if (this.fileCounter > 20) {
            alert("You can select maximum 20 images");
            return;
          } else if (this.allFileSize > 100000000) {
            alert("Total selected images size should be less than 100 mb");
            return;
          } else if (
            !this.isFileExist(file) &&
            this.fileCounter < 20 &&
            this.allFileSize <= 100000000
          ) {
            //this.fileCounter += 1;
            this.addValidFile(file);
          } else {
            alert("File already opened");
          }
        });
      }
    } else if (history.location.pathname === "/meme") {
      if (acceptedFiles.length > 1) {
        alert("You can select maximum 1 images");
        return;
      } else if (images.length == 1) {
        alert("You can select maximum 1 images");
        return;
      } else {
        acceptedFiles.forEach((file) => {
          this.allFileSize += file.size;
          if (acceptedFiles.length === 0) {
            return;
          } else if (!this.isFileExist(file) && images.length < 2) {
            this.addValidFile(file);
          } else {
            alert("File already opened");
          }
        });
      }
    }
    /* if (acceptedFiles.length > 20) {
      alert("You can select maximum 20 images");
    } else {
      acceptedFiles.forEach((file) => {
        this.allFileSize += file.size;
        if (acceptedFiles.length === 0) {
          return;
        } else if (this.fileCounter > 20) {
          alert("You can select maximum 20 images");
          return;
        } else if (file.type === "video/mp4" && this.allFileSize > 150000000) {
          alert("Video size should be less than 150 mb");
          return;
        } else if (!file.type === "video/mp4" && this.allFileSize > 100000000) {
          alert("Total selected images size should be less than 100 mb");
          return;
        } else if (!this.isFileExist(file)) {
          this.fileCounter += 1;
          this.addValidFile(file);
        } else {
          alert("File already opened");
        }
      });
    } */
  };
  isFileExist = (file) => {
    const { images, videos, activeTab } = this.props;
    var i, len;
    var status = false;
    const files = activeTab === UploadTab.IMAGE ? images : videos;
    for (i = 0, len = files.length; i < len; i++) {
      if (
        files[i].file.name === file.name &&
        files[i].file.size === file.size &&
        files[i].file.lastModified === file.lastModified
      ) {
        status = true;
      }
    }
    return status;
  };
  render() {
    const { title, activeTab, history } = this.props;
    // console.log(history);
    if (!isServer) {
      if (
        activeTab === UploadTab.IMAGE &&
        history.location.pathname === "/upload"
      ) {
        this.handleImagesCounter();
      } else if (
        activeTab === UploadTab.IMAGE &&
        history.location.pathname === "/meme"
      ) {
        this.handleMemeCounter();
      } else if (activeTab === UploadTab.VIDEO) {
        this.handleVideosCounter();
      }
    }
    const format =
      activeTab === UploadTab.IMAGE
        ? "image/*"
        : "video/mp4,video/x-m4v,video/*";
    const splitTitle = title.split("Browse");
    return (
      <Fragment>
        {/* {!isMobileOnly ? (
          <Dropzone onDrop={this.handleOnDrop} accept={format} multiple={true}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div style={this.baseStyle} className="drag-and-drop upload-sec" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div>
                    <p className="upload-btn">
                      <ButtonBase>Browse</ButtonBase>
                      {splitTitle[1]}
                    </p>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        ) : ( */}
        <Dropzone onDrop={this.handleOnDrop} accept={format} multiple={true}>
          {({ getRootProps, getInputProps }) => (
            <section style={{ justifyContent: "center", display: "flex" }}>
              <div
                style={this.baseStyleMobile}
                className="drag-and-drop upload-sec"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div>
                  <p className="upload-btn">
                    <AddIcon />
                  </p>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        {/* )} */}
      </Fragment>
    );
  }
}

export default withRouter(FileSelector);
