import React, { PureComponent, Fragment } from "react";
import { indexOf, remove } from "lodash";
import { UploadTab, ModalType, UploadStep } from "../../../constant";
import "./language-selector.scss";
import footerLine from "../../../assets/images/pbar2.png";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18";
import { compose } from "redux";

class LanguageSelector extends PureComponent {
  onLanguageChange = (id) => {
    const { setUploadLanguage, selectedLanguages } = this.props;
    (selectedLanguages.length < 8 || indexOf(selectedLanguages, id) !== -1) && setUploadLanguage(id);
  };
  onSiteLanguageChange = (id) => {
    const { setUploadLanguage, selectedLanguages } = this.props;
    (selectedLanguages.length < 1 || indexOf(selectedLanguages, id) !== -1) && setUploadLanguage(id);
  };

  setSiteLanguages = () => {
    
    const { setSiteLanguage,closeModal,selectedLanguages,languages,languageCode,activeTab,setUploadImageLanguage} = this.props;
    const filterLanguages = languages.filter((l) => selectedLanguages.includes(l.id));
    const num_=(filterLanguages?.length? filterLanguages[0].languageCode:[])
    
    console.log(num_)
    setSiteLanguage(num_);
    i18n.changeLanguage(num_);
    if (activeTab === UploadTab.IMAGE) {
      setUploadImageLanguage(selectedLanguages);};
    closeModal(ModalType.COMMON);
  };
  setLanguage = () => {
    const {
      closeModal,
      activeTab,
      selectedLanguages,
      setUploadWeblinkLanguage,
      setUploadVideoLanguage,
      setUploadImageLanguage,
      setUploadStoryLanguage,
      clearUploadLanguage,
    } = this.props;
    if (activeTab === UploadTab.IMAGE) {
      setUploadImageLanguage(selectedLanguages);
    } else if (activeTab === UploadTab.VIDEO) {
      setUploadVideoLanguage(selectedLanguages);
    } else if (activeTab === UploadTab.WEB) {
      setUploadWeblinkLanguage(selectedLanguages);
    } else if (activeTab === UploadTab.STORY) {
      setUploadStoryLanguage(selectedLanguages);
    }
    clearUploadLanguage();
    closeModal(ModalType.COMMON);
  };

  render() {
    const {
      buttonText,
      languages,
      selectedLanguages,
      error,
      setSearchLanguage,
      searchLanguage,
      activeStep,
      t,
    } = this.props;
    return (
      <div className="modalContent">
        <div className="modalHeader">
          <h1 id="transition-modal-title" className="heading-modal modalTitle">
            {t("uploadSection.title3")} 
          </h1>
        </div>
        <div className="modalBody">
          {/* <div className="search-language">
            <input
              type="text"
              placeholder="Search"
              value={searchLanguage}
              onChange={(e) => setSearchLanguage(e.target.value)}
            />
          </div> */}
          <div className="language-container c-height">
          {window.location.pathname === "/settings" ? <h4>
              {t("preferenceModal.label1")} 
            </h4>:
            <h4>
              {t("uploadSection.title1")} <span>({t("uploadSection.title2")})</span>
            </h4>}
            {/* {!!!languages.length && (
              <div className="no-content">
                <p>Entered language is not in the list. Please select from the available option.</p>
              </div>
            )} */}
            {languages.map((language, index) => (
              <Fragment key={`${index}_language`}>
                {window.location.pathname === "/settings" ? 
                <input
                  type="checkbox"
                  name="language_check"
                  checked={indexOf(selectedLanguages, language.id) !== -1}
                  onChange={() => this.onSiteLanguageChange(language.id)}
                  id={`${index}_languageInput`}
                />:
                <input
                  type="checkbox"
                  name="language_check"
                  checked={indexOf(selectedLanguages, language.id) !== -1}
                  onChange={() => this.onLanguageChange(language.id)}
                  id={`${index}_languageInput`}
                />}
                <label className="language-label col " htmlFor={`${index}_languageInput`}>
                  {language.nameUtf8}
                  <div className="tick"></div>
                </label>
              </Fragment>
            ))}
          </div>
        </div>
        <div className="modalFooter">
          {console.log(selectedLanguages)}
          {!!languages.length && (
            <div className="w-50">
              {window.location.pathname === "/settings" ?
              <button
              className="btn submit form-control"
              // disabled={!this.state.formValid && buttonText !== "Next"}
              
              onClick={this.setSiteLanguages}
            >
              {t("uploadSection.button")}
            </button>:
              <button
                className="btn submit form-control"
                // disabled={!this.state.formValid && buttonText !== "Next"}
                
                onClick={this.setLanguage}
              >
                {t("uploadSection.button")}
              </button>}
            </div>
          )}
          <span className="error">{error}</span>
          {activeStep === UploadStep.LANGUAGE && (
            <div className="pbar">
              <img src={footerLine} alt="" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default compose(withTranslation())(LanguageSelector);