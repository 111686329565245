import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import "./404-page.scss";

class DefaultPage extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="home-tile tymoff-contact">
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h1>404</h1>
            </div>
            <h2>{t("notFoundPage.title1")}</h2>
            <p>
            {t("notFoundPage.title2")}<br /><br /> <Link to="/">{t("notFoundPage.title3")}</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
//export default compose(withTranslation())(PrivacyPolicy);
export default compose(withTranslation())(DefaultPage);
