import { CONTENT } from "../../actions";

const audioLevel = (state = 20, action) => {
  switch (action.type) {
    case CONTENT.CHANGE_AVOLUME:
      return action.value;
    default:
      return state;
  }
};

export default audioLevel;
