import React, { PureComponent, Fragment, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "@material-ui/core/Divider";
import { faUserCircle, faClock, faMoon, faEdit } from "@fortawesome/free-regular-svg-icons";
import { isMobileOnly } from "react-device-detect";
import { faBan, faEraser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import { Modal, Backdrop, Fade, Button, ClickAwayListener, Popper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { ModalType, SettingMenu, ClearHistoryAlert, LogoutAlert } from "../../../../constant";

import { setGlobalThemeVariable } from "../../../../core";
import { SignInUser, Play, Pause } from "../../../../icons";
import muteIcon from "../../../../assets/images/muteA.svg";
import unMuteIcon from "../../../../assets/images/unmute.svg";
import Grid from "@material-ui/core/Grid";
import "./sound-setting-modal.scss";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FcRadio, FcSelect, FcCustomSearch } from "../../fc";
import { Link } from "react-router-dom";
import i18n from "../../../../i18";
import axios from "axios";
import { apiSound, apiCategory } from "../../../../api";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
class SoundSettingModal extends PureComponent {
  constructor(props) {
    super(props);

    this.myPlayerRef = React.createRef();

    this.state = {
      content: true,
      loading: false,
      volume: this.props.audioLevel ? this.props.audioLevel : 0.01,
      value: 25,
      reverseValue: 8,
      checked: [],
      songsList: [],
      isplaying: true,
      activeId: 0,
      sounds: [],
      isLoading: true,
      mergeSounds: [],
      soundURL: "",
      soundDuration: 0,
      isSelected: false,
      saved: false,
      msg: false,
    };
  }
  checkAvailability = (arr, val) => {
    return arr.some(function (arrVal) {
      return val === arrVal.userSelected;
    });
  };
  handleToggle = (value) => () => {
    const index = this.state.mergeSounds.findIndex((i) => {
      return i.id === value.id;
    });
    const sounds = [...this.state.mergeSounds];
    if (sounds[index].userSelected) {
      sounds[index] = {
        ...sounds[index],
        userSelected: false,
      };
      this.setState({ saved: true });
    } else {
      sounds[index] = {
        ...sounds[index],
        userSelected: true,
      };
      this.setState({ saved: true });
    }

    console.log(sounds);
    this.setState({ mergeSounds: sounds });
  };
  handleClose = () => {
    const { closeModal } = this.props;
    const isUserSelected = this.checkAvailability(this.state.mergeSounds, true);
    console.log(isUserSelected);
    if (isUserSelected) {
      this.setState({ isSelected: true, saved: false, msg: false });
      this.pauseCurrent();
      closeModal(ModalType.SOUND_SETTING);
    } else {
      this.setState({ saved: false, isSelected: true, msg: false });
    }

    /* if (this.state.saved) {
    } else {
      const localApiSounds = window.localStorage.getItem("userSounds");
      const apiSounds = localApiSounds ? JSON.parse(localApiSounds) : [];
      this.setState({
        mergeSounds: apiSounds,
      });
    } */
    this.resetSounds();
    closeModal(ModalType.SOUND_SETTING);
  };
  onVolumeChange = (event) => {
    const { muteAudio, unMuteAudio } = this.props;
    const target = event.target;
    target.muted ? muteAudio() : unMuteAudio();
  };

  saveUserSongs = () => {
    const { token, loadSounds, closeModal } = this.props;
    const isUserSelected = this.checkAvailability(this.state.mergeSounds, true);
    if (isUserSelected) {
      const selectedSounds = this.state.mergeSounds.filter((sound) => sound.userSelected);

      localStorage.setItem("userSounds", JSON.stringify(selectedSounds));
      const userAudio = [];
      const soundData = {
        ambientSoundList: [...userAudio],
      };
      const selectedId = selectedSounds.filter((sound, index) => soundData.ambientSoundList.push(sound.id));
      const data = apiSound(soundData, token);
      this.setState({ saved: !this.state.saved, msg: false });
      closeModal(ModalType.SOUND_SETTING);
      /*  data.then((result) => {
      console.log(result.data.ambientSoundList);
    }); */
    } else {
      this.setState({ msg: true });
    }
  };

  change_Volume = (e) => {
    const { value } = e.target;
    const { changeAudioLevel, openModal } = this.props;

    if (parseFloat(value)) {
      this.props.unMuteAudio();
      this.setState(
        {
          volume: parseFloat(value),
        },
        () => {
          changeAudioLevel(this.state.volume);
        }
      );
    } else {
      this.props.muteAudio();
      this.setState(
        {
          volume: parseFloat(value),
        },
        () => {
          changeAudioLevel(this.state.volume);
        }
      );
    }
    var volume = document.getElementById("volume");
    var player = document.getElementById("myCustomAudio");
    if (player) {
      player.volume = this.state.volume;
    }
  };
  playCurrent = (id, src) => {
    var mAudio = document.getElementById("my-audio");
    mAudio.removeEventListener("timeupdate", this.updateProgressBar, false);
    mAudio.pause();
    mAudio.setAttribute("src", src);
    mAudio.load();
    mAudio.play();
    mAudio.addEventListener(
      "loadedmetadata",
      function () {
        mAudio.setAttribute("data-time", mAudio.duration);
      },
      false
    );

    setTimeout(() => {
      this.setState({
        activeId: id,
        soundDuration: Math.floor(mAudio.duration),
      });
      mAudio.addEventListener("timeupdate", this.updateProgressBar, false);
      mAudio.addEventListener(
        "ended",
        () => {
          this.pauseCurrent();
        },
        false
      );
    }, 400);
  };
  updateProgressBar = () => {
    var player = document.getElementById("my-audio");
    var progressBar = document.getElementById("progress-bar-1");
    var percentage = Math.floor((100 / player.duration) * player.currentTime);
    progressBar.value = percentage;
    progressBar.innerHTML = progressBar.title = percentage + "% played";
  };
  pauseCurrent = () => {
    var mAudio = document.getElementById("my-audio");
    mAudio.pause();
    this.setState({ activeId: 0 });
    mAudio.removeEventListener("timeupdate", this.updateProgressBar, false);
  };
  resetSounds = () => {
    const localApiSounds = localStorage.getItem("apiSounds");
    const localUserSounds = localStorage.getItem("userSounds");
    console.log({ localApiSounds, localUserSounds });
    const apiSounds = localApiSounds ? JSON.parse(localApiSounds) : [];
    const userSounds = localUserSounds ? JSON.parse(localUserSounds) : [];
    if (userSounds?.length) {
      const map = new Map();
      userSounds.forEach((userSound) => {
        map.set(userSound.id, true);
      });
      apiSounds.forEach((apiSound) => {
        if (map.has(apiSound.id)) {
          apiSound.userSelected = true;
        }
      });
      this.setState({ mergeSounds: apiSounds });
    } else {
      apiSounds.forEach((apiSound) => {
        apiSound.userSelected = true;
      });
      this.setState({ mergeSounds: apiSounds });
    }
  };
  componentDidMount() {
    const { isLoggedIn } = this.props;
    var audio_custom = document.getElementById("myCustomAudio");
    var vloumeSl = document.getElementById("volume");
    if (vloumeSl) {
      document.getElementById("volume").removeEventListener("volumechange", this.onVolumeChange);
    }
    this.resetSounds();
  }
  toggleAll = () => {
    const { isSelected } = this.state;
    this.setState({ isSelected: !isSelected, saved: !this.state.saved });
    const { mergeSounds } = this.state;
    const localApiSounds = localStorage.getItem("apiSounds");
    const apiSounds = localApiSounds ? JSON.parse(localApiSounds) : [];
    if (isSelected) {
      console.log(isSelected);
      apiSounds.forEach((apiSound) => {
        apiSound.userSelected = false;
      });
      this.setState({ mergeSounds: apiSounds, saved: true });
    } else {
      apiSounds.forEach((apiSound) => {
        apiSound.userSelected = true;
      });
      this.setState({ mergeSounds: apiSounds, saved: true });
    }
  };
  componentWillUnmount() { }
  render() {
    const {
      modal,
      closeModal,
      activeContent,
      classes,
      muteUnmute,
      muteAudio,
      unMuteAudio,
      audioMuted,
      openModal,
      anchorEl,
      t,
    } = this.props;
    const { songsList, isplaying, saved, activeId, isSelected, sounds, mergeSounds } = this.state;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal backdrop-transparent"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper1 sound-modal">
            {isMobileOnly ? (
              <div className="sub-header text-left tab-hidden">
                <button className="back-btn" onClick={this.handleClose}></button>
                <h1>{t("sound.label1")}</h1>
              </div>
            ) : null}
            {/* <button
              className="closeBtn mobile-hidden"
              onClick={this.handleClose}
            ></button> */}
            {/* <Grid container spacing={0} className="py-10"> */}
            <Grid container spacing={0} className="sound-container">
              <h1 id="transition-modal-title" className="heading modalTitle font-bold mobile-hidden">
                {t("sound.label1")}
              </h1>
              <Grid item xs={12}>
                {/* <List className="contentOption ambsound"> */}
                <List className="ambsound">
                  <Grid container spacing={0} className="m-0">
                    <Grid item xs={8}>
                      <h3>{t("sound.label3")}</h3>
                    </Grid>
                    <Grid item xs={4}>
                      <ListItem key={996} button className="p0">
                        <h3 className="sound-checkbox">
                          {/*  <span> {isSelected ? "Selected" : "Unselect"}</span> */}
                          <input type="checkbox" checked={isSelected} onClick={this.toggleAll} id="checkbox" />
                          <label for="checkbox"></label>
                        </h3>
                      </ListItem>
                    </Grid>
                  </Grid>
                  <div className="amblist">
                    {mergeSounds.map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value.id}`;
                      return (
                        <ListItem key={value.id} button className="ambrow">
                          <Grid container spacing={0} className="m-0">
                            <Grid item xs={1}>
                              {/* <ListItemAvatar className="playicon">
                                {activeId == value.id ? (
                                  <PauseCircleOutlineIcon
                                    onClick={() => this.pauseCurrent(value.id)}
                                  />
                                ) : (
                                  <PlayCircleOutlineIcon
                                    onClick={() =>
                                      this.playCurrent(value.id, value.soundURL)
                                    }
                                  />
                                )}
                              </ListItemAvatar> */}
                              <ListItemAvatar className="playicon">
                                {activeId == value.id ? (
                                  <div onClick={() => this.pauseCurrent(value.id)}>
                                    {" "}
                                    <Pause width={"40px"} height={"40px"} className="play play-icon" />
                                  </div>
                                ) : (
                                  <div onClick={() => this.playCurrent(value.id, value.soundURL)}>
                                    {" "}
                                    <Play width={"40px"} height={"40px"} className="play play-icon" />
                                  </div>
                                )}
                              </ListItemAvatar>
                            </Grid>
                            <Grid item xs={6}>
                              <ListItemText
                                id={labelId}
                                primary={`${value.id}`}
                                className="sound-name"
                                primary={` ${value.id}`}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              {activeId == value.id ? (
                                <ListItem className="progress-bar">
                                  <Grid item xs={7} className="p-right">
                                    <progress id="progress-bar-1" min="0" max="100" value="0">
                                      {t("sound.label2")}
                                    </progress>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <span className="middle">
                                      <small>{this.state.soundDuration} sec</small>
                                    </span>
                                  </Grid>
                                </ListItem>
                              ) : null}
                            </Grid>

                            <Grid item xs={1}>
                              <ListItemSecondaryAction className="right0 sound-checkbox">
                                <input
                                  type="checkbox"
                                  checked={value.userSelected}
                                  onClick={this.handleToggle(value)}
                                  id={value.id}
                                />
                                <label for={value.id}></label>
                              </ListItemSecondaryAction>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    })}
                  </div>
                  <div style={{ display: "none" }}>
                    <audio id="my-audio" ref={this.myPlayerRef} controls>
                      <source src="assets/felling1.mp3" type="audio/mp3"></source>
                    </audio>
                  </div>
                  <div class="audio-player" style={{ display: "none" }}>
                    <div class="player-controls scrubber">
                      <span id="seek-obj-container">
                        <progress id="seek-obj" value="0" max="1"></progress>
                      </span>
                    </div>
                  </div>
                  {/* <ListItem></ListItem>
                  <Divider /> */}
                  <ListItem key={998} button className="volume">
                    <div
                      className={`volume-bar-wrapper__range ${isMobileOnly && "show-range"
                        }`}
                    >
                      {/*  <input
                        className={`video-volume-bar`}
                        type="range"
                        id="volume"
                        min="0"
                        max="1"
                        step="0.1"
                        value={this.state.volume}
                        onChange={this.change_Volume}
                      /> */}
                      <img
                        src={audioMuted ? muteIcon : unMuteIcon}
                        alt=""
                        className="mute-unmute-icon"
                        onClick={() =>
                          audioMuted ? unMuteAudio() : muteAudio()
                        }
                      />
                    </div>
                  </ListItem>
                  <Grid container spacing={0} className="savebtn">
                    <Grid item xs={12}>
                      <ListItem key={999} button>
                        <button onClick={this.saveUserSongs} className="submitButton" disabled={saved ? false : true}>
                          {t("profile.button0")}
                        </button>
                      </ListItem>
                      <div className="error">{this.state.msg ? "Select atleast one sound" : ""}</div>
                    </Grid>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default compose(withTranslation())(SoundSettingModal);
