import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./verification-modal-style";
import { ModalType } from "../../../../constant";
import { withTranslation } from "react-i18next";
import { Popper } from "@material-ui/core";
import { compose } from "redux";
import Grid from "@material-ui/core/Grid";
import "./verification-modal.scss";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import firebase from "firebase";
import {
  http,
  headerOptions,
  fileHeaderOptions,
  metaHeaderOptions,
} from "../../../../core";
import { URL } from "../../../../api/_urls";
import { SocialIcon } from "react-social-icons";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
//import { LinkedIn } from "../../../../pages/verify/linkedin";
//import { LinkedInLoginButton } from "react-social-login-buttons";
import SocialInstaButton from "./button/socialinstaButton";
import SocialLinkedinButton from "./button/socialLinkedinButton";
import InstagramLogin from "react-instagram-login";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";
import { isServer } from "../../../../store";

const config = {
  apiKey: "AIzaSyAsvvbXCNt1rRX-UwkW4zmhG9EJFtkSH2Q",
  authDomain: "tymoff-original.firebaseapp.com",
  databaseURL: "https://tymoff-original.firebaseio.com",
  projectId: "tymoff-original",
  storageBucket: "tymoff-original.appspot.com",
  messagingSenderId: "418600127287",
  appId: "1:418600127287:web:4556c286237cf42c0a68b2",
  measurementId: "G-Z7F7BSGR3X",
};
const FACEBOOK = "facebook";
const TWITTER = "twitter";
const INSTAGRAM = "instagram";
const LINKEDIN = "linkedin";
const STATUS = "Verifying";
const STATUS1 = "Submitted";
let socialHashMap = {};

class VerificationModal extends PureComponent {
  constructor(props) {
    super(props);
    //firebase.initializeApp(config);
    this.state = {
      facebook: false,
      twitter: false,
      instagram: false,
      linkdin: false,
      verificationStatus: false,
      code: "",
      errorMessage: "",
    };
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
    this.myMap = new Map();
    this.myobj = { verificationData: [] };
  }
  tokenData = (token, type) => {
    return {
      verificationData: [
        {
          token,
          socialType: type,
        },
      ],
    };
  };
  authHandlerFirebase = (provider, socialId) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((data) => {
        this.postTokenData(data.user._lat, socialId);
      });
  };
  postDataToServer = () => {
    const { token } = this.props;
    http
      .post(
        URL.VERIFICATION_API() + "?timestamp" + new Date().getTime(),
        this.convertMapToArray(),
        headerOptions(token)
      )
      .then((response) => {
        this.setState({ verificationStatus: true });
        //console.log(response);
      });
  };
  convertMapToArray = () => {
    for (const [key, value] of this.myMap.entries()) {
      this.myobj.verificationData.push({ token: key, socialType: value });
    }
    return this.myobj;
  };

  postTokenData = (tokenData, sid) => {
    this.myMap.set(tokenData, sid);
    if (sid == FACEBOOK) {
      this.setState({ facebook: true });
    } else if (sid == TWITTER) {
      this.setState({ twitter: true });
    }
  };
  instaRequest = () => {
    var instaUrl = "https://tymoff.com/preference/";
    if (!isServer) {
      window.open(
        `https://api.instagram.com/oauth/authorize?client_id=1325245911154811&redirect_uri=${instaUrl}&scope=user_profile,user_media&response_type=code`,
        "_self"
      );
    }
  };
  componentDidMount() {
    const { isLoggedIn } = this.props;
    if(isLoggedIn){
    http
      .get(URL.GET_VERIFICATION_API(), headerOptions(this.props.token))
      .then((response) => {
        //console.log(response.data);
        if (response.data.length) {
          response.data.map((item, index) => {
            if (index === 0 && item.socialType === "facebook") {
              this.setState({ facebook: true });
            } else if (index === 1 && item.socialType === "instagram") {
              this.setState({ instagram: true });
            } else if (index === 2 && item.socialType === "twitter") {
              this.setState({ twitter: true });
            } else if (index === 3 && item.socialType === "linkedin") {
              this.setState({ linkdin: true });
            }
          });
        }
      });
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {//console.log("success ")
      }
      );

    }
  }

  handleSuccess = (data) => {
    this.setState({
      code: data.code,
      errorMessage: "",
    });
    //console.log(data.code);
  };

  handleFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };
  deleteRequest = () => {
    http
      .delete(URL.GET_VERIFICATION_API(), headerOptions(this.props.token))
      .then((response) => {
        if (response.success) {
          this.setState({ verificationStatus: false });
        }
      });
  };
  handleClose = () => {
    const { closeModal, isModalRoute, history } = this.props;
    document.body.style.position = "";
    closeModal(ModalType.VERIFICATION);
    isModalRoute && history.goBack();
  };
  navigate = () => {
    //console.log("navi");
  };

  render() {
    const { modal, closeModal } = this.props;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper verification-modal">
            {isMobileOnly ? (
              <button
                className="back-btn btn2"
                onClick={() => closeModal(ModalType.VERIFICATION)}
              ></button>
            ) : null}

            <div className="p-30">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div>
                    {this.state.verified ? (
                      <h3
                        id="transition-modal-title"
                        className="modalTitle m-1"
                      >
                        requested
                      </h3>
                    ) : (
                      <h3
                        id="transition-modal-title"
                        className="modalTitle m-1"
                      >
                        Request verification
                      </h3>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <label id="transition-modal-title" className="gray gap">
                    We need you to connect your social accounts to start
                    verifying your identity for Tymoff.
                  </label>
                </Grid>
              </Grid>
              <div className="">
                {this.state.verificationStatus ? (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      Verification Requested
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {this.state.facebook ? (
                        <FacebookIcon
                          size={36}
                          round
                        //onClick={this.handleFacebookLogin}
                        />
                      ) : null}
                      {this.state.instagram ? (
                        <InstapaperIcon
                          size={36}
                          round
                        //onClick={this.instaRequest}
                        />
                      ) : null}
                      {this.state.twitter ? (
                        <TwitterIcon
                          size={36}
                          round
                        //onClick={this.handleTwitterLogin}
                        />
                      ) : null}

                      {this.state.linkdin ? (
                        <LinkedinIcon
                          size={36}
                          round
                        //onClick={this.handleLinkdinLogin}
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={5}>
                      {!this.state.facebook ? (
                        <FacebookShareButton url="" quote="" className="">
                          <FacebookIcon
                            size={36}
                            round
                            onClick={() =>
                              this.authHandlerFirebase(
                                this.facebookProvider,
                                FACEBOOK
                              )
                            }
                          />
                        </FacebookShareButton>
                      ) : null}
                      {!this.state.instagram ? (
                        <SocialInstaButton
                          provider="instagram"
                          appId="1325245911154811"
                          redirect="https://tymoff.com/preference/"
                          onLoginSuccess={this.handleSuccess}
                          onLoginFailure={this.handleFailure}
                        ></SocialInstaButton>
                      ) : null}
                      {!this.state.twitter ? (
                        <TwitterShareButton url="" title="" className="">
                          <TwitterIcon
                            size={36}
                            round
                            onClick={() =>
                              this.authHandlerFirebase(
                                this.twitterProvider,
                                TWITTER
                              )
                            }
                          />
                        </TwitterShareButton>
                      ) : null}

                      {!this.state.linkdin ? (
                        /*   <LinkedIn
                          clientId="77dnnqniu0jula"
                          redirect="http://localhost:3000/preference/linkedin"
                          scope="r_emailaddress"
                          state="34232423"
                          onLoginSuccess={this.handleSuccess}
                          onLoginFailure={this.handleFailure}
                          redirectPath="/linkedin"
                        ></LinkedIn> */
                        <h1>h</h1>
                      ) : null}
                    </Grid>
                  </Grid>
                )}
              </div>
              <div className="" style={{ marginBottom: 35 }}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <label id="transition-modal-title" className="gap">
                      Link to any other social media profiles
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <label id="transition-modal-title" className="gap gray">
                      URL 1
                    </label>
                    <input placeholder="Url" name="url" />
                    <label id="transition-modal-title" className="gap gray">
                      URL 2
                    </label>
                    <input type="text" placeholder="Url" name="url" />
                  </Grid>
                </Grid>
              </div>
              {/* <Grid container spacing={0}>
                <Grid item xs={12}>
                  {this.state.verified ? (
                    <div>
                      <SocialIcon url="https://facebook.com" />
                    </div>
                  ) : (
                    <div>
                      <Grid item xs={6}>
                        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                      </Grid>
                      <Grid item xs={6}>
                        <div></div>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <LinkedIn
                          clientId="81lx5we2omq9xh"
                          onFailure={this.handleFailure}
                          onSuccess={this.handleSuccess}
                          redirectUri="http://localhost:3000/linkedin"
                        >
                          <LinkedInLoginButton onClick={() => alert("Hello")} />
                        </LinkedIn>
                      </Grid> * /}
                    </div>
                  )}
                </Grid>
              </Grid> */}
              <Grid item xs={12}>
                {" "}
                {!this.state.verificationStatus ? (
                  <button
                    className={"submitButton"}
                    onClick={this.postDataToServer}
                  >
                    Save
                  </button>
                ) : null}
                <button className={"submitButton"} onClick={this.deleteRequest}>
                  Delete
                </button>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(VerificationModal);
