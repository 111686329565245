import { connect } from "react-redux";
import { default as VideoUpload } from "./video-upload";
import {
  setUploadVideoDescription,
  setUploadVideoLanguage,
  setUploadGenre,
  setVideoUploadGenre,
  openModal,
  setUploadStep,
  closeModal,
  updateUploadVideo,
  updateUploadGenre,
  updateUploadLanguage,
  disableVideoUploadMatureContent
} from "../../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.upload || false,
  description: state.upload.videoDescription,
  error: state.upload.error,
  genres: state.master.genres,
  languages: state.master.languages,
  uploadGenres: state.upload.uploadGenre,
  videoUploadGenre: state.upload.videoUploadGenre,
  videoLanguage: state.upload.videoLanguage,
  commonModal: state.ui.modal.common,
  videoDescription: state.upload.videoDescription,
  videos: state.upload.videos,
  matureContent: state.upload.videoMatureContent
});

const mapDispatchToProps = (dispatch) => ({
  setUploadVideoDescription: (description) => dispatch(setUploadVideoDescription(description)),
  setUploadVideoLanguage: (langId) => dispatch(setUploadVideoLanguage(langId)),
  setUploadGenre: (genreId) => dispatch(setUploadGenre(genreId)),
  setVideoUploadGenre: (genreId) => dispatch(setVideoUploadGenre(genreId)),
  openModal: (modal) => dispatch(openModal(modal)),
  setUploadStep: (step) => dispatch(setUploadStep(step)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  updateUploadVideo: (videos) => dispatch(updateUploadVideo(videos)),
  updateUploadGenre: (genres) => dispatch(updateUploadGenre(genres)),
  updateUploadLanguage: (languages) => dispatch(updateUploadLanguage(languages)),
  disableVideoUploadMatureContent: () => dispatch(disableVideoUploadMatureContent())
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoUpload);
