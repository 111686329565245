import { connect } from "react-redux";
import { default as WebUpload } from "./web-upload";
import {
  setUploadWeblink,
  loadMetadata,
  setUploadGenre,
  setUploadLanguage,
  setUploadWeblinkLanguage,
  validateUpload,
  setLinkUploadGenre,
  openModal,
  setUploadStep,
  closeModal,
  updateUploadGenre,
  updateUploadLanguage,
  disableWeblinkUploadMatureContent
} from "../../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.upload || false,
  weblink: state.upload.weblink,
  metaData: state.upload.metaData,
  error: state.upload.error,
  genres: state.master.genres,
  languages: state.master.languages,
  uploadGenres: state.upload.uploadGenre,
  linkUploadGenre: state.upload.linkUploadGenre,
  webLinkLanguages: state.upload.weblinkLanguage,
  commonModal: state.ui.modal.common,
  matureContent: state.upload.weblinkMatureContent
});

const mapDispatchToProps = (dispatch) => ({
  setUploadWeblink: (weblink) => dispatch(setUploadWeblink(weblink)),
  loadMetadata: () => dispatch(loadMetadata()),
  setUploadGenre: (genreId) => dispatch(setUploadGenre(genreId)),
  setUploadLanguage: (languageId) => dispatch(setUploadLanguage(languageId)),
  setLinkUploadGenre: (languageId) => dispatch(setLinkUploadGenre(languageId)),
  setUploadWeblinkLanguage: (languageId) => dispatch(setUploadWeblinkLanguage(languageId)),
  validateUpload: () => dispatch(validateUpload()),
  disableWeblinkUploadMatureContent: () => dispatch(disableWeblinkUploadMatureContent()),
  openModal: (modal) => dispatch(openModal(modal)),
  setUploadStep: (step) => dispatch(setUploadStep(step)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  updateUploadGenre: (genres) => dispatch(updateUploadGenre(genres)),
  updateUploadLanguage: (languages) => dispatch(updateUploadLanguage(languages))
});

export default connect(mapStateToProps, mapDispatchToProps)(WebUpload);
