import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as ReportUserModalDetail } from "./report-user-modal-detail";
import {
  closeModal,
  setReportContentId,
  reportContent,
  startScrollTimer,
  setReportContentText,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.report_user_detail || false,
  isContentDetailOpen: state.content.isContentDetailOpen,
  contentAlreadyVisited: state.content.contentAlreadyVisited,
  reportReasons: state.master.report,
  error: state.content.error,
  commentBox: state.ui.commentBox,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: (modal) => dispatch(closeModal(modal)),
  setReportContentId: (reportId) => dispatch(setReportContentId(reportId)),
  setReportContentText: (reportText) => dispatch(setReportContentText(reportText)),
  startScrollTimer: () => dispatch(startScrollTimer()),
  reportContent: () => dispatch(reportContent()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportUserModalDetail));
