import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as LanguageSelector } from "./language-selector";
import {
  openModal,
  closeModal,
  setFilterLanguage,
  setUploadLanguage,
  setFilter,
  validateUploadLanguage,
  listSearchedLanguages,
  setSearchLanguage,
  setUploadImageLanguage,
  setUploadVideoLanguage,
  setUploadWeblinkLanguage,
  setUploadStoryLanguage,
  setSiteLanguage,
  clearUploadLanguage,
} from "../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.language || false,
  isModalRoute: state.ui.isModalRoute,
  languages: listSearchedLanguages(state),
  modalAction: state.ui.modalAction,
  filter: state.content.filter,
  selectedLanguages: state.upload.uploadLanguage,
  error: state.upload.error,
  searchLanguage: state.language.searchLanguage,
  activeStep: state.upload.activeStep,
  activeTab: state.upload.activeTab,
  selectedLanguageUpload: state.language.uploadLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  setFilterLanguage: (languageId) => dispatch(setFilterLanguage(languageId)),
  setUploadLanguage: (languageId) => dispatch(setUploadLanguage(languageId)),
  setFilter: (filter) => dispatch(setFilter(filter)),
  setSearchLanguage: (searchText) => dispatch(setSearchLanguage(searchText)),
  setSiteLanguage: (searchText) => dispatch(setSiteLanguage(searchText)),
  validateUploadLanguage: () => dispatch(validateUploadLanguage()),
  setUploadImageLanguage: (languageId) => dispatch(setUploadImageLanguage(languageId)),
  setUploadVideoLanguage: (languageId) => dispatch(setUploadVideoLanguage(languageId)),
  setUploadWeblinkLanguage: (languageId) => dispatch(setUploadWeblinkLanguage(languageId)),
  setUploadStoryLanguage: (languageId) => dispatch(setUploadStoryLanguage(languageId)),
  clearUploadLanguage:()=> dispatch(clearUploadLanguage())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageSelector));
