import { connect } from "react-redux";
import { default as ImageUpload } from "./image-upload";
import {
  setUploadImageDescription,
  setUploadImageLanguage,
  setUploadGenre,
  setImageUploadGenre,
  openModal,
  setUploadStep,
  validateUpload,
  publishContent,
  addMoreContent,
  resetAddMoreContent,
  closeModal,
  updateUploadImage,
  updateUploadGenre,
  updateUploadLanguage,
  disableImageUploadMatureContent,
} from "../../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.upload || false,
  description: state.upload.imageDescription,
  error: state.upload.error,
  genres: state.master.genres,
  languages: state.master.languages,
  uploadGenres: state.upload.uploadGenre,
  imageUploadGenre: state.upload.imageUploadGenre,
  imageLanguage: state.upload.imageLanguage,
  commonModal: state.ui.modal.common,
  activeStep: state.upload.activeStep,
  previewContents: state.upload.contentPreview,
  isLoading: state.upload.isLoading,
  images: state.upload.images,
  imageDescription: state.upload.imageDescription,
  matureContent: state.upload.imageMatureContent,
  token: state.auth.token,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  setUploadImageDescription: (description) =>
    dispatch(setUploadImageDescription(description)),
  setUploadImageLanguage: (langId) => dispatch(setUploadImageLanguage(langId)),
  setUploadGenre: (genreId) => dispatch(setUploadGenre(genreId)),
  setImageUploadGenre: (genreId) => dispatch(setImageUploadGenre(genreId)),
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  setUploadStep: (step) => dispatch(setUploadStep(step)),
  validateUpload: () => dispatch(validateUpload()),
  publishContent: () => dispatch(publishContent()),
  addMoreContent: () => dispatch(addMoreContent()),
  resetAddMoreContent: () => dispatch(resetAddMoreContent()),
  disableImageUploadMatureContent: () =>
    dispatch(disableImageUploadMatureContent()),
  updateUploadImage: (images) => dispatch(updateUploadImage(images)),
  updateUploadGenre: (genres) => dispatch(updateUploadGenre(genres)),
  updateUploadLanguage: (languages) =>
    dispatch(updateUploadLanguage(languages)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
