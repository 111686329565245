import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as ContentMoreOptions } from "./content-more-options";
import {
  setSelectedUserID,
  loadFollowers,
  loadFollowing,
  followUser,
  unFollowUser,
  openModal,
  closeModal,
  hideContent,
  startScrollTimer,
  areModalsOpenOnContentDetail,
  showCommentBox,
  hideCommentBox,
  setErrorMessage,
  setMessage,
  showSelectedText,
  downloadFile,
  setContentUserAction,
  stopScrollTimer,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.more_option || false,
  loadFromGrid: state.content.loadFromGrid,
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.data,
  shareLink: state.share.data,
  commentBox: state.ui.commentBox,
  activeContent: state.content.activeContent,
  isOtherModalOpen: areModalsOpenOnContentDetail(state),
  loadFromSession: state.content.loadFromSession,
  following: state.auth.following,
  followers: state.auth.followers,
});

const mapDispatchToProps = (dispatch) => ({
  loadFollowers: () => dispatch(loadFollowers()),
  loadFollowing: () => dispatch(loadFollowing()),
  setSelectedUserID: (userId) => dispatch(setSelectedUserID(userId)),
  followUser: () => dispatch(followUser()),
  unFollowUser: () => dispatch(unFollowUser()),
  showCommentBox: () => dispatch(showCommentBox()),
  hideCommentBox: () => dispatch(hideCommentBox()),
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  hideContent: () => dispatch(hideContent()),
  startScrollTimer: () => dispatch(startScrollTimer()),
  stopScrollTimer: () => dispatch(stopScrollTimer()),
  setErrorMessage: (errorMessage) => dispatch(setErrorMessage(errorMessage)),
  setMessage: (message) => dispatch(setMessage(message)),
  showSelectedText: (text) => dispatch(showSelectedText(text)),
  downloadFile: () => dispatch(downloadFile()),
  setContentUserAction: (userAction) =>
    dispatch(setContentUserAction(userAction)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentMoreOptions)
);
