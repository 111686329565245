const UploadAlert = { title: "uploadAlert.title", body: "uploadAlert.body", type: "upload", successText: "uploadAlert.successText", cancelText: "uploadAlert.cancelText" };
const ClearHistoryAlert = { title: "clearHistoryAlert.title", body: "clearHistoryAlert.body", type: "clear_history", successText: "clearHistoryAlert.successText", cancelText: "clearHistoryAlert.cancelText" };
// const ClearHistoryAlert = { title: "Alert!", body: "Your like and download will not be affected.", type: "clear_history", successText: "Ok", cancelText: "Cancel" };
// const ClearHistoryAlert = { title: "Alert!", body: "Your tymoff history will be cleared from all devices.", type: "clear_history", successText: "Ok", cancelText: "Cancel" };
const LogoutAlert = { title: "logoutAlert.title", body: "logoutAlert.body", type: "logout", successText: "logoutAlert.successText", cancelText: "logoutAlert.cancelText" };
const FeedbackResponseAlert = { title: "feedbackResponseAlert.title", body: "feedbackResponseAlert.body", type: "feedback_response", successText: "feedbackResponseAlert.successText", cancelText: "feedbackResponseAlert.cancelText" };
const RemoveImageAlert = { title: "removeImageAlert.title", body: "removeImageAlert.body", type: "remove_profile_image", successText: "removeImageAlert.successText", cancelText: "removeImageAlert.cancelText" };
const DeletePost = { title: "deletePost.title", body: "deletePost.body", type: "delete_post", successText: "deletePost.successText", cancelText: "dele1tePost.cancelText" };
const ContactResponseAlert = { title: "contactResponseAlert.title", body: "contactResponseAlert.body", type: "contact_reponse", successText: "contactResponseAlert.successText", cancelText: "contactResponseAlert.cancelText" };
export { UploadAlert, ClearHistoryAlert, LogoutAlert, FeedbackResponseAlert, RemoveImageAlert, DeletePost, ContactResponseAlert };

/*import { withTranslation } from "react-i18next";
import { compose } from "redux";



//export { UploadAlert, ClearHistoryAlert, LogoutAlert, FeedbackResponseAlert, RemoveImageAlert, DeletePost, ContactResponseAlert };
import React, {PureComponent} from 'react'
class Alerts extends PureComponent{
    render(){
        const UploadAlert = { title: "Alert!", body: "You have data to publish in the upload. Do you want to cancel the process?", type: "upload", successText: "Yes", cancelText: "No" };
        const ClearHistoryAlert = { title: "Clear history?", body: "This will permanently erase your search history. Are you sure you want to clear history?", type: "clear_history", successText: "Ok", cancelText: "Cancel" };
        // const ClearHistoryAlert = { title: "Alert!", body: "Your like and download will not be affected.", type: "clear_history", successText: "Ok", cancelText: "Cancel" };
        // const ClearHistoryAlert = { title: "Alert!", body: "Your tymoff history will be cleared from all devices.", type: "clear_history", successText: "Ok", cancelText: "Cancel" };
        const LogoutAlert = { title: "Logout", body: "Are you sure you want to Logout?", type: "logout", successText: "Ok", cancelText: "Cancel" };
        const FeedbackResponseAlert = { title: "", body: "Thanks for submitting your feedback.", type: "feedback_response", successText: "Ok", cancelText: "" };
        const RemoveImageAlert = { title: "Remove profile photo", body: "Are you sure to remove your profile photo?", type: "remove_profile_image", successText: "Ok", cancelText: "Cancel" };
        const DeletePost = { title: "Delete Post", body: "Are you sure you want to delete your post?", type: "delete_post", successText: "Delete", cancelText: "Cancel" };
        const ContactResponseAlert = { title: "", body: "Thanks for submitting your message.", type: "contact_reponse", successText: "ok", cancelText: "" };
    
    }
};

export default Alerts;

 */
