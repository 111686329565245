import { UPLOAD } from "./action-types";

const clearUpload = () => ({
  type: UPLOAD.CLEAR,
});

const setInitialTab = () => ({
  type: UPLOAD.RESET_UPLOAD_TAB,
});

const clearUploadPreviewFiles = () => ({
  type: UPLOAD.CLEAR_PREVIEW_FILES,
});

const setUploadImage = (images) => ({
  type: UPLOAD.SET_UPLOAD_IMAGE,
  images,
});

const updateUploadImage = (images) => ({
  type: UPLOAD.UPDATE_UPLOAD_IMAGE,
  images,
});

const setUploadImageDescription = (description) => ({
  type: UPLOAD.SET_UPLOAD_IMAGE_DESCRIPTION,
  description,
});

const setUploadVideo = (videos) => ({
  type: UPLOAD.SET_UPLOAD_VIDEO,
  videos,
});

const updateUploadVideo = (videos) => ({
  type: UPLOAD.UPDATE_UPLOAD_VIDEO,
  videos,
});

const setUploadVideoDescription = (description) => ({
  type: UPLOAD.SET_UPLOAD_VIDEO_DESCRIPTION,
  description,
});

const setUploadStoryTitle = (title) => ({
  type: UPLOAD.SET_UPLOAD_STORY_TITLE,
  title,
});

const setUploadStoryDescription = (description) => ({
  type: UPLOAD.SET_UPLOAD_STORY_DESCRIPTION,
  description,
});

const setUploadWeblink = (weblink) => ({
  type: UPLOAD.SET_UPLOAD_WEBLINK,
  weblink,
});

const setActiveTab = (tab) => ({
  type: UPLOAD.SET_ACTIVE_TAB,
  tab,
});

const setUploadError = (error) => ({
  type: UPLOAD.SET_UPLOAD_ERROR,
  error,
});

const validateUpload = () => ({
  type: UPLOAD.VALIDATE_UPLOAD,
});

const validateUploadLanguage = () => ({
  type: UPLOAD.VALIDATE_UPLOAD_LANGUAGE,
});

const setUploadStep = (step) => ({
  type: UPLOAD.SET_UPLOAD_STEP,
  step,
});

const setUploadImageLanguage = (languages) => ({
  type: UPLOAD.SET_UPLOAD_IMAGE_LANGUAGE,
  languages,
});

const setUploadVideoLanguage = (languages) => ({
  type: UPLOAD.SET_UPLOAD_VIDEO_LANGUAGE,
  languages,
});

const setUploadStoryLanguage = (languages) => ({
  type: UPLOAD.SET_UPLOAD_STORY_LANGUAGE,
  languages,
});

const setUploadWeblinkLanguage = (languages) => ({
  type: UPLOAD.SET_UPLOAD_WEB_LANGUAGE,
  languages,
});

const setUploadGenre = (uploadGenre) => ({
  type: UPLOAD.SET_UPLOAD_GENRE,
  uploadGenre,
});

const updateUploadGenre = (uploadGenre) => ({
  type: UPLOAD.UPDATE_UPLOAD_GENRE,
  uploadGenre,
});

const setUploadLanguage = (uploadLanguage) => ({
  type: UPLOAD.SET_UPLOAD_LANGUAGE,
  uploadLanguage,
});

const updateUploadLanguage = (uploadLanguage) => ({
  type: UPLOAD.UPDATE_UPLOAD_LANGUAGE,
  uploadLanguage,
});

const setImageUploadGenre = (imageUploadGenre) => ({
  type: UPLOAD.SET_IMAGE_UPLOAD_GENRE,
  imageUploadGenre,
});
const setVideoUploadGenre = (videoUploadGenre) => ({
  type: UPLOAD.SET_VIDEO_UPLOAD_GENRE,
  videoUploadGenre,
});
const setLinkUploadGenre = (linkUploadGenre) => ({
  type: UPLOAD.SET_LINK_UPLOAD_GENRE,
  linkUploadGenre,
});
const setStoryUploadGenre = (storyUploadGenre) => ({
  type: UPLOAD.SET_STORY_UPLOAD_GENRE,
  storyUploadGenre,
});

const setImageUploadPercentage = (imageUploadPercentage) => ({
  type: UPLOAD.SET_IMAGE_UPLOAD_PERCENTAGE,
  imageUploadPercentage,
});
const setVideoUploadPercentage = (videoUploadPercentage) => ({
  type: UPLOAD.SET_VIDEO_UPLOAD_PERCENTAGE,
  videoUploadPercentage,
});
const setLinkUploadPercentage = (linkUploadPercentage) => ({
  type: UPLOAD.SET_LINK_UPLOAD_PERCENTAGE,
  linkUploadPercentage,
});
const setStoryUploadPercentage = (storyUploadPercentage) => ({
  type: UPLOAD.SET_STORY_UPLOAD_PERCENTAGE,
  storyUploadPercentage,
});

const resetImageUploadPercentage = () => ({
  type: UPLOAD.RESET_IMAGE_UPLOAD_PERCENTAGE,
});
const resetVideoUploadPercentage = () => ({
  type: UPLOAD.RESET_VIDEO_UPLOAD_PERCENTAGE,
});
const resetLinkUploadPercentage = () => ({
  type: UPLOAD.RESET_LINK_UPLOAD_PERCENTAGE,
});
const resetStoryUploadPercentage = () => ({
  type: UPLOAD.RESET_STORY_UPLOAD_PERCENTAGE,
});

const clearUploadGenre = () => ({
  type: UPLOAD.CLEAR_UPLOAD_GENRE,
});
const clearUploadLanguage = () => ({
  type: UPLOAD.CLEAR_UPLOAD_LANGUAGE,
});

const clearUploadError = () => ({
  type: UPLOAD.CLEAR_ERROR,
});

const setContentDataList = (contentData) => ({
  type: UPLOAD.SET_CONTENT_DATA_LIST,
  contentData,
});

const updateContentDataList = (contentData) => ({
  type: UPLOAD.UPDATE_CONTENT_DATA_LIST,
  contentData,
});

const setContentFiles = (contentFiles) => ({
  type: UPLOAD.SET_CONTENT_FILES,
  contentFiles,
});

const setUploadinFiles = (uploadingFiles) => ({
  type: UPLOAD.SET_UPLOADING_FILES,
  uploadingFiles,
});

const setPreviewContent = (prevContent) => ({
  type: UPLOAD.SET_CONTENT_PREVIEW,
  prevContent,
});

const publishContent = () => ({
  type: UPLOAD.PUBLISH_UPLOAD,
});

const uploadContentDataList = () => ({
  type: UPLOAD.UPLOAD_DATALIST,
});

const uploadUploadFiles = () => ({
  type: UPLOAD.UPLOAD_FILES,
});

const addMoreContent = () => ({
  type: UPLOAD.ADD_MORE_CONTENT,
});
const moreSelected = () => ({
  type: UPLOAD.MORE_SELECTED,
});

const resetAddMoreContent = () => ({
  type: UPLOAD.RESET_ADD_MORE_CONTENT,
});
const resetMoreSelected = () => ({
  type: UPLOAD.RESET_MORE_SELECTED,
});

const clearUploadContent = () => ({
  type: UPLOAD.CLEAR_CONTENT,
});
const cancelUploadContent = () => ({
  type: UPLOAD.CANCEL,
});

const clearUploadingFiles = () => ({
  type: UPLOAD.CLEAR_UPLOADING_FILES,
});

const removeUploadedFile = (uploadedFile) => ({
  type: UPLOAD.UPDATE_UPLOADING_FILES,
  uploadedFile,
});

const loadMetadata = () => ({
  type: UPLOAD.LOAD_METADATA,
});

const setMetaDetail = (meta) => ({
  type: UPLOAD.SET_META_DETAIL,
  meta,
});

const clearSearchGenre = () => ({
  type: UPLOAD.CLEAR_SEARCH,
});

const setSearchGenre = (searchText) => ({
  type: UPLOAD.SEARCH_GENRE,
  searchText,
});

const enableVideoUploadMatureContent = () => ({
  type: UPLOAD.ENABLE_VIDEO_UPLOAD_MATURE_CONTENT,
});

const disableVideoUploadMatureContent = () => ({
  type: UPLOAD.DISABLE_VIDEO_UPLOAD_MATURE_CONTENT,
});

const enableImageUploadMatureContent = () => ({
  type: UPLOAD.ENABLE_IMAGE_UPLOAD_MATURE_CONTENT,
});

const disableImageUploadMatureContent = () => ({
  type: UPLOAD.DISABLE_IMAGE_UPLOAD_MATURE_CONTENT,
});

const enableStoryUploadMatureContent = () => ({
  type: UPLOAD.ENABLE_STORY_UPLOAD_MATURE_CONTENT,
});

const disableStoryUploadMatureContent = () => ({
  type: UPLOAD.DISABLE_STORY_UPLOAD_MATURE_CONTENT,
});

const enableWeblinkUploadMatureContent = () => ({
  type: UPLOAD.ENABLE_LINK_UPLOAD_MATURE_CONTENT,
});

const disableWeblinkUploadMatureContent = () => ({
  type: UPLOAD.DISABLE_LINK_UPLOAD_MATURE_CONTENT,
});

export {
  clearUpload,
  clearUploadError,
  enableVideoUploadMatureContent,
  disableVideoUploadMatureContent,
  enableImageUploadMatureContent,
  disableImageUploadMatureContent,
  enableStoryUploadMatureContent,
  disableStoryUploadMatureContent,
  enableWeblinkUploadMatureContent,
  disableWeblinkUploadMatureContent,
  setUploadImage,
  setUploadVideo,
  setUploadStoryTitle,
  setUploadStoryDescription,
  setUploadWeblink,
  setActiveTab,
  setUploadVideoDescription,
  setUploadImageDescription,
  updateUploadImage,
  updateUploadVideo,
  setUploadError,
  validateUpload,
  setUploadStep,
  setUploadImageLanguage,
  setUploadVideoLanguage,
  setUploadStoryLanguage,
  setUploadWeblinkLanguage,
  validateUploadLanguage,
  setUploadGenre,
  updateUploadGenre,
  setImageUploadGenre,
  setVideoUploadGenre,
  setLinkUploadGenre,
  setStoryUploadGenre,
  clearUploadGenre,
  setContentDataList,
  setContentFiles,
  publishContent,
  uploadContentDataList,
  uploadUploadFiles,
  updateContentDataList,
  setPreviewContent,
  addMoreContent,
  clearUploadContent,
  clearUploadPreviewFiles,
  resetAddMoreContent,
  setUploadinFiles,
  clearUploadingFiles,
  removeUploadedFile,
  setMetaDetail,
  loadMetadata,
  clearSearchGenre,
  setSearchGenre,
  clearUploadLanguage,
  moreSelected,
  resetMoreSelected,
  setUploadLanguage,
  updateUploadLanguage,
  setImageUploadPercentage,
  setVideoUploadPercentage,
  setLinkUploadPercentage,
  setStoryUploadPercentage,
  resetImageUploadPercentage,
  resetVideoUploadPercentage,
  resetStoryUploadPercentage,
  resetLinkUploadPercentage,
  setInitialTab,
  cancelUploadContent,
};
