import { Grid, Switch, TextField } from "@material-ui/core";
import React, { Fragment, PureComponent } from "react";
import { ModalType, UploadStep } from "../../../../../constant";
import { FileSelector } from "../../../../containers";
import VideoPlayer from "../../../../containers/video-player/video-player";
import "./video-upload.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class VideoUpload extends PureComponent {
  handleDescriptionChange = (description) => {
    const { setUploadVideoDescription } = this.props;
    setUploadVideoDescription(description);
  };
  handleLanguageChange = (lang) => {
    const { setUploadVideoLanguage } = this.props;
    setUploadVideoLanguage(lang);
  };
  handleGenreChange = (genre) => {
    const { setUploadGenre, setVideoUploadGenre } = this.props;
    setVideoUploadGenre(genre);
  };
  handleModal = (modalType) => {
    const {
      openModal,
      setUploadStep,
      updateUploadGenre,
      videoUploadGenre,
      videoLanguage,
      updateUploadLanguage,
    } = this.props;
    setUploadStep(modalType);
    if (modalType === UploadStep.GENRE) updateUploadGenre(videoUploadGenre);
    if (modalType === UploadStep.LANGUAGE) updateUploadLanguage(videoLanguage);
    openModal(ModalType.COMMON);
  };
  removeFile = (index) => {
    const { videos, updateUploadVideo } = this.props;
    videos.splice(index, 1);
    updateUploadVideo(videos);
  };
  handleMatureContent = () => {
    const {
      openModal,
      disableVideoUploadMatureContent,
      matureContent,
    } = this.props;
    matureContent
      ? disableVideoUploadMatureContent()
      : openModal(ModalType.UPLOAD_MATURE_CONTENT);
  };

  render() {
    const {
      t,
      description,
      error,
      setUploadVideoDescription,
      genres,
      languages,
      videoLanguage,
      uploadGenres,
      videoUploadGenre,
      videos,
      matureContent,
    } = this.props;
    const initialGenres = genres.filter((g) => videoUploadGenre.includes(g.id));
    const initialLanguage = languages.filter((l) =>
      videoLanguage.includes(l.id)
    );
    return (
      <Fragment>
        <Grid item xs={12} sm={12}>
          <div className="description">
            {/* {isMobileOnly ? ( */}
            <Fragment>
              <Grid container spacing={0} className="m-0">
                <Grid item xs={12} className="grd-frm">
                  <label className="bold">{t("uploadSection.label4")}</label>
                  <TextField
                    name="desc"
                    variant="outlined"
                    value={description}
                    onChange={(e) =>
                      this.handleDescriptionChange(e.target.value)
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12} className="select-photo">
                  <div className="step-two-images" data-text="Preview">
                    {!!videos.length &&
                      videos.map((video, index) => (
                        <div key={index} className="img" alt="">
                          <button
                            className="delete-img"
                            onClick={() => this.removeFile(index)}
                          ></button>
                          <VideoPlayer
                            url={video.preview}
                            height={"100px"}
                            width={"100px"}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="text-center" style={{ cursor: "pointer" }}>
                    <FileSelector title=" Browse or drop video here" />
                    <label className="mobile-label">{t("uploadSection.video.add")}</label>
                  </div>
                </Grid>
              </Grid>
            </Fragment>
            {/* ) : (
                <FileSelector title=" Browse or drop video here" />
              )} */}
            <div className="description-content">
              {/* {isMobileOnly ? ( */}
              <Fragment>
                <h4 style={{ marginLeft: 10 }}>{t("uploadSection.h4")}</h4>
                <Grid container spacing={0} className="grid-container">
                  <Grid container item xs={12} className="grid-section">
                    <Grid item xs={4}>
                      <label className="">{t("uploadSection.label1")}</label>
                    </Grid>
                    <Grid item xs={8} className="txt-rght">
                      <label onClick={() => this.handleModal(UploadStep.GENRE)}>
                        {initialGenres.length ? (
                          initialGenres.length <= 2 ? (
                            initialGenres.map((res, index) => (
                              <span key={index}>
                                {res.name}{" "}
                                {index < initialGenres.length - 1 ? ", " : null}
                              </span>
                            ))
                          ) : (
                            <Fragment>
                              <span key={0}>{initialGenres[0].name}, </span>
                              <span key={1}>{initialGenres[1].name}, </span>
                              <span key={2}>
                                +{initialGenres.length - 2} more
                              </span>
                            </Fragment>
                          )
                        ) : (
                          <span className="not-set"> {t("uploadSection.span")}</span>
                        )}
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} className="grid-section">
                    <Grid item xs={4}>
                      <label className="">{t("uploadSection.label2")}</label>
                    </Grid>
                    <Grid item xs={8} className="txt-rght">
                      <label
                        onClick={() => this.handleModal(UploadStep.LANGUAGE)}
                      >
                        {initialLanguage.length ? (
                          initialLanguage.length <= 2 ? (
                            initialLanguage.map((res, index) => (
                              <span key={index}>
                                {res.nameUtf8}
                                {index < initialLanguage.length - 1
                                  ? ","
                                  : null}
                              </span>
                            ))
                          ) : (
                            <Fragment>
                              <span key={0}>
                                {initialLanguage[0].nameUtf8},{" "}
                              </span>
                              <span key={1}>
                                {initialLanguage[1].nameUtf8},{" "}
                              </span>
                              <span key={2}>
                                +{initialLanguage.length - 2} more
                              </span>
                            </Fragment>
                          )
                        ) : (
                          <span className="not-set">{t("uploadSection.span")}</span>
                        )}
                      </label>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className="grid-section"
                    style={{ paddingBottom: 0 }}
                  >
                    <Grid item xs={8}>
                      <label className="">{t("uploadSection.label3")}</label>
                    </Grid>
                    <Grid item xs={4} className="txt-rght">
                      <Switch
                        className="matureSwitch"
                        checked={matureContent}
                        onChange={this.handleMatureContent}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
              {/* ) : null} */}
              {/* {!isMobileOnly ? (
                <form>
                  <Grid container spacing={0} className="m-0">
                    <Grid item xs={12} className="grd-frm">
                      <label className="bold">Description</label>
                      <TextField
                        name="name"
                        placeholder="Enter Description"
                        value={description}
                        multiline={true}
                        rows={1}
                        rowsMax={4}
                        variant="outlined"
                        onChange={(e) => this.handleDescriptionChange(e.target.value)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} className="grd-frm">
                      <label className="bold">Genre</label>
                      <FcCustomSearch
                        name="genre"
                        val={initialGenres}
                        optionValue="id"
                        optionLabel="name"
                        source={genres}
                        multiple={true}
                        onChangeSelect={this.handleGenreChange}
                      ></FcCustomSearch>
                    </Grid>
                    <Grid item xs={12} sm={6} className="grd-frm">
                      <label className="bold">Language</label>
                      <FcCustomSearch
                        name="language"
                        val={initialLanguage}
                        optionValue="id"
                        optionLabel="nameUtf8"
                        source={languages}
                        multiple={true}
                        onChangeSelect={this.handleLanguageChange}
                      ></FcCustomSearch>
                    </Grid>
                  </Grid>
                </form>
              ) : //   )}
              // </Formik>
              null} */}
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={4}>
          {!isMobileOnly ? <SelectedVideos slider={true} /> : null}
        </Grid> */}
      </Fragment >
    );
  }
  componentWillUnmount() {
    const { closeModal, commonModal } = this.props;
    if (commonModal) {
      closeModal(ModalType.COMMON);
    }
  }
}

export default compose(withTranslation())(VideoUpload);
