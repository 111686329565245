import React, { PureComponent } from "react";
import "./Mobile-Contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Contact from "../../contact/Contact";

export default class MobileContact extends PureComponent {
  render() {
    return (
      <div className="mobilecontact"><Contact /></div>
    );
  }
}
