import { UPLOAD } from "../../actions";

const moreSelectedReducer = (state = false, action) => {
  switch (action.type) {
    case UPLOAD.CLEAR:
    case UPLOAD.CLEAR_CONTENT:
    case UPLOAD.RESET_MORE_SELECTED:
      return false;
    case UPLOAD.MORE_SELECTED:
      return true;
    // case UPLOAD.PUBLISH_UPLOAD:
    //   return false;
    default:
      return state;
  }
};

export default moreSelectedReducer;
