import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { isServer } from "./store"
//const Languages = ['en', 'ko', 'chi'];
let lng = ""
let setLng = ""
if (!isServer) {
  lng = localStorage.getItem("lang")
  setLng = lng ? lng : "en"
}

console.log(setLng)

//console.log(lng)
i18n
  .use(Backend)
  .use(initReactI18next)
  // .use(LanguageDetector)

  .init({
    debug: true,
    lng: setLng,
    //whitelist: Languages,
    fallbackLng: "en",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
