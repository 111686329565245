import { AUTH } from "../../actions";

const userIdReducer = (state = null, action) => {
  switch (action.type) {
    case AUTH.CLEAR_USER_ID:
      return null;
    case AUTH.USER_ID:
      return action.id;
    default:
      return state;
  }
};

export default userIdReducer;
