import { get } from "lodash";
import { http, headerOptions, fileHeaderOptions } from "../core";
import { URL } from "./_urls.js";

const apiSendOtp = async (data) => {
  const response = await http.put(URL.SEND_OTP_API(), data);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiValidateOtp = async (data) => {
  const response = await http.put(URL.VEALIDATE_OTP_API(), data);
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiGetUserProfile = async (token) => {
  const response = await http.get(URL.PROFILE_API(), headerOptions(token));
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiGetMemes = async (token) => {
  const response = await http.get(URL.GET_MEME_API(), headerOptions(token));
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || [];
};
const apiGetUserFollowers = async (data, token) => {
  const response = await http.post(
    URL.FOLLOWED_API(),
    data,
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiGetUserFollowing = async (data, token) => {
  const response = await http.post(
    URL.FOLLOWING_API(),
    data,
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiGetUsersSearch = async (data, token) => {
  // console.log(data, token);
  const response = await http.get(
    URL.GET_USERS_API(data),
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiFollowUser = async (userId, token) => {
  const response = await http.put(
    URL.FOLLOW_API(userId),
    null,
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiUnFollowUser = async (userId, token) => {
  const response = await http.delete(
    URL.FOLLOW_API(userId),
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiGetOtherUserProfile = async (data, token) => {
  const response = await http.post(
    URL.PROFILE_OTHER_API(),
    data,
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiUpdateProfile = async (data, token) => {
  const response = await http.put(
    URL.PROFILE_API(),
    data,
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiUpdateProfileImage = async (formData, token) => {
  const response = await http.put(
    URL.PROFILE_IMAGE_API(),
    formData,
    fileHeaderOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiRemoveProfileImage = async (token) => {
  const response = await http.delete(
    URL.PROFILE_IMAGE_API(),
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiCategories = async (data, token) => {
  const response = await http.post(
    URL.CATEGORY_API(),
    data,
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};

const apiSound = async (data, token) => {
  const response = await http.post(URL.SOUND_API(), data, headerOptions(token));
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiSelectedSound = async (data, token) => {
  const response = await http.post(
    URL.SOUND_SELECTED_API(),
    data,
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
const apiCategory = async (data, token) => {
  const response = await http.post(
    URL.CATEGORY_API(),
    data,
    headerOptions(token)
  );
  if (response && response.status >= 400) {
    const errorMessage = get(response, "data.message", "");
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response || null;
};
export {
  apiSendOtp,
  apiValidateOtp,
  apiUpdateProfile,
  apiUpdateProfileImage,
  apiRemoveProfileImage,
  apiGetUserProfile,
  apiGetOtherUserProfile,
  apiGetUserFollowers,
  apiGetUserFollowing,
  apiFollowUser,
  apiUnFollowUser,
  apiGetUsersSearch,
  apiCategories,
  apiCategory,
  apiSound,
  apiSelectedSound,
  apiGetMemes,
};
