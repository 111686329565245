import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as ReportUserModal } from "./report-user-modal";
import {
  closeModal,
  setReportContentId,
  reportContent,
  startScrollTimer,
  setReportContentText,
  openModal,
  stopScrollTimer,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.report_user || false,
  reportUserProfile: state.ui.modal.report_user_profile || false,
  isContentDetailOpen: state.content.isContentDetailOpen,
  contentAlreadyVisited: state.content.contentAlreadyVisited,
  reportReasons: state.master.report,
  error: state.content.error,
  commentBox: state.ui.commentBox,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  setReportContentId: (reportId) => dispatch(setReportContentId(reportId)),
  setReportContentText: (reportText) => dispatch(setReportContentText(reportText)),
  startScrollTimer: () => dispatch(startScrollTimer()),
  stopScrollTimer: () => dispatch(stopScrollTimer()),
  reportContent: () => dispatch(reportContent()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportUserModal));
