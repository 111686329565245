import React, { PureComponent } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import {
  ChromePicker
} from "react-color";
import reactCSS from 'reactcss'
import axios from "axios";
//import Draggable from "react-draggable";
import { ModalType } from "../../../../constant";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
//import CreateMeme from "./create-meme";
import "./meme-modal.scss";
import { UploadTab } from "../../../../constant";
import html2canvas from "html2canvas";
import { withStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import { isServer, closeModal } from "../../../../store";
import GridListTile from "@material-ui/core/GridListTile";
import IconTabs from "./settingTab";
import Draggable from 'react-draggable';

const divStyle = {
  overflowY: "scroll",
  float: "left",
  position: "relative",
};
const fontArray = [
  "URW Chancery L, cursive",
  "Helvetica, sans-serif",
  "American Typewriter, serif",
];

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",

    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
});

class MemeModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      topText: "Type here",
      bottomText: "Type here",
      textTSize: "",
      textBSize: "",
      tColor: "",
      bColor: "",
      myBFont: "",
      myTFont: "",
      baseImage: "",
      myth2Top: "-25px",
      myth2Left: "102px",
      mybh2Top: "104px",
      mybh2Left: "116px",
      currentText: "",
      sliderValue: 20,
      createMeme: false,
      handletab: 0,
      activeDrags: 0,
      displayColorPicker: false,

    };
    const localMemeImages = localStorage.getItem("memeImages");
    this.memeImages = localMemeImages ? JSON.parse(localMemeImages) : [];
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleTabs = (value) => {
    this.setState({ handletab: value });
    console.log(value);
  };
  editImageHandler = () => {
    var myImage = document.getElementById("myimg");
    const lMemeImage = localStorage.getItem("baseImage");
    const checkMemeimage = lMemeImage ? JSON.parse(lMemeImage) : [];
    this.setState({ baseImage: checkMemeimage, createMeme: true });
  };
  editPropertiesHandle = () => {
    const lMemeSetting = localStorage.getItem("memeProperties");
    const checkMemeimageSetting = lMemeSetting ? JSON.parse(lMemeSetting) : {};
    this.setState({
      myth2Top: checkMemeimageSetting.myth2Top,
      myth2Left: checkMemeimageSetting.myth2Left,
      mybh2Top: checkMemeimageSetting.mybh2Top,
      mybh2Left: checkMemeimageSetting.mybh2Left,
      tColor: checkMemeimageSetting.tColor,
      bColor: checkMemeimageSetting.bColor,
      textTSize: checkMemeimageSetting.textTSize,
      textBSize: checkMemeimageSetting.textBSize,
      myTFont: checkMemeimageSetting.myTFont,
      myBFont: checkMemeimageSetting.myBFont,
      topText: checkMemeimageSetting.topText,
      bottomText: checkMemeimageSetting.bottomText,
    });
  };
  handleChangeComplete = (color) => {
    if (this.state.currentText === "myTh2") {
      this.setState({ tColor: color.hex });
    } else {
      this.setState({ bColor: color.hex });
    }
  };
  componentDidMount() {
    const { modal, anchorEl, open, imageFile, modalw } = this.props;
    if (modal) {
      this.editImageHandler();
      this.editPropertiesHandle();
    } else {
      this.editPropertiesHandle();
    }
  }

  componentWillUnmount() { }
  getBase64 = (url) => {
    var myImage = document.getElementById("myimg");
    return axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const img = Buffer.from(response.data, "binary").toString("base64");
        const createImageData = `data:image/jpeg;base64,${img}`;
        localStorage.setItem("baseImage", JSON.stringify(createImageData));

        myImage.src = createImageData;
        this.setState({ createMeme: true });
      })
      .catch((ex) => {
        console.error(ex);
      });
  };
  handleMemeModal = (url) => {
    this.getBase64(url);
  };
  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  addValidFile = (preview) => {
    const { activeTab, setUploadImage, setUploadVideo, imageFile } = this.props;
    var filename = `${this.getRandomInt(13231232131)}.jpg`;
    const file = this.dataURLtoFile(preview, filename);
    activeTab === UploadTab.IMAGE
      ? setUploadImage({ file, preview })
      : setUploadVideo({ file, preview });
  };
  /*   toDataURL = (src, callback, outputFormat) => {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      console.log(dataURL);
      callback(dataURL);
    };

    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }; */

  handleProperties = () => {
    const {
      myth2Top,
      myth2Left,
      mybh2Top,
      mybh2Left,
      tColor,
      topText,
      bottomText,
      myTFont,
      myBFont,
      textTSize,
      textBSize,
      bColor,
    } = this.state;
    const wMemeProperties = {
      myth2Top: myth2Top,
      myth2Left: myth2Left,
      mybh2Top: mybh2Top,
      mybh2Left: mybh2Left,
      tColor: tColor,
      bColor: bColor,
      textTSize: textTSize,
      textBSize: textBSize,
      myTFont: myTFont,
      myBFont: myBFont,
      topText: topText,
      bottomText: bottomText,
    };
    localStorage.setItem("memeProperties", JSON.stringify(wMemeProperties));
  };
  takeScreenShot = () => {
    document.getElementById("myimg").focus();
    this.handleProperties();
    html2canvas(document.querySelector("#mymeme")).then((canvas) => {
      const base64Canvas = canvas.toDataURL("image/jpeg");
      this.addValidFile(base64Canvas);
      this.handleClose();
      var a = document.createElement("a");
      a.href = base64Canvas;
      a.download = `${this.getRandomInt(1234567800)}.jpg`;
    });
  };
  updateSlider = (event) => {
    if (this.state.currentText === "myTh2") {
      this.setState({ textTSize: event.target.value });
    } else {
      this.setState({ textBSize: event.target.value });
    }
  };
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClosePiker = () => {
    this.setState({ displayColorPicker: false })
  };
  handleDragStop = () => this.props.update(this.state.textSize);
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }
  /*  changeColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    this.setState({ yellow: "yellow" });
  }; */
  handleSubmit(event) {
    event.preventDefault();
    html2canvas(document.querySelector("#mymeme")).then((canvas) => {
      document.body.appendChild(canvas);
    });
  }
  removeFile = (index) => {
    const { images, updateUploadImage } = this.props;
    images.splice(index, 1);
    updateUploadImage(images);
  };
  handleClose = () => {
    const { closeModal, openModal, imageFile, modalw } = this.props;
    if (imageFile.length > 1) {
      this.removeFile(0);
    }
    closeModal(modalw ? ModalType.MEMEMODALW : ModalType.MEMEMODAL);
  };
  setFtFamily = (index, font) => {
    if (this.state.currentText === "myTh2") {
      this.setState({ myTFont: font });
    } else {
      this.setState({ myBFont: font });
    }
  };

  handleDrag = (e, ui) => {
    const { myth2Top, myth2Left } = this.state;
    this.setState({

      myth2Left: myth2Left + ui.deltaX,
      myth2Top: myth2Top + ui.deltaY,

    });
  };

  onStart = (e) => {
    this.setState({ activeDrags: ++this.state.activeDrags });
    const { currentText } = this.state
    if (e.target.id === "myTh2header") {
      this.setState({
        currentText: "myTh2"
      })
    } else {
      this.setState({
        currentText: "myBh2"
      })
    }

  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
  };
  onDrop = (e) => {
    this.setState({ activeDrags: --this.state.activeDrags });

    if (e.target.classList.contains("drop-target")) {
      alert("Dropped!");
      e.target.classList.remove('hovered');
    }

  };
  onDropAreaMouseEnter = (e) => {
    if (this.state.activeDrags) {
      e.target.classList.add('hovered');
    }


  }
  onDropAreaMouseLeave = (e) => {
    e.target.classList.remove('hovered');
  }

  // For controlled component
  /*   adjustXPos = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { x, y } = this.state.controlledPosition;
      this.setState({ controlledPosition: { x: x - 10, y } });
    };
  
    adjustYPos = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { controlledPosition } = this.state;
      const { x, y } = controlledPosition;
      this.setState({ controlledPosition: { x, y: y - 10 } });
    };
  
    onControlledDrag = (e, position) => {
      const { x, y } = position;
      this.setState({ controlledPosition: { x, y } });
    };
  
    onControlledDragStop = (e, position) => {
      this.onControlledDrag(e, position);
      this.onStop();
    }; */
  render() {
    const { modal, anchorEl, open, imageFile, modalw, classes, t } = this.props;
    const {
      topText,
      bottomText,
      tColor,
      textTSize,
      myTFont,
      myBFont,
      mybh2Left,
      mybh2Top,
      myth2Left,
      myth2Top,
      bColor,
      textBSize,
      createMeme,
      handletab,
      currentText,
    } = this.state;
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '36px',
          borderRadius: '2px',
          background: tColor ? tColor : bColor,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          top: "10px"
        },
        popover: {
          position: "absolute",
          zIndex: '9199',
          right: "20px",
          top: "10px"
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal || modalw}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal || modalw}>
          <div className="paper meme-modal">
            {/* <button
              className="closeBtn mobile-hidden"
              onClick={this.handleClose}
            ></button> */}
            <div className="sub-header text-left tab-hidden">
              <button
                className="back-btn tab-hidden"
                onClick={this.handleClose}
              ></button>
              <h1>{t("mememodal.label6")}</h1>
            </div>

            <div className="p-50">
              {modalw ? (
                <Grid container spacing={0}>
                  <div className={classes.root}>
                    <GridList className={classes.gridList} cols={isMobileOnly ? 3.5 : 5.5}>
                      {this.memeImages.map((tile) => (
                        <GridListTile key={tile.id} cols={tile.cols || 1}>
                          <img
                            src={tile.url}
                            onClick={() => this.handleMemeModal(tile.url)}
                          />
                        </GridListTile>
                      ))}
                    </GridList>
                  </div>
                </Grid>
              ) : null}
              <Grid container spacing={0}>
                <Grid item xs={6} justify="center">
                  <IconTabs onTabClick={this.handleTabs} />
                </Grid>

                <Grid item xs={6} className="h50">
                  {handletab == 0 ? (
                    <Grid item xs={12}>
                      <input
                        type="range"
                        min="12"
                        max="42"
                        id="size-slider"
                        //value={20}
                        onChange={this.updateSlider}
                        onDragStop={this.handleDragStop}
                      />
                    </Grid>
                  ) : null}
                  {handletab == 1 ? (
                    <Grid item xs={12}>
                      {fontArray.map((item, index) => (
                        <button
                          className={"size-btn"}
                          key={index}
                          id={`fontbt${index}`}
                          fontFamily={item}
                          onClick={() => this.setFtFamily(index, item)}
                        >
                          Aa
                        </button>
                      ))}
                    </Grid>
                  ) : null}

                  {handletab == 2 ? (
                    <Grid item xs={12}>
                      <div style={styles.swatch} onClick={this.handleClick}>
                        <div style={styles.color} />
                      </div>
                      {this.state.displayColorPicker ? <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClosePiker} />
                        <ChromePicker color={currentText == "myTh2" ? tColor : bColor} onChange={this.handleChangeComplete} />
                      </div> : null}
                      {/* < 
                        color={currentText == "myTh2" ? tColor : bColor}
                        onChange={this.handleChangeComplete}
                      /> */}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12}><br></br></Grid>
                <Grid item xs={12} sm={6}>
                  <label className="bold">{t("mememodal.label1")}</label>
                  <input
                    type="text"
                    name="topText"
                    value={this.state.topText}
                    placeholder=""
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className="bold">{t("mememodal.label2")}</label>
                  <input
                    type="text"
                    name="bottomText"
                    value={this.state.bottomText}
                    placeholder=""
                    onChange={this.handleChange}
                  />
                </Grid>


                <Grid item xs={12} className="mt10">
                  <label className="bold">{t("mememodal.label4")}</label>
                  <div className="meme" id="mymeme">
                    {modal || modalw ? (
                      <img id="myimg" src={this.state.baseImage} alt="" />
                    ) : null}

                    <Draggable
                      bounds="parent" {...dragHandlers}
                    >
                      <div

                        id="myTh2"
                        style={{
                          top: `${myth2Top}`,
                          left: `${myth2Left}`,
                        }}
                      >
                        <h2
                          className="top"
                          id="myTh2header"
                          style={{
                            color: tColor,
                            fontSize: `${textTSize}px`,
                            fontFamily: myTFont,
                          }}
                        /*  onClick={() => {
                           if (!isServer) { this.dragElement(document.getElementById("myTh2")) }
                         }} */
                        >
                          {topText}
                        </h2>
                      </div></Draggable>
                    <Draggable
                      bounds="parent" {...dragHandlers}
                    >
                      <div
                        id="myBh2"
                        style={{
                          top: `${mybh2Top}`,
                          left: `${mybh2Left}`,
                        }}
                      >
                        <h2
                          id="myBh2header"
                          className="bottom"
                          style={{
                            color: bColor,
                            fontSize: `${textBSize}px`,
                            fontFamily: myBFont,
                          }}
                        /*  onClick={() =>
                           this.dragElement(document.getElementById("myBh2"))
                         } */
                        >
                          {bottomText}
                        </h2>
                      </div></Draggable>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} justify="center">
                  <button
                    className={"submitButton no-shadow"}
                    onClick={
                      createMeme
                        ? this.takeScreenShot
                        : () => alert("please Select Image first")
                    }
                  >
                    {t("mememodal.label5")}
                  </button>
                </Grid>
              </Grid>
              {/* <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
              </Grid> */}
            </div>
          </div>
          {/* <div className="paper login-modal">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div
                  className="myimage"
                  style={{ height: "400px", width: "500px" }}
                >
                  {imageFile.length ? (
                    <img
                      src={imageFile[0].preview}
                      alt=""
                      style={{ height: "400px", width: "500px" }}
                    />
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </div> */}
        </Fade>
      </Modal>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(useStyles, { withTheme: true })
)(withRouter(MemeModal))

//import { compose } from "redux";
//import { withRouter } from "react-router";
/* export default withRouter(
  compose(withTranslation())(
    withStyles(useStyles, { withTheme: true })(MemeModal)
  )
); */

/*
dragElement = ((elmnt) => {
  this.setState({ currentText: elmnt.id });
  let elementDrg = elementDrag.bind(this);
  let closeDragElem = closeDragElement.bind(this);
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;

    document.onmouseup = closeDragElem;
    document.onmousemove = elementDrg;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    if (this.state.currentText === "myTh2") {
      this.setState({
        myth2Top: elmnt.offsetTop - pos2 + "px",
        myth2Left: elmnt.offsetLeft - pos1 + "px",
      });
    } else if (this.state.currentText === "myBh2") {
      this.setState({
        mybh2Top: elmnt.offsetTop - pos2 + "px",
        mybh2Left: elmnt.offsetLeft - pos1 + "px",
      });
    }
  }
  function closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;
    this.setState({ currentText: "" });
  }
}).bind(this); */