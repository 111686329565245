import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { snakeCase } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { ButtonBase, Menu, MenuItem } from "@material-ui/core";
import { Like, Comment, Share, MoreOption } from "../../../icons";
import { styles } from "./card-footer.style";
import { kFormatter, getProfileImageUrl, getFirstName } from "../../../core";
import {
  ModalType,
  ContentAction,
  ShareType,
  DeletePost,
  ContentUserAction,
  SelectedText,
} from "../../../constant";
import "./card-footer.scss";
import Grid from "@material-ui/core/Grid";
import { isMobileOnly } from "react-device-detect";
import { withRouter } from "react-router";
class CardFooter extends PureComponent {
  state = { anchorEl: null };

  handleClose = () => this.setState({ anchorEl: null });

  handleUploadedOptionClick = (event, content) => {
    const { setActiveContent } = this.props;
    setActiveContent(content);
    this.setState({ anchorEl: event.currentTarget });
  };

  handleShareClick = (modal, contentIndex) => {
    const {
      openModal,
      setActiveContentIndex,
      setShareType,
      setInitialSlide,
    } = this.props;
    setShareType(ShareType.CONTENT);
    setActiveContentIndex(contentIndex);
    setInitialSlide(contentIndex);
    openModal(modal);
  };

  handleActionClick = (contentId, index, showComment = false) => {
    const {
      setActiveContentIndex,
      openModal,
      loadFromSession,
      setInitialSlide,
      contentOpen,
      showCommentBox,
      isLoggedIn,
      showSelectedText,
      content,
    } = this.props;
    const encryptedUrl = btoa(contentId);
    const contentPath = content?.contentUrl[0]?.contentOcr
      ? `/${snakeCase(
        content?.contentUrl[0]?.contentOcr
      )}/content/${encryptedUrl.replace(/=/g, "")}`
      : `/content/${encryptedUrl.replace(/=/g, "")}`;
    if (!loadFromSession) {
      if (showComment && isLoggedIn) {
        contentOpen();
        setActiveContentIndex(index);
        setInitialSlide(index);
        showCommentBox();
        openModal(ModalType.CONTENT_DETAIL);
      } else {
        openModal(ModalType.LOGIN);
        showSelectedText(SelectedText.COMMENT_TEXT);
      }

      window.history.pushState("content detail", "tymoff", contentPath);
    } else window.open(contentPath, "_self");
  };

  handleLikeClick = (isLike, contentId, index) => {
    const {
      setContentUserAction,
      isLoggedIn,
      openModal,
      loadFromSession,
      showSelectedText,
      setActiveContentIndex,
    } = this.props;
    if (!loadFromSession) {
      if (isLoggedIn) {
        setActiveContentIndex(index);
        isLike
          ? setContentUserAction(ContentUserAction.UNLIKE)
          : setContentUserAction(ContentUserAction.LIKE);
      } else {
        openModal(ModalType.LOGIN);
        showSelectedText(SelectedText.LIKE_TEXT);
      }
    }
  };

  handleDeleteContent = () => {
    this.setState({ anchorEl: null });
    const { setAlertAction } = this.props;
    setAlertAction(DeletePost);
  };

  reduceStringChar = (w) => {
    return w.length > 10 ? `${w.substr(0, 10)}...` : w;
  };
  toUpper = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        if (word[0]) {
          return word[0].toUpperCase() + word.substr(1);
        } else {
          return null;
        }
      })
      .join(" ");
  };
  handleLabelClick = (userId) => {
    console.log(userId)
    const {
      history,
      setAuthUserId,
      loadOtherUserProfile,
      clearUserId,
      clearContent,
      resetContentPage,
      setContentAction,
      cancelUserLoadContent,
      cancelLoadContent,
      setOtherUser
    } = this.props;
    if (userId) {
      cancelLoadContent();
      cancelUserLoadContent();
      clearContent();
      resetContentPage();
      clearUserId();
      setOtherUser(userId)
      setAuthUserId(userId);
      setContentAction(ContentAction.upload);
      loadOtherUserProfile();
      history.push({
        pathname: `/account/${userId}`,
        state: { userId },
      });
    }
  };
  componentDidMount() {
    const { history, closeModal } = this.props;
    history.listen(() => {
      closeModal(ModalType.MORE_OPTION_DETAIL);
      closeModal(ModalType.MORE_OPTION);
      closeModal(ModalType.SHARE_POPPER);
      closeModal(ModalType.REPORT_USER);
    });
  }

  render() {
    const {
      genres,
      classes,
      content,
      contentIndex,
      contentAction,
      shareClick,
      activeContent,
      history,
      brandName,
      adsIndex,
      adsFooter,
      brandImage,
    } = this.props;
    let genre = null;
    let displayName = null;
    if (!adsFooter) {
      genre = genres.find(
        (gen) => String(gen.id) === String(content.catId)
      );
      displayName = content.userDetail.name
        ? content.userDetail.name
        : genre
          ? ""
          : null;
    }
    const { anchorEl } = this.state;

    return (
      <div className={classes.footer}>
        {!adsFooter ? <div className="footbar">
          <div
            className="username singleLineName"
            onClick={() => this.handleLabelClick(content.userId)}
          >
            <img
              src={getProfileImageUrl(
                content.userDetail.picUrl,
                content.userId,
                displayName
              )}
              alt=""
              onError={(e) => {
                e.target.src = getProfileImageUrl(
                  "",
                  content.userId,
                  displayName
                );
              }}
            />
            <label
              className="singleLineName"
              style={
                history.location.pathname !== "/" && isMobileOnly
                  ? { fontSize: "10px" }
                  : null
              }
            >
              {displayName ? this.toUpper(getFirstName(displayName)) : null}
            </label>
          </div>
          <div className="details">
            <ul className={classes.ul}>
              <li className="cardFooterli">
                <ButtonBase
                  onClick={() =>
                    this.handleLikeClick(
                      content.isLike,
                      content.id,
                      contentIndex
                    )
                  }
                >
                  <Like
                    width={"20px"}
                    height={"20px"}
                    className={content.isLike ? "like-active" : "like-inactive"}
                  />
                  <span className={classes.likeCountStyle}>
                    {kFormatter(content.likeCount)}
                  </span>
                  {/* <span className={classes.likeCountStyle}>{kFormatter(content.likeCount)}</span> */}
                </ButtonBase>
              </li>
              {history.location.pathname == "/" && isMobileOnly ? (
                <Fragment>
                  <li className="cardFooterli">
                    <ButtonBase
                      onClick={() =>
                        this.handleActionClick(content.id, contentIndex, true)
                      }
                    >
                      <Comment
                        width={"20px"}
                        height={"20px"}
                        className="comment"
                      />
                    </ButtonBase>
                  </li>

                  <li className="cardFooterli">
                    <ButtonBase
                      onClick={(event) =>
                        shareClick(event, contentIndex, content)
                      }
                    >
                      {/* <Share width={"14px"} height={"14px"} className="share" /> */}
                      <MoreOption
                        width={"14px"}
                        height={"14px"}
                        className="more-option-content"
                      />
                    </ButtonBase>
                  </li>
                </Fragment>
              ) : null}
            </ul>
          </div>
        </div> : <div className="footbar">
          <div
            className="username singleLineName"

          >
            <img
              src={getProfileImageUrl(
                "",
                adsIndex,
                brandName,
              )}
              alt=""
              onError={(e) => {
                e.target.src = getProfileImageUrl(
                  "",
                  adsIndex,
                  brandName,
                );
              }}
            />
            <label
              className="singleLineName"
              style={
                history.location.pathname !== "/" && isMobileOnly
                  ? { fontSize: "10px" }
                  : null
              }
            >
              Promoted by  {brandName ? this.toUpper(getFirstName(brandName)) : null}
            </label>
          </div>

        </div>}
      </div>
    );
  }
}
CardFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(withRouter(CardFooter));
