import { PREFERENCE } from "../../actions";

const countryReducer = (state = "", action) => {
  switch (action.type) {
    case PREFERENCE.CLEAR:
      return "";
    case PREFERENCE.SET_COUNTRY:
      return action.country;
    default:
      return state;
  }
};

export default countryReducer;