import React, { useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Popper } from "@material-ui/core";
import { styles } from "./share-popper-modal.style";
import Grid from "@material-ui/core/Grid";
import { isServer } from "../../../../store";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
} from "react-share";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Like, Comment, Share, MoreOption } from "../../../../icons";
import { ButtonBase, Menu, MenuItem } from "@material-ui/core";
import {
  ModalType,
  ContentAction,
  ShareType,
  DeletePost,
} from "../../../../constant";
import "./share-popper-modal.scss";
import { ClickAwayListener } from "@material-ui/core";

const SharePopperModal = (props) => {
  const {
    classes,
    modal,
    shareLink,
    contentIndex,
    content,
    open,
    anchorEl,
    closeModal, t
  } = props;

  //32c5ff
  const [copySuccess, setCopySuccess] = useState("Copy");

  const textAreaRef = useRef(null);

  const title = "Trending on tymoff";
  const copyUrl = () => {
    const value = textAreaRef.current.value;

    navigator.clipboard
      .writeText(value)
      .then((result) => {
        setCopySuccess("Copied");
      })
      .catch((err) => {
        setCopySuccess({ copySuccess: err });
      });
  };
  const handleOnClose = () => {
    closeModal(ModalType.SHARE_POPPER);
    closeModal(ModalType.SHARE_POPPER_GRID);
  };
  // const handleClickAway = () => {
  //   console.log('here');
  //   setAnchorEl(null);
  //   setPopoverId(null);
  // }

  // const handleShareClick = (modal, contentIndex, event) => {
  //   const { openModal, setActiveContentIndex,
  //     setShareType, setInitialSlide, setShareAnchorEl,
  //     content, loadShareLink, shareType } = props;
  //   setShareType(ShareType.CONTENT);
  //   // setAnchorEl(anchorEl ? null : event.currentTarget);
  //   //setShareAnchorEl(JSON.stringify(event.currentTarget));
  //   setActiveContentIndex(contentIndex);
  //   setInitialSlide(contentIndex);
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  //   setPopoverId(popoverId ? null : content.id);
  //   setPlacement('bottom-start');
  //   // openModal(modal);
  //   loadShareLink();
  // };

  return (
    <Popper
      style={{ zIndex: 9999 }}
      open={open}
      anchorEl={anchorEl}
      placement={"top-end"}
      transition
    >
      <ClickAwayListener onClickAway={handleOnClose}>
        <div className="share-modal">
          {/* <button className="closeBtn" onClick={this.handleClose}></button> */}
          <div className="modalContent share-modal-icon">
            <div className="modalHeader">
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <h3 id="transition-modal-title" className="modalTitle">
                    {t("contentmore.title")}
                  </h3>
                </Grid>
                <Grid item xs={2}>
                  <FacebookShareButton
                    url={shareLink}
                    quote={title}
                    className={classes.shareButton}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </Grid>
                <Grid item xs={2}>
                  <TwitterShareButton
                    url={shareLink}
                    title={title}
                    className={classes.shareButton}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </Grid>
                <Grid item xs={2}>
                  <WhatsappShareButton
                    url={shareLink}
                    title={title}
                    separator=""
                    className={classes.shareButton}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </Grid>
              </Grid>
            </div>
            <div className="modalBody">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <input
                    ref={textAreaRef}
                    readOnly={true}
                    value={shareLink}
                    placeholder="Url"
                    name="url"
                  />
                  <div className="btn">
                    <button className="btnCopy" onClick={copyUrl}>
                      {copySuccess}
                      {/* {t("contentmore.title1")} */}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

export default compose(withTranslation())(withStyles(styles)(SharePopperModal));
