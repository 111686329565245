import React, { PureComponent } from "react";
import Parser from "html-react-parser";
import { Dialog, Slide } from "@material-ui/core";
import { ModalType, Fonts } from "../../../../constant";
import "./see-more-modal.scss";
import { ContentDetailFooter, ContentDetailHeader } from "../../../containers";
import Comment from "../../comment";
import { getRandomColor } from "../../../../core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class SeeMoreModal extends PureComponent {
  constructor(props) {
    super(props);
  }
  handleClose = () => {
    const { closeModal, loadFromGrid, isOtherModalOpen, startScrollTimer, contentAlreadyVisited } = this.props;
    loadFromGrid && !isOtherModalOpen && !contentAlreadyVisited && startScrollTimer();
    closeModal(ModalType.SEE_MORE);
  };

  render() {
    const { modal, activeContent, commentBox } = this.props;
    const index = activeContent.id % Fonts.length;
    const font = Fonts[index];
    const color = getRandomColor(activeContent?.id);
    const maxContent = activeContent.contentValue;

    return (
      <Dialog
        open={modal}
        className="show-more-detail-modal"
        fullScreen
        // TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="show-more-detail-title"
        aria-describedby="show-more-detail-description"
      >
        <div className="contentPage">
          <button className="closeBtn mobile-hidden" onClick={this.handleClose}></button>
          <div className={"slider-container"}>
            <div key={index} className={`image-container`} tabIndex="0">
              <div className="image-wrapper">
                <ContentDetailHeader content={activeContent} />
                <div className={`story`} style={{ backgroundColor: color, color: "white" }}>
                  <h3 style={{ fontFamily: font, fontSize: 16 }}>{activeContent.contentTitle}</h3>
                  <div className="description">
                    <span>
                      <span className="ql-size-large" style={{ fontFamily: font, fontSize: 16 }}>
                        {Parser(maxContent)}
                      </span>
                    </span>
                  </div>
                </div>
                <ContentDetailFooter />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="show-more-container">
          <div className="text-light2">
            <button className="back-btn" onClick={this.handleClose}>
              Back
            </button>
            <div className="textarea-light">
              <div className="image-text text-box-popup">
                <div>{activeContent.contentTitle}</div>
                <div className="description"  style={{ fontFamily: font, fontSize: 16}}>{Parser(activeContent.contentValue)}</div>
              </div>
            </div>
          </div>
        </div> */}
        {commentBox && <Comment />}
      </Dialog>
    );
  }
}
