const styles = (theme) => ({
  scanTitle: {
    marginTop: 0,
    fontSize: "24px",
    color: "#606060",
    fontWeight: "800",
    marginBottom: "0",
  },
  noAccountLink: {
    maxWidth: 204,
    display: "flex",
    fontSize: 12,
    color: "#707070",
    margin: "20px auto 10px",
  },
});

export { styles };
