import { connect } from "react-redux";
import { default as Preference } from "./preference";
import {
  setAlertAction,
  setAuthError,
  setPreferenceActiveTab,
} from "../../store";

const mapStateToProps = (state) => ({
  activeTab: state.preference.activeTab,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setAlertAction: (alert) => dispatch(setAlertAction(alert)),
  setPreferenceActiveTab: (tab) => dispatch(setPreferenceActiveTab(tab)),
  setAuthError: (error) => dispatch(setAuthError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preference);
