import { UPLOAD } from "../../actions";

const weblinkUploadPercentageReducer = (state = -1, action) => {
    switch (action.type) {
        case UPLOAD.CLEAR:
        case UPLOAD.RESET_LINK_UPLOAD_PERCENTAGE:
            return -1;
        case UPLOAD.SET_LINK_UPLOAD_PERCENTAGE: {
            return action.linkUploadPercentage;
        }
        default:
            return state;
    }
};

export default weblinkUploadPercentageReducer;
