import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as ContentMoreOptionsDetail } from "./content-more-options-detail";
import {
  openModal,
  closeModal,
  hideContent,
  startScrollTimer,
  areModalsOpenOnContentDetail,
  showCommentBox,
  hideCommentBox,
  setErrorMessage,
  setMessage,
  showSelectedText,
  downloadFile,
  setContentUserAction,
  loadShareLink,
  setAlertAction,
  stopScrollTimer,
  loadFollowing, cancelUserLoadContent,
  cancelLoadContent,
  clearUserId,
  clearContent,
  resetContentPage,
  loadOtherUserProfile,
  setAuthUserID,
  setContentAction
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.more_option_detail || false,
  loadFromGrid: state.content.loadFromGrid,
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.data,
  shareLink: state.share.data,
  commentBox: state.ui.commentBox,
  activeContent: state.content.activeContent,
  isOtherModalOpen: areModalsOpenOnContentDetail(state),
  loadFromSession: state.content.loadFromSession,
  contentAction: state.content.contentAction,
  following: state.auth.following,
  followers: state.auth.followers,
});

const mapDispatchToProps = (dispatch) => ({
  loadFollowing: () => dispatch(loadFollowing()),
  showCommentBox: () => dispatch(showCommentBox()),
  hideCommentBox: () => dispatch(hideCommentBox()),
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  hideContent: () => dispatch(hideContent()),
  startScrollTimer: () => dispatch(startScrollTimer()),
  stopScrollTimer: () => dispatch(stopScrollTimer()),
  setErrorMessage: (errorMessage) => dispatch(setErrorMessage(errorMessage)),
  setMessage: (message) => dispatch(setMessage(message)),
  showSelectedText: (text) => dispatch(showSelectedText(text)),
  downloadFile: () => dispatch(downloadFile()),
  loadShareLink: () => dispatch(loadShareLink()),
  setContentUserAction: (userAction) =>
    dispatch(setContentUserAction(userAction)),
  setAlertAction: (action) => dispatch(setAlertAction(action)),
  cancelUserLoadContent: () => dispatch(cancelUserLoadContent()),
  cancelLoadContent: () => dispatch(cancelLoadContent()),
  clearUserId: () => dispatch(clearUserId()),
  clearContent: () => dispatch(clearContent()),
  resetContentPage: () => dispatch(resetContentPage()),
  loadOtherUserProfile: () => dispatch(loadOtherUserProfile()),
  setAuthUserId: (id) => dispatch(setAuthUserID(id)),
  setContentAction: (action) => dispatch(setContentAction(action)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentMoreOptionsDetail)
);
