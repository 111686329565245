
/* eslint-disable */
import React, { PureComponent, Fragment } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { snakeCase } from "lodash";
//import feeling from "../../../../assets/images/feeling.mp3";
//import feeling1 from "../../../../assets/images/feeling1.mp3";
import muteIcon from "../../../../assets/images/muteA.svg";
import unMuteIcon from "../../../../assets/images/unmute.svg";
import {
  Dialog,
  Slide,
  ClickAwayListener,
  ButtonBase,
  Hidden,
  Button
} from "@material-ui/core";
import { LazyImage, CardFooter } from "../../../containers";
import Parser from "html-react-parser";
import IntervalTimer from "react-interval-timer";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { ModalType, ContentTypeId, ShareType } from "../../../../constant";
import { UniqueTag, VideoJsonLD } from "../../../../jsonLD";
import { Slider } from "../../../../modules";
import {
  ContentDetailFooter,
  TabActivity,
  ContentArrow,
  ContentDetailHeader,
} from "../../../containers";
import Comment from "../../comment";
import ContentDetail from "../../content-detail";
import { Logo } from "../../../../icons";
import "./content-modal.scss";
import ContentMoreOptions from "../content-more-options";
import ReportUserModal from "../report-user-modal";
import ReportOther from "../report-other";
import SharePopperModal from "../share-popper-modal";
import { isMobile, isMobileOnly } from "react-device-detect";
import LoadingBar from "react-top-loading-bar";
import { isServer } from "../../../../store";
import { boolean } from "yup";
import { string } from "prop-types";
import shareModal from "../share-modal/share-modal";
import SoundSettingModal from "../sound-setting-modal";
import { apiSound, apiCategory, apiSelectedSound } from "../../../../api";
import luxorAdsImg1 from "../../../../assets/ads/luxor_paker_2/Content_Jotter-Campaign-1200x1200-02.jpg";
import luxorAdsImg2 from "../../../../assets/ads/luxor_paker_1/Content_Pramotion-range-FB-Camp_Final_1200x1200Pixel-02.jpg";
import spawakeAdsImg2 from "../../../../assets/ads/Spawake_Feb/Content_768_1024.jpg";
import jackfruitAdsImg1 from "../../../../assets/ads/jackfruit/Content_Banner_1024px X 768px.jpg";
import byjus from "../../../../assets/ads/byjus/1024x768.jpg";
import heroMoto from "../../../../assets/ads/Hero_Motocorp/Destiny/1024x768.jpg"
import myntra from "../../../../assets/ads/Myntra/1024x768.jpg"
import netmeds from "../../../../assets/ads/Netmeds/1024x768.jpg"
import paytm from "../../../../assets/ads/Paytm/1024x768.jpg"
import policybazaar from "../../../../assets/ads/policybazaar/1024x7681.jpg"
//import from "../../assets/ads"
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let _isOtherModalOpen = true;
let isMyCustomCaw = false;
let trackID = 0;
let soundURL;
let _isMoveCardBlocked = false;
const initialAds = [
  { id: 1, src: luxorAdsImg1, brandName: "Luxor Parker", url: "https://luxor.in/" },
  {
    id: 2,
    src: spawakeAdsImg2,
    brandName: "Spawake",
    url: "https://www.spawake.in/products/whitening-triple-care-serum",
  },
  { id: 3, src: luxorAdsImg2, brandName: "Luxor Parker", url: "https://luxor.in/" },
  {
    id: 4,
    src: jackfruitAdsImg1,
    brandName: "Jackfruit 365",
    url:
      "https://www.amazon.in/Eastern-Jackfruit365-Green-Jackfruit-Flour/dp/B08BXWVKJJ/",
  },
  {
    id: 5,
    src: byjus,
    brandName: "Byjus",
    url:
      "https://byjus.com/",
  },
  {
    id: 6,
    src: heroMoto,
    brandName: "Hero",
    url:
      "https://m.heromotocorp.com/two-wheelers.html",
  },
  {
    id: 7,
    src: myntra,
    brandName: "Myntra",
    url:
      "https://www.myntra.com/",
  },
  {
    id: 8,
    src: netmeds,
    brandName: "Netmeds",
    url:
      "https://www.netmeds.com/",
  },
  {
    id: 9,
    src: paytm,
    brandName: "Paytm",
    url:
      "https://paytm.com/",
  },
  {
    id: 10,
    src: policybazaar,
    brandName: "PolicyBazaar",
    url:
      "https://www.policybazaar.com/",
  },
];

class ContentModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lazyLoad: true,
      progress: 0,
      anchorEl: null,
      userName: null,
      mMuted: false,
      selectedSound: [],
      isLoading: true,
      errors: null,
      stateInitialAds: initialAds,
      adsLoading: true,
      adsImgId: 0,
    };

    const {
      loadFromGrid,
      loadContentDetail,
      match,
      closeModal,
      activeContent,
    } = props;
    if (!loadFromGrid) {
      const { params } = match;
      if (params.contentId) {
        loadContentDetail(params.contentId);
      }
    }
    closeModal(ModalType.MORE_OPTION_DETAIL);
    this.myRef = React.createRef();
  }

  playSongs = () => {
    const { authSounds, token } = this.props;
    const localUserSounds = localStorage.getItem("userSounds");
    const userSounDCheck = localUserSounds ? JSON.parse(localUserSounds) : [];
    const localApiSounds = localStorage.getItem("apiSounds");
    const apiSounds = localApiSounds ? JSON.parse(localApiSounds) : [];
    if (!userSounDCheck.length) {
      console.log(authSounds);
      const userSelectionOfSounds = authSounds ?? [];
      const userSelectedSounds = apiSounds.filter(
        (sound) =>
          !!userSelectionOfSounds.find((userSound) => sound.id === userSound)
      );
      localStorage.setItem("userSounds", JSON.stringify(userSelectedSounds));
    }
    this.setState({
      selectedSound: userSounDCheck.length ? userSounDCheck : apiSounds,
    }, () => document.querySelector("#myCustomAudio").volume = 0.5);
  };
  componentDidMount() {
    console.log(this)
    this.playSongs();
    //document.addEventListener("scroll", this.handleScroll, false);
    let swipe = document.querySelector("#swipeme")
    try {
      var vid = document.getElementById("myCustomAudio");
      vid.volume = 0.1;
      //document.querySelector("#myCustomAudio").volume = 0.01;
      // swipe.addEventListener("touchstart", this.detectswipe, false);
    } catch (error) {

    }

  }
  shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
      // Generate random number
      var j = Math.floor(Math.random() * (i + 1));

      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  };
  handleScroll = () => {
    this.closeOtherModal();
  };

  onEndedAudio = () => {
    const { isLoading, selectedSound, sounds } = this.state;
    var aud = document.getElementById("myCustomAudio");
    trackID += 1;
    if (selectedSound.length > trackID) {
      aud.src = selectedSound[trackID].soundURL;
    } else {
      trackID = 0;
      aud.src = selectedSound[trackID].soundURL;
    }
  };

  renderAudio = () => {
    const { isLoading, selectedSound, audioMuted } = this.state;
    console.log(selectedSound);
    if (selectedSound[trackID]) {
      return (
        <div style={{ display: "none" }}>
          <audio
            onEnded={this.onEndedAudio}
            id="myCustomAudio"
            ref={this.myRef}
            controls
            autoPlay
            volume={0.1}
            muted={this.state.mMuted}
          >
            <source
              src={selectedSound[trackID].soundURL}
              type="audio/mp3"
            ></source>

            {/* {sounds.length >= 1 ? (
              <source src={sounds[trackID].soundURL} type="audio/mp3"></source>
            ) : (
              <source src={feeling} type="audio/mp3"></source>
            )} */}
          </audio>
        </div>
      );
    } else {
      return null;
    }
  };
  renderCustomVolume = () => {
    return (
      <div className="volume-bar-wrapper">
        {!isMobileOnly ? (
          <button
            onClick={togglePlayPause}
            className="play-pause-btn"
            name="forward"
          >
            <img src={playVideo ? pauseIcon : playIcon} alt="Play Icon" />
          </button>
        ) : (
          <div></div>
        )}
      </div>
    );
  };
  renderAds = (index) => {

    const { stateInitialAds, state2InitialAds } = this.state;

    return (
      <div>
        {!isServer ? <><img

          onClick={() => window.open(stateInitialAds[index].url)}
          src={stateInitialAds[index].src}
          className={"detailAds"}
        />
          <ContentDetailFooter
            brandName={stateInitialAds[index].brandName}
            adsIndex={index}
            adsFooter={true}
            brandImage={stateInitialAds[index].brandImage}
          />
        </> : null}
      </div>
    );
  };
  handleOnClose = () => {
    document.body.style.position = "";
    const {
      loadFromGrid,
      history,
      hideCommentBox,
      closeModal,
      isOtherModalOpen,
      contentClose,
      commentBox,
      stopProgressBar,
      soundSettingModal,
    } = this.props;
    if (soundSettingModal == true) {
      stopProgressBar();
      return;
    }

    if (commentBox) {
      hideCommentBox();
    } else if (!isOtherModalOpen) {
      if (this.loader) {
        clearInterval(this.loader);
      }
      if (this.timeout) {
        clearTimeout(this.timeout);
        clearTimeout(this.timeout2);
      }
      hideCommentBox();
      contentClose();
      if (_isOtherModalOpen) {
        this.closeOtherModal();
      }

      // this.resetLoader();
      loadFromGrid && history.goBack();
    }
  };

  /*   handleClickAway = () => {
    let { isOtherModalOpen } = this.props;

    if (_isOtherModalOpen) {
      this.handleOnClose();
    } else {
      _isOtherModalOpen = !_isOtherModalOpen;
    }
  }; */
  closeOtherModal = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.CONTENT_DETAIL);
    closeModal(ModalType.SHARE_POPPER);
    closeModal(ModalType.MORE_OPTION);
    closeModal(ModalType.MORE_OPTION_DETAIL);
    closeModal(ModalType.REPORT_USER);
    closeModal(ModalType.REPORT_USER_DETAIL);
    closeModal(ModalType.SOUND_SETTING);
  };

  // startLoader = (loadingTime) => {
  //   this.resetInterval();
  //   this.resetTimeout();
  //   const timerDivision = loadingTime + 2000 / 5;

  //   this.timeout = setTimeout(() => {
  //     this.handleContentAutoScroll();
  //   }, loadingTime);

  // }
  startLoading = () => {
    const { startProgressBar, stopProgressBar, progress } = this.props;
    this.setState({ progress: 20 });
    this.loader = setInterval(() => {
      this.setState({ progress: this.state.progress + 20 });
      startProgressBar(this.state.progress);
    }, 500);
  };

  resetLoader() {
    const { startProgressBar, stopProgressBar } = this.props;
    this.setState({ progress: 0 });
    this.resetInterval();
    stopProgressBar();
  }

  handleContentAutoScroll = () => {
    const {
      totalContent,
      activeContentIndex,
      setActiveContentIndex,
      stopScrollTimer,
      hideNextArrow,
      showNextArrow,
      startProgressBar,
      currentScrollTime,
      soundSettingModal,
    } = this.props;
    const timeout =
      currentScrollTime > 5000 ? +(currentScrollTime - 2000) : 5000;
    //showNextArrow();
    this.startLoading();

    this.timeout2 = setTimeout(() => {
      stopScrollTimer();
      if (
        !!(activeContentIndex + 1 < totalContent) &&
        soundSettingModal == false
      ) {
        setActiveContentIndex(activeContentIndex + 1);
        this.slider && this.slider.slickNext();
      }
      //this.startLoader(timeout);
      //hideNextArrow();
      startProgressBar(98);
      this.resetLoader();
    }, 2000);
  };

  renderUniqueTag = () => {
    const { activeContent, genres } = this.props;
    const selectedCategory = genres.filter(
      (gen) => gen.id == activeContent.catId
    );

    const {
      contentTitle,
      contentValue,
      contentUrl,
      typeId,
      id,
    } = activeContent;
    const description =
      typeId === ContentTypeId.Images ? contentUrl[0].contentOcr : contentValue;
    const encryptedUrl = btoa(id);
    const contentUniqueUrl = `https://www.tymoff.com/content/${encryptedUrl.replace(
      /=/g,
      ""
    )}`;
    let contentImageUrl;
    let title;
    if (typeId === ContentTypeId.Images || typeId === ContentTypeId.Videos) {
      contentImageUrl =
        typeId === ContentTypeId.Images
          ? contentUrl[0].url
          : contentUrl[0].thumbnailImage;
      title = contentTitle || contentUrl[0].contentOcr;
      if (!title) {
        // title = !!selectedCategory.length ? `Best ${selectedCategory[0].name} on tymoff` : `Best ${typeId === ContentTypeId.Images ? "images" : "videos"} on tymoff`;
        title = "Let's take some týmoff";
      }
    } else {
      contentImageUrl = "https://www.tymoff.com/logo.png";
      title = contentTitle;
      if (!title) {
        //title = !!selectedCategory.length ? `Best ${selectedCategory[0].name} on tymoff` : `Best ${typeId === ContentTypeId.Text ? "content" : "articles"} on tymoff`;
        title = "Let's take some týmoff";
      }
    }
    return (
      <UniqueTag
        Title={
          title.substring(0, 50).replace(/(?:\r\n|\r|\n)/g, " ") || "tymoff"
        }
        ContentUrl={contentUniqueUrl}
        Description={
          description
            ? description.substring(0, 150).replace(/(?:\r\n|\r|\n)/g, " ")
            : "tymoff"
        }
        ContentImageUrl={contentImageUrl}
      />
    );
  };

  renderVideoTag = () => {
    const { activeContent, genres } = this.props;
    const {
      contentTitle,
      contentValue,
      contentUrl,
      typeId,
      id,
      modifiedTime,
    } = activeContent;
    if (typeId === ContentTypeId.Videos) {
      const selectedCategory = genres.filter(
        (gen) => gen.id == activeContent.catId
      );
      const description = contentUrl[0].contentOcr || contentValue;
      let title = contentTitle || contentUrl[0].contentOcr;
      const encryptedUrl = btoa(id);
      const contentUniqueUrl = `https://www.tymoff.com/content/${encryptedUrl.replace(
        /=/g,
        ""
      )}`;
      const contentImageUrl = contentUrl[0].thumbnailImage;
      if (!title) {
        title = !!selectedCategory.length
          ? `Best ${selectedCategory[0].name} on tymoff`
          : `Best ${typeId === ContentTypeId.Images ? "images" : "videos"
          } on tymoff`;
      }
      return (
        <VideoJsonLD
          ContentUrl={contentUniqueUrl}
          Title={title.substring(0, 50).replace(/(?:\r\n|\r|\n)/g, " ")}
          ContentImageUrl={contentImageUrl}
          Description={
            description
              ? description.substring(0, 150).replace(/(?:\r\n|\r|\n)/g, " ")
              : "tymoff"
          }
          EmbedUrl={contentUniqueUrl}
          UploadDate={modifiedTime}
        />
      );
    } else {
      return null;
    }
  };

  moveNext = () => {
    const {
      totalContent,
      activeContentIndex,
      closeModal,
      hideCommentBox,
      shareModal,
      commentBox,
      soundSettingModal,
    } = this.props;

    if (!!(activeContentIndex + 1 < totalContent && !soundSettingModal)) {
      if (isMobileOnly && commentBox) {
      } else {
        this.slider.slickNext();
      }
      closeModal(ModalType.MORE_OPTION);
      closeModal(ModalType.REPORT_USER);
      closeModal(ModalType.REPORT_OTHER);
      closeModal(ModalType.SHARE_POPPER);
      if (!isMobileOnly) {
        hideCommentBox();
      }
    }

  };

  movePrev = () => {
    const {
      activeContentIndex,
      closeModal,
      hideCommentBox,
      commentBox,
    } = this.props;
    if (activeContentIndex > 0) {
      if (isMobileOnly && commentBox) {
      } else {
        this.slider.slickPrev();
      }
      closeModal(ModalType.MORE_OPTION);
      closeModal(ModalType.REPORT_USER);
      closeModal(ModalType.REPORT_OTHER);
      closeModal(ModalType.SHARE_POPPER);
      if (!isMobileOnly) {
        hideCommentBox();
      }
    }
  };

  beforeChange = (current, next) => {
    setTimeout(() => { this.setState({ adsImgId: this.getRandomInt(10) }) }, 200)
    const { setActiveContentIndex, activeContentIndex } = this.props;
    activeContentIndex !== next && setActiveContentIndex(next);
  };
  afterChange = (current) => {
    const { activeContent, history } = this.props;
    const encryptedUrl = btoa(activeContent.id);
    const contentPath = activeContent?.contentUrl[0]?.contentOcr
      ? `/${snakeCase(
        activeContent?.contentUrl[0]?.contentOcr
      )}/content/${encryptedUrl.replace(/=/g, "")}`
      : `/content/${encryptedUrl.replace(/=/g, "")}`;

    if (!isServer) {
      window.history.replaceState("content detail", "tymoff", contentPath);
    }
  };
  getSliderSettings = (initialSlide, activeContentIndex, commentBox) => {
    return {
      dots: false,
      infinite: false,
      speed: 500,
      lazyLoad: !!(initialSlide === activeContentIndex),
      slidesToShow: 1,
      vertical: true,
      initialSlide: initialSlide,
      touchMove: !commentBox,
      focusOnSelect: true,
      verticalSwiping: true,
      touchThreshold: 5000,
      waitForAnimate: false,
      arrows: false,
      beforeChange: (current, next) => this.beforeChange(current, next),
      afterChange: (current) => this.afterChange(current),
    };
  };

  componentWillUnmount() {
    const { closeModal } = this.props;
    closeModal(ModalType.SOUND_SETTING)
    if (this.loader) {
      clearInterval(this.loader);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      clearTimeout(this.timeout2);
    }
    //!isServer && document.body.classList.remove("prevent-scroll");
  }

  resetInterval = () => {
    if (this.loader) {
      clearInterval(this.loader);
    }
  };
  resetTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  handleMoreOption = (e, userName) => {
    const {
      openModal,
      moreOptionModal,
      closeModal,
      loadShareLink,
      stopScrollTimer,
      startScrollTimer,
      loadFromGrid,
      contentAlreadyVisited,
      loadFromSession,
      setShareType,
      setAnchor,
    } = this.props;

    if (!loadFromSession) {
      if (moreOptionModal) {
        loadFromGrid && !contentAlreadyVisited && startScrollTimer();
        this.setState({ anchorEl: null, userName }, () => {
          closeModal(ModalType.MORE_OPTION);
          closeModal(ModalType.REPORT_USER);
        });
      } else {
        setShareType(ShareType.CONTENT);
        loadShareLink();
        loadFromGrid && stopScrollTimer();
        this.setState({ anchorEl: e.currentTarget, userName }, () => {
          openModal(ModalType.MORE_OPTION);
        });
      }
    }
  };
  handleSoundOption = (e, name) => {
    const {
      openModal,
      moreOptionModal,
      closeModal,
      loadShareLink,
      stopScrollTimer,
      startScrollTimer,
      loadFromGrid,
      contentAlreadyVisited,
      loadFromSession,
      setShareType,
      setAnchor,
      sound_setting,
      isLoggedIn,
    } = this.props;
    console.log({ e, name });
    if (!loadFromSession) {
      if (sound_setting) {
        loadFromGrid && !contentAlreadyVisited && startScrollTimer();
        this.setState({ anchorEl: null }, () => {
          closeModal(ModalType.MORE_OPTION);
          closeModal(ModalType.REPORT_USER);
        });
      } else {
        loadFromGrid && stopScrollTimer();
        if (isLoggedIn) {
          this.setState({ anchorEl: e.currentTarget }, () => {
            openModal(ModalType.SOUND_SETTING);
          });
        } else {
          openModal(ModalType.LOGIN);
        }
      }
    }
  };

  handleAudioPlayer = () => {
    const {
      shareModal,
      activeContent,
      browserTab,
      modal,
      audioMuted,
      soundSettingModal,
    } = this.props;
    var mAudio = document.getElementById("myCustomAudio");
    if (audioMuted == false) {
      if (shareModal) {
        this.setState({ mMuted: true });
      } else if (soundSettingModal) {
        this.setState({ mMuted: true });
      } else if (activeContent.typeId == 2) {
        this.setState({ mMuted: true });
      } else if (!browserTab) {
        this.setState({ mMuted: true });
      } else {
        this.setState({ mMuted: false });
      }
    }
  };

  handleClickAwayListener = () => {
    const { showMoreModal, moreOptionModal, shareModal, installApp, soundSettingModal, report_other } = this.props;
    if (shareModal) {
      isMyCustomCaw = false;
    } else if (moreOptionModal) {
      isMyCustomCaw = false;
    } else if (soundSettingModal) {
      isMyCustomCaw = false;
    } else if (report_other) {
      isMyCustomCaw = false;
    } else if (showMoreModal) {
      isMyCustomCaw = false;
    } else if (installApp) {
      isMyCustomCaw = false;
    } else {
      isMyCustomCaw = "onClick";
    }
  };
  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };
  swipeBlockTimer = () => {
    _isMoveCardBlocked = true
    setTimeout(() => {
      _isMoveCardBlocked = false
    }, 200)
  }
  detectswipe = () => {
    let isTouchMove = false
    let swipe_det = new Object();
    swipe_det.sX = 0;
    swipe_det.sY = 0;
    swipe_det.eX = 0;
    swipe_det.eY = 0;
    var min_x = 20;  //min x swipe for horizontal swipe
    var max_x = 40;  //max x difference for vertical swipe
    var min_y = 70;  //min y swipe for vertical swipe
    var max_y = 90;  //max y difference for horizontal swipe
    var direc = "";


    let ele = document.querySelector("#swipeme");

    try {
      ele.addEventListener('touchstart', (e) => {
        console.log("sssss")
        var t = e.touches[0];
        swipe_det.sX = t.screenX;
        swipe_det.sY = t.screenY;
      }, false);
      ele.addEventListener('touchmove', (e) => {
        isTouchMove = true
        e.preventDefault();
        var t = e.touches[0];
        swipe_det.eX = t.screenX;
        swipe_det.eY = t.screenY;
        console.log("tmmmmm")
      }, false);


      ele.addEventListener('touchend', (e) => {
        console.log("touchend")
        //horizontal detection
        /*  if ((((swipe_det.eX - min_x > swipe_det.sX) || (swipe_det.eX + min_x < swipe_det.sX)) && ((swipe_det.eY < swipe_det.sY + max_y) && (swipe_det.sY > swipe_det.eY - max_y)))) {
           if (swipe_det.eX > swipe_det.sX) direc = "r";
           else direc = "l";
         } */
        if ((((swipe_det.eY - min_y > swipe_det.sY) || (swipe_det.eY + min_y < swipe_det.sY)) && ((swipe_det.eX < swipe_det.sX + max_x) && (swipe_det.sX > swipe_det.eX - max_x)))) {
          if (swipe_det.eY > swipe_det.sY) direc = "d";
          else direc = "u";
        }

        if (direc != "" && !_isMoveCardBlocked && isTouchMove === true) {
          console.log(isTouchMove)
          if (direc === "u") {
            this.swipeBlockTimer()

            this.moveNext();
            //console.log(direc)
          } else if (direc === "d") {
            this.swipeBlockTimer()
            this.movePrev()
            // console.log(direc)
          }
        }
        isTouchMove = false
        direc = "";
      }, false);
    } catch (error) {

    }

  }



  render() {

    const {
      modal,
      contentList,
      initialSlide,
      commentBox,
      isScrollTimerEnable: enabled,
      currentScrollTime,
      loadFromSession,
      activeContentIndex,
      activeContent,
      openModal,
      reportModal,
      reportOther,
      shareModal,
      progress,
      isOtherModalOpen,
      browserTab,
      audioMuted,
      muteAudio,
      unMuteAudio,
      sound_setting,
      brandName,
      adsIndex,
      adsFooter,
      brandImage,
      toggleVideoControl
    } = this.props;

    /*  if (audioMuted == true) {
      this.setState({ mMuted: true });
    }
    if (activeContent.typeId !== 2 && !shareModal && browserTab) {
      this.setState({ mMuted: false });
      this.handleAudioPlayer();
    } */
    if (audioMuted == false) {
      this.setState({ mMuted: false });

      this.handleAudioPlayer();
    } else if (audioMuted == true) {
      this.setState({ mMuted: true });
    }

    this.handleClickAwayListener();
    const settings = this.getSliderSettings(
      initialSlide,
      activeContentIndex,
      commentBox
    );

    const timeout =
      currentScrollTime > 5000 ? +(currentScrollTime - 2000) : 10000;

    return (
      <Dialog
        fullScreen
        open={modal}
        className="modal content-modal"
        onClose={this.handleOnClose}
        TransitionComponent={Transition}
        aria-labelledby="content-title"
        aria-describedby="content-description"
      >
        {!isServer && <LoadingBar progress={progress} height={3} color="red" />}
        <ReactScrollWheelHandler
          upHandler={this.movePrev}
          downHandler={this.moveNext}
        >
          <div className="contentPage">
            {this.renderAudio()}
            <button
              className="closeBtn mobile-hidden"
              onClick={() => this.handleOnClose}
            ></button>
            {/*  <Button className="followButton"
              size="small"
              color="primary"
              type="button" onClick={this.movePrev}>Prev</Button> */}


            {/*   <Button
              className="followButton"
              size="small"
              color="primary"
              type="button" onClick={this.moveNext}>Next</Button> */}
            <TabActivity />
            {loadFromSession && (
              <div className="session-detail">
                <Fragment>
                  <Link to="/">
                    <Logo
                      width={"80px"}
                      height={"24px"}
                      className={`logo-original`}
                    />
                  </Link>
                  <a href="https://www.tymoff.com/categories/travel">Travel</a>
                  <a href="https://www.tymoff.com/categories/motivational">
                    Motivational
                  </a>
                  <a href="https://www.tymoff.com/categories/humor">Humour</a>
                  <a href="https://www.tymoff.com/categories/creativity">
                    Creativity
                  </a>
                  <a href="https://www.tymoff.com/categories/business">
                    Business
                  </a>
                  <a href="https://www.tymoff.com/categories/technology">
                    Technology
                  </a>
                  <a href="https://www.tymoff.com/categories/sports">Sports</a>
                </Fragment>
              </div>
            )}

            {this.renderVideoTag()}
            {this.renderUniqueTag()}
            {/*enabled && currentScrollTime > 0 */}
            <IntervalTimer
              timeout={timeout}
              callback={() => this.handleContentAutoScroll()}
              enabled={enabled && currentScrollTime > 0}
              repeat={false}
            />
            {/*    <div className="overBtnPrev"
              id="swipeme"
              size="small"
              color="primary"
              type="button" onTouchStart={!toggleVideoControl ? () => console.log("") : this.detectswipe}> */}
            <ClickAwayListener
              disableReactTree
              onClickAway={!isMobileOnly && this.handleOnClose}
              mouseEvent={
                typeof isMyCustomCaw == String
                  ? `"${isMyCustomCaw}"`
                  : isMyCustomCaw
              }
            >

              <div
                className={
                  commentBox
                    ? "slider-container comment-active"
                    : "slider-container "
                }
              >

                {!!contentList.length && (


                  <Slider
                    allowMouseEvents={false}
                    {...settings}
                    ref={(slider) => (this.slider = slider)}
                    className={
                      commentBox
                        ? "modal-content comment-active"
                        : "modal-content"
                    }
                  >
                    {/* <div className="followButton overBtnPrev"
                      id="swipeme"
                      size="small"
                      color="primary"
                      type="button" onTouchStart={this.detectswipe}></div> */}
                    {contentList.map((content, index) => (
                      <div
                        key={index}
                        className={`image-container ${loadFromSession ? "session-container" : ""
                          }`}
                        tabIndex="0"
                      >
                        <button
                          className="back-btn tab-hidden"
                          onClick={this.handleOnClose}
                        ></button>


                        <div className="image-wrapper">
                          {/* <ContentDetailHeader moreOptionClick={this.handleMoreOption} content={content} /> */}
                          {index % 10 == 0 && index != 0 ? (
                            <div>{this.renderAds(this.state.adsImgId)}</div>
                          ) : (
                            <>
                              <ContentDetail
                                content={content}
                                contentId={content.id}
                                adsIndex={index}
                              />
                              {activeContent.typeId !== 2 ? <div className={`logoutAsound ${index % 10 === 1 && index >= 10 ? "logoutAsoundAds" : null}`}>
                                <img
                                  src={audioMuted ? muteIcon : unMuteIcon}
                                  alt=""
                                  className="mute-unmute-icon"
                                  onClick={(event) => activeContent.typeId !== 2 ? this.handleSoundOption(event, "sounds-setting") : null}
                                // audioMuted ? unMuteAudio() : muteAudio()

                                /></div> : null}
                              <div className="footerz">
                                <ContentDetailFooter
                                  moreOptionClick={this.handleMoreOption}
                                  //soundOptionClick={this.handleSoundOption}
                                  content={content}
                                  index={index}
                                /></div>
                              {!!activeContent.contentValue &&
                                (activeContent.typeId === ContentTypeId.Images ||
                                  activeContent.typeId ===
                                  ContentTypeId.Videos) && (
                                  <span className={`detail-desc ${index % 10 === 1 && index >= 10 ? "adsDesc" : ""}`}>
                                    {Parser(activeContent.contentValue).length > 80
                                      ? Parser(
                                        activeContent.contentValue.substr(0, 80)
                                      )
                                      : Parser(activeContent.contentValue)}
                                  </span>
                                )}
                              {!!activeContent.contentValue &&
                                (activeContent.typeId === ContentTypeId.Images ||
                                  activeContent.typeId === ContentTypeId.Videos) &&
                                Parser(activeContent.contentValue).length > 80 && (
                                  <ButtonBase
                                    className={`view-more ${index % 10 === 1 && index >= 10 ? "adsView" : ""}`}
                                    onClick={() => {
                                      openModal(ModalType.SHOW_MORE_DETAIL);
                                    }}
                                  >
                                    See more
                                  </ButtonBase>
                                )}
                            </>
                          )}


                          {contentList.length > 1 && <ContentArrow />}



                        </div>
                      </div>
                    ))}
                  </Slider>
                )}
                {commentBox && <Comment />}

                <ContentMoreOptions
                  anchorEl={this.state.anchorEl}
                  userName={this.state.userName}
                />
                <SoundSettingModal
                  anchorEl={this.state.anchorEl}
                  open={SoundSettingModal}
                />
                <SharePopperModal
                  anchorEl={this.state.anchorEl}
                  open={shareModal}
                />
                <ReportUserModal
                  anchorEl={this.state.anchorEl}
                  open={reportModal}
                  openReason={true}
                ></ReportUserModal>
                <ReportOther
                  anchorEl={this.state.anchorEl}
                  open={reportOther}
                ></ReportOther>
              </div>
            </ClickAwayListener>

          </div>
        </ReactScrollWheelHandler>
      </Dialog>
    );
  }
}

export default withRouter(ContentModal)