import { connect } from "react-redux";
import { default as GenreModal } from "./genre-modal";
import { getActiveFilterGenres } from "../../../../store";

const mapStateToProps = (state) => ({
  genres: state.master.genres,
  selectedGenres: getActiveFilterGenres(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GenreModal);
