import React, { useState, useEffect, Fragment, useRef } from "react";
import "./upload.scss";
import { Grid, Button, LinearProgress } from "@material-ui/core";
import { UploadTab, UploadAlert } from "../../constant";
import {
  ImageUpload,
  VideoUpload,
  StoryUpload,
  WebUpload,
} from "../../components";
import { isMobileOnly } from "react-device-detect";
import { PhotoMobile, VideoMobile, StoryMobile, LinkMobile } from "../../icons";
import { withRouter } from "react-router-dom";
import { Prompt } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const Upload = (props) => {
  //const didMountRef = useRef(false);
  //const [hideVideoUpload, setHideVideoUpload] = useState(false);
  //const [hideImageUpload, setHideImageUpload] = useState(false);

  const checkPathname = props.location.pathname;
  //console.log(checkPathname);
  const uploadContent = () => {
    const { resetAddMoreContent, validateUpload, history } = props;
    resetAddMoreContent();
    validateUpload();
  };

  useEffect(() => {
    props.history.listen(() => {
      props.clearUpload();
      localStorage.removeItem("memeProperties");
      localStorage.removeItem("baseImage");
    });
  }, []);
  const handleBack = () => {
    const { history } = props;
    !!history.length ? history.goBack() : history.push("/");
  };

  const handleTabClickImage = () => {
    const { setActiveTab } = props;
    setActiveTab(UploadTab.IMAGE);
  };
  const handleTabClickVideo = () => {
    const { setActiveTab } = props;
    setActiveTab(UploadTab.VIDEO);
  };
  const handleTabClickStory = () => {
    const { setActiveTab } = props;
    setActiveTab(UploadTab.STORY);
  };
  const handleTabClickWeb = () => {
    const { setActiveTab } = props;
    setActiveTab(UploadTab.WEB);
  };
  const handleBlockedNavigation = () => {
    const { setAlertAction, unsavedUploadChange } = props;
    if (unsavedUploadChange) {
      setAlertAction(UploadAlert);
      return false;
    } else return true;
  };

  const {
    t,
    activeTab,
    isLoading,
    error,
    videoUploadParcentage,
    imageUploadPercentage,
    weblinkUploadPercentage,
    storyUploadPercentage,
    resetVideoUploadPercentage,
    resetStoryUploadPercentage,
    resetLinkUploadPercentage,
  } = props;
  return (
    <Fragment>
      {/* <Prompt
        when={props.unsavedUploadChange || props.contents.length >= 1}
        message={handleBlockedNavigation}
      /> */}
      <div className="sub-header text-left tab-hidden">
        <button className="back-btn" onClick={handleBack}></button>
        <h1>{checkPathname == "/upload" ? t("uploadSection.upload.h1") : t("sidebar.title10")}</h1>
      </div>

      <div className="tymoff-container">
        <Grid container className="m-0">
          <Grid item xs={12}>
            <h1
              id="transition-modal-title"
              className="heading my-20 mr-10 font-bold mobile-hidden"
            >
              {checkPathname == "/upload"
                ? t("uploadSection.upload.h1")
                : t("sidebar.title10")}
            </h1>
          </Grid>
          <Grid item xs={12}>
            <div className="progressbar">
              {videoUploadParcentage >= 0 && (
                <div className="bar">
                  <LinearProgress
                    variant="determinate"
                    value={videoUploadParcentage}
                    className="video-pb"
                  />
                  <span>{`${videoUploadParcentage}%`}</span>
                  <CloseIcon
                    className="close"
                    onClick={() => {
                      resetVideoUploadPercentage();
                    }}
                  ></CloseIcon>
                </div>
              )}
              {imageUploadPercentage >= 0 && (
                <div className="bar">
                  <LinearProgress
                    variant="determinate"
                    value={imageUploadPercentage}
                    className="image-pb"
                  />
                  <span>{`${imageUploadPercentage}%`}</span>
                  <CloseIcon
                    className="close"
                    onClick={() => {
                      resetVideoUploadPercentage();
                    }}
                  ></CloseIcon>
                </div>
              )}
              {weblinkUploadPercentage >= 0 && (
                <div className="bar">
                  <LinearProgress
                    variant="determinate"
                    value={weblinkUploadPercentage}
                    className="link-pb"
                  />
                  <span>{`${weblinkUploadPercentage}%`}</span>
                  <CloseIcon
                    className="close"
                    onClick={resetLinkUploadPercentage}
                  ></CloseIcon>
                </div>
              )}
              {storyUploadPercentage >= 0 && (
                <div className="bar">
                  <LinearProgress
                    variant="determinate"
                    value={storyUploadPercentage}
                    className="story-pb"
                  />
                  <span>{`${storyUploadPercentage}%`}</span>
                  <CloseIcon
                    className="close"
                    onClick={resetStoryUploadPercentage}
                  ></CloseIcon>
                </div>
              )}
            </div>
          </Grid>
          {/* mobile-hidden */}
          <Grid
            item
            xs={7}
            className="pref-btn txt-lft desktop-hidden tab-hidden mobile-hidden pb-5"
          >
            {checkPathname == "/upload" ? (
              <>
                {" "}
                <Button
                  variant="contained"
                  className={`mr-10 spl-btn ${UploadTab.IMAGE === activeTab ? "active" : ""
                    }`}
                  onClick={handleTabClickImage}
                  disableElevation
                >
                  Photo
                </Button>
                <Button
                  variant="contained"
                  className={`mr-10 spl-btn ${UploadTab.VIDEO === activeTab ? "active" : ""
                    }`}
                  onClick={handleTabClickVideo}
                  disableElevation
                >
                  Video
                </Button>
                <Button
                  variant="contained"
                  className={`mr-10 spl-btn ${UploadTab.STORY === activeTab ? "active" : ""
                    }`}
                  onClick={handleTabClickStory}
                  disableElevation
                >
                  Story
                </Button>
                <Button
                  variant="contained"
                  className={`mr-10 spl-btn ${UploadTab.WEB === activeTab ? "active" : ""
                    }`}
                  onClick={handleTabClickWeb}
                  disableElevation
                >
                  Link
                </Button>
              </>
            ) : null}
          </Grid>
          {/* desktop-hidden tab-hidden */}
          <Grid item xs={12} className="upload-btn ">
            {checkPathname == "/upload" ? (
              <>
                {" "}
                <Button
                  variant="contained"
                  className={`mr-10 spl-btn img ${UploadTab.IMAGE === activeTab ? "active" : ""
                    }`}
                  onClick={handleTabClickImage}
                  disableElevation
                >
                  <PhotoMobile width={"24px"} height={"24px"} />
                  <label>{t("uploadSection.upload.label1")}</label>
                </Button>
                <Button
                  variant="contained"
                  className={`mr-10 spl-btn vid ${UploadTab.VIDEO === activeTab ? "active" : ""
                    }`}
                  onClick={handleTabClickVideo}
                  disableElevation
                >
                  <VideoMobile width={"30px"} height={"20px"} />
                  <label>{t("uploadSection.upload.label2")}</label>
                </Button>
                <Button
                  variant="contained"
                  className={`mr-10 spl-btn stry ${UploadTab.STORY === activeTab ? "active" : ""
                    }`}
                  onClick={handleTabClickStory}
                  disableElevation
                >
                  <StoryMobile width={"21px"} height={"28px"} />
                  <label>{t("uploadSection.upload.label3")}</label>
                </Button>
                <Button
                  variant="contained"
                  className={`mr-10 spl-btn lnk ${UploadTab.WEB === activeTab ? "active" : ""
                    }`}
                  onClick={handleTabClickWeb}
                  disableElevation
                >
                  <LinkMobile width={"32px"} height={"16px"} />
                  <label>{t("uploadSection.upload.label4")}</label>
                </Button>
              </>
            ) : null}
          </Grid>
          {UploadTab.IMAGE === activeTab && (
            <ImageUpload pathLocation={checkPathname} />
          )}
          {UploadTab.VIDEO === activeTab && <VideoUpload />}
          {UploadTab.STORY === activeTab && <StoryUpload />}
          {UploadTab.WEB === activeTab && <WebUpload />}
          <Grid item xs={12} sm={12}>
            <button
              className="submitButton no-shadow"
              disabled={isLoading}
              onClick={uploadContent}
            >
              {!isLoading ? (
                t("uploadSection.upload.button1")
              ) : (
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              )}
            </button>
            <span className="error">{error}</span>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};
export default withRouter(compose(withTranslation())(Upload));
