import React, { PureComponent } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
} from "@material-ui/core";
import { ModalType } from "../../../../constant";
import "./unfollow-alert-modal.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UnfollowAlertModal extends PureComponent {
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.UNFOLLOW_ALERT);
  };

  handleSuccess = () => {
    const { closeModal, unFollowUser } = this.props;
    closeModal(ModalType.UNFOLLOW_ALERT);
    unFollowUser();
  };

  render() {
    const { modal,t } = this.props;
    return (
      <Dialog
        className="alert-modal"
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="unfollow-alert-dialog-slide-title"
        aria-describedby="unfollow-alert-dialog-slide-description"
      >
        <DialogTitle id="unfollow-alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="unfollow-alert-dialog-slide-description">
            <span>
              {t("editProfile.title2")}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            {t("clearHistoryAlert.cancelText")}
          </Button>
          <Button onClick={this.handleSuccess} color="primary">
            {t("editProfile.title3")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default compose(withTranslation())(UnfollowAlertModal);