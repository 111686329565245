import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as ContentModal } from "./content-modal";
import {
  setActiveContentIndex,
  stopScrollTimer,
  loadContentDetail,
  hideCommentBox,
  routeFromLightbox,
  closeModal,
  openModal,
  clearFilter,
  areModalsOpenOnContentDetail,
  showNextArrow,
  hideNextArrow,
  contentClose,
  startProgressBar,
  stopProgressBar,
  startScrollTimer,
  setShareType,
  loadShareLink,
  disableFooterAds,
  muteAudio,
  unMuteAudio,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.content_detail || false,
  contentList: state.content.data,
  activeContentIndex: state.content.activeContentIndex,
  initialSlide: state.content.initialSlide,
  commentBox: state.ui.commentBox,
  totalContent: state.content.totalContent,
  isScrollTimerEnable: state.content.isScrollTimerEnable,
  displayNextArrow: state.content.showNextArrow,
  loadFromGrid: state.content.loadFromGrid,
  loadFromSession: state.content.loadFromSession,
  activeContent: state.content.activeContent,
  isOtherModalOpen: areModalsOpenOnContentDetail(state),
  currentScrollTime: state.setting.currentScrollTime,
  genres: state.master.genres,
  progress: state.ui.progress,
  moreOptionModal: state.ui.modal.more_option || false,
  contentAlreadyVisited: state.content.contentAlreadyVisited,
  reportModal: state.ui.modal.report_user,
  reportOther: state.ui.modal.report_other,
  shareModal: state.ui.modal.share_popper || false,
  browserTab: state.ui.browserTab,
  showMoreModal: state.ui.modal.show_more_detail || false,
  soundSettingModal: state.ui.modal.sound_setting || false,
  audioMuted: state.content.audioMuted,
  audioLevel: state.content.audioLevel,
  authSounds: state.auth.data.ambientSoundList,
  token: state.auth.token,
  isLoggedIn: state.auth.isLoggedIn,
  sound_setting: state.ui.modal.sound_setting || false,
  installApp: state.ui.installApp,
  toggleVideoControl: state.content.videoControls,
  report_other: state.ui.modal.report_other
});

const mapDispatchToProps = (dispatch) => ({
  clearFilter: () => dispatch(clearFilter()),
  contentClose: () => dispatch(contentClose()),
  startScrollTimer: () => dispatch(startScrollTimer()),
  stopScrollTimer: () => dispatch(stopScrollTimer()),
  hideCommentBox: () => dispatch(hideCommentBox()),
  routeFromLightbox: () => dispatch(routeFromLightbox()),
  showNextArrow: () => dispatch(showNextArrow()),
  hideNextArrow: () => dispatch(hideNextArrow()),
  setActiveContentIndex: (index) => dispatch(setActiveContentIndex(index)),
  loadContentDetail: (contentId) => dispatch(loadContentDetail(contentId)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  openModal: (modal) => dispatch(openModal(modal)),
  startProgressBar: (progress) => dispatch(startProgressBar(progress)),
  stopProgressBar: () => dispatch(stopProgressBar()),
  setShareType: (type) => dispatch(setShareType(type)),
  loadShareLink: () => dispatch(loadShareLink()),
  disableFooterAds: () => dispatch(disableFooterAds()),
  muteAudio: () => dispatch(muteAudio()),
  unMuteAudio: () => dispatch(unMuteAudio()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentModal)
);