import { connect } from "react-redux";
import { default as Slider } from "./slider";
import {
  getActiveFilterGenres,
  clearFilterOnDiscover,
  resetContentPage,
  setFilter,
  clearAction,
  enableMagicStick,
  disableMagicStick,
  updateAuth,
  setGenre,
  openModal,
} from "../../../../../store";

const mapStateToProps = (state) => ({
  genres: state.master.genres,
  authCategory: state.auth.data.tappedCategoryList,
  selectedGenres: getActiveFilterGenres(state),
  filter: state.content.filter,
  contentAction: state.content.contentAction,
  selectedCountries: state.content.filter.countriesList,
  selectedLanguages: state.content.filter.languages,
  magicStick: state.content.magicStick,
  token: state.auth.token,
  user: state.auth.data,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  clearFilterOnDiscover: () => dispatch(clearFilterOnDiscover()),
  resetContentPage: () => dispatch(resetContentPage()),
  setFilter: (filter) => dispatch(setFilter(filter)),
  clearAction: () => dispatch(clearAction()),
  enableMagicStick: () => dispatch(enableMagicStick()),
  disableMagicStick: () => dispatch(disableMagicStick()),
  updateAuth: (user) => dispatch(updateAuth(user)),
  setGenre: (genre) => dispatch(setGenre(genre)),
  openModal: (modal) => dispatch(openModal(modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
