import { UI } from "../../actions";

const progressBarReducer = (state = 0, action) => {
    switch (action.type) {
        case UI.START_PROGRESS_BAR:
            return action.progress;
        case UI.STOP_PROGRESS_BAR:
            return action.progress;
        default:
            return state;
    }
};

export default progressBarReducer;
