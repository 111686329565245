import React from "react";
import "./link.scss";

const Link = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    style={{ enableBackground: "new 0 0 16 16" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <g>
          <path
            className="primary"
            d="M1.1,1.1c1.5-1.5,3.8-1.5,5.3,0l3.4,3.4c0.5,0.5,0.8,1.1,1,1.7l0,0v0.2v0.1v0.2v0.1c0,0.1,0,0.1,0,0.2v0.2
					l0,0v0.2c0,0.1,0,0.3,0,0.4s0,0.2-0.1,0.3v0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2
					C10.2,9.3,10,9.7,9.7,10c-0.1,0-0.4,0.1-0.7,0.1S8.5,10,8.3,9.8c-0.4-0.4-0.4-1.1,0-1.5c0.6-0.6,0.6-1.6,0-2.3L7.4,5.1l0,0
					L4.9,2.6C4.2,2,3.2,2,2.6,2.6S2,4.2,2.6,4.9l2,2C4.7,7,4.7,7.1,4.7,7.2C4.5,7.7,4.3,8.3,4.3,8.9V9c0,0.1-0.1,0.2-0.2,0.3
					c-0.1,0-0.2,0-0.3-0.1L1.1,6.4C-0.4,4.9-0.4,2.5,1.1,1.1z"
          />
          <path
            className="primary"
            d="M6.2,6.2C6.5,6,6.8,5.9,7.1,5.9c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1.1,0,1.5c-0.6,0.6-0.6,1.6,0,2.3
					l3.4,3.4c0.6,0.6,1.6,0.6,2.3,0c0.6-0.6,0.6-1.6,0-2.3l-2-2c-0.1-0.1-0.1-0.2-0.1-0.3c0.2-0.5,0.3-1.1,0.3-1.7V7
					c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.1,0,0.2,0.1L15,9.6c0.7,0.7,1.1,1.6,1.1,2.6c0,2.1-1.7,3.7-3.7,3.7c-1,0-1.9-0.4-2.6-1.1
					l-3.4-3.4c-0.7-0.7-1.1-1.7-1.1-2.6c0-0.7,0.2-1.4,0.6-2C5.9,6.6,6,6.4,6.2,6.2z"
          />
        </g>
      </g>
    </g>
  </svg>
);

const WebLink = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 48 48"
    style={{ enableBackground: "new 0 0 48 48" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      className="primary"
      d="M21.6,34.5l-3.9,3.9c-1.9,1.9-5.1,1.9-7,0c-1.9-1.9-1.9-5.1,0-7l7.8-7.8c1.8-1.8,4.6-1.9,6.6-0.4
	c0.2,0.1,0.3,0.3,0.4,0.4c0.9,0.9,2.2,0.9,3.1,0l2.4-2.4c-0.4-0.6-0.9-1.2-1.4-1.7c-0.7-0.7-1.6-1.3-2.4-1.8
	c-3.4-1.9-7.6-1.8-10.9,0.3c-0.7,0.4-1.3,0.9-1.9,1.5l-7.8,7.7c-4.2,4.2-4.2,11,0,15.3c4.2,4.2,11.1,4.2,15.3,0l7-7
	C26.4,35.8,23.9,35.5,21.6,34.5z"
    />
    <path
      className="primary"
      d="M42.4,6.7c-4.2-4.2-11.1-4.2-15.3,0l-7,7c2.4-0.3,4.9,0,7.2,1l3.9-3.9c1.9-1.9,5.1-1.9,7,0c1.9,1.9,1.9,5.1,0,7
	l-7.8,7.8c-1.8,1.8-4.6,1.9-6.6,0.4c-0.2-0.1-0.3-0.3-0.5-0.4c-0.9-0.8-2.2-0.8-3.1,0L18,28c0.4,0.6,0.9,1.2,1.4,1.7
	c0.7,0.7,1.6,1.3,2.4,1.8c3.4,1.9,7.6,1.8,11-0.3c0.7-0.4,1.3-0.9,1.9-1.5l7.8-7.7C46.7,17.7,46.7,10.9,42.4,6.7z"
    />
  </svg>
);
const WebLink2 = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 16.2 16"
    style={{ enableBackground: "new 0 0 16.2 16" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <path className="primary" d="M8.1,1.2c3.7,0,6.8,3,6.8,6.8c0,3.7-3,6.8-6.8,6.8S1.3,11.7,1.3,8C1.3,4.3,4.3,1.2,8.1,1.2 M8.1,0
			C3.6,0,0,3.6,0,8s3.6,8,8.1,8s8.1-3.6,8.1-8S12.5,0,8.1,0L8.1,0z"/>
      </g>
      <path className="primary" d="M5.2,10L9,6.2H6.3c-0.3,0-0.6-0.3-0.6-0.6C5.7,5.3,5.9,5,6.3,5h4.8v4.8c0,0.3-0.3,0.6-0.6,0.6
		c-0.3,0-0.6-0.3-0.6-0.6V7.1l-3.8,3.7C6,11,5.8,11,5.7,11S5.3,11,5.2,10.8C5,10.6,5,10.2,5.2,10z"/>
    </g>
  </svg>
);
export { Link, WebLink, WebLink2 };
