import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as Followers } from "./followers";
import {
  openModal,
  closeModal,
  editUserInfo,
  updateProfile,
  loadFollowers,
  followUser,
  unFollowUser,
  setSelectedUserID,
  clearUserId,
  clearContent,
  resetContentPage,
  setContentAction,
  setAuthUserID,
  loadOtherUserProfile,
  //setUserId,
} from "../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.followers || false,
  isModalRoute: state.ui.isModalRoute,
  languages: state.master.languages,
  countries: state.master.countries,
  loginUser: state.auth.data,
  otherUser: state.auth.otherUser,
  error: state.auth.error,
  isLoading: state.auth.isLoading,
  followers: state.auth.followers,
  isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  editUserInfo: (user) => dispatch(editUserInfo(user)),
  updateProfile: () => dispatch(updateProfile()),
  loadFollowers: () => dispatch(loadFollowers()),
  setSelectedUserID: (userId) => dispatch(setSelectedUserID(userId)),
  followUser: () => dispatch(followUser()),
  unFollowUser: () => dispatch(unFollowUser()),
  setAuthUserId: (id) => dispatch(setAuthUserID(id)),
  loadOtherUserProfile: () => dispatch(loadOtherUserProfile()),
  clearUserId: () => dispatch(clearUserId()),
  clearContent: () => dispatch(clearContent()),
  resetContentPage: () => dispatch(resetContentPage()),
  setContentAction: (action) => dispatch(setContentAction(action)),
  //setUserId: (userId) => dispatch(setUserId(userId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Followers)
);
