import React from "react";
import "./report-post.scss";

const ReportPost = ({ width, height, className }) => (
  <svg
    className={`svg-icon ${className || ""}`}
    width={width}
    height={height}
    viewBox="0 0 17 19"
    style={{ enableBackground: "new 0 0 17 19" }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Tymoff-website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Post-actions" transform="translate(-828.000000, -337.000000)" fill="#6C7B8A" fillRule="nonzero">
        <g id="Group" transform="translate(811.000000, 132.000000)">
          <g transform="translate(17.000000, 205.000000)" id="Icons-/-message-Copy-3">
            <path
              d="M16.435,18.05 L8.835,0.407142857 C8.58166667,-0.135714286 7.94833333,-0.135714286 7.695,0.407142857 L0.095,18.05 C-0.0316666667,18.3214286 -0.0316666667,18.4571429 0.095,18.7285714 C0.221666667,18.8642857 0.475,19 0.601666667,19 L15.8016667,19 C16.055,19 16.1816667,18.8642857 16.3083333,18.7285714 C16.435,18.5928571 16.435,18.1857143 16.435,18.05 Z M1.26666667,17.8725275 L8.36,1.35714286 L15.4533333,17.8725275 L1.26666667,17.8725275 Z"
              id="Shape"
            ></path>
            <path
              d="M7.56833333,6.10714286 L7.56833333,12.8928571 C7.56833333,13.3 7.82166667,13.5714286 8.20166667,13.5714286 C8.58166667,13.5714286 8.835,13.3 8.835,12.8928571 L8.835,6.10714286 C8.835,5.7 8.58166667,5.42857143 8.20166667,5.42857143 C7.82166667,5.42857143 7.56833333,5.7 7.56833333,6.10714286 Z"
              id="Path"
            ></path>
            <path
              d="M7.695,15.0642857 C7.56833333,15.2 7.56833333,15.3357143 7.56833333,15.6071429 C7.56833333,15.7428571 7.56833333,16.0142857 7.695,16.15 C7.82166667,16.2857143 7.94833333,16.2857143 8.20166667,16.2857143 C8.455,16.2857143 8.58166667,16.2857143 8.70833333,16.0142857 C8.835,15.8785714 8.835,15.7428571 8.835,15.6071429 C8.835,15.4714286 8.835,15.2 8.58166667,15.0642857 C8.455,14.9285714 7.94833333,14.9285714 7.695,15.0642857 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ReportPost;
