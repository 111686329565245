import { combineReducers } from "redux";
import imageReducer from "./image";
import videoReducer from "./video";
import activeTabReducer from "./active-tab";
import imageDescriptionReducer from "./image-description";
import videoDescriptionReducer from "./video-description";
import storyTitleReducer from "./story-title";
import storyDescriptionReducer from "./story-description";
import weblinkReducer from "./weblink";
import errorReducer from "./error";
import activeStepReducer from "./active-step";
import imageLanguageReducer from "./image-language";
import videoLanguageReducer from "./video-language";
import storyLanguageReducer from "./story-language";
import weblinkLanguageReducer from "./weblink-language";
import uploadGenreReducer from "./upload-genre";
import contentDatalistReducer from "./content-datalist";
import contentFileReducer from "./content-file";
import contentPreviewReducer from "./content-preview";
import addMoreContentReducer from "./add-more-content";
import uploadingFilesReducer from "./uploading-files";
import weblinkMetaDetailReducer from "./weblink-meta-detail";
import loadingReducer from "./loading";
import searchGenreReducer from "./search-genre";
import imageGenreReducer from "./image-genre";
import videoGenreReducer from "./video-genre";
import webLinkGenreReducer from "./weblink-genre";
import storyGenreReducer from "./story-genre";
import moreSelectedReducer from "./more-selected";
import uploadLanguageReducer from "./upload-language";
import videoUploadPercentageReducer from "./video-percentage";
import imageUploadPercentageReducer from "./image-percentage";
import weblinkUploadPercentageReducer from "./weblink-percentage";
import storyUploadPercentageReducer from "./story-percentage";
import weblinkMatureContentReducer from "./weblink-mature-content";
import videoMatureContentReducer from "./video-mature-content";
import storyMatureContentReducer from "./story-mature-content";
import imageMatureContentReducer from "./image-mature-content";

const uploadReducer = combineReducers({
  images: imageReducer,
  videos: videoReducer,
  activeTab: activeTabReducer,
  imageDescription: imageDescriptionReducer,
  videoDescription: videoDescriptionReducer,
  storyTitle: storyTitleReducer,
  storyDescription: storyDescriptionReducer,
  weblink: weblinkReducer,
  error: errorReducer,
  activeStep: activeStepReducer,
  weblinkLanguage: weblinkLanguageReducer,
  imageLanguage: imageLanguageReducer,
  videoLanguage: videoLanguageReducer,
  storyLanguage: storyLanguageReducer,
  uploadGenre: uploadGenreReducer,
  uploadLanguage: uploadLanguageReducer,
  imageUploadGenre: imageGenreReducer,
  videoUploadGenre: videoGenreReducer,
  storyUploadGenre: storyGenreReducer,
  linkUploadGenre: webLinkGenreReducer,
  videoUploadParcentage: videoUploadPercentageReducer,
  imageUploadPercentage: imageUploadPercentageReducer,
  weblinkUploadPercentage: weblinkUploadPercentageReducer,
  storyUploadPercentage: storyUploadPercentageReducer,
  videoMatureContent: videoMatureContentReducer,
  imageMatureContent: imageMatureContentReducer,
  weblinkMatureContent: weblinkMatureContentReducer,
  storyMatureContent: storyMatureContentReducer,
  contentDataList: contentDatalistReducer,
  contentFiles: contentFileReducer,
  contentPreview: contentPreviewReducer,
  addMoreSelected: addMoreContentReducer,
  uploadingFiles: uploadingFilesReducer,
  metaData: weblinkMetaDetailReducer,
  isLoading: loadingReducer,
  searchGenre: searchGenreReducer,
  moreSelected: moreSelectedReducer,
});

export { uploadReducer };
