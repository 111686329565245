import { ButtonBase, Grid } from "@material-ui/core";
import { get, kebabCase } from "lodash";
import React, { Component, Fragment } from "react";
import { LazyImage, Notifications } from "../../components";
import { ContentTypeId, NotificationType } from "../../constant";
import { loadImage } from "../../core";
import { Play, WebLink } from "../../icons";
import { AnimatedSlider } from "../../modules";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import ReactTimeAgo from 'react-time-ago'
import "./mobile-nofication.scss";

class MobileNotification extends Component {
  handleBackClick = () => {
    const { history } = this.props;
    !!history.length ? history.goBack() : history.push("/");
  };
  renderImages = (contentUrl) => {
    const { url, thumbnailImage } = contentUrl;
    return (
      <LazyImage
        containerStyle={{ height: "230px", width: "100%" }}
        style={{ height: "230px", width: "100%" }}
        alt={"alt"}
        src={url}
        thumbnail={thumbnailImage}
      />
    );
  };

  handleNotificationClick = (notification) => {
    const { notificationType } = notification;
    if (notificationType === NotificationType.CONTENT) {
      const { contentMain } = notification;
      const encryptedUrl = btoa(contentMain.id);
      window.open(`/content/${encryptedUrl.replace(/=/g, "")}`, "_self");
    } else if (notificationType === NotificationType.DISCOVER) {
      const { discoverData } = notification;
      window.open(`/discover/${kebabCase(discoverData.name)}`, "_self");
    }
  };
  handleOtherContentClick = () => { };

  renderNotification = (notification) => {
    const contentUrl = get(notification, "contentMain.contentUrl", []);
    const content = get(notification, "contentMain", {});
    const isMultipleImages = !!(contentUrl.length > 1);
    const userDetail = get(notification, "contentMain.userDetail", []);
    return (
      //onClick={() => this.handleNotificationClick(notification)}
      <ButtonBase
        className="notifbtn"
        onClick={() => {
          content.typeId === ContentTypeId.Images && contentUrl.length > 1
            ? this.handleOtherContentClick()
            : this.handleNotificationClick(notification);
        }}
      >
        <div className={isMultipleImages ? "notification-scroll" : "notification-tile"}>
          <div className="notification-list">
            {loadImage(userDetail.picUrl, userDetail.id, userDetail.name)}
            <label className="top-label">&ensp;{notification.title}</label>
            <small><ReactTimeAgo date={notification.createdDateTime} locale="en-US" /></small>
          </div>
          {/* <span className="title">{notification.title}</span> */}
          {/* <span className="time"> 1h </span> */}
          {content.typeId === ContentTypeId.Videos && (
            <Play width={"40px"} height={"40px"} className="play play-icon notify-play" />
          )}
          {isMultipleImages && (
            <AnimatedSlider>
              {contentUrl.map((content, index) => (
                <div className="slide" key={content.id || index}>
                  {this.renderImages(content)}
                </div>
              ))}
            </AnimatedSlider>
          )}
          {!!contentUrl.length && !isMultipleImages && (
            <div className="notification-image">
              <LazyImage
                containerStyle={{ height: "230px", width: "100%" }}
                style={{ height: "230px", width: "100%" }}
                alt={"alt"}
                src={contentUrl[0].url}
                thumbnail={contentUrl[0].thumbnailImage}
              />
            </div>
          )}
          {content.typeId === ContentTypeId.Weblink && (
            <div className="brand-link mobile-hidden desktop-hidden tab-hidden">
              <a target="_blank" rel="noopener noreferrer" href={contentUrl[0].url}>
                <WebLink width={"16px"} height={"16px"} className="web-link" />
              </a>
            </div>
          )}
        </div>
      </ButtonBase>
    );
  };
  render() {
    const { notifications, t } = this.props;
    return (
      <div className="tymoff-container mobile-notification">
        <div className="sub-header text-left tab-hidden" style={{ marginLeft: -13 }}>
          <button className="back-btn" onClick={this.handleBackClick}></button>
          <h1>{t("sidebar.title6")}</h1>
        </div>
        <Grid container spacing={0} className="m-0">
          <Grid item xs={12}>
            {/* {!!notifications.length &&
              notifications.map((notification, index) => (
                <Fragment key={index}>{this.renderNotification(notification)}</Fragment>
              ))} */}


            {!!notifications.length ? (
              notifications.map((notification, index) => (
                <Fragment key={index}>{this.renderNotification(notification, index)}</Fragment>
              ))
            ) : (
                <div className="notif-msg">
                  <div className="content">
                    <h5>{t("notifications.h5")}</h5>
                    <h6>{t("notifications.h6")}</h6>
                  </div>

                </div>
              )}

          </Grid>
        </Grid>
      </div>
    );
  }
}
export default compose(withTranslation())(MobileNotification)