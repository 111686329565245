import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as SeeMoreModal } from "./see-more-modal";
import { closeModal, areModalsOpenOnContentDetail, startScrollTimer } from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.see_more || false,
  isOtherModalOpen: areModalsOpenOnContentDetail(state),
  contentAlreadyVisited: state.content.contentAlreadyVisited,
  loadFromGrid: state.content.loadFromGrid,
  activeContent: state.content.activeContent,
  commentBox: state.ui.commentBox,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: (modal) => dispatch(closeModal(modal)),
  startScrollTimer: () => dispatch(startScrollTimer()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeeMoreModal));
