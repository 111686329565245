import activeTabReducer from "./active-tab";
import fullNameReducer from "./fullName";
import emailReducer from "./email";
import phoneReducer from "./phone";
import countryReducer from "./country";
import cityReducer from "./city";
import genderReducer from "./gender";
import { combineReducers } from "redux";

const preferenceReducer = combineReducers({
  activeTab: activeTabReducer,
  fullName: fullNameReducer,
  email: emailReducer,
  city: cityReducer,
  country: countryReducer,
  phone: phoneReducer,
  gender: genderReducer

});
export { preferenceReducer };
