import React, { Suspense } from "react";
import { render, hydrate } from "react-dom";
import Loadable from "react-loadable";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
//import de from 'javascript-time-ago/locale/de'
//import * as serviceWorker from "./serviceWorker";
import Root from "./pages/root/Root";
import { useSSR } from "react-i18next";
import "./i18";
TimeAgo.addDefaultLocale(en)
const root = document.querySelector("#root");

const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);
  return (
    // <Suspense fallback={<div>Still loading i18n...</div>}>
    <Suspense fallback={<div></div>}>
      <Root />
    </Suspense>
  );
};

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(<BaseApp />, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(<BaseApp />, root);
}

// ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
