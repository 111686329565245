import React, { PureComponent, Fragment, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faClock, faMoon, faEdit } from "@fortawesome/free-regular-svg-icons";
import { faBan, faEraser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Modal, Backdrop, Fade, Button } from "@material-ui/core";
import { ModalType, SettingMenu, ClearHistoryAlert, LogoutAlert } from "../../../../constant";
import { setGlobalThemeVariable } from "../../../../core";
import { SignInUser } from "../../../../icons";
import Grid from "@material-ui/core/Grid";
import "./preference-modal.scss";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { FcRadio, FcSelect, FcCustomSearch } from "../../fc";
//import { withTranslation } from "react-i18next";
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import i18n from "../../../../i18";
import preferenceModal from ".";

class PreferenceModal extends Component {
  state = {
    content: true,
    loading: false,
  };
  handleChange = (type) => {
    if (type === "content") {
      this.setState({ content: true });
    } else {
      this.setState({ content: false });
    }
  };

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.PREFERENCES);
  };

  handleSwitch = (selectedMenu) => {
    const {
      restrictedMode,
      darkTheme,
      enableRestrictedMode,
      disableRestrictedMode,
      enableDarkMode,
      disableDarkMode,
    } = this.props;
    if (selectedMenu === SettingMenu.RESTRICTED_MODE) restrictedMode ? disableRestrictedMode() : enableRestrictedMode();
    if (selectedMenu === SettingMenu.DARK_THEME) {
      setGlobalThemeVariable(darkTheme);
      darkTheme ? disableDarkMode() : enableDarkMode();
    }
  };

  setLanguage = (languages) => {
    const { setSiteLanguage } = this.props;
    localStorage.setItem("lang",languages)
    setSiteLanguage(languages);
    i18n.changeLanguage(languages);
  };
  openLoginModal = () => {
    const { openModal, closeModal } = this.props;
    closeModal(ModalType.PREFERENCES);
    openModal(ModalType.LOGIN);
  };
  handleClearHistory = () => {
    const { setAlertAction } = this.props;
    setAlertAction(ClearHistoryAlert);
  };
  handleCountryChange = (countryIds) => {
    const { setFilterCountry } = this.props;
    setFilterCountry(countryIds);
  };
  handleLanguageChange = (languageIds) => {
    const { setFilterLanguage } = this.props;
    setFilterLanguage(languageIds);
  };
  savePreferences = () => {
    this.setState({ loading: true });
    const {
      filter,
      setFilter,
      selectedCountries,
      selectedLanguages,
      closeModal,
      clearCountry,
      clearLanguage,
    } = this.props;
    setFilter({
      ...filter,
      discoverId: null,
      languagesList: selectedLanguages?selectedLanguages:"en",
      countriesList: selectedCountries,
    });
    clearCountry();
    clearLanguage();
    this.setState({ loading: false });
    closeModal(ModalType.PREFERENCES);
  };

  render() {
    const {
      t,
      modal,
      darkThememodal,
      restrictedMode,
      user,
      isLoggedIn,
      closeModal,
      darkTheme,
      contentScrollTime,
      picUrl,
      disableRestrictedMode,
      enableRestrictedMode,
      disableDarkMode,
      enableDarkMode,
      changeContentScrollTime,
      selectedSiteLanguage,
      clearHistory,
      selectedLanguages,
      selectedCountries,
      countries,
      languages,
      setFilter,
    } = this.props;
    console.log(languages)
    const lngs = localStorage.getItem("lang")
    const setLng = lngs?lngs:"en"
    console.log(lngs)
    const lng = [
      //{ label: "All Languages", value: "" },
      { label: "English", value: "en" },
      
      
    ];
    const theme = [
      { label: t("formOptions.label3"), value: true },
      { label: t("formOptions.label4"), value: false },
    ];
    const mode = [
      { label: t("formOptions.label1"), value: true },
      { label: t("formOptions.label2"), value: false },
    ];
    const scrlTimer = [
      { label: "0 "+t("formOptions.label5"), value: 0 },
      { label: "5 "+t("formOptions.label5"), value: 5 },
      { label: "10 "+t("formOptions.label5"), value: 10 },
      { label: "15 "+t("formOptions.label5"), value: 15 },
      { label: "20 "+t("formOptions.label5"), value: 20 },
      { label: "25 "+t("formOptions.label5"), value: 25 },
      { label: "30 "+t("formOptions.label5"), value: 30 },
      { label: "35 "+t("formOptions.label5"), value: 35 },
      { label: "40 "+t("formOptions.label5"), value: 40 },
      { label: "45 "+t("formOptions.label5"), value: 45 },
      { label: "50 "+t("formOptions.label5"), value: 50 },
      { label: "55 "+t("formOptions.label5"), value: 55 },
      { label: "60 "+t("formOptions.label5"), value: 60 },
    ];

    const filterCountries = countries.filter((c) => selectedCountries.includes(c.id));
    const filterLanguages = languages.filter((l) => selectedLanguages.includes(l.id));

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal backdrop-transparent"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper preference-modal">
            {/* <div className={`p-50 maxHeight ${this.state.content == false ? "maxHeight" : ""}`}> */}
            <div className="p-50 maxHeight">
              <div className="pref-modal-head">
                <Grid container spacing={0} className="py-10">
                  <Grid item xs={7}>
                    <div>
                      <h1 id="transition-modal-title" className="heading modalTitle font-bold">
                      {t("preferenceModal.h1")}
                      </h1>
                    </div>
                  </Grid>
                  <Grid item xs={5} className="pref-btn">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleChange("content")}
                      className={`mr-10 spl-btn ${this.state.content ? "active" : ""}`}
                    >
                      {t("preferenceModal.button1")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleChange("website")}
                      className={`mr-10 spl-btn ${!this.state.content ? "active" : ""}`}
                    >
                      {t("preferenceModal.button2")}
                    </Button>
                    {/*<Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleChange("language")}
                      className={`mr-10 spl-btn ${!this.state.content ? "active" : ""}`}
                    >
                      Language
                    </Button>*/}
                  </Grid>
                </Grid>
              </div>
              <div className="pref-modal-body">
                {!this.state.content ? (
                  <Formik
                    initialValues={{
                                       
                      language: languages.find((f) => (f.id ? f.languageCode ===(selectedSiteLanguage || lngs) : f.isSupported===true?f.languageCode ="en":null)),
                      darkTheme: darkTheme,
                      restrictedMode: restrictedMode,
                      timer: scrlTimer.find((f) => f.value === contentScrollTime) || "",
                    }}
                    
                    validationSchema={null}
                    onSubmit={(fields) => {
                      
                      fields.restrictedMode ? enableRestrictedMode() : disableRestrictedMode();
                      //console.log(fields.language.languageCode)
                      if (fields.darkTheme) {
                        enableDarkMode();
                        setGlobalThemeVariable(false);
                      } else {
                        disableDarkMode();
                        setGlobalThemeVariable(true);
                      }
                      if (fields.language) {
                        this.setLanguage(fields.language.languageCode);
                      }
                      else{
                        this.setLanguage("en")
                      }
                      if (fields.timer.value) {
                        changeContentScrollTime(fields.timer.value);
                      }
                      closeModal(ModalType.PREFERENCES);
                    }}
                    render={({ submitForm, isSubmitting, touched, errors }) => (
                      <Fragment>
                        <Grid container spacing={0} className="py-10 m-0">
                          <Grid item xs={6}>
                            <label className="bold">{t("preferenceModal.website.label1")}</label>
                            <Field
                              name="darkTheme"
                              component={FcRadio}
                              source={theme}
                              optionValue="value"
                              optionLabel="label"
                            ></Field>
                          </Grid>
                          <Grid item xs={6} className="pref-btn">
                            <Button className="history-btn" onClick={this.handleClearHistory}>
                            {t("preferenceModal.website.button1")}
                            </Button>
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={0} className="py-10 m-0">
                          <Grid item xs={5}>
                            <label className="bold">Language </label>
                            <Field
                              name="language"
                              component={FcCustomSearch}
                              source={lng}
                              optionValue="value"
                              optionLabel="label"
                            ></Field>
                          </Grid>
                          <Grid item xs={7}>
                            <div className="pref-block">
                              <span>Tymoff website & menus will be displayed in this language</span>
                            </div>
                          </Grid>
                        </Grid> */}
                        <Grid container spacing={0} className="py-10 m-0">
                          <Grid item xs={5}>
                            <label className="bold">{t("preferenceModal.website.label2")}</label>
                            <Field
                              name="restrictedMode"
                              component={FcRadio}
                              source={mode}
                              optionValue="value"
                              optionLabel="label"
                            ></Field>
                          </Grid>
                          <Grid item xs={7}>
                            <div className="pref-block">
                              <span>{t("preferenceModal.website.span0")}</span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0} className="py-10 m-0">
                          <Grid item xs={5}>
                            <label className="bold">{t("preferenceModal.website.label3")}</label>
                            <Field
                              name="timer"
                              component={FcCustomSearch}
                              source={scrlTimer}
                              optionValue="value"
                              optionLabel="label"
                            ></Field>
                          </Grid>
                          <Grid item xs={7}>
                            <div className="pref-block">
                              <span>{t("preferenceModal.website.span1")}</span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0} className="py-10 m-0">
                          <Grid item xs={5}>
                            <label className="bold">{t("preferenceModal.website.label4")}</label>
                            <Field
                              name="language"
                              component={FcCustomSearch}
                              source={languages}
                              optionValue="id"
                              optionLabel="nameUtf8"
                            ></Field>
                          </Grid>
                          <Grid item xs={7}>
                            <div className="pref-block">
                              <span>{t("preferenceModal.website.span2")}</span>
                            </div>
                          </Grid>
                        </Grid>


                        <Grid container justify="center" className="m-0">
                          <Grid item xs={6}>
                            <button className={"submitButton"} type="submit" onClick={submitForm}>
                              {!this.state.loading ? (
                                t("preferenceModal.button3")
                              ) : (
                                <div className="spinner">
                                  <div className="bounce1"></div>
                                  <div className="bounce2"></div>
                                  <div className="bounce3"></div>
                                </div>
                              )}
                            </button>
                          </Grid>
                        </Grid>
                      </Fragment>
                    )}
                  />
                ) : (
                  <Fragment>
                    <Grid container spacing={0} className="">
                      <Grid item xs={6} className="grd-frm">
                        <label className="bold">{t("preferenceModal.label1")}</label>
                        <FcCustomSearch
                          name="languages"
                          optionValue="id"
                          optionLabel="nameUtf8"
                          source={languages}
                          multiple={true}
                          val={filterLanguages}
                          onChangeSelect={this.handleLanguageChange}
                        ></FcCustomSearch>
                      </Grid>
                      
                      <Grid item xs={6} className="grd-frm">
                        <label className="bold">{t("preferenceModal.label2")}</label>
                        <FcCustomSearch
                          name="countries"
                          optionValue="id"
                          optionLabel="name"
                          source={countries}
                          val={filterCountries}
                          multiple={true}
                          onChangeSelect={this.handleCountryChange}
                        ></FcCustomSearch>
                      </Grid>
                    </Grid>
                    {/* <div className="error">
                      {errors.languages ? errors.languages : errors.countries ? errors.countries : null}
                    </div> */}
                    <Grid container justify="center" className="bottom-btn">
                      <Grid item xs={6}>
                        <button className={"submitButton"} onClick={this.savePreferences}>
                          {!this.state.loading ? (
                            t("preferenceModal.button3")
                          ) : (
                            <div className="spinner">
                              <div className="bounce1"></div>
                              <div className="bounce2"></div>
                              <div className="bounce3"></div>
                            </div>
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </div>
            </div>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="modalFooter">
                  {!isLoggedIn ? (
                    <p>
                      {t("preferenceModal.p1")}{" "}
                      <Link to="/#" onClick={this.openLoginModal} className="primary-color">
                      {t("preferenceModal.link")}{" "}
                      </Link>
                    </p>
                  ) : (
                    <p>{t("preferenceModal.p2")}</p>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    );
  }
}

//export default PreferenceModal;
export default compose(withTranslation())(PreferenceModal)