import { AUTH } from "../../actions";

const followingReducer = (state = [], action) => {
  switch (action.type) {
    case AUTH.LOAD_FOLLOWING:
    case AUTH.CLEAR:
      return [];
    case AUTH.SET_FOLLOWING:
      return action.data;
    default:
      return state;
  }
};

export default followingReducer;
