import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Report } from "../../../../icons";

class FcRadio extends Component {
  handleChange = (e) => {
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props;

    setFieldValue(name, this.normalizeBoolean(e.target.value));
  };
  normalizeBoolean = (value) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return value;
  };
  render() {
    const { field, source, optionValue, optionLabel, label, radioClass } = this.props;
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          row
          aria-label={field.name}
          name={field.name}
          onChange={this.handleChange}
          value={field.value}
          className="radioClass"
        >
          {source.map((res, index) => (
            <FormControlLabel
              key={`${index}`}
              value={res[optionValue]}
              label={res[optionLabel]}
              className={res?.color ? "profileColor " + res.color : null}
              control={<Radio color="primary" />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default FcRadio;
