import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { ModalType } from "../../../../constant";
import "./mature-content-modal.scss";
import { List, ListItem, ListItemText, Switch } from "@material-ui/core";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class MatureContentModal extends PureComponent {
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.MATURE_CONTENT);
  };

  handleSwitch = () => {
    const {
      restrictedMode,
      enableRestrictedMode,
      disableRestrictedMode,
    } = this.props;
    restrictedMode ? disableRestrictedMode() : enableRestrictedMode();
  };

  render() {
    const { modal, restrictedMode,t } = this.props;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper">
            <div className="modalContent">
              <div className="modalBody">
                <h6>{t("formOptions.mature.title1")}</h6>
                <p>
                  {t("formOptions.mature.title4")}
                 </p>
                <div className="blk">
                  <span>{t("formOptions.mature.title5")}</span>
                  {/* for red switch add class 'm-red'. */}
                  <Switch className="mature-switch" checked={restrictedMode} value={restrictedMode} onChange={this.handleSwitch} />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default compose(withTranslation())(MatureContentModal);
