import { CONTENT } from "../../actions";

const notFoundReducer = (state = null, action) => {
    switch (action.type) {

        case CONTENT.CONTENT_NOT_FOUND:
            return action.notFound;

        default:
            return state;
    }
};

export default notFoundReducer;
