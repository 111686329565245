import { CONTENT } from "../../actions";

const magicStickReducer = (state = false, action) => {
    switch (action.type) {
        case CONTENT.MAGIC_STICK_ENABLED:
            return true;
        case CONTENT.MAGIC_STICK_DISABLED:
            return false;
        default:
            return state;
    }
};

export default magicStickReducer;
