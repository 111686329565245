import React, { PureComponent, Fragment } from "react";
import { get } from "lodash";
import { LazyImage, VideoPlayer } from "../../../containers";
import "./weblink.scss";
import { WebLink, WebLink2 } from "../../../../icons";
import { getRandomColor, isValidYoutubeUrl } from "../../../../core";
import { Grid } from "@material-ui/core";

export default class Video extends PureComponent {
  getValidUrl = (url) => {
    if (!/^((http|https|ftp):\/\/)/.test(url)) {
      url = "http://" + url;
    }
    return url;
  };

  onStartVideo = () => {
    const { stopScrollTimer } = this.props;
    stopScrollTimer();
  };

  onEndedVideo = () => {
    const { startScrollTimer, commentBox, contentAlreadyVisited } = this.props;
    !commentBox && !contentAlreadyVisited && startScrollTimer();
  };

  onPlayVideo = () => {
    const { stopScrollTimer } = this.props;
    stopScrollTimer();
  };
  handleRedirect = (url) => {
    const validUrl = this.getValidUrl(url);
    window.open(validUrl, "_blank");
  };
  handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  renderImage = () => {
    const { activeContent } = this.props;
    const contentUrl = get(activeContent, "contentUrl", []);
    const color = getRandomColor(activeContent?.id);

    return (
      <div
        className={`link-box`}
        style={{ backgroundColor: color }}
        onClick={() => this.handleRedirect(contentUrl[0].url)}
      >
        <Grid container spacing={0} className="m-0">
          <Grid item xs={4} sm={4}>
            <LazyImage alt={"alt"} src={contentUrl[0].thumbnailImage} thumbnail={""} />
          </Grid>
          <Grid item xs={8} sm={8} style={{ paddingLeft: 15 }}>
            <h3 className="singleLine" >
              {activeContent.contentTitle}
            </h3>
            <h5
              className="site singleLine"
              style={{ cursor: "pointer" }}
            // onClick={() =>
            //   this.handleOpenUrl(
            //     "https://" +
            //     activeContent?.contentUrl[0]?.url?.replace("http://", "").replace("https://", "").split(/[/?#]/)[0]
            //   )
            // }
            >
              <WebLink2 className="link-icon" />&nbsp;&nbsp;
              {activeContent?.contentUrl[0]?.url?.replace("http://", "").replace("https://", "").split(/[/?#]/)[0]}
            </h5>
            {/* <h6
              style={{ cursor: "pointer" }}
              className="url"
              onClick={() => this.handleOpenUrl(activeContent?.contentUrl[0]?.url)}
            >
              {activeContent?.contentUrl[0]?.url}
            </h6>
            <span className="singleLine" style={{ marginTop: 15 }}>
              {activeContent.contentValue}
            </span> */}
          </Grid>
        </Grid>
      </div>
    );
  };
  render() {
    const { activeContent } = this.props;
    const contentUrl = get(activeContent, "contentUrl", []);
    const hasYoutubeVideo = contentUrl[0].url ? isValidYoutubeUrl(contentUrl[0].url) : false;
    return (
      <div className="image-text text-box-popup web-light">
        {!hasYoutubeVideo && this.renderImage()}
        {hasYoutubeVideo ? (
          <div className="iframe-container">
            {/* <iframe title="Youtube" className="embed-responsive-item" src={contentUrl[0].url.replace("watch?v=", "embed/")}></iframe> */}
            <VideoPlayer
              url={contentUrl[0].url.replace("watch?v=", "embed/")}
              onStart={this.onStartVideo}
              onEnded={this.onEndedVideo}
              onPlay={this.onPlayVideo}
              playing
              controls
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
            />
          </div>
        ) : (
            // <div className="lb-webcard" onClick={() => this.handleRedirect(contentUrl[0].url)}>
            <div className="lb-webcard">

              {/* <h3>{activeContent.contentTitle}</h3> */}
              <p>&nbsp;</p>
              <span>{activeContent.contentValue}</span>
              

              {/* <div className="web-gradient"></div> */}
              {/* <div className="web-icon">
              <a href={this.getValidUrl(contentUrl[0].url)} target="_blank" rel="noopener noreferrer">
                <WebLink width={"16px"} height={"16px"} className="web-link" />
                <span>Visit</span>
              </a>
            </div> */}
              {/* {contentList.length > 1 && <ContentArrow />} */}
            </div>
          )}
      </div>
    );
  }
}
