import React, { PureComponent, Fragment } from "react";
import { ClickAwayListener, Popper, Modal } from "@material-ui/core";
import { ModalType } from "../../../../constant";
import { isMobileOnly } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class ReportOther extends PureComponent {
  submitOtherReason = () => {
    const { reportContent, setReportContentId } = this.props;
    setReportContentId(-1);
    this.handleOnClose();
    reportContent();
  };
  handleOnClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.REPORT_OTHER);
    closeModal(ModalType.REPORT_OTHER_PROFILE);
  };
  handleReasonChange = (e) => {
    const { setReportContentText } = this.props;
    // this.setState({ reasonVal: e.target.value });
    setReportContentText(e.target.value);
  };
  render() {
    const { modal, reportReasons, error, anchorEl, open, t } = this.props;
    return (
      <Popper
        style={{ zIndex: 9999, background: "#FFFFFF", borderRadius: "4px" }}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        className="dialog-modal report-content-modal"
        onClose={this.handleOnClose}
        //TransitionComponent={Transition}
        aria-labelledby="report-content-title"
        aria-describedby="report-content-description"
        disableEnforceFocus
        disablePortal
      >
        <ClickAwayListener onClickAway={() => this.handleOnClose()}>
          <div className="modal-body account-report-modal">
            <form onSubmit={this.submitOtherReason}>
              <label className="f-1-2"> {t("report.label6")}</label>
              <input name="otherReasonModal" onChange={(event) => this.handleReasonChange(event)}></input>
              <br />
              {!isMobileOnly ? (
                <button type="submit" hidden></button>
              ) : (
                <button className={"submitButton small"} type="submit">
                  {t("report.label7")}
                </button>
              )}
            </form>
          </div>
        </ClickAwayListener>
      </Popper>
    );
  }
}
export default compose(withTranslation())(ReportOther);