import { CONTENT } from "../../actions";

const footerAdsReducer = (state = false, action) => {
   switch (action.type) {

      case CONTENT.SHOW_FOOTER_ADS:
         return true;
      case CONTENT.HIDE_FOOTER_ADS:
         return false;
      default:
         return state;
   }
};

export default footerAdsReducer;
