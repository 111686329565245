import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as Image } from "./image";
import { stopScrollTimer, enableFooterAds, disableFooterAds } from "../../../../store";

const mapStateToProps = state => ({ loadFromSession: state.content.loadFromSession, contentList: state.content.data, isFooterAds: state.content.footerAds });

const mapDispatchToProps = dispatch => ({ stopScrollTimer: () => dispatch(stopScrollTimer()) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Image));
