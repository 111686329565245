import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as PreferenceModal } from "./preference-modal";
import {
  closeModal,
  openModal,
  enableDarkMode,
  disableDarkMode,
  enableRestrictedMode,
  disableRestrictedMode,
  setAlertAction,
  setSiteLanguage,
  changeContentScrollTime,
  clearHistory,
  getSelectedLanguages,
  setFilterLanguage,
  getSelectedCountries,
  setFilterCountry,
  listSearchedCountries,
  listSearchedLanguages,
  setFilter,
  clearCountry,
  clearLanguage,
} from "../../../../store";

const mapStateToProps = (state) => ({
  modal: state.ui.modal.preferences || false,
  darkThememodal: state.ui.modal.dark_theme || false,
  darkTheme: state.setting.darkTheme,
  restrictedMode: state.setting.restrictedMode,
  user: state.auth.data,
  isLoggedIn: state.auth.isLoggedIn,
  contentScrollTime: state.setting.contentScrollTime,
  picUrl: state.auth.data.picUrl || "",
  selectedSiteLanguage: state.ui.siteLanguage || window.localStorage.i18nextLng,
  selectedLanguages: getSelectedLanguages(state),
  selectedCountries: getSelectedCountries(state),
  countries: listSearchedCountries(state),
  languages: listSearchedLanguages(state),
  filter: state.content.filter,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: (modal) => dispatch(closeModal(modal)),
  openModal: (modal) => dispatch(openModal(modal)),
  enableDarkMode: () => dispatch(enableDarkMode()),
  disableDarkMode: () => dispatch(disableDarkMode()),
  enableRestrictedMode: () => dispatch(enableRestrictedMode()),
  disableRestrictedMode: () => dispatch(disableRestrictedMode()),
  setAlertAction: (alert) => dispatch(setAlertAction(alert)),
  setSiteLanguage: (searchText) => dispatch(setSiteLanguage(searchText)),
  changeContentScrollTime: (scrollTime) => dispatch(changeContentScrollTime(scrollTime)),
  clearHistory: () => dispatch(clearHistory()),
  clearCountry: () => dispatch(clearCountry()),
  clearLanguage: () => dispatch(clearLanguage()),
  setFilterLanguage: (languageId) => dispatch(setFilterLanguage(languageId)),
  setFilterCountry: (countryId) => dispatch(setFilterCountry(countryId)),
  setFilter: (filter) => dispatch(setFilter(filter)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PreferenceModal));
