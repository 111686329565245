const StaticRoutes = [
  { to: "/contact", title: "staticRoutes.title1" },
  { to: "/privacy-policy", title: "staticRoutes.title2" },
  { to: "/terms-conditions", title: "staticRoutes.title3" },
];

const AuthorizedDiscoverRoute = [
  { to: "/login", title: "My Likes" },
  { to: "/login", title: "Bookmarks" },
  { to: "/login", title: "Downloads" },
];

export { StaticRoutes, AuthorizedDiscoverRoute };
