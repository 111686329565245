import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { ButtonBase } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { ContentAction, ModalType } from "../../../../constant";
import { getProfileImageUrl } from "../../../../core";
import { ProfileImage } from "../../../containers";
import { Grid, Button } from "@material-ui/core";
import "./following-modal.scss";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class FollowingModal extends PureComponent {
  constructor(props) {
    super(props);
    const { loadFollowing } = props;
    loadFollowing();
  }

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.FOLLOWINGS);
  };

  followUser = (id) => {
    const { setSelectedUserID, followUser: fllo } = this.props;
    setSelectedUserID(id);
    fllo();
  };

  unfollowUser = (id) => {
    const { setSelectedUserID, openModal } = this.props;
    setSelectedUserID(id);
    openModal(ModalType.UNFOLLOW_ALERT);
  };
  navigate = (userId) => {
    const {
      history,
      setAuthUserId,
      loadOtherUserProfile,
      clearUserId,
      clearContent,
      resetContentPage,
      setContentAction,
    } = this.props;
    if (userId) {
      clearContent();
      resetContentPage();
      clearUserId();
      setAuthUserId(userId);
      setContentAction(ContentAction.upload);
      loadOtherUserProfile();
      this.handleClose();
      history.push({
        pathname: `/account/${userId}`,
        state: { userId },
      });
    }
  };

  render() {
    const { modal, following, isLoading, isLoggedIn, loginUser, t } = this.props;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper followersModal">
            <button
              className="closeBtn mobile-hidden"
              onClick={this.handleClose}
            ></button>
            <button
              className="back-btn tab-hidden"
              onClick={this.handleClose}
            ></button>
            <div className="modalContent">
              <div className="modalHeader mobile-hidden">
                <h1 className="heading modalTitle" style={{ marginBottom: 0 }}>
                  {t("followerModal.button1")}
                </h1>
              </div>
              <div className="modalBody">
                <div className="follow-list">
                  {isLoading && (
                    <div className="shimmer-follower">
                      <div className="follow-item">
                        <div className="follow-name">
                          <div className="img"></div>
                          <span></span>
                        </div>
                        <div className="follow-btn"></div>
                      </div>
                      <div className="follow-item">
                        <div className="follow-name">
                          <div className="img"></div>
                          <span></span>
                        </div>
                        <div className="follow-btn"></div>
                      </div>
                      <div className="follow-item">
                        <div className="follow-name">
                          <div className="img"></div>
                          <span></span>
                        </div>
                        <div className="follow-btn"></div>
                      </div>
                      <div className="follow-item">
                        <div className="follow-name">
                          <div className="img"></div>
                          <span></span>
                        </div>
                        <div className="follow-btn"></div>
                      </div>
                    </div>
                  )}

                  {!isLoading && !!following.length ? (
                    following.map((user, i) => (
                      <div key={i} className="follow-item">
                        <div
                          className="follow-name singleLineName"
                          onClick={() => this.navigate(user.id)}
                        >
                          <img
                            src={getProfileImageUrl(
                              user.picUrl,
                              user.id,
                              user.name
                            )}
                            alt=""
                            onError={(e) => {
                              e.target.src = getProfileImageUrl(
                                "",
                                user.id,
                                user.name
                              );
                            }}
                          />
                          <span>{user.name}</span>
                        </div>
                        {user.id !== loginUser.id && (
                          <div className="follow-btn">
                            {user.isFollowingLoggedUser ? (
                              <Button
                                className="f-btn f-btn-dis"
                                onClick={() => this.unfollowUser(user.id)}
                              >
                                {t("followerModal.button1")}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                className="f-btn"
                                onClick={() => this.followUser(user.id)}
                              >
                                Follow
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    ))
                  ) : !isLoading ? (
                    <div className="txt-cntr">{t("followerModal.div2")}</div>
                  ) : null}
                </div>
              </div>
              {/* <div className="modalFooter mobile-hidden"></div> */}
            </div >
          </div >
        </Fade >
      </Modal >
    );
  }
}

export default compose(withTranslation())(FollowingModal);
