import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { BottomNavigation, BottomNavigationAction, MenuItem, List } from "@material-ui/core";
import { ModalType, SelectedText } from "../../../constant";
import "./mobile-navigation.scss";
import AddIcon from "@material-ui/icons/Add";
// import { Search } from "@material-ui/icons";
import { Home, Search, SearchSelected } from "../../../icons";

export default class MobileNavigation extends PureComponent {
  handleUnauthorizedRoute = () => {
    const { openModal } = this.props;
    if (isMobileOnly) document.body.style.position = "fixed";
    openModal(ModalType.LOGIN);
  };
  navigateTo = (url) => {
    const { history, isLoggedIn, openModal, showSelectedText } = this.props;
    if (isLoggedIn) {
      history.push(url);
    } else {
      openModal(ModalType.LOGIN);
      showSelectedText(SelectedText.UPLOAD_TEXT);
    }
  };
  handleCategory = () => {
    const { clearFilter } = this.props;
    clearFilter()
  }
  render() {
    const { isLoggedIn, history } = this.props;
    const { location } = history;
    return (
      <BottomNavigation className="footer-navigation">
        <BottomNavigationAction
          exact
          to={"/"}
          component={NavLink}
          value="Home"
          onClick={this.handleCategory}
          icon={<Home width={20} height={20} className="home-m" />}
        />
        <BottomNavigationAction
          className="mobileUpload"
          //component={NavLink}
          value="Upload"
          onClick={() => this.navigateTo("/upload")}
          icon={<AddIcon width={30} height={30} className="upload-m" />}
        />
        <BottomNavigationAction
          className={location.pathname.indexOf("/discover") > -1 ? "active" : ""}
          to={"/search"}
          component={NavLink}
          value="Discover"
          icon={
            location.pathname.indexOf("/search") > -1 ? (
              <SearchSelected width={"15px"} height={"15px"} className="discover-m" />
            ) : (
              <Search width={"15px"} height={"15px"} className="discover-m" />
            )
          }
        />

        {/* <BottomNavigationAction
          exact
          to={"/"}
          component={NavLink}
          label="Home"
          value="Home"
          icon={<HomeMobile width={20} height={20} className="home-m" />}
        />
        <BottomNavigationAction
          className={location.pathname.indexOf("/discover") > -1 ? "active" : ""}
          to={"/discover-category"}
          component={NavLink}
          label="Discover"
          value="Discover"
          icon={<DiscoverMobile width={20} height={20} className="discover-m" />}
        />
        {isLoggedIn ? (
          <BottomNavigationAction
            exact
            to={"/notification"}
            component={NavLink}
            label="Notifications"
            value="Notifications"
            icon={<Bell width={20} height={20} className="bell" />}
          />
        ) : (
          <BottomNavigationAction
            onClick={this.handleUnauthorizedRoute}
            label="Notifications"
            value="Notifications"
            icon={<Bell width={20} height={20} className="bell" />}
          />
        )}

        {isLoggedIn ? (
          <BottomNavigationAction
            exact
            to={"/account"}
            component={NavLink}
            label="Me"
            value="Me"
            icon={<SignInUser width={20} height={20} className="signin-user" />}
          />
        ) : (
          <BottomNavigationAction
            onClick={this.handleUnauthorizedRoute}
            label="Me"
            value="Me"
            icon={<SignInUser width={20} height={20} className="signin-user" />}
          />
        )} */}
      </BottomNavigation>
    );
  }
}
