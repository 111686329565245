import { PREFERENCE } from "../../actions";
import { PreferenceTab } from "../../../constant";

const activeTabReducer = (state = PreferenceTab.PROFILE, action) => {
  switch (action.type) {
    case PREFERENCE.SET_ACTIVE_TAB:
      return action.tab;
    default:
      return state;
  }
};

export default activeTabReducer;
