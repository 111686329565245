import React, { PureComponent } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { ModalType, UploadTab } from "../../../../constant";
import "./mature-content-modal.scss";
import { List, ListItem, ListItemText, Switch } from "@material-ui/core";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class UploadMatureContentModal extends PureComponent {
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal(ModalType.UPLOAD_MATURE_CONTENT);
  };

  handleSwitch = () => {
    const {
      activeTab,
      matureContent,
      enableVideoUploadMatureContent,
      disableVideoUploadMatureContent,
      enableImageUploadMatureContent,
      disableImageUploadMatureContent,
      enableStoryUploadMatureContent,
      disableStoryUploadMatureContent,
      enableWeblinkUploadMatureContent,
      disableWeblinkUploadMatureContent
    } = this.props;
    if (activeTab === UploadTab.IMAGE) matureContent ? disableImageUploadMatureContent() : enableImageUploadMatureContent();
    else if (activeTab === UploadTab.STORY) matureContent ? disableStoryUploadMatureContent() : enableStoryUploadMatureContent();
    else if (activeTab === UploadTab.VIDEO) matureContent ? disableVideoUploadMatureContent() : enableVideoUploadMatureContent();
    else if (activeTab === UploadTab.WEB) matureContent ? disableWeblinkUploadMatureContent() : enableWeblinkUploadMatureContent();
  };

  render() {
    const { modal, matureContent, t } = this.props;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={modal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className="paper">
            <div className="modalContent">
              <div className="modalBody">
                <h6>{t("formOptions.mature.title1")}</h6>
                <p>
                  {t("formOptions.mature.title2")}
                </p>
                <div className="blk">
                  <span>{t("formOptions.mature.title3")}</span>
                  {/* for red switch add class 'm-red'. */}
                  <Switch className="mature-switch" checked={matureContent} value={matureContent} onChange={this.handleSwitch} />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default compose(withTranslation())(UploadMatureContentModal);
