import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { MoreOption } from "../../../icons";
import { IconButton } from "@material-ui/core";
import { ModalType, ContentUserAction, ContentTypeId, ShareType, SelectedText } from "../../../constant";
import { getProfileImageUrl } from "../../../core";
import { ContentMoreOptions } from "../../presentational";

class ContentDetailHeader extends React.Component {
  state = {
    anchorEl: null,
  };
  constructor(props) {
    super(props);
  }

  handleMoreOptionClick = (event) => {
    const {
      openModal,
      modal,
      closeModal,
      loadShareLink,
      stopScrollTimer,
      startScrollTimer,
      loadFromGrid,
      contentAlreadyVisited,
      loadFromSession,
      setShareType,
      setAnchor,
    } = this.props;

    if (!loadFromSession) {
      if (modal) {
        loadFromGrid && !contentAlreadyVisited && startScrollTimer();
        this.setState({ anchorEl: null }, () => {
          closeModal(ModalType.MORE_OPTION);
        });
      } else {
        setShareType(ShareType.CONTENT);
        loadShareLink();
        loadFromGrid && stopScrollTimer();
        this.setState({ anchorEl: event.currentTarget }, () => {
          openModal(ModalType.MORE_OPTION);
        });
      }
    }
  };

  render() {
    const { genres, content, moreOptionClick } = this.props;
    //const genre = genres.find((gen) => String(gen.id) === String(content.catId));
    const displayName = content.userDetail.name ? content.userDetail.name : "";

    return (
      <Fragment>
        <Grid container spacing={0} className="topheader">
          <Grid item xs={10}>
            <img
              className="oval"
              src={getProfileImageUrl(content.userDetail.picUrl, content.userDetail.id, displayName)} alt="" onError={(e) => { e.target.src = getProfileImageUrl("", content.userDetail.id, displayName) }}
              style={{ marginLeft: 10 }}
            />
            <label className="top-label">&ensp;{displayName}</label>
          </Grid>
          <Grid item xs={2} className="right-align">
            <IconButton onClick={(event) => moreOptionClick(event, displayName)} className="more-icon">
              <MoreOption width={"14px"} height={"14px"} className="more-option-content-white" />
            </IconButton>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default ContentDetailHeader;
