import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as Search } from "./search";
import {
  loadSearch,
  setFilter,
  setCustomFilter,
  clearSearchResult,
  clearSearch,
} from "../../../store";
import {
  clearFilter,
  openModal,
  setContentAction,
  resetContentPage,
  clearContent,
  closeModal,
  setPreferenceActiveTab,
  setAuthUserID,
  loadOtherUserProfile,
  clearUserId,
  cancelLoadContent,
  loadFollowing,
  loadFollowers,
  followUser,
  unFollowUser,
  setSelectedUserID,
  loadUiData,
  hideNewfeed,
  cancelUserLoadContent,
  loadSearchUser,
} from "../../../store";

const mapStateToProps = (state) => ({
  searchResults: state.search.data.hints || [],
  searchHint: state.search.searchHint,
  isLoading: state.auth.isLoading,
  filter: state.content.filter,
  token: state.auth.token,
  modal: state.ui.modal.report_user_profile || false,
  userId: state.auth.userId,
  user: state.auth.userId ? state.auth.otherUser : state.auth.data,
  loginUser: state.auth.data,
  searchDataHint: state.search.data.hints,
  isFirstTimeLogin: state.auth.editUser.isFirstTimeLogin,
});

const mapDispatchToProps = (dispatch) => ({
  loadSearch: (searchHint) => dispatch(loadSearch(searchHint)),
  setFilter: (filter) => dispatch(setFilter(filter)),
  setCustomFilter: (filter) => dispatch(setCustomFilter(filter)),
  clearSearchResult: () => dispatch(clearSearchResult()),
  clearSearch: () => dispatch(clearSearch()),
  clearFilter: () => dispatch(clearFilter()),
  openModal: (modal) => dispatch(openModal(modal)),
  closeModal: (modal) => dispatch(closeModal(modal)),
  loadSearchUser: (data) => dispatch(loadSearchUser(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
