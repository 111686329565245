import React, { PureComponent, Fragment } from "react";
import {
  ButtonBase,
  Menu,
  MenuItem,
  CircularProgress,
  Popper,
  ClickAwayListener,
  Fade,
  Paper,
  ListItem,
} from "@material-ui/core";
import { isMobileOnly } from "react-device-detect";
import { ContentAction, ModalType, RemoveImageAlert } from "../../../constant";
import {
  http,
  headerOptions,
  fileHeaderOptions,
  metaHeaderOptions,
} from "../../../core";
//import Avatar from "avataaars";
import { URL } from "../../../api/_urls";
import { getProfileImageUrl } from "../../../core";
import { SignInUser } from "../../../icons";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import "./profile-image.scss";

class ProfileImage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
 
    };
  }

  
  handleProfileOptionClick = (event) => {
    const { openModal, editProfileModal, history, user, match } = this.props;

    editProfileModal
      ? openModal(ModalType.PROFILE_IMAGE_OPTION)
      : isMobileOnly
      ? !!user.picUrl
        ? history.push("/profile-image")
        : openModal(ModalType.EDIT_PROFILE)
      : match.params.id == undefined
      ? this.setState({ anchorEl: event.currentTarget, open: true })
      : this.setState({ anchorEl: event.currentTarget, open: false });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleViewImage = () => {
    const { openModal } = this.props;
    openModal(ModalType.VIEW_PROFILE_IMAGE);
    this.handleClose();
  };

  handleAvatarModal = () => {
    const { openModal } = this.props;
    openModal(ModalType.EDIT_AVATAR);
  };
  handleUploadProfileImage = (event) => {
    this.handleClose();
    const file = event.target.files[0];
    if (file) {
      const { updateProfileImage } = this.props;
      console.log(file);
      updateProfileImage(file);
    }
  };

  handleRemoveImage = () => {
    this.handleClose();
    const { setAlertAction } = this.props;
    setAlertAction(RemoveImageAlert);
  };

  handleTakeImage = () => {
    const { openModal } = this.props;
    openModal(ModalType.CAMERA);
    this.handleClose();
  };

  onError = (e) => {
    const { user } = this.props;
    e.target.src = getProfileImageUrl("", user.id, user.name);
  };
  handleVerifyClick = () => {
    const { openModal } = this.props;
    openModal(ModalType.VERIFICATION);
  };
  deleteVerifyClick = () => {
    http
      .delete(URL.GET_VERIFICATION_API(), headerOptions(this.props.token))
      .then((response) => {
        if (response.success) {
          console.log(response);
        }
      });
  };
  navigate = (url) => {
    const { history } = this.props;
    history.push(url);
  };
  render() {
    const {
      anchorEl,
      open,
     
    } = this.state;
    console.log(this.state);
    const { t, user, isImageLoading, history, match } = this.props;
    let path;
    if (history.location.pathname.match(/account/g)) {
      path = history.location.pathname.match(/account/g)[0];
    } else {
      path = history.location.pathname.match(/account/g);
    }

    return (
      <div className="profileImage">
        <ButtonBase
          aria-controls="profile_options"
          style={{
            height: "100%",
            width: "",
            marginBottom: 5,
            position: "relative",
          }}
          onClick={this.handleProfileOptionClick}
        >
          
           
            <img
              style={
                match.params.id !== undefined ? { cursor: "default" } : null
              }
              src={getProfileImageUrl(user.picUrl, user.id, user.name)}
              alt=""
              onError={this.onError}
            />
        

          {isImageLoading && <CircularProgress className="loader" />}
        </ButtonBase>
        {path !== "account" && !isMobileOnly && (
          <div>
            <label
              className="changePhotoProfile"
              onClick={this.handleProfileOptionClick}
            >
              {t("profile.imageChange")}
            </label>
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text history-btn"
              tabIndex="0"
              type="button"
            >
              <span
                className="MuiButton-label"
                //onClick={this.handleVerifyClick}
                onClick={() => this.navigate("/verify")}
              >
                {t("editProfile.label1")}
              </span>
            </button>
          </div>
        )}
        <Popper
          id="profile_options"
          className="profile-image-option"
          anchorEl={anchorEl}
          open={open}
          placement={"bottom-end"}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={this.handleClose}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  {/* {user.picUrl && <ListItem onClick={this.handleViewImage}>View Image</ListItem>} */}
                  {isMobileOnly && (
                    <ListItem onClick={this.handleTakeImage}>
                      {t("avatars.title1")}
                    </ListItem>
                  )}
                  <ListItem
                    onClick={() =>
                      this.uploadRefs ? this.uploadRefs.click() : {}
                    }
                  >
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      ref={(ref) => (this.uploadRefs = ref)}
                      style={{ display: "none" }}
                      onChange={this.handleUploadProfileImage}
                    />
                    {t("profile.imageUpload")}
                  </ListItem>
                  <ListItem onClick={this.handleAvatarModal}>
                    {t("avatars.title")}
                  </ListItem>
                  {user.picUrl && (
                    <ListItem onClick={this.handleRemoveImage}>
                      {t("profile.imageRemove")}
                    </ListItem>
                  )}
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </div>
    );
  }
}

export default compose(withTranslation())(ProfileImage);
