import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as Genre } from "./genre";
import { setFilter, getActiveFilterGenres, setGenre } from "../../../../store";

const mapStateToProps = (state) => ({
  filter: state.content.filter,
  selectedGenres: getActiveFilterGenres(state),
  genres: state.master.genres,
  authCategory: state.auth.data.tappedCategoryList,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filter) => dispatch(setFilter(filter)),
  setGenre: (genre) => dispatch(setGenre(genre)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Genre));
