import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

class FcCustomRadio extends Component {
  render() {
    const { name, value, source, optionValue, optionLabel, label, radioClass, onChange } = this.props;
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          row
          aria-label={name}
          name={name}
          onChange={onChange}
          value={value}
          className={radioClass ? radioClass : null}
        >
          {source.map((res, index) => (
            <FormControlLabel
              key={`${index}`}
              value={res[optionValue]}
              label={optionLabel ? res[optionLabel] :'Aa'}
              style={{ color: res.textColorHex, backgroundColor: res.backgroundHex }}
              className={"profileColor"}
              control={<Radio color="primary" />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default FcCustomRadio;
